/* eslint-disable import/no-mutable-exports */
import { createStore, applyMiddleware, compose, AnyAction } from 'redux'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import * as Sentry from '@sentry/react'
import { signin, signup, newSignup } from 'global/redux/features/auth/authSlice'
import { config } from 'global/lib/config'
import accessTokenMiddleware from 'global/redux/features/accessToken/accessTokenMiddleware'

let reduxStore: any

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware, thunk, accessTokenMiddleware]

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
  }
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer(action: AnyAction): AnyAction | null {
    if ([signin.toString(), signup.toString(), newSignup.toString()].includes(action.type)) {
      return null
    }
    return action
  }
})

export { reduxStore }

export default function initStore(appReducers: any, appSagas?: any) {
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && !config.isProd
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          compose
        })
      : compose
  /* eslint-enable no-underscore-dangle */

  reduxStore = createStore(appReducers, composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer))
  if (appSagas) {
    sagaMiddleware.run(appSagas)
  }

  return reduxStore
}
/* eslint-enable import/no-mutable-exports */
