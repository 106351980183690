import React, { useMemo, useCallback } from 'react'

import { Typography, Tooltip } from '@barracuda-internal/bds-core'
import { Critical as ErrorIcon } from '@barracuda-internal/bds-core/dist/Icons/Feedback'

import Grid from '@material-ui/core/Grid'

import { Indicator } from 'global/lib/analyzeAttack/analyzeAttack'
import Score, { ScoreSize } from 'global/components/lib/score/Score'
import styles from 'global/components/lib/dialogs/emailDetailsDialog/emailDetailsDialogStyles'
import { useFormatMessage } from 'global/lib/localization'
import AttackTypeLabel from 'global/components/lib/attackTypeLabel/AttackTypeLabel'
import StatusTypeLabel from 'global/components/lib/statusTypeLabel/StatusTypeLabel'
import { Analysis } from 'global/components/lib/dialogs/emailDetailsDialog/emailDetailDialogTypes'

export interface EmailAnalisysProps {
  analysis: Analysis
  etsAttackTypes?: boolean
}

const BASE_I18N_KEY = 'app.email_details_dialog'

const MULTIPLE_ROWS_ANALYSIS = [
  'blackmailing',
  'attachment_attack',
  'service_phishing',
  'service_phishing_with_keywords',
  'domain_impersonation',
  'conversation_hijacking'
]

export const EmailAnalysis: React.FC<EmailAnalisysProps> = ({ analysis, etsAttackTypes }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  const IndicatorText = useCallback(
    ({ indicator, secondRow = false }: { indicator: Indicator; secondRow?: boolean }) => {
      return (
        <Grid className={classes.indicatorWrapper} container direction="row">
          <Grid item xs={1}>
            <ErrorIcon className={classes.errorIcon} />
          </Grid>
          <Grid item xs={11}>
            <Typography className={classes.indicatorText} align="left">
              {formatMessage(`analysis_items.${indicator.id}${secondRow ? '_2' : ''}`, {
                ...indicator.meta,
                i: (text: string) => (
                  <data key={text} className={classes.indicatorItalic}>
                    {text}
                  </data>
                ),
                b: (text: string) => (
                  <data key={text} className={classes.indicatorBold}>
                    {text}
                  </data>
                ),
                if: (text: string) => {
                  return indicator?.meta?.domains ? text : null
                },
                br: (text: string) => (
                  <data key={text}>
                    <br />
                    {text}
                  </data>
                )
              })}
            </Typography>
          </Grid>
        </Grid>
      )
    },
    [classes, formatMessage]
  )

  return useMemo(() => {
    return (
      <Grid item className={classes.analysisSection}>
        <Grid container direction="column" id="analysis-section">
          <Typography className={classes.analysisTitle}>{formatMessage('analysis')}</Typography>

          {analysis && !analysis.isStatusEmpty && (
            <Grid container className={classes.analysisItem} direction="row" alignItems="flex-end">
              <Grid item xs={5}>
                <Typography className={classes.analysisItemLabel}>{formatMessage('action_taken')}</Typography>
              </Grid>
              <Grid item className={classes.attackType} xs>
                <StatusTypeLabel status={analysis?.statusId} />
              </Grid>
            </Grid>
          )}

          {analysis.severityScore && (
            <Grid container className={classes.analysisItem} direction="row" alignItems="flex-end">
              <Grid item xs={5}>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={formatMessage('severity_tooltip')}
                  placement="top-start"
                >
                  <Typography className={classes.analysisItemLabel}>{formatMessage('severity')}</Typography>
                </Tooltip>
              </Grid>
              <Grid item xs>
                <Score size={ScoreSize.normal} level={analysis.severityScore} />
              </Grid>
            </Grid>
          )}

          {analysis.confidenceScore && (
            <Grid container className={classes.analysisItem} direction="row" alignItems="flex-end">
              <Grid item xs={5}>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={formatMessage('confidence_tooltip')}
                  placement="top-start"
                >
                  <Typography className={classes.analysisItemLabel}>{formatMessage('confidence')}</Typography>
                </Tooltip>
              </Grid>
              <Grid item xs>
                <Score size={ScoreSize.normal} level={analysis.confidenceScore} />
              </Grid>
            </Grid>
          )}

          {analysis.attackType && (
            <Grid container className={classes.analysisItem} direction="row" alignItems="flex-end">
              <Grid item xs={5}>
                <Typography className={classes.analysisItemLabel}>{formatMessage('determination')}</Typography>
              </Grid>
              <Grid item className={classes.attackType} xs>
                <AttackTypeLabel attackType={analysis.attackType} etsAttackTypes={etsAttackTypes} />
              </Grid>
            </Grid>
          )}

          <Typography className={classes.analysisSubtitle}>{formatMessage('key_indicators')}</Typography>

          {analysis.indicators.map((indicator: Indicator) => (
            <data key={indicator.id}>
              <IndicatorText indicator={indicator} />
              {MULTIPLE_ROWS_ANALYSIS.includes(indicator.id) && <IndicatorText indicator={indicator} secondRow />}
            </data>
          ))}
        </Grid>
      </Grid>
    )
  }, [analysis, classes, etsAttackTypes, formatMessage, IndicatorText])
}

export default EmailAnalysis
