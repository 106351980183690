import React from 'react'
import PropTypes from 'prop-types'

import styles from 'global/components/lib/layout/navbar/navbarStyles'

export interface NavbarLogoProps {
  src: string
}

const NavbarLogo: React.FC<NavbarLogoProps> = ({ src }) => {
  const classes = styles()

  return <img alt="Barracuda Product logo" className={classes.navbarProductLogo} src={src} />
}

NavbarLogo.propTypes = {
  src: PropTypes.string.isRequired
}

export default NavbarLogo
