import { useMemo, useCallback, useState, useEffect, useReducer } from 'react'

import { process } from '@progress/kendo-data-query'
import { BDSGridPagerConfig, BDSGridSortConfig } from 'global/types/dataTables/dataTables'

import { useEffectOnInit } from 'global/lib/useCustomEffect'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import { getErrorMessage, isPending } from 'global/redux/toolkit/api'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import { ExportToCsvButtonProps } from 'global/components/lib/exportToCsvButton/ExportToCsvButton'
import { formatDate } from 'global/lib/datetime'
import { Alert, ModifiedAlert } from 'global/types/api/ato'
import getAtoAlertStatus, { AtoAlertStatus } from 'global/lib/getAtoAlertStatus/getAtoAlertStatus'
import { StatusIds } from 'global/components/lib/statusTypeLabel/StatusIds.enum'
import useTablePeriodicCheck from 'global/lib/useTablePeriodicCheck/useTablePeriodicCheck'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { AlertDialogProps } from 'sen/components/lib/dialogs/alertDialog/AlertDialog'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import apiRoutes from 'sen/lib/api/apiRoutes'
import {
  update as updateAlertsTable,
  reset as resetAlertsTable
} from 'sen/redux/features/dataTables/alerts/alertsSlice'
import { getAlerts, resetAlerts, setCompromisedAccount, resetResetPassword } from 'sen/redux/features/ato/atoSlice'

export type InProgress = boolean
export type ToggleDialog = () => void

export interface TableConfig {
  isLoaded: boolean
  tableData: {
    total: number
    data: ModifiedAlert[]
  }
  pageConfig: BDSGridPagerConfig
  sortConfig: BDSGridSortConfig | {}
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
  exportToCsvConfig: ExportToCsvButtonProps
  onOpenDetails: (alert: Alert, isReviewed?: boolean) => void
  isFlexibleTable: boolean
}
export interface AlertDialogConfig {
  open: boolean
  onClose: () => void
  alert: AlertDialogProps['alert'] | undefined
}

export interface CreateIncidentDialogConfig {
  open: boolean
  onOpen: (alert: Alert) => void
  onClose: ToggleDialog
}

export interface ResetPasswordDialogConfig {
  open: boolean
  onClose: ToggleDialog
}

export interface State {
  isResetPasswordOpened: boolean
}

export type Error = string | undefined

export default function useAlertsLogic(): [
  InProgress,
  TableConfig,
  AlertDialogConfig,
  CreateIncidentDialogConfig,
  ResetPasswordDialogConfig,
  Error
] {
  const dispatch = useAppDispatch()
  const {
    accessTokenId,
    alertsTable,
    alerts,
    loadedAlertOffsets,
    inProgress,
    isLoaded,
    isNewPassword,
    error
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken?.accessToken?.id || '',
    alertsTable: _stores.dataTables.alerts,
    alerts: _stores.ato.alerts,
    loadedAlertOffsets: _stores.ato.loadedAlertOffsets,
    inProgress: isPending(_stores.ato.getAlertsApiStatus),
    isLoaded: !!_stores.ato.alerts?.accessTokenId,
    isNewPassword: !!_stores.ato.resetPassword,
    error: getErrorMessage(_stores.ato.getAlertsApiStatus)
  }))
  const [state, setState] = useReducer((_state: State, newState: Partial<State>) => ({ ..._state, ...newState }), {
    isResetPasswordOpened: false
  })
  const [initTableRefresh] = useTablePeriodicCheck()
  const [isAlertDialogOpened, toggleAlertDialog] = useDialogLogic()
  const [isCreateIncidentDialogOpened, toggleCreateIncidentDialog] = useDialogLogic()
  const [isResetPasswordDialogOpened, toggleResetPasswordDialog] = useDialogLogic()
  const [selectedAlert, setSelectedAlert] = useState<AlertDialogConfig['alert']>()

  // init
  useEffectOnInit(() => {
    initTableRefresh(tableRefresh)

    dispatch(getAlerts())

    return () => {
      dispatch(resetAlerts(true))
      dispatch(resetAlertsTable())
    }
  }, [])

  const resetTable = useCallback(() => {
    dispatch(resetAlerts())
    dispatch(getAlerts())
  }, [dispatch])

  const tableRefresh = useCallback(() => {
    if (!inProgress) {
      resetTable()
    }
  }, [resetTable, inProgress])

  const updateTableData = useCallback(
    (changes: any = {}) => {
      const { selectedTab, ...updatedValues } = changes

      dispatch(
        updateAlertsTable({
          ...updatedValues,
          skip: 0
        })
      )

      initTableRefresh(tableRefresh)
      resetTable()
    },
    [dispatch, resetTable, initTableRefresh, tableRefresh]
  )

  const getStatusId = useCallback((alert: Alert) => {
    const atoAlertStatus = getAtoAlertStatus(alert)

    switch (atoAlertStatus) {
      case AtoAlertStatus.Info:
        return StatusIds.notReviewed
      case AtoAlertStatus.Reviewed:
        return StatusIds.reviewed
      case AtoAlertStatus.Fp:
        return StatusIds.markedAsFp
      case AtoAlertStatus.Expired:
        return StatusIds.olderThank30days
      default:
        return StatusIds.emptyStatus
    }
  }, [])

  const tableData = useMemo(() => {
    const { skip, take } = alertsTable

    const { data } = process(
      (alerts?.report?.data || []).map((report: Alert) => ({
        ...(report && {
          ...report,
          formattedDate: formatDate(report.createdDay || ''),
          atoAlertStatus: getAtoAlertStatus(report),
          alertStatus: getStatusId(report)
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.displayName),
      total: alerts?.report?.totalCount || 0
    }
  }, [alerts, alertsTable, getStatusId])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = alertsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateAlertsTable(e.page))

        if (!loadedAlertOffsets?.includes(e.page.skip)) {
          dispatch(getAlerts())
        }
      }
    }
  }, [alertsTable, tableData, dispatch, loadedAlertOffsets])

  const sortConfig: BDSGridSortConfig | {} = useMemo(() => {
    if (!tableData.total) {
      return {}
    }

    return {
      sortable: !inProgress && {
        allowUnsort: false,
        mode: 'single'
      },
      sort: alertsTable.sort,
      onSortChange: (e: any) => {
        updateTableData({ sort: e.sort })
      }
    }
  }, [alertsTable, updateTableData, tableData.total, inProgress])

  const exportToCsvConfig: ExportToCsvButtonProps = useMemo(() => {
    return {
      getExportPath: sessionId => {
        return apiRoutes.EXPORT_ALERTS_AS_CSV.path({
          accessTokenId,
          sessionId
        })
      },
      exportName: analyticsLib.EVENTS.ATO_EXPORT_AS_CSV_ALERTS,
      analyticsParams: {
        accessTokenId
      },
      totalCount: alerts?.report?.totalCount || 0
    }
  }, [accessTokenId, alerts])

  const onOpenDetails = useCallback(
    (alert: any, isReviewed = true) => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.ATO_OPEN_ALERT_DIALOG, {
        accessTokenId,
        alert: alert.id
      })
      setSelectedAlert({ alert, isReviewed })
      toggleAlertDialog()
    },
    [accessTokenId, toggleAlertDialog]
  )

  const onCloseAlertDialog = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.ATO_CLOSE_ALERT_DIALOG, {
      accessTokenId,
      alert: selectedAlert?.alert.id
    })
    setSelectedAlert(undefined)
    toggleAlertDialog()
  }, [accessTokenId, selectedAlert, toggleAlertDialog])

  const onOpenCreateIncidentDialog = useCallback(
    (alert: any) => {
      dispatch(setCompromisedAccount(alert))
      toggleCreateIncidentDialog()
    },
    [toggleCreateIncidentDialog, dispatch]
  )

  const onCloseCreateIncidentDialog = useCallback(() => {
    toggleCreateIncidentDialog()
  }, [toggleCreateIncidentDialog])

  const onCloseResetPasswordDialog = useCallback(() => {
    dispatch(resetResetPassword())
    setState({ isResetPasswordOpened: false })
    toggleResetPasswordDialog()
  }, [toggleResetPasswordDialog, dispatch])

  useEffect(() => {
    if (isNewPassword && !state.isResetPasswordOpened) {
      setState({ isResetPasswordOpened: true })
      // close create incident dialogs
      toggleCreateIncidentDialog()
      // open reset password dialog with new password
      toggleResetPasswordDialog()
    }
  }, [isNewPassword, toggleCreateIncidentDialog, toggleResetPasswordDialog, state.isResetPasswordOpened])

  return useMemo(() => {
    return [
      inProgress,
      {
        isLoaded,
        tableData,
        pageConfig,
        sortConfig,
        columns: alertsTable.GRID_COLUMNS,
        columnsConfig: alertsTable.columnsConfig,
        exportToCsvConfig,
        onOpenDetails,
        isFlexibleTable: tableData.total < alertsTable.ITEMS_PER_PAGE
      },
      {
        open: isAlertDialogOpened,
        onClose: onCloseAlertDialog,
        alert: selectedAlert
      },
      {
        open: isCreateIncidentDialogOpened,
        onOpen: onOpenCreateIncidentDialog,
        onClose: onCloseCreateIncidentDialog
      },
      {
        open: isResetPasswordDialogOpened,
        onClose: onCloseResetPasswordDialog
      },
      error
    ]
  }, [
    inProgress,
    alertsTable,
    tableData,
    pageConfig,
    sortConfig,
    exportToCsvConfig,
    onOpenDetails,
    isLoaded,
    isAlertDialogOpened,
    selectedAlert,
    onCloseAlertDialog,
    isCreateIncidentDialogOpened,
    isResetPasswordDialogOpened,
    onCloseCreateIncidentDialog,
    onCloseResetPasswordDialog,
    onOpenCreateIncidentDialog,
    error
  ])
}
