import React from 'react'

import useSignupLogic from 'sen/components/pages/signup/signinSignup/useSigninSignupLogic'

const Signup: React.FC = () => {
  useSignupLogic()

  return null
}

export default Signup
