import { makeStyles } from '@material-ui/core/styles'

import dialogStyles from 'ets/configs/styles/dialogStyles'

const DIALOG_WIDTH = 800

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)

  return {
    ...defaultDialogStyles,

    freeTrialButton: {
      height: 40,
      width: 178
    },
    paper: {
      minWidth: DIALOG_WIDTH,
      height: 476
    },
    withAlert: {
      minWidth: DIALOG_WIDTH,
      height: 524
    },
    text: {
      ...defaultDialogStyles.text
    },
    primaryButton: {
      height: 40,
      width: 110
    },
    secondaryButton: {
      height: 40,
      width: 59
    },
    buttonProgress: {
      color: 'white',
      position: 'absolute',
      right: '8%'
    },
    buttonWrapper: {
      paddingRight: 0
    },
    hideMastheadButton: {
      display: 'none'
    }
  }
})
