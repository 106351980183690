import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { List, ListItem, Tooltip, Typography } from '@barracuda-internal/bds-core'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { useFormatMessage } from 'global/lib/localization'
// TODO: add/import icons from '@barracuda-internal/bds-core/icons'
import IMAGES from 'global/configs/theme/images.config'
import { UnifiedReportingReportType } from 'global/types/api/unifiedReporting'
import { SideMenuProps } from 'global/components/lib/sideMenu/SideMenu'
import {
  pinUnifiedReportingReport,
  resetReportData
} from 'global/redux/features/unifiedReporting/unifiedReportingSlice'

import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import routesConfig from 'fir/lib/routes/routesConfig'

import styles from './unifiedReportingStyles'

const BASE_UR_I18N_KEY = 'app.unified_reporting'
const MAX_REPORT_NAME_LENGTH = 40

// TODO: remove isNewLayout prop
export default function useSideMenuLogic(): SideMenuProps[] {
  const classes = styles()
  const params = useParams()
  const dispatch = useAppDispatch()

  const formatMessage = useFormatMessage(BASE_UR_I18N_KEY)

  const [sideNavSearchValue, setSideNavSearchValue] = useState<string>('')

  const { accessToken, pinnedReportUuid, reports } = useAppSelector(_stores => ({
    accessToken: _stores.accessToken.accessToken?.id || '',
    pinnedReportUuid: _stores.unifiedReporting.pinnedReportUuid,
    reports: _stores.unifiedReporting.reports
  }))

  const handleSearch = useCallback((value: string) => {
    setSideNavSearchValue(value)
  }, [])

  const barracudaReports = useMemo(() => {
    return reports
      .filter((r: { name: string; type: UnifiedReportingReportType }) => {
        return (
          r.name.toLowerCase().includes(sideNavSearchValue.toLowerCase()) &&
          r.type === UnifiedReportingReportType.BARRACUDA
        )
      })
      .sort((a: { uuid: any }) => (a.uuid === pinnedReportUuid ? 1 : 0))
  }, [pinnedReportUuid, reports, sideNavSearchValue])

  const myReports = useMemo(() => {
    return reports
      .filter((r: { name: string; type: UnifiedReportingReportType }) => {
        return (
          r.name.toLowerCase().includes(sideNavSearchValue.toLowerCase()) &&
          r.type === UnifiedReportingReportType.CUSTOM
        )
      })
      .sort((a: { uuid: any }) => (a.uuid === pinnedReportUuid ? 1 : 0))
  }, [pinnedReportUuid, reports, sideNavSearchValue])

  const handlePinReport = useCallback(
    (reportUuid: string, e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()

      dispatch(
        pinUnifiedReportingReport({
          reportUuid
        })
      )
    },
    [dispatch]
  )

  const dropdownItems = useMemo(
    () => [
      {
        id: 'my-reports',
        label: formatMessage('sidemenu.my_reports'),
        count: myReports.length,
        content: (
          <List component="div" disablePadding>
            {myReports.map(myReport => (
              <ListItem
                className={`${classes.listItem} ${classes.listItemSecondary}`}
                key={myReport.uuid}
                button
                onClick={() => {
                  routesConfig.REPORT_LIST_REPORT.goto({
                    reportId: myReport.uuid
                  })

                  if (myReport.uuid !== params.reportId) {
                    analyticsLib.trackAppEvent(analyticsLib.EVENTS.UNIFIED_REPORTING_REPORT_VIEW, {
                      accessTokenId: accessToken,
                      reportDateRangeEnd: myReport.config.absoluteDateRangeEnd,
                      reportDateRangeStart: myReport.config.absoluteDateRangeStart,
                      reportFilters: myReport.config.filters,
                      reportId: myReport.uuid,
                      reportName: myReport.name,
                      reportRelativeDateRange: myReport.config.relativeDateRange,
                      reportSort: myReport.config.sortBy,
                      reportType: myReport.type
                    })

                    dispatch(resetReportData())
                  }
                }}
                selected={myReport.uuid === params.reportId}
              >
                <Tooltip
                  title={myReport.name.length > MAX_REPORT_NAME_LENGTH ? myReport.name : ''}
                  placement="bottom"
                  interactive
                >
                  <Typography className={classes.listItemText} variant="body2">
                    {myReport.name.length > MAX_REPORT_NAME_LENGTH
                      ? `${myReport.name.slice(0, MAX_REPORT_NAME_LENGTH)}...`
                      : myReport.name}
                  </Typography>
                </Tooltip>

                {myReport.uuid === pinnedReportUuid ? (
                  <img alt="Push Pin" src={myReport.uuid === params.reportId ? IMAGES.pushPin : IMAGES.pushPinGrey} />
                ) : (
                  // TODO: change this image to an icon button
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                  <img
                    alt="Push Pin"
                    className={classes.pushPin}
                    onClick={e => handlePinReport(myReport.uuid, e)}
                    src={IMAGES.pushPinGrey}
                  />
                )}
              </ListItem>
            ))}
          </List>
        )
      },
      {
        id: 'barracuda-reports',
        label: formatMessage('sidemenu.barracuda_reports'),
        count: barracudaReports.length,
        content: (
          <List component="div" disablePadding>
            {barracudaReports.map(barracudaReport => (
              <ListItem
                key={barracudaReport.uuid}
                button
                onClick={() => {
                  routesConfig.REPORT_LIST_REPORT.goto({
                    reportId: barracudaReport.uuid
                  })

                  if (barracudaReport.uuid !== params.reportId) {
                    analyticsLib.trackAppEvent(analyticsLib.EVENTS.UNIFIED_REPORTING_REPORT_VIEW, {
                      accessTokenId: accessToken,
                      reportDateRangeEnd: barracudaReport.config.absoluteDateRangeEnd,
                      reportDateRangeStart: barracudaReport.config.absoluteDateRangeStart,
                      reportFilters: barracudaReport.config.filters,
                      reportId: barracudaReport.uuid,
                      reportName: barracudaReport.name,
                      reportRelativeDateRange: barracudaReport.config.relativeDateRange,
                      reportSort: barracudaReport.config.sortBy,
                      reportType: barracudaReport.type
                    })

                    dispatch(resetReportData())
                  }
                }}
                className={`${classes.listItem} ${classes.listItemSecondary}`}
                selected={barracudaReport.uuid === params.reportId}
              >
                <Tooltip
                  title={barracudaReport.name.length > MAX_REPORT_NAME_LENGTH ? barracudaReport.name : ''}
                  placement="bottom"
                  interactive
                >
                  <Typography className={classes.listItemText} variant="body2">
                    {barracudaReport.name.length > MAX_REPORT_NAME_LENGTH
                      ? `${barracudaReport.name.slice(0, MAX_REPORT_NAME_LENGTH)}}...`
                      : barracudaReport.name}
                  </Typography>
                </Tooltip>
                {barracudaReport.uuid === pinnedReportUuid ? (
                  <img
                    alt="Push Pin"
                    src={barracudaReport.uuid === params.reportId ? IMAGES.pushPin : IMAGES.pushPinGrey}
                  />
                ) : (
                  // TODO: change this image to an icon button
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                  <img
                    alt="Push Pin"
                    className={classes.pushPin}
                    onClick={e => handlePinReport(barracudaReport.uuid, e)}
                    src={IMAGES.pushPinGrey}
                  />
                )}
              </ListItem>
            ))}
          </List>
        )
      }
    ],
    [
      accessToken,
      barracudaReports,
      classes.listItem,
      classes.listItemSecondary,
      classes.listItemText,
      classes.pushPin,
      dispatch,
      formatMessage,
      handlePinReport,
      myReports,
      params.reportId,
      pinnedReportUuid
    ]
  )

  return useMemo(
    () => [
      {
        dropdownItems,
        onSearchChange: handleSearch,
        searchPlaceholder: formatMessage('sidemenu.search_placeholder')
      }
    ],
    [dropdownItems, handleSearch, formatMessage]
  )
}
