import React, { useMemo, useEffect, useCallback, useState } from 'react'
import { DateTime, Duration } from 'luxon'

import colors from '@barracuda-internal/bds-core/dist/styles/colors'
import CircularProgress from '@barracuda-internal/bds-core/dist/Progress'
import EmptyStateIcon from '@barracuda-internal/bds-core/dist/Icons/Core/EmptyState'
import { ChartOptions } from 'chart.js'
import { BarChartOptions, LineChartOptions } from '@barracuda-internal/bds-core'

import {
  ChartConfig,
  chartDimension,
  ChartPeriod,
  ChartType,
  formatLabels,
  getChartBackgroundColor,
  HORIZONTAL_CHARTS,
  MultiSeriesDataChartConfig,
  SingleSeriesDataChartConfig
} from 'global/components/lib/chartsV2/ChartConfig'
import { ChartWrapperProps } from 'global/components/lib/chartsV2/chartWrapper/ChartWrapper'
import { useFormatMessage } from 'global/lib/localization'
import { update as updateUnifiedReporting } from 'global/redux/features/unifiedReporting/unifiedReportingSlice'
import { isPending, isSuccess, isFailed } from 'global/redux/toolkit/api'
import { RelativeDateRange } from 'global/types/api/unifiedReporting'
import { truncate } from 'global/lib/truncateMiddle/truncate'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'

const BASE_UR_I18N_KEY = 'app.unified_reporting'

export default function useUnifiedReportingChartLogic(): [ChartWrapperProps] {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_UR_I18N_KEY)

  const {
    absoluteDateRangeEnd,
    absoluteDateRangeStart,
    chart,
    chartData,
    getReportDataAPIStatus,
    isSingleColumnGrouped,
    isMultiSeriesData,
    relativeDateRange,
    report,
    tableDataTotalCount
  } = useAppSelector(_stores => ({
    absoluteDateRangeEnd: _stores.dataTables.unifiedReporting.absoluteDateRangeEnd,
    absoluteDateRangeStart: _stores.dataTables.unifiedReporting.absoluteDateRangeStart,
    chart: _stores.unifiedReporting.reportData.chart,
    chartData: _stores.unifiedReporting.reportData.chart.data,
    getReportDataAPIStatus: _stores.unifiedReporting.getUnifiedReportingReportDataApiStatus,
    isSingleColumnGrouped:
      _stores.unifiedReporting.reportData.chart.isGrouped &&
      _stores.unifiedReporting.reportData.chart.fieldNames.length === 1,
    isMultiSeriesData:
      _stores.unifiedReporting.reportData.chart.isGrouped &&
      _stores.unifiedReporting.reportData.chart.fieldNames.length > 1,
    relativeDateRange: _stores.dataTables.unifiedReporting.relativeDateRange,
    report: _stores.unifiedReporting.report,
    tableDataTotalCount: _stores.unifiedReporting.reportData.table.totalCount
  }))

  const [chartPeriodOptions, setChartPeriodOptions] = useState([ChartPeriod.daily])

  useEffect(() => {
    let updatedChartPeriodOptions = [ChartPeriod.daily]
    // If either the absoluteDateRangeEnd or absoluteDateRangeStart is null, update chartPeriodOptions based on relativeDateRange
    if (!absoluteDateRangeEnd || !absoluteDateRangeStart) {
      switch (relativeDateRange) {
        case RelativeDateRange.LAST_24_HOURS:
          updatedChartPeriodOptions = [ChartPeriod.hourly]
          break
        case RelativeDateRange.LAST_3_DAYS:
          updatedChartPeriodOptions = [ChartPeriod.hourly, ChartPeriod.daily]
          break
        case RelativeDateRange.LAST_7_DAYS:
          updatedChartPeriodOptions = [ChartPeriod.daily]
          break
        case RelativeDateRange.LAST_30_DAYS:
          updatedChartPeriodOptions = [ChartPeriod.daily, ChartPeriod.weekly]
          break
        case RelativeDateRange.LAST_90_DAYS:
          updatedChartPeriodOptions = [ChartPeriod.daily, ChartPeriod.weekly, ChartPeriod.monthly]
          break
        case RelativeDateRange.LAST_6_MONTHS:
          updatedChartPeriodOptions = [ChartPeriod.weekly, ChartPeriod.monthly]
          break
        default:
          updatedChartPeriodOptions = [ChartPeriod.weekly, ChartPeriod.monthly]
      }
    }

    // If absolute start and end are not null, update chartPeriodOptions based on the durations
    if (absoluteDateRangeEnd && absoluteDateRangeStart) {
      const dateRangeEnd = DateTime.fromISO(absoluteDateRangeEnd)
      const dateRangestart = DateTime.fromISO(absoluteDateRangeStart)
      const diff = dateRangeEnd.diff(dateRangestart).milliseconds

      switch (true) {
        case diff < Duration.fromObject({ days: 2 }).as('milliseconds'):
          updatedChartPeriodOptions = [ChartPeriod.hourly]
          break
        case diff < Duration.fromObject({ days: 4 }).as('milliseconds'):
          updatedChartPeriodOptions = [ChartPeriod.hourly, ChartPeriod.daily]
          break
        case diff < Duration.fromObject({ days: 8 }).as('milliseconds'):
          updatedChartPeriodOptions = [ChartPeriod.daily]
          break
        case diff < Duration.fromObject({ days: 31 }).as('milliseconds'):
          updatedChartPeriodOptions = [ChartPeriod.daily, ChartPeriod.weekly]
          break
        case diff < Duration.fromObject({ days: 91 }).as('milliseconds'):
          updatedChartPeriodOptions = [ChartPeriod.daily, ChartPeriod.weekly, ChartPeriod.monthly]
          break
        default:
          updatedChartPeriodOptions = [ChartPeriod.weekly, ChartPeriod.monthly]
      }
    }

    setChartPeriodOptions(updatedChartPeriodOptions)

    // Attempt to find the chartPeriod set in report store in the updatedChartPeriodOptions array
    const defaultChartPeriod = updatedChartPeriodOptions.find(
      (chartPeriod: string) => chartPeriod === report.config.chartPeriod
    )

    dispatch(
      updateUnifiedReporting({
        report: {
          ...report,
          config: {
            ...report.config,
            chartPeriod: defaultChartPeriod || updatedChartPeriodOptions[0]
          }
        }
      })
    )
    // Exception added so that we don't need to include report, which would cause infinite re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [absoluteDateRangeEnd, absoluteDateRangeStart, dispatch, relativeDateRange])

  const updateChartConfig = useCallback(
    (chartConfigKey: string, updatedChartConfig: string) => {
      dispatch(
        updateUnifiedReporting({
          report: {
            ...report,
            config: {
              ...report.config,
              [chartConfigKey]: updatedChartConfig
            }
          }
        })
      )
    },
    [dispatch, report]
  )

  const showBarChart = useMemo(() => {
    return (
      (isSingleColumnGrouped && report.config.chartType === ChartType.horizontalBar) ||
      (!isSingleColumnGrouped && report.config.chartType === ChartType.verticalBar)
    )
  }, [isSingleColumnGrouped, report.config.chartType])

  const showStackedBarChart = useMemo(() => {
    return report.config.chartType === 'HORIZONTAL_STACKED_BAR'
  }, [report.config.chartType])

  const setShowBarChart = useCallback(() => {
    if (isSingleColumnGrouped) {
      updateChartConfig(
        'chartType',
        report.config.chartType === ChartType.table ? ChartType.horizontalBar : ChartType.table
      )
    } else {
      updateChartConfig(
        'chartType',
        report.config.chartType === ChartType.line ? ChartType.verticalBar : ChartType.line
      )
    }
  }, [isSingleColumnGrouped, report.config.chartType, updateChartConfig])

  const setShowStackedBarChart = useCallback(() => {
    updateChartConfig(
      'chartType',
      report.config.chartType === ChartType.horizontalGroupedBar
        ? ChartType.horizontalStackedBar
        : ChartType.horizontalGroupedBar
    )
  }, [report.config.chartType, updateChartConfig])

  const getCustomToolTipTitle = useCallback((originalTitle: string) => {
    // originalTitle is 12-hour clock format: 1 AM, 2 AM...12 PM
    const currentHour = parseInt(originalTitle.split(' ')[0], 10)
    // The logic in the next line is intended for situations where the currentHour is 12 AM/PM.
    const nextHour = (currentHour + 1) % 12 || 12
    const currentPeriod = originalTitle.split(' ')[1]
    let nextPeriod = currentPeriod

    if (nextHour === 12) {
      nextPeriod = currentPeriod === 'AM' ? 'PM' : 'AM'
    }

    return `${originalTitle} to ${nextHour} ${nextPeriod}`
  }, [])

  const formatTicks = useCallback(
    (value: string | number, labels: string[]) => {
      if (HORIZONTAL_CHARTS.includes(report.config.chartType)) {
        if (typeof value === 'number') {
          return labels[value] && truncate(labels[value], { position: 'middle' })
        }
        return truncate(value, { position: 'middle' })
      }

      return value
    },
    [report.config.chartType]
  )

  const singleSeriesDataChartConfig: SingleSeriesDataChartConfig = useMemo(() => {
    const tooltipLabel = formatMessage('chart.tooltip.default_label')
    const labels = formatLabels(
      report.config.chartPeriod as string,
      Object.keys(chartData),
      isMultiSeriesData || isSingleColumnGrouped
    )
    const chartOptions: ChartOptions<'bar' | 'line'> = {
      plugins: {
        tooltip: {
          callbacks: {
            title: (tooltipItem: any) => {
              return report.config.chartPeriod === ChartPeriod.hourly
                ? getCustomToolTipTitle(tooltipItem[0].label)
                : tooltipItem[0].label
            }
          }
        }
      },
      scales: {
        y: {
          ticks: {
            callback(value) {
              return formatTicks(value, labels)
            }
          }
        }
      }
    }

    const data = {
      barChartData: {
        datasets: [
          {
            backgroundColor: colors.chartBarracudaDarkBlue,
            borderColor: colors.chartBarracudaDarkBlue,
            borderRadius: 5,
            borderWidth: 1,
            data: Object.values(chartData),
            label: tooltipLabel
          }
        ],
        labels
      },
      lineChartData: {
        datasets: [
          {
            backgroundColor: colors.chartBarracudaDarkBlue,
            borderColor: colors.chartBarracudaDarkBlue,
            borderWidth: 2,
            data: Object.values(chartData),
            label: tooltipLabel
          }
        ],
        labels
      }
    }

    const options = {
      barChartOptions: {
        horizontal: chartOptions,
        vertical: chartOptions
      },
      lineChartOptions: chartOptions
    }

    return { data, options }
  }, [
    chartData,
    formatMessage,
    formatTicks,
    getCustomToolTipTitle,
    isMultiSeriesData,
    isSingleColumnGrouped,
    report.config.chartPeriod
  ])

  const multiSeriesDataChartConfig: MultiSeriesDataChartConfig = useMemo(() => {
    const labels = Object.keys(chartData)

    const allTooltipKeys = Object.values(chartData).flatMap(obj => Object.keys(obj))
    const tooltipLabels = [...new Set(allTooltipKeys)]

    const datasets = tooltipLabels.map((tooltipLabel, index) => ({
      label: tooltipLabel,
      data: Object.values(chartData).map(obj => obj[tooltipLabel] || 0),
      backgroundColor: getChartBackgroundColor(index),
      borderWidth: 1
    }))

    const chartOptions: ChartOptions<'bar'> = {
      scales: {
        y: {
          ticks: {
            callback(value) {
              return formatTicks(value, labels)
            }
          }
        }
      }
    }

    const options: MultiSeriesDataChartConfig['options'] = {
      horizontalGrouped: chartOptions,
      horizontalStacked: chartOptions
    }

    return { data: { labels, datasets }, options }
  }, [chartData, formatTicks])

  const emptyState = useMemo(() => {
    const emptyChart = {
      icon: <EmptyStateIcon data-testid="empty-state" />,
      text: formatMessage('empty_state_text.no_results')
    }

    const loadingChart = {
      icon: <CircularProgress data-testid="empty-loading-state" />,
      text: formatMessage('empty_state_text.loading')
    }

    const errorLoadingChart = {
      icon: <EmptyStateIcon data-testid="empty-error-state" />,
      text: formatMessage('empty_state_text.error')
    }

    switch (true) {
      case isPending(getReportDataAPIStatus):
        return loadingChart
      case isSuccess(getReportDataAPIStatus):
        return emptyChart
      case isFailed(getReportDataAPIStatus):
        return errorLoadingChart
      default:
        return loadingChart
    }
  }, [formatMessage, getReportDataAPIStatus])

  const chartConfig: ChartConfig = useMemo(() => {
    return {
      chartData: chart.data,
      chartDimension,
      chartFieldNames: chart.fieldNames,
      chartPeriod: report.config.chartPeriod,
      chartPeriodOptions,
      chartType: report.config.chartType,
      emptyState,
      hasEntries: !!tableDataTotalCount,
      isMultiSeriesData,
      isSingleColumnGrouped,
      setShowBarChart,
      setShowStackedBarChart,
      showBarChart,
      showStackedBarChart,
      updateChartConfig
    }
  }, [
    chart.data,
    chart.fieldNames,
    report.config.chartPeriod,
    report.config.chartType,
    chartPeriodOptions,
    emptyState,
    tableDataTotalCount,
    isMultiSeriesData,
    isSingleColumnGrouped,
    setShowBarChart,
    setShowStackedBarChart,
    showBarChart,
    showStackedBarChart,
    updateChartConfig
  ])

  return useMemo(() => [{ chartConfig, multiSeriesDataChartConfig, singleSeriesDataChartConfig }], [
    chartConfig,
    multiSeriesDataChartConfig,
    singleSeriesDataChartConfig
  ])
}
