import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DateTime } from 'luxon'

import { MenuItem } from '@barracuda-internal/bds-core'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { luxonDate } from 'global/lib/datetime'
import { downloadCSVFile, downloadPDFFile } from 'global/lib/downloadFile'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import useUserDataLib from 'global/lib/userData/useUserData'
import {
  createUnifiedReportingReport,
  deleteUnifiedReportingReport,
  getUnifiedReportingCSVReport,
  getUnifiedReportingPDFReport,
  setErrorMessage,
  updateUnifiedReportingReport
} from 'global/redux/features/unifiedReporting/unifiedReportingSlice'
import {
  DownLoadButtonConfig,
  ReportDialogConfig,
  ReportingOptionsButtonConfig,
  UnifiedReportingHeaderLogicProps
} from 'global/components/features/unifiedReporting/unifiedReportingHeader/UnifiedReportingHeader'
import { ChartPeriod, ChartType } from 'global/components/lib/chartsV2/ChartConfig'
import { useFormatMessage } from 'global/lib/localization'
import { Filter, UnifiedReportingReportType } from 'global/types/api/unifiedReporting'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import useScheduledReportsLogic from 'sen/components/pages/unifiedReporting/schedules/useScheduledReportsLogic'
import useUnifiedReportingDatatableLogic from 'sen/components/pages/unifiedReporting/reports/useUnifiedReportingDatatableLogic'

const BASE_I18N_KEY = 'sen.app.unified_reporting.report_header'

export default function useUnifiedReportingHeaderLogic(): [UnifiedReportingHeaderLogicProps] {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [userDataLib] = useUserDataLib()
  const [isSaveReportDialogOpen, toggleSaveReportDialog, setSaveReportDialogOpen] = useDialogLogic()
  const [isRenameReportDialogOpen, toggleRenameReportDialog, setRenameReportDialogOpen] = useDialogLogic()
  const [{ dateRangeConfig }] = useUnifiedReportingDatatableLogic()
  const [
    { handleOpenScheduledReport, isScheduledReportsDrawerOpen, scheduledReportsDrawerConfig, toggleDrawer }
  ] = useScheduledReportsLogic()
  const downloadOptions = useMemo(
    () => [formatMessage('download_options.data_csv'), formatMessage('download_options.summary_pdf')],
    [formatMessage]
  )

  const {
    absoluteDateRangeEnd,
    absoluteDateRangeStart,
    accessToken,
    dataTableFilters,
    dataTableHiddenColumns,
    dataTableSearchQuery,
    dataTableSorting,
    filters,
    groupBy,
    hiddenColumns,
    isSenDemoUser,
    relativeDateRange,
    report,
    searchQuery,
    sortBy,
    topDisplayCount,
    userEmail
  } = useAppSelector(_stores => ({
    absoluteDateRangeEnd: _stores.dataTables.unifiedReporting.absoluteDateRangeEnd,
    absoluteDateRangeStart: _stores.dataTables.unifiedReporting.absoluteDateRangeStart,
    accessToken: _stores.accessToken.accessToken?.id || '',
    dataTableFilters: _stores.dataTables.unifiedReporting.filters,
    dataTableHiddenColumns: _stores.dataTables.unifiedReporting.hiddenColumns,
    dataTableSearchQuery: _stores.dataTables.unifiedReporting.searchQuery,
    dataTableSorting: _stores.dataTables.unifiedReporting.sortBy,
    filters: _stores.dataTables.unifiedReporting.filters,
    groupBy: _stores.dataTables.unifiedReporting.groupBy,
    hiddenColumns: _stores.dataTables.unifiedReporting.hiddenColumns,
    isSenDemoUser: _stores.user.isSenDemoUser,
    relativeDateRange: _stores.dataTables.unifiedReporting.relativeDateRange,
    report: _stores.unifiedReporting.report,
    searchQuery: _stores.dataTables.unifiedReporting.searchQuery,
    sortBy: _stores.dataTables.unifiedReporting.sortBy,
    topDisplayCount: _stores.dataTables.unifiedReporting.topDisplayCount,
    userEmail: _stores.user.data.email
  }))

  const [downLoadAnchorEl, setDownLoadAnchorEl] = useState<null | HTMLElement>(null)
  const [reportingOptionsAnchorEl, setReportingOptionsAnchorEl] = useState<null | HTMLElement>(null)
  // TODO we can add selectedDownLoadOption and seltecedReportingOption when needed
  const [, setSelectedDownLoadOption] = useState<string | null>(null)
  const [reportName, setReportName] = useState(report.name)

  useEffect(() => {
    setReportName(report.type === UnifiedReportingReportType.BARRACUDA ? '' : report.name)
  }, [report.name, report.type])

  // TODO: remove as string when methods using accountId are updated
  const accountId = useMemo(() => userDataLib.getAccountByAccessToken(accessToken)?.accountId, [
    accessToken,
    userDataLib
  ]) as string

  const handleSetReportName = (e: any) => {
    setReportName(e.target.value)
  }

  const handleDownLoadButtonClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setDownLoadAnchorEl(event.currentTarget)
  }, [])

  const handleReportingOptionsButtonClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setReportingOptionsAnchorEl(event.currentTarget)
  }, [])

  const handleDownLoadMenuItemClick = useCallback(
    (option: string) => {
      setDownLoadAnchorEl(null)

      if (isSenDemoUser) {
        dispatch(setErrorMessage('unified_reporting_demo_error'))
        return
      }

      analyticsLib.trackAppEvent(analyticsLib.EVENTS.UNIFIED_REPORTING_EXPORT_REPORT, {
        accessTokenId: accessToken,
        exportType: option,
        relativeDateRange,
        reportDateRangeEnd: absoluteDateRangeEnd,
        reportDateRangeStart: absoluteDateRangeStart,
        reportFilters: filters,
        reportId: report.uuid,
        reportName: report.name,
        reportSort: sortBy,
        reportType: report.type
      })

      setSelectedDownLoadOption(option)

      switch (option) {
        case downloadOptions[0]:
          dispatch(
            getUnifiedReportingCSVReport({
              absoluteDateRangeEnd,
              absoluteDateRangeStart,
              accessTokenId: accessToken,
              accountId,
              chartPeriod: report.config.chartPeriod,
              chartType: report.config.chartType,
              filters: dataTableFilters,
              groupBy,
              hiddenColumns: dataTableHiddenColumns,
              relativeDateRange,
              reportName: report.name,
              searchQuery: dataTableSearchQuery,
              sortBy: dataTableSorting,
              topDisplayCount,
              uuid: report.uuid,
              zoneId: DateTime.local().zoneName
            })
          )
            .unwrap()
            .then((response: any) => {
              downloadCSVFile(response.data, response.filename, 'text/csv;charset=UTF-8')
            })
          break
        case downloadOptions[1]:
          dispatch(
            getUnifiedReportingPDFReport({
              absoluteDateRangeEnd,
              absoluteDateRangeStart,
              accessTokenId: accessToken,
              accountId,
              chartPeriod: report.config.chartPeriod,
              chartType: report.config.chartType,
              filters: dataTableFilters,
              groupBy,
              hiddenColumns: dataTableHiddenColumns,
              relativeDateRange,
              searchQuery: dataTableSearchQuery,
              sortBy: dataTableSorting,
              topDisplayCount,
              uuid: report.uuid,
              zoneId: DateTime.local().zoneName
            })
          )
            .unwrap()
            .then((response: any) => {
              downloadPDFFile(response.data, response.filename)
            })
          break
        default:
          break
      }
    },
    [
      absoluteDateRangeEnd,
      absoluteDateRangeStart,
      accessToken,
      accountId,
      dataTableFilters,
      dataTableHiddenColumns,
      dataTableSearchQuery,
      dataTableSorting,
      dispatch,
      downloadOptions,
      filters,
      groupBy,
      isSenDemoUser,
      relativeDateRange,
      report.config.chartPeriod,
      report.config.chartType,
      report.name,
      report.type,
      report.uuid,
      sortBy,
      topDisplayCount
    ]
  )

  const handleDeleteReportMenuItemClick = useCallback(() => {
    setReportingOptionsAnchorEl(null)

    if (isSenDemoUser) {
      dispatch(setErrorMessage('unified_reporting_demo_error'))
      return
    }

    dispatch(deleteUnifiedReportingReport({ uuid: report.uuid }))
  }, [dispatch, isSenDemoUser, report.uuid])

  const handleRenameMenuItemClick = useCallback(() => {
    setReportingOptionsAnchorEl(null)
    toggleRenameReportDialog()
  }, [toggleRenameReportDialog])

  // const handleSaveCopyMenuItemClick = useCallback(() => {
  //   setReportingOptionsAnchorEl(null)
  //   // TODO: update this once the API Thunk is created
  //   // eslint-disable-next-line no-console
  //   console.log('Save copy of report')
  // }, [])

  const reportingOptionsMenuItems = useMemo(() => {
    if (report.type === UnifiedReportingReportType.BARRACUDA) {
      return [
        <MenuItem
          key="scheduleBarracudaReport"
          onClick={e => {
            handleOpenScheduledReport(report.uuid, true)
            toggleDrawer(e)
            setReportingOptionsAnchorEl(null)
          }}
        >
          {formatMessage('report_options.schedule')}
        </MenuItem>
      ]
    }

    return [
      <MenuItem
        key="scheduleReport"
        onClick={e => {
          handleOpenScheduledReport(report.uuid, true)
          toggleDrawer(e)
          setReportingOptionsAnchorEl(null)
        }}
      >
        {formatMessage('report_options.schedule')}
      </MenuItem>,
      <MenuItem key="renameReport" onClick={() => handleRenameMenuItemClick()}>
        {formatMessage('report_options.rename')}
      </MenuItem>,
      // TODO: uncomment once options are available
      //   <MenuItem key="saveReportAsCopy" onClick={() => handleSaveCopyMenuItemClick()}>
      //     {formatMessage('report_options.save_as_copy')}
      //   </MenuItem>,
      <MenuItem key="deleteReport" onClick={() => handleDeleteReportMenuItemClick()}>
        {formatMessage('report_options.delete_report')}
      </MenuItem>
    ]
  }, [
    formatMessage,
    handleDeleteReportMenuItemClick,
    handleOpenScheduledReport,
    handleRenameMenuItemClick,
    report.type,
    report.uuid,
    toggleDrawer
  ])

  const handleDownLoadMenuClose = () => {
    setDownLoadAnchorEl(null)
  }
  const handleReportingOptionsMenuClose = useCallback(() => {
    setReportingOptionsAnchorEl(null)
  }, [])

  const downLoadButtonConfig: DownLoadButtonConfig = useMemo(
    () => ({
      downloadOptions,
      downLoadAnchorEl,
      handleDownLoadButtonClick,
      handleDownLoadMenuItemClick,
      handleDownLoadMenuClose
    }),
    [downLoadAnchorEl, downloadOptions, handleDownLoadButtonClick, handleDownLoadMenuItemClick]
  )

  const reportingOptionsButtonConfig: ReportingOptionsButtonConfig = useMemo(
    () => ({
      handleReportingOptionsButtonClick,
      handleReportingOptionsMenuClose,
      reportingOptions: reportingOptionsMenuItems,
      reportingOptionsAnchorEl
    }),
    [
      handleReportingOptionsButtonClick,
      handleReportingOptionsMenuClose,
      reportingOptionsMenuItems,
      reportingOptionsAnchorEl
    ]
  )

  const shouldDisableSaveButton = useMemo(() => {
    if (!reportName.trim()) {
      return true
    }

    if ((!relativeDateRange || relativeDateRange === 'RESET') && (!absoluteDateRangeStart || !absoluteDateRangeEnd)) {
      return true
    }

    return false
  }, [absoluteDateRangeEnd, absoluteDateRangeStart, relativeDateRange, reportName])

  const createUpdateReportParams = useMemo(
    () => ({
      baseDataType: report.baseDataType,
      config: {
        chartType: report.config.chartType as ChartType,
        chartPeriod: report.config.chartPeriod as ChartPeriod,
        searchQuery,
        relativeDateRange: relativeDateRange || undefined,
        absoluteDateRangeStart: absoluteDateRangeStart || undefined,
        absoluteDateRangeEnd: absoluteDateRangeEnd || undefined,
        topDisplayCount,
        groupBy,
        sortBy,
        filters: filters as Filter[],
        hiddenColumns
      },
      lastModifiedBy: userEmail,
      name: reportName,
      product: report.product,
      uuid: report.uuid
    }),
    [
      report.baseDataType,
      report.config.chartType,
      report.config.chartPeriod,
      report.product,
      report.uuid,
      searchQuery,
      relativeDateRange,
      absoluteDateRangeStart,
      absoluteDateRangeEnd,
      topDisplayCount,
      groupBy,
      sortBy,
      filters,
      hiddenColumns,
      userEmail,
      reportName
    ]
  )

  const reportDialogConfig: ReportDialogConfig = useMemo(
    () => ({
      dataRetentionWarning: {
        shouldShowWarning: report.type !== UnifiedReportingReportType.BARRACUDA && !!absoluteDateRangeStart,
        dataLossDate: luxonDate(absoluteDateRangeStart || undefined)
          .plus({ days: 180 })
          .toLocaleString()
      },
      dateRangeConfig,
      isUpdate: report.type !== UnifiedReportingReportType.BARRACUDA,
      toggleRenameReportDialog,
      toggleSaveReportDialog,
      onSave: () => {
        setSaveReportDialogOpen(false)
        setRenameReportDialogOpen(false)

        if (
          ((!relativeDateRange || relativeDateRange === 'RESET') &&
            (!absoluteDateRangeStart || !absoluteDateRangeEnd)) ||
          isSenDemoUser
        ) {
          if (isSenDemoUser) {
            dispatch(setErrorMessage('unified_reporting_demo_error'))

            if (report.type === UnifiedReportingReportType.BARRACUDA) {
              setReportName('')
            } else {
              setReportName(report.name)
            }
          }
          return
        }

        switch (report.type) {
          case UnifiedReportingReportType.BARRACUDA:
            dispatch(createUnifiedReportingReport(createUpdateReportParams))
            analyticsLib.trackAppEvent(analyticsLib.EVENTS.UNIFIED_REPORTING_SAVE_REPORT, {
              accessTokenId: accessToken,
              relativeDateRange,
              reportDateRangeEnd: absoluteDateRangeEnd,
              reportDateRangeStart: absoluteDateRangeStart,
              reportFilters: filters,
              reportName,
              reportSort: sortBy,
              reportType: report.type
            })
            break
          case UnifiedReportingReportType.CUSTOM:
            dispatch(updateUnifiedReportingReport(createUpdateReportParams))
            analyticsLib.trackAppEvent(analyticsLib.EVENTS.UNIFIED_REPORTING_EDIT_REPORT, {
              accessTokenId: accessToken,
              relativeDateRange,
              reportDateRangeEnd: absoluteDateRangeEnd,
              reportDateRangeStart: absoluteDateRangeStart,
              reportFilters: filters,
              reportId: report.uuid,
              reportName,
              reportSort: sortBy,
              reportType: report.type
            })
            break
          default:
            break
        }
      },
      onUpdate: handleSetReportName,
      reportName,
      isSaveReportDialogOpen,
      isRenameReportDialogOpen,
      shouldDisableSaveButton
    }),
    [
      absoluteDateRangeEnd,
      absoluteDateRangeStart,
      accessToken,
      createUpdateReportParams,
      dateRangeConfig,
      dispatch,
      filters,
      isRenameReportDialogOpen,
      isSaveReportDialogOpen,
      isSenDemoUser,
      relativeDateRange,
      report.name,
      report.type,
      report.uuid,
      reportName,
      setRenameReportDialogOpen,
      setSaveReportDialogOpen,
      shouldDisableSaveButton,
      sortBy,
      toggleRenameReportDialog,
      toggleSaveReportDialog
    ]
  )

  return useMemo(
    () => [
      {
        downLoadButtonConfig,
        isScheduledReportsDrawerOpen,
        reportingOptionsButtonConfig,
        reportTitle: report.name,
        reportDialogConfig,
        scheduledReportsDrawerConfig,
        toggleDrawer
      }
    ],
    [
      downLoadButtonConfig,
      isScheduledReportsDrawerOpen,
      report.name,
      reportDialogConfig,
      reportingOptionsButtonConfig,
      scheduledReportsDrawerConfig,
      toggleDrawer
    ]
  )
}
