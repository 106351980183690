import React, { useMemo } from 'react'

import GlobalLayout, { GlobalLayoutProps } from 'global/components/lib/layout/Layout'

import EtsNavbar from './Navbar'
import useLayoutLogic from './useLayoutLogic'

export default function Layout(props: GlobalLayoutProps): JSX.Element {
  const [layoutLogic] = useLayoutLogic()

  const { initAccount, navbarContextMenuO365Props, onSelectAccountCallback } = layoutLogic

  return useMemo(
    () => (
      <GlobalLayout
        isNewLayout
        isLicenseBannerEnabled={false}
        isMaintenanceBannerEnabled
        initAccount={initAccount}
        navbar={<EtsNavbar />}
        navbarContextMenuO365Props={navbarContextMenuO365Props}
        onSelectAccountCallback={onSelectAccountCallback}
        {...props}
      >
        {props.children}
      </GlobalLayout>
    ),
    [initAccount, navbarContextMenuO365Props, onSelectAccountCallback, props]
  )
}
