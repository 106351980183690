import { useMemo } from 'react'

import { isFailed } from 'global/redux/toolkit/api'

import { useAppSelector } from 'admin/redux/toolkit/hooks'

export default function useDashboardLogic(): [boolean] {
  const { urlParams, impersonateFailed, deactivateFailed } = useAppSelector(_stores => ({
    urlParams: _stores.app.activePath || {},
    impersonateFailed: isFailed(_stores.admin.impersonateApiStatus),
    deactivateFailed: isFailed(_stores.admin.deactivateApiStatus)
  }))

  return useMemo(() => {
    const error = impersonateFailed || deactivateFailed
    return [error]
  }, [impersonateFailed, deactivateFailed])
}
