import React, { useEffect, useMemo, useCallback } from 'react'
import { isEqual } from 'lodash'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

import {
  NavigationBarProps,
  ProfileAvatar,
  ProfileMenu
} from '@barracuda-internal/bds-core/dist/UnifiedComponents/UnifiedNavigation'
import { BDSProvider } from '@barracuda-internal/bds-core'
import { FaviconEts } from '@barracuda-internal/bds-core/dist/Logos/Favicons'
import { Apps, Dashboard, ExitToApp, OpenInNew, SupervisorAccount } from '@barracuda-internal/bds-core/dist/Icons/Core'
import ProductSwitcher from '@barracuda-internal/bds-core/dist/UnifiedComponents/ProductSwitcher/ProductSwitcher'
import {
  ProductDataSubscriptionGroup,
  ProductName
} from '@barracuda-internal/bds-core/dist/UnifiedComponents/ProductSwitcher/types'
import { useProductData } from '@barracuda-internal/bds-core/dist/UnifiedComponents/ProductSwitcher/hooks/useProductData'

import browserHistory from 'global/lib/routes/browserHistory'
import { config } from 'global/lib/config'
import useUserDataLib from 'global/lib/userData/useUserData'
import { useFormatMessage } from 'global/lib/localization'
import { isAfterDate } from 'global/lib/datetime'
import { logout, refreshToken } from 'global/redux/features/auth/authSlice'
import { setAccountSubscriptionData } from 'global/redux/features/account/accountSlice'

import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'
import { Account } from 'global/types/api/accountType'
import { isPending } from 'global/redux/toolkit/api'

type Jwt = {
  exp: number
  [key: string]: string | number
}

// TODO: Remove TOKEN_PLACEHOLDER in ticket BNFIR-4224
const TOKEN_PLACEHOLDER = 'TOKEN_PLACEHOLDER'
const BASE_FIR_I18N_KEY = 'ets.app.navbar'
const ETS_PRODUCT_ID = config.PRODUCTS.IDS.ETS.toString()

type GetInitials = {
  (name: string): string
}

export default function useEtsNavbarLogic(): NavigationBarProps[] {
  const formatMessage = useFormatMessage(BASE_FIR_I18N_KEY)
  const [userDataLib] = useUserDataLib()
  const dispatch = useAppDispatch()
  const bccToken = Cookies.get(config.COOKIES.BCC_AT) || ''

  const {
    accessTokenId,
    accountSubscriptionData,
    activePath,
    bccAccount,
    defaultAccountBccId,
    isRefreshTokenPending,
    tenantId,
    userBccId,
    userEmail,
    userName
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    accountSubscriptionData: _stores.account.accountSubscriptionData,
    activePath: _stores.app.activePath.url || '',
    bccAccount: _stores.accessToken.bccAccount || '',
    defaultAccountBccId: _stores.user.data?.defaultAccountBccId || '',
    isRefreshTokenPending: isPending(_stores.auth.refreshTokenApiStatus),
    splitStore: _stores.splitio,
    tenantId: _stores.accessToken.accessToken?.tenantId || '',
    userBccId: _stores.user.data.bccUserId,
    userEmail: _stores.user.data.email,
    userName: _stores.user.data.displayName || ''
  }))

  const apiToken = bccToken || TOKEN_PLACEHOLDER

  const { data } = useProductData({
    userId: userBccId,
    accountId: bccAccount || defaultAccountBccId,
    apiToken,
    hostname: config.productDataServiceUrl
  })

  // BCC token has 1 hour expiration time, we need to get a new token when old token is expired
  useEffect(() => {
    // impersonation don't go through login flow curretnly, so skip the token check for them and use the hardcoded IP/ETS
    if (userDataLib.isImpersonationMode()) {
      return
    }

    if (apiToken === TOKEN_PLACEHOLDER && !isRefreshTokenPending) {
      dispatch(refreshToken())

      return
    }

    if (bccToken) {
      const decodedToken = jwtDecode(bccToken) as Jwt
      const tokenExpireTime = new Date(decodedToken.exp * 1000 - 5 * 60 * 1000) // Set the token expiration time to 5 minutes earlier, allowing us to get a new token when there are about 5 minutes remaining before it expires.
      const isTokenExpired = isAfterDate({ initialDate: new Date(), subtractedDate: tokenExpireTime })

      if (isTokenExpired) {
        dispatch(refreshToken())
      }
    }
  }, [apiToken, bccToken, dispatch, isRefreshTokenPending, userDataLib])

  useEffect(() => {
    if (data?.subscriptionGroups && !isEqual(data.subscriptionGroups, accountSubscriptionData)) {
      const mostRecentEtsAT = userDataLib.getEtsAccessTokensByTenantId(tenantId)[0]?.id || ''

      // Loop through each subscription group and product, update URL with access token if product ID is IP, IR, or ETS
      // Once we globalize the Unified Nav this function will only need to be added to one place, for now we will add it to all products
      data?.subscriptionGroups.forEach(subscriptionGroup =>
        subscriptionGroup.subscriptions.forEach(subscription => {
          switch (subscription.product.id) {
            case config.PRODUCTS.IDS.ETS.toString():
              // eslint-disable-next-line no-param-reassign
              subscription.product.appUrls.main += mostRecentEtsAT ? `/report/${mostRecentEtsAT}` : ''
              break
            case config.PRODUCTS.IDS.FORENSICS.toString():
              // eslint-disable-next-line no-param-reassign
              subscription.product.appUrls.main += accessTokenId ? `/report/${accessTokenId}/remediation` : ''
              break
            case config.PRODUCTS.IDS.DFP.toString():
            case config.PRODUCTS.IDS.SENTINEL.toString():
              // eslint-disable-next-line no-param-reassign
              subscription.product.appUrls.main += accessTokenId ? `/report/${accessTokenId}/dashboard` : ''
              break
            default:
              break
          }
        })
      )

      dispatch(setAccountSubscriptionData(data.subscriptionGroups))
    }
  }, [accountSubscriptionData, dispatch, data, userDataLib, tenantId, accessTokenId])

  const accounts = userDataLib.getAccounts()

  const isAdmin = useMemo(() => userDataLib.isUserAdmin(accessTokenId), [accessTokenId, userDataLib])

  const isProductInResponse = useCallback(
    (productName: string) => {
      return (accountSubscriptionData as ProductDataSubscriptionGroup[]).some(item =>
        item.subscriptions.some(subscription => subscription.product?.name === productName)
      )
    },
    [accountSubscriptionData]
  )

  const hasIRInSubscriptionData = useMemo(() => {
    return isProductInResponse(formatMessage('productSwitcher.incidentResponse'))
  }, [formatMessage, isProductInResponse])

  const hasIPInSubscriptionData = useMemo(() => {
    return isProductInResponse(formatMessage('productSwitcher.impersonationProtection'))
  }, [formatMessage, isProductInResponse])

  const impersonationProtectionTokens = useMemo(
    () =>
      accounts.flatMap((account: Account) =>
        account.accessTokens
          .filter(accessToken => accessToken.products.includes('sentinel'))
          .map(accessToken => accessToken.id)
      ),
    [accounts]
  )

  const incidentResponseTokens = useMemo(
    () =>
      accounts.flatMap((account: Account) =>
        account.accessTokens
          .filter(accessToken => accessToken.products.includes('fir'))
          .map(accessToken => accessToken.id)
      ),
    [accounts]
  )

  const incidentResponse = useMemo(
    () => ({
      appName: ProductName.INCIDENT_RESPONSE,
      url: `${config.domains.forensics}/report/${incidentResponseTokens[0]}`
    }),
    [incidentResponseTokens]
  )

  const impersonationProtection = useMemo(
    () => ({
      appName: ProductName.IMPERSONATION_PROTECTION,
      url: `${config.domains.sentinel}/report/${impersonationProtectionTokens[0]}`
    }),
    [impersonationProtectionTokens]
  )

  const getInitials: GetInitials = useCallback((name: string) => {
    const names = name.split(' ')
    const initials = names.map((n: string) => n[0]).join('')
    return initials.toUpperCase()
  }, [])

  const onNavigate = useCallback((path: string) => {
    browserHistory.push(path)
  }, [])

  // start logout
  const onLogout = useCallback(() => {
    dispatch(logout(true))
  }, [dispatch])

  return useMemo(
    () => [
      {
        title: '',
        currentPath: activePath,
        onNavigate,
        options: [
          {
            name: formatMessage('options.apps'),
            icon: <Apps />,
            menu: (
              // TODO: remove BDSProvider once we remove MuiThemeProvider in App.tsx
              <BDSProvider useBdsTheme>
                <ProductSwitcher
                  currentProductId={ETS_PRODUCT_ID}
                  subscriptionGroups={accountSubscriptionData as ProductDataSubscriptionGroup[]}
                  impersonationProtection={!hasIPInSubscriptionData ? impersonationProtection : undefined}
                  incidentResponse={!hasIRInSubscriptionData ? incidentResponse : undefined}
                />
              </BDSProvider>
            )
          },
          {
            name: formatMessage('options.profile'),
            icon: <ProfileAvatar size={28}>{getInitials(userName)}</ProfileAvatar>,
            menu: (
              <ProfileMenu
                icon={<ProfileAvatar>{getInitials(userName)}</ProfileAvatar>}
                name={userName}
                email={userEmail}
                menuItems={[
                  ...(isAdmin
                    ? [
                        {
                          name: formatMessage('options.profile_menu.manage_account'),
                          onClick: (): void => {
                            window.open(`${config.bccAccountUserUrl}/#user=${userBccId}`, '_blank')
                          },
                          icon: <SupervisorAccount />,
                          endIcon: <OpenInNew />
                        }
                      ]
                    : []),
                  {
                    name: formatMessage('options.profile_menu.log_out'),
                    onClick: onLogout,
                    icon: <ExitToApp />
                  }
                ]}
              />
            )
          }
        ],
        routes: [
          {
            path: `/report/${accessTokenId}`,
            icon: <Dashboard />,
            name: formatMessage('dashboard')
          }
        ],
        logo: <FaviconEts viewBox="0 0 56 56" />
      }
    ],
    [
      accessTokenId,
      accountSubscriptionData,
      activePath,
      formatMessage,
      getInitials,
      hasIPInSubscriptionData,
      hasIRInSubscriptionData,
      impersonationProtection,
      incidentResponse,
      isAdmin,
      onLogout,
      onNavigate,
      userBccId,
      userEmail,
      userName
    ]
  )
}
