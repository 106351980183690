/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Typography, Grid, Paper, Alert as AlertComponent } from '@barracuda-internal/bds-core'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'
import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import ContentTabSelector, { TabConfig } from 'global/components/lib/contentTabSelector/ContentTabSelector'

import routesConfig from 'sen/lib/routes/routesConfig'
import useIncidentLogic from 'sen/components/pages/ato/content/tabs/incidents/useIncidentLogic'
import styles from 'sen/components/pages/ato/content/tabs/incidents/incidentStyles'
import RecipientsTable from 'sen/components/pages/ato/content/tabs/incidents/recipientsTable/RecipientsTable'

const BASE_I18N_KEY = 'sen.app.account_takeover.incident'

const TABS: TabConfig[] = [
  [[routesConfig.ACCOUNT_TAKEOVER_INCIDENT], 'internal_attacks', () => <RecipientsTable isInternal />],
  [[routesConfig.ACCOUNT_TAKEOVER_INCIDENT_EXTERNAL_RECIPIENTS], 'external_recipients', RecipientsTable]
]

const Incident: React.FC = () => {
  const [inProgress, incident, incidentLogic, error] = useIncidentLogic()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageError = useErrorFormatMessage()
  const classes = styles()

  const OverviewFullRow: React.FC<{ withBorder?: boolean; children: any }> = React.useCallback(
    ({ withBorder = false, children }) => (
      <Grid className={withBorder ? classes.overviewFullRow : ''} container direction="row">
        {children}
      </Grid>
    ),
    [classes.overviewFullRow]
  )
  OverviewFullRow.propTypes = {
    withBorder: PropTypes.bool,
    children: PropTypes.any.isRequired
  }
  OverviewFullRow.defaultProps = {
    withBorder: false
  }

  const OverViewRow: React.FC<{
    label: string
    text?: string | undefined
    rawValue?: string | number | undefined
  }> = React.useCallback(
    ({ label, text, rawValue }) => (
      <Grid className={classes.overviewRow} item xs={6}>
        <Grid container direction="row">
          <Grid className={classes.overviewLabel} item xs={3} data-label={label}>
            {formatMessage(label)}
          </Grid>
          <Grid className={classes.overviewText} item xs data-value-for={label} data-raw-value={rawValue}>
            {!inProgress && !error && text}
          </Grid>
        </Grid>
      </Grid>
    ),
    [classes.overviewRow, classes.overviewLabel, classes.overviewText, formatMessage, inProgress, error]
  )
  OverViewRow.propTypes = {
    label: PropTypes.string.isRequired,
    text: PropTypes.string,
    rawValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }
  OverViewRow.defaultProps = {
    text: undefined,
    rawValue: undefined
  }

  return useMemo(
    () => (
      <Grid container direction="column" data-field="incident-grid">
        {error && (
          <AlertComponent variant="filled" severity="error" data-field="incident-overview-alert">
            {formatMessageError(error)}
          </AlertComponent>
        )}

        <Grid item data-field="incident-grid-title">
          <Typography className={classes.title} data-field="incidents-title">
            {formatMessage('title', {
              name: incident?.sender?.email,
              date: incident?.formattedDate,
              link: (text: string) => (
                <data
                  key={text}
                  role="presentation"
                  onClick={incidentLogic.gotoIncidents}
                  className={classes.linkText}
                  data-field="incidents-link"
                >
                  {text}
                </data>
              ),
              b: (text: string) => (
                <data className={classes.linkData} key={text}>
                  {!inProgress && !error && text}
                </data>
              )
            })}
          </Typography>
          {inProgress && <CircularProgress className={classes.loading} data-field="circular-progress" />}
        </Grid>
        <Paper className={classes.overview} data-field="incident-overview" elevation={0}>
          {inProgress && <LinearProgress className={classes.indicator} data-field="linear-progress" />}

          <Grid container direction="column" data-field="incident-overview-grid">
            <OverviewFullRow withBorder>
              <OverViewRow label="reported" text={incident?.formattedDateWithTime} />
              <OverViewRow label="compromised" text={`${incident?.sender.displayName}<${incident?.sender.email}>`} />
            </OverviewFullRow>
            <OverviewFullRow withBorder>
              <OverViewRow label="by" text={incident?.createdBy || 'N/A'} />
              <OverViewRow label="subject" text={incident?.subject} />
            </OverviewFullRow>
            <OverviewFullRow>
              <OverViewRow
                label="impact"
                text={formatMessage('impact_text', {
                  internal: incident?.internalAttackCount || 0,
                  external: incident?.externalAttackCount || 0
                })}
              />
              <OverViewRow label="search_timeframe" text={incident?.timePeriodName} rawValue={incident?.timeframe} />
            </OverviewFullRow>
          </Grid>
        </Paper>

        {incidentLogic.searchInProgress && (
          <Paper className={classes.searchInProgress} data-field="incident-search-in-progress" elevation={0}>
            <CircularProgress
              className={classes.searchInProgressLoading}
              data-field="incident-search-in-progress-loader"
            />
            <Typography className={classes.title} data-field="incident-search-in-progress-title">
              {formatMessage('search_in_progress')}
            </Typography>
          </Paper>
        )}

        <Grid container direction="column">
          <ContentTabSelector
            BASE_I18N_KEY={BASE_I18N_KEY}
            tabs={TABS}
            analyticsEventName={analyticsLib.EVENTS.ATO_INCIDENT_VIEW}
          />
        </Grid>
      </Grid>
    ),
    [
      classes,
      error,
      formatMessage,
      formatMessageError,
      incident,
      incidentLogic,
      inProgress,
      OverviewFullRow,
      OverViewRow
    ]
  )
}

export default Incident
