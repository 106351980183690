import React, { useMemo } from 'react'

import { Typography, Grid } from '@barracuda-internal/bds-core'

import ReactCopyToClipboard from 'react-copy-to-clipboard'
import { useFormatMessage } from 'global/lib/localization'
import useCopyToClipboardLogic from 'global/components/lib/copyToClipboard/useCopyToClipboardLogic'

import styles from 'sen/components/lib/dialogs/addDomainWizardDialog/addDomainWizardDialogStyles'

const BASE_I18N_KEY = 'sen.app.add_domain_dialog.domain_values'

const DEFAULT_DOMAIN_TYPE = 'TXT'

export type DomainInfoConfig = [string, string, 2 | 4]

export interface DomainValues {
  txtDomain: string
  txtRecord: string
}

export interface DomainValuesProps {
  domainValues: DomainValues | undefined
  withTitle?: boolean
}

export const DomainValues: React.FC<DomainValuesProps> = ({ domainValues, withTitle = true }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(() => {
    if (!domainValues) {
      return null
    }

    const DomainValue = ({ domainInfoConfig }: { domainInfoConfig: DomainInfoConfig }) => {
      const [onCopyToClipBoard, isCopiedVisible] = useCopyToClipboardLogic()
      const [localizationPath, value, valueWidth] = domainInfoConfig
      return (
        <Grid item xs={valueWidth}>
          <Grid container direction="column">
            <Typography className={classes.subtitle}>{formatMessage(localizationPath)}</Typography>
            <div className={classes.domainValueWrapper}>
              <ReactCopyToClipboard text={value as string} onCopy={onCopyToClipBoard}>
                <Typography className={classes.copyText} noWrap>
                  {value}
                </Typography>
              </ReactCopyToClipboard>
            </div>
            <Typography className={classes.copiedText}>{isCopiedVisible && formatMessage('copied')}&nbsp;</Typography>
          </Grid>
        </Grid>
      )
    }

    return (
      <>
        {withTitle && <Typography className={classes.domainValueTitleText}>{formatMessage('title')}</Typography>}
        <Grid className={classes.domainValuesWrapper} container direction="row">
          {([
            ['domain_name', domainValues.txtDomain, 4],
            ['type', DEFAULT_DOMAIN_TYPE, 2],
            ['value', domainValues.txtRecord, 6]
          ] as DomainInfoConfig[]).map(domainInfoConfig => (
            <DomainValue key={domainInfoConfig[0]} domainInfoConfig={domainInfoConfig} />
          ))}
        </Grid>
      </>
    )
  }, [classes, domainValues, formatMessage, withTitle])
}

export default DomainValues
