import React, { useMemo } from 'react'

import {
  Button,
  DataTable,
  DataTableColumn,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Tooltip,
  Typography
} from '@barracuda-internal/bds-core'
import { Attachment as AttachmentIcon } from '@barracuda-internal/bds-core/dist/Icons/Email'

import { useIntl } from 'react-intl'
import { INCIDENT_EMAIL_DIRECTION, INCIDENT_EMAIL_STATUS } from 'fir/redux/types/Remediation'

import Cell from 'global/components/lib/grid/cell/Cell'
import ColumnMenu from 'global/components/lib/grid/columnMenu/ColumnMenu'
import TextFilter from 'global/components/lib/grid/filter/TextFilter'
import CellIcon from 'global/components/lib/grid/cell/CellIcon'
import CellText from 'global/components/lib/grid/cell/CellText'
import gridStyles from 'global/components/lib/grid/gridStyles'
import IMAGES from 'global/configs/theme/images.config'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'

import { useFormatMessage } from 'global/lib/localization'

import DropdownFilter from './DropdownFilter'
import useEmailByIncidentLogic, { EmailsByIncidentProps } from './useEmailsByIncidentLogic'
import styles from './emailsByIncidentStyles'

const BASE_I18N_KEY = 'fir.app.remediation.email_by_incident'

const EmailsByIncident: React.FC<EmailsByIncidentProps> = () => {
  const classes = styles()
  const gridClasses = gridStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const intl = useIntl()

  const [emailsByIncidentLogic] = useEmailByIncidentLogic()

  return useMemo(() => {
    /* eslint-disable @typescript-eslint/no-empty-function */
    const {
      columnsConfig,
      columnMenuConfig,
      expandConfig,
      exportPath,
      filterConfig,
      GRID_COLUMNS,
      gridData,
      isColumnActive = () => {},
      isDeleteDialogOpen = false,
      isPageInProgress = false,
      onDeleteExistingEmails,
      onExportData,
      pageConfig,
      showDeleteEmails = false,
      sortConfig,
      toggleDeleteDialog
    } = emailsByIncidentLogic
    /* eslint-enable @typescript-eslint/no-empty-function */

    return (
      <>
        <iframe className={classes.exportIframe} src={exportPath} title="export-emails" />
        <Dialog open={isDeleteDialogOpen}>
          <DialogTitle>{formatMessage('delete_dialog.title', { count: gridData.total })}</DialogTitle>
          <DialogContent>
            <DialogContentText>{formatMessage('delete_dialog.content')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="text" onClick={toggleDeleteDialog}>
              {formatMessage('delete_dialog.cancel')}
            </Button>
            <Button color="primary" variant="contained" onClick={onDeleteExistingEmails}>
              {formatMessage('delete_dialog.delete')}
            </Button>
          </DialogActions>
        </Dialog>
        <Paper className={classes.wrapper} elevation={1}>
          <div className={classes.header}>
            <div className={classes.title}>
              <Typography variant="h5">{formatMessage('labels.page_title')}</Typography>
              <Typography variant="subtitle1">{formatMessage('labels.page_subtitle')}</Typography>
            </div>
            <Button className={classes.exportButton} color="primary" variant="contained" onClick={onExportData}>
              {formatMessage('labels.export_csv')}
            </Button>
            {showDeleteEmails && (
              <Button
                className={`${classes.exportButton} ${classes.buttonSpacing}`}
                color="secondary"
                variant="contained"
                onClick={toggleDeleteDialog}
              >
                {formatMessage('labels.delete_existing_emails')}
              </Button>
            )}
          </div>
          {isPageInProgress && <LinearProgress />}
          <DataTable
            className={gridClasses.gridLayout}
            pageable
            {...sortConfig}
            {...pageConfig}
            {...expandConfig}
            {...filterConfig}
            data={gridData}
          >
            <DataTableColumn
              field={GRID_COLUMNS.RECEIVED_DATE}
              {...columnsConfig[GRID_COLUMNS.RECEIVED_DATE]}
              title={formatMessage('email_table.received_date')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <div className={classes.dateWrapper}>
                    <CellText>{dataItem.receivedDate}</CellText>
                  </div>
                  <div className={classes.directionWrapper}>
                    {dataItem.sendingDirection === INCIDENT_EMAIL_DIRECTION.INCOMING && (
                      <>
                        <img alt="Inbound Icon" src={IMAGES.emailInbound} /> <CellText>Inbound</CellText>
                      </>
                    )}
                    {dataItem.sendingDirection === INCIDENT_EMAIL_DIRECTION.OUTGOING && (
                      <>
                        <img alt="Outbound Icon" src={IMAGES.emailOutbound} /> <CellText>Outbound</CellText>
                      </>
                    )}
                  </div>
                </Cell>
              )}
            />
            <DataTableColumn
              filterable
              filter="text"
              field={GRID_COLUMNS.SENDER_EMAIL}
              headerClassName={isColumnActive(GRID_COLUMNS.SENDER_EMAIL) ? 'active' : ''}
              {...columnsConfig[GRID_COLUMNS.SENDER_EMAIL]}
              title={formatMessage('email_table.sender_email')}
              cell={({ dataItem }: { dataItem: any }) => <Cell>{dataItem.senderEmail}</Cell>}
              columnMenu={(columnProps: any) => (
                <ColumnMenu
                  {...columnProps}
                  {...columnMenuConfig}
                  filterUI={({ firstFilterProps }: any) => (
                    <TextFilter
                      intl={intl}
                      firstFilterProps={firstFilterProps}
                      operators={['DJANGO_CONTAINS', 'DJANGO_DOES_NOT_CONTAIN']}
                    />
                  )}
                />
              )}
            />
            <DataTableColumn
              filterable
              filter="text"
              field={GRID_COLUMNS.AFFECTED_MAILBOX}
              headerClassName={isColumnActive(GRID_COLUMNS.AFFECTED_MAILBOX) ? 'active' : ''}
              {...columnsConfig[GRID_COLUMNS.AFFECTED_MAILBOX]}
              title={formatMessage('email_table.affected_mailbox')}
              columnMenu={(columnProps: any) => (
                <ColumnMenu
                  {...columnProps}
                  {...columnMenuConfig}
                  filterUI={({ firstFilterProps }: any) => (
                    <TextFilter
                      intl={intl}
                      firstFilterProps={firstFilterProps}
                      operators={['DJANGO_CONTAINS', 'DJANGO_DOES_NOT_CONTAIN']}
                    />
                  )}
                />
              )}
              cell={({ dataItem }: { dataItem: any }) => {
                return (
                  <Cell>
                    <CellText>
                      {dataItem.displayName && <span className={classes.bold}>{dataItem.displayName}</span>}{' '}
                      {dataItem.email}
                    </CellText>
                  </Cell>
                )
              }}
            />
            <DataTableColumn
              filterable
              filter="text"
              field={GRID_COLUMNS.SUBJECT}
              {...columnsConfig[GRID_COLUMNS.SUBJECT]}
              headerClassName={isColumnActive(GRID_COLUMNS.SUBJECT) ? 'active' : ''}
              title={formatMessage('email_table.subject')}
              columnMenu={(columnProps: any) => (
                <ColumnMenu
                  {...columnProps}
                  {...columnMenuConfig}
                  filterUI={({ firstFilterProps }: any) => (
                    <TextFilter
                      intl={intl}
                      firstFilterProps={firstFilterProps}
                      operators={['DJANGO_CONTAINS', 'DJANGO_DOES_NOT_CONTAIN']}
                    />
                  )}
                />
              )}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <CellText>{dataItem.subject}</CellText>
                </Cell>
              )}
            />
            <DataTableColumn
              field={GRID_COLUMNS.STATUS}
              filterable
              filter="text"
              columnMenu={(columnProps: any) => (
                <ColumnMenu
                  {...columnProps}
                  {...columnMenuConfig}
                  filterUI={({ firstFilterProps }: any) => (
                    <DropdownFilter
                      intl={intl}
                      firstFilterProps={firstFilterProps}
                      operators={['DJANGO_REMEDIATED', 'DJANGO_NOT_REMEDIATED']}
                    />
                  )}
                />
              )}
              {...columnsConfig[GRID_COLUMNS.STATUS]}
              headerClassName={`${classes.statusColumn} ${isColumnActive(GRID_COLUMNS.STATUS) ? 'active' : ''}`}
              title={formatMessage('email_table.status')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell className={classes.statusColumn}>
                  <div className={classes.statusWrapper}>
                    {dataItem.isContinuousRemediation
                      ? {
                          [INCIDENT_EMAIL_STATUS.DELETED]: (
                            <Tooltip title={formatMessage('email_table.tooltip.creRemediated')} placement="left-end">
                              <img alt="Continuous Remediation Success Icon" src={IMAGES.statusCRESuccess} />
                            </Tooltip>
                          ),
                          [INCIDENT_EMAIL_STATUS.QUARANTINED]: (
                            <Tooltip title={formatMessage('email_table.tooltip.creRemediated')} placement="left-end">
                              <img alt="Continuous Remediation Success Icon" src={IMAGES.statusCRESuccess} />
                            </Tooltip>
                          ),
                          [INCIDENT_EMAIL_STATUS.NOT_FOUND]: (
                            <Tooltip title={formatMessage('email_table.tooltip.creNotFound')} placement="left-end">
                              <img alt="Continuous Remediation Success Icon" src={IMAGES.statusCRESuccess} />
                            </Tooltip>
                          ),
                          [INCIDENT_EMAIL_STATUS.ERROR]: (
                            <Tooltip title={formatMessage('email_table.tooltip.creError')} placement="left-end">
                              <img alt="Continuous Remediation Failed Icon" src={IMAGES.statusCREFailed} />
                            </Tooltip>
                          )
                        }[dataItem.status]
                      : {
                          [INCIDENT_EMAIL_STATUS.FOUND]: (
                            <Tooltip title={formatMessage('email_table.tooltip.found')} placement="left-end">
                              <img alt="Remediation Found Icon" src={IMAGES.statusInfo} />
                            </Tooltip>
                          ),
                          [INCIDENT_EMAIL_STATUS.IN_PROGRESS]: (
                            <Tooltip title={formatMessage('email_table.tooltip.inProgress')} placement="left-end">
                              <img alt="Remediation Pending Icon" src={IMAGES.statusPending} />
                            </Tooltip>
                          ),
                          [INCIDENT_EMAIL_STATUS.DELETED]: (
                            <Tooltip title={formatMessage('email_table.tooltip.remediated')} placement="left-end">
                              <img alt="Remediation Success Icon" src={IMAGES.statusSuccess} />
                            </Tooltip>
                          ),
                          [INCIDENT_EMAIL_STATUS.QUARANTINED]: (
                            <Tooltip title={formatMessage('email_table.tooltip.remediated')} placement="left-end">
                              <img alt="Remediation Success Icon" src={IMAGES.statusSuccess} />
                            </Tooltip>
                          ),
                          [INCIDENT_EMAIL_STATUS.NOT_FOUND]: (
                            <Tooltip title={formatMessage('email_table.tooltip.notFound')} placement="left-end">
                              <img alt="Remediation Success Icon" src={IMAGES.statusSuccess} />
                            </Tooltip>
                          ),
                          [INCIDENT_EMAIL_STATUS.ERROR]: (
                            <Tooltip title={formatMessage('email_table.tooltip.error')} placement="left-end">
                              <img alt="Remediation Failed Icon" src={IMAGES.statusFailed} />
                            </Tooltip>
                          )
                        }[dataItem.status]}
                  </div>
                </Cell>
              )}
            />
            <DataTableColumn
              {...columnsConfig[GRID_COLUMNS.ATTACHMENTS]}
              headerClassName={classes.cellIcon}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell className={classes.cellIcon}>
                  <CellIcon className={!dataItem.hasAttachment ? classes.hidden : ''} icon={AttachmentIcon} />
                </Cell>
              )}
            />
          </DataTable>
        </Paper>
      </>
    )
  }, [classes, emailsByIncidentLogic, formatMessage, gridClasses.gridLayout, intl])
}

export default EmailsByIncident
