/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react'

import { Grid, Typography, Paper, Tabs, Tab } from '@barracuda-internal/bds-core'
import { snakeCase } from 'lodash'

import { useFormatMessage } from 'global/lib/localization'

import { DmarcReviewTabs, SectionsProps } from 'sen/components/pages/domainFraudDmarcReview/domainFraudDmarcReviewTypes'
import styles from 'sen/components/pages/domainFraudDmarcReview/content/domainFraudDmarcReviewContentStyles'
import useDmarcReviewSourcesLogic from 'sen/components/pages/domainFraudDmarcReview/content/sections/useDmarcReviewSourcesLogic'
import UnknownTab from 'sen/components/pages/domainFraudDmarcReview/content/sections/tabs/UnknowTab'
import ApprovedTab from 'sen/components/pages/domainFraudDmarcReview/content/sections/tabs/ApprovedTab'
import RejectedTab from 'sen/components/pages/domainFraudDmarcReview/content/sections/tabs/RejectedTab'

export interface DmarcReviewSourcesProps extends SectionsProps {
  activeTab: DmarcReviewTabs
}

export interface DmarcTotalProps {
  intlPath: string
  value: number | undefined
  fullWidth?: boolean
  dataField?: string
}

const BASE_I18N_KEY = 'sen.app.domain_fraud_dmarc_review.sources'

const DmarcReviewSources: React.FC<DmarcReviewSourcesProps> = ({ activeTab, domain }) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [logicConfig, eventHandlers] = useDmarcReviewSourcesLogic({ activeTab, domain })

  const DmarcTotal: React.FC<DmarcTotalProps> = React.useCallback(
    ({ intlPath, value, fullWidth = false, dataField }) => {
      if (!value) {
        return null
      }

      return (
        <Grid item xs={fullWidth ? 12 : 6}>
          <Grid container direction="column">
            <Typography className={classes.dmarcTotalCount} data-field={dataField}>
              {value}
            </Typography>
            <Typography className={classes.dmarcTotalText}>{formatMessage(intlPath)}</Typography>
          </Grid>
        </Grid>
      )
    },
    [classes, formatMessage]
  )

  return useMemo(() => {
    return (
      <Grid className={classes.container} container direction="column" data-field="root-grid">
        <Grid container direction="column">
          <Typography className={classes.title} data-field="dmarc-review-sources-title">
            {formatMessage('title')}
          </Typography>
          <Typography className={classes.subtitle} data-field="dmarc-review-sources-subtitle">
            {formatMessage('subtitle')}
          </Typography>
        </Grid>
        <Paper className={classes.sourceSectionPaper}>
          <Grid container direction="column">
            <Tabs
              className={classes.tabs}
              value={logicConfig.selectedTab}
              onChange={eventHandlers.onTabChange}
              classes={{
                indicator: classes.indicator
              }}
              data-field="dmarc-review-sources-tablist"
              data-active-tab={logicConfig.selectedTab}
            >
              {[DmarcReviewTabs.unknownState, DmarcReviewTabs.approved, DmarcReviewTabs.rejected].map((tab: string) => (
                <Tab
                  classes={{ root: classes.tabRoot }}
                  label={
                    <Grid container direction="column">
                      <Typography className={classes.tabTitle} align="left" data-field="tab-title">
                        {formatMessage(`tabs.${snakeCase(tab)}`)}
                      </Typography>
                      <Typography className={classes.tabSubtitle} align="left" data-field="tab-subtitle">
                        {formatMessage(`tabs.${snakeCase(tab)}_subtitle`)}
                      </Typography>
                    </Grid>
                  }
                  value={tab}
                  key={tab}
                />
              ))}
            </Tabs>
            <Grid container direction="row">
              <Grid className={classes.dmarcTotalsContainer} container direction="row">
                <DmarcTotal
                  intlPath="high_volume"
                  value={logicConfig.domainTotals?.totalMajor}
                  dataField="total-major"
                />
                <DmarcTotal
                  intlPath="low_volume"
                  value={logicConfig.domainTotals?.totalMinor}
                  dataField="total-minor"
                />
              </Grid>
              <Grid className={classes.dmarcTotalsContainer} container direction="column">
                <DmarcTotal
                  intlPath="sources_failing"
                  value={logicConfig.domainTotals?.totalDmarcFailures}
                  fullWidth
                  dataField="total-dmarc-failures"
                />
              </Grid>
            </Grid>
            <Grid className={classes.tabContent} item>
              {logicConfig.selectedTab === DmarcReviewTabs.unknownState && <UnknownTab domain={domain} />}
              {logicConfig.selectedTab === DmarcReviewTabs.approved && <ApprovedTab domain={domain} />}
              {logicConfig.selectedTab === DmarcReviewTabs.rejected && <RejectedTab domain={domain} />}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  }, [classes, formatMessage, logicConfig, eventHandlers, domain, DmarcTotal])
}

export default DmarcReviewSources
