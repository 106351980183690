import React, { useMemo } from 'react'

import GlobalConvergedMessageLog from 'global/components/features/convergedMessageLog/ConvergedMessageLog'

import Layout from 'sen/components/lib/layout/Layout'

import useConvergedMessageLogLogic from './useConvergedMessageLogLogic'

const ConvergedMessageLog: React.FC = () => {
  const [convergedMessageLogsLogic] = useConvergedMessageLogLogic()
  const { bccId, pageTitle, pageSubtitle } = convergedMessageLogsLogic

  return useMemo(
    () => (
      <Layout pageSubtitle={pageSubtitle} pageTitle={pageTitle} isMastheadEnabled={false}>
        <GlobalConvergedMessageLog bccId={bccId} />
      </Layout>
    ),
    [bccId, pageSubtitle, pageTitle]
  )
}

export default ConvergedMessageLog
