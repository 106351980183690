import { useMemo, useEffect, useCallback, useReducer, useRef } from 'react'
import { Duration } from 'luxon'

import { IconButtonProps, MenuProps } from '@barracuda-internal/bds-core'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { formatDate, formatDateWithTime, humanizeDuration, isAfterDate, timeDifference } from 'global/lib/datetime'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import { setCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'
import { getScanStats, getScanThreatLog, reset as resetScan } from 'global/redux/features/scan/scanSlice'
import { setError, resetError } from 'global/redux/features/report/reportSlice'
import { isPending } from 'global/redux/toolkit/api'
import { ScanStats } from 'global/types/api/scan'
import styles, { NAV_BAR_HEIGHT } from 'global/components/lib/layout/layoutStyles'
import { TabsProps } from 'global/components/lib/Tabs/Tabs'
import { Account } from 'global/types/api/accountType'
import useUserDataLib from 'global/lib/userData/useUserData'

import { getEmployeesReport } from 'ets/redux/features/reports/employees/employeesSlice'
import { getThreatsReport } from 'ets/redux/features/reports/threats/threatsSlice'
import { getDomainsReport } from 'ets/redux/features/reports/domains/domainsSlice'
import { getAttacksByDomainReport } from 'ets/redux/features/reports/attacksByDomain/attacksByDomainSlice'
import { getChartsReports } from 'ets/redux/features/reports/chartReport/chartReportSlice'
import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'
import {
  activateReportSharing,
  deactivateReportSharing,
  deleteEtsReport
} from 'ets/redux/features/settings/settingsApiThunks'
import { IsUserInputDisabledForTable } from 'ets/components/pages/dashboard/isUserInputDisabledForTableType'
import { rerunCurrentScan } from 'ets/redux/features/settings/settingsSlice'

import routesConfig from 'ets/lib/routes/routesConfig'
import { useFormatMessage } from 'global/lib/localization'

export const SCAN_STATUSES = {
  COMPLETED: 'scan_completed',
  IN_PROGRESS: 'scan_in_progress',
  PREPARING: 'scan_preparing'
}

export interface ModifiedScanStats extends ScanStats {
  duration: string
  finishedOn: string
  name: string | undefined
  percentageCompleted: number
  remaining: string
  threatsDetected: number
  triggeredOn: string
}

export interface OptionsMenu {
  action: () => void
  disabled: boolean
  id: string
}
export interface DashboardLogic {
  error?: string | false
  fixTableHeight: (tableId: string, newHeight: number) => void
  getSectionElement: (section: string) => HTMLElement | null
  getShareLinkExpiration: () => string
  getReportShareLink: () => string
  isShareChecked: boolean
  isShareInProgress: boolean
  inProgress: boolean
  isButtonHidden: boolean
  isDeleteDialogOpened: boolean
  isDeleteInProgress: boolean
  isUserInputDisabledForTable: IsUserInputDisabledForTable
  isNewScan: boolean
  scanCount: number
  tabsConfig: any
  moreInfoButtonMenuOptions: {
    iconButtonProps: IconButtonProps
    menuItems: OptionsMenu[]
    menuProps: MenuProps
  }
  onDeleteScan: () => void
  onNewScan: () => void
  onShare: () => void
  onToggleDeleteDialog: () => void
  scan: ModifiedScanStats | undefined
  scanStatus: string
  scrollToSection: (sectionIndex: number) => void
  sendShareTrackingEvent: () => void
}

export type State = {
  activeTab: string | undefined
  anchorOptionsMenu: React.SyntheticEvent['currentTarget'] | undefined
  createNewScan: boolean
  scanCount: number
}

export const TABS = {
  OVERVIEW: 'overview',
  EMPLOYEES: 'employees',
  THREATS: 'threats',
  DOMAINS: 'domains',
  SOURCES_OF_ATTACKS: 'attacks'
}

export const APP_HEADER_HEIGHT = NAV_BAR_HEIGHT
const BASE_I18N_KEY = 'ets.app.dashboard.new_scan'
const BASE_I18N_ERROR_KEY = 'ets.app.error'

export default function useDashboardLogic(): [DashboardLogic] {
  const isScanStartedToLoad = useRef(false)
  const dispatch = useAppDispatch()
  const [isDeleteDialogOpened, toggleDeleteDialog] = useDialogLogic()
  const [accessTokenLib] = useAccessTokenLib()
  const classes = styles()
  const [userDataLib] = useUserDataLib()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const {
    accessToken,
    error,
    isDeleteInProgress,
    isInitialScanStatsLoaded,
    isReportsInProgress,
    isScanInProgress,
    isShareSecretSet,
    isShareInProgress,
    isShareValid,
    isStatsInProgress,
    scan,
    userEmail
  } = useAppSelector(_stores => ({
    accessToken: _stores.accessToken?.accessToken,
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    error: _stores.report.error,
    isDeleteInProgress: isPending(_stores.settings.deleteEtsReportApiStatus),
    isInitialScanStatsLoaded: _stores.scan.isInitialScanStatsLoaded,
    isReportsInProgress: isPending(_stores.reports.employees.apiStatus),
    isScanInProgress: _stores.scan.stats?.id ? _stores.scan.stats.inProgress : true,
    isShareSecretSet: !!_stores.accessToken.shareSecret?.value,
    isStatsInProgress: !!_stores.scan.stats?.inProgress,
    isShareInProgress:
      isPending(_stores.settings.activateReportSharingApiStatus) ||
      isPending(_stores.settings.deactivateReportSharingApiStatus),
    isShareValid: isAfterDate({ initialDate: _stores.accessToken.accessToken?.reportSecret?.expires }),
    scan: _stores.scan,
    userEmail: _stores.user.data.email || ''
  }))

  const [state, setState] = useReducer((_state: State, newState: Partial<State>) => ({ ..._state, ...newState }), {
    activeTab: accessToken?.id,
    anchorOptionsMenu: undefined,
    createNewScan: false,
    scanCount: 0
  })

  // check query params only on init
  useEffect(() => {
    if (window.location.toString().includes(analyticsLib.QUERY_PARAMS.PDF)) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.REPORT_OPENED_IN_PDF, {
        url: window.location.href
      })
    }
  }, [])

  useEffect(() => {
    // user is logged in but the report is being opened from email
    if (window.location.toString().includes('emailscancomplete')) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.REPORT_OPENED_IN_EMAIL, {
        url: window.location.href
      })
      routesConfig.DASHBOARD.goto({ reportId: accessToken?.id })
    }

    if (!isScanStartedToLoad.current) {
      isScanStartedToLoad.current = true
      dispatch(getScanStats())
      dispatch(getScanThreatLog())
    }

    if (isStatsInProgress)
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.VIEW_SCAN_IN_PROGRESS, { url: window.location.href })

    if (isInitialScanStatsLoaded && !isScanInProgress && !isReportsInProgress) {
      dispatch(getChartsReports({ accessTokenId: accessToken?.id }))
      dispatch(getEmployeesReport({ accessTokenId: accessToken?.id }))
      dispatch(getThreatsReport({ accessTokenId: accessToken?.id }))
      dispatch(getDomainsReport({ accessTokenId: accessToken?.id }))
      dispatch(getAttacksByDomainReport({ accessTokenId: accessToken?.id }))

      analyticsLib.trackAppEvent(analyticsLib.EVENTS.DASHBOARD_VIEW, { accessTokenId: accessToken?.id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isInitialScanStatsLoaded, isScanInProgress, accessToken?.id, isStatsInProgress])

  const inProgress = useMemo(() => {
    return !isInitialScanStatsLoaded && !state.createNewScan
  }, [isInitialScanStatsLoaded, state.createNewScan])

  const isUserInputDisabledForTable: IsUserInputDisabledForTable = useCallback(
    (tableData, reportData, isReportLoaded) => {
      const { totalCount } = reportData.report
      const isDataFiltered = !!tableData.search.length || !!tableData.filter

      return !isReportLoaded || (isReportLoaded && !totalCount && !isDataFiltered)
    },
    []
  )

  const tabsConfig = useMemo(() => {
    // Retrieve a list of the 5 most recent scans (represented by access token object)
    const recentEtsScanATs = userDataLib.getEtsAccessTokensByTenantId(accessToken?.tenantId || '').slice(0, 5)
    setState({ scanCount: 5 - recentEtsScanATs.length })

    // Create tab props for each recent scan
    const etsScanTabs = recentEtsScanATs.map(token => {
      return {
        id: token?.id,
        label: formatDate(token?.created),
        onClick: token
          ? () => {
              dispatch(setCurrentAccessToken(token.id))
              dispatch(getScanStats())
              setState({ activeTab: token?.id, createNewScan: false })
              routesConfig.DASHBOARD.softGoto({ reportId: token?.id })
            }
          : undefined
      }
    })

    // If there are less than 5 scans, add the `New Scan` tab
    if (etsScanTabs.length < 5) {
      etsScanTabs.push({
        id: 'newScan',
        label: `+ ${formatMessage('new_scan_tab')}`,
        onClick: () => {
          setState({ createNewScan: true, activeTab: 'newScan' })
          dispatch(resetScan())
        }
      })
    }

    return {
      activeTab: state.activeTab,
      tabs: etsScanTabs
    } as TabsProps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken?.tenantId, state.activeTab])

  const getSectionElement = useCallback((section: string): HTMLElement | null => {
    return document.getElementsByClassName(`${section}-section`)[0] as HTMLElement | null
  }, [])

  const fixTableHeight = useCallback((tableId: string, newHeight: number) => {
    const table = document.getElementsByClassName(`${tableId}-table`)[0] as HTMLElement | null

    if (table) {
      table.style.height = `${newHeight}px`
    }
  }, [])

  const scrollToSection = useCallback(
    (sectionIndex: number) => {
      const sectionElement = getSectionElement(Object.values(TABS)[sectionIndex])
      // Get the scrollable parent element
      const mainElement = document.querySelector(`.${classes.content}`)

      if (sectionElement) {
        if ('scrollBehavior' in document.documentElement.style && mainElement) {
          mainElement.scroll({
            top: sectionElement.offsetTop - APP_HEADER_HEIGHT,
            behavior: 'smooth'
          })
        } else {
          window.scroll(0, sectionElement.offsetTop)
        }
      }
    },
    [classes, getSectionElement]
  )

  const scanData: ModifiedScanStats | undefined = useMemo(() => {
    const { stats } = scan

    if (!stats.id) {
      return undefined
    }

    const spentTime = Math.round(timeDifference({ subtractedDate: stats.createdOn }).as('minute'))
    const totalTime = spentTime + stats.scanTimeRemaining

    return {
      ...stats,
      name: accessToken?.name,
      triggeredOn: formatDateWithTime(stats.createdOn),
      finishedOn: formatDateWithTime(stats.finishedOn),
      duration: humanizeDuration(
        timeDifference({ initialDate: stats.finishedOn, subtractedDate: stats.createdOn }).milliseconds
      ),
      threatsDetected: stats.spAttackCount,
      remaining: humanizeDuration(Duration.fromObject({ minutes: stats.scanTimeRemaining }).as('millisecond')),
      percentageCompleted: stats.scanTimeRemaining ? Math.floor((spentTime / totalTime) * 100) : 0
    }
  }, [scan, accessToken])

  const scanStatus = useMemo(() => {
    if (!scanData?.scanTimeRemaining) {
      return SCAN_STATUSES.PREPARING
    }
    if (scanData?.inProgress) {
      return SCAN_STATUSES.IN_PROGRESS
    }

    return SCAN_STATUSES.COMPLETED
  }, [scanData])

  const isButtonHidden = useMemo(() => {
    return scanData?.inProgress || scanStatus === SCAN_STATUSES.PREPARING || !scanData?.id || isShareSecretSet
  }, [scanData, scanStatus, isShareSecretSet])

  const onCloseMoreOptionsMenu = useCallback(() => {
    setState({ anchorOptionsMenu: undefined })
  }, [])

  const onToggleDeleteDialog = useCallback(() => {
    if (!isDeleteInProgress) {
      toggleDeleteDialog()
    }
  }, [isDeleteInProgress, toggleDeleteDialog])

  const onDeleteScan = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DELETE_REPORT, { accessToken: accessToken?.id })
    dispatch(deleteEtsReport())
  }, [dispatch, accessToken])

  const moreInfoButtonMenuOptions = useMemo(() => {
    return {
      iconButtonProps: {
        onClick: (e: React.SyntheticEvent) => {
          setState({ anchorOptionsMenu: e.currentTarget })
        }
      } as IconButtonProps,
      menuProps: {
        anchorEl: state.anchorOptionsMenu,
        open: Boolean(state.anchorOptionsMenu),
        onClose: onCloseMoreOptionsMenu
      } as MenuProps,
      menuItems: [
        {
          id: 'delete_scan',
          disabled: scanData?.inProgress || !scanData?.id,
          action: () => {
            onCloseMoreOptionsMenu()
            onToggleDeleteDialog()
          }
        }
      ] as OptionsMenu[]
    }
  }, [onToggleDeleteDialog, scanData, state, onCloseMoreOptionsMenu])

  const isAccessTokenSecretActive = useMemo(() => {
    return !!accessToken?.reportSecret?.active && !!isShareValid
  }, [accessToken, isShareValid])

  const onShare = useCallback(() => {
    if (isAccessTokenSecretActive) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.SHARE_LINK_DISABLED, { accessToken: accessToken?.id })
      dispatch(deactivateReportSharing())
    } else {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.SHARE_LINK_ENABLED, { accessToken: accessToken?.id })
      dispatch(activateReportSharing())
    }
  }, [dispatch, isAccessTokenSecretActive, accessToken])

  const sendShareTrackingEvent = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SHARE_REPORT, { accessToken: accessToken?.id })
  }, [accessToken])

  const getReportShareLink = useCallback(() => {
    return isAccessTokenSecretActive ? accessTokenLib.getAccessTokenShareLink(accessToken?.id) : ''
  }, [isAccessTokenSecretActive, accessTokenLib, accessToken])

  const getShareLinkExpiration = useCallback(() => {
    return isAccessTokenSecretActive ? accessTokenLib.getAccessTokenSecretExpires(accessToken?.id) : ''
  }, [isAccessTokenSecretActive, accessTokenLib, accessToken])

  const onNewScan = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.NEW_SCAN, { accessToken: accessToken?.id })
    dispatch(resetError())

    if (accessToken) {
      const { accountId } = userDataLib.getAccountByAccessToken(accessToken.id) || ({} as Account)
      dispatch(rerunCurrentScan({ accessTokenId: accessToken.id, accountId, email: userEmail }))
    }

    dispatch(setError(`${BASE_I18N_ERROR_KEY}.unable_to_load_scan`))
  }, [accessToken, userEmail, dispatch, userDataLib])

  return useMemo(
    () => [
      {
        error,
        fixTableHeight,
        getSectionElement,
        getShareLinkExpiration,
        getReportShareLink,
        inProgress,
        isButtonHidden,
        isDeleteDialogOpened,
        isDeleteInProgress,
        isNewScan: state.createNewScan,
        isShareChecked: isAccessTokenSecretActive,
        isShareInProgress,
        isUserInputDisabledForTable,
        moreInfoButtonMenuOptions,
        onDeleteScan,
        onNewScan,
        onShare,
        onToggleDeleteDialog,
        scan: scanData,
        scanCount: state.scanCount,
        scanStatus,
        scrollToSection,
        sendShareTrackingEvent,
        tabsConfig
      }
    ],
    [
      error,
      fixTableHeight,
      getReportShareLink,
      getSectionElement,
      getShareLinkExpiration,
      inProgress,
      isAccessTokenSecretActive,
      isButtonHidden,
      isDeleteDialogOpened,
      isDeleteInProgress,
      isShareInProgress,
      isUserInputDisabledForTable,
      moreInfoButtonMenuOptions,
      onDeleteScan,
      onNewScan,
      onShare,
      onToggleDeleteDialog,
      scanData,
      scanStatus,
      scrollToSection,
      sendShareTrackingEvent,
      state.createNewScan,
      state.scanCount,
      tabsConfig
    ]
  )
}
