import { useReducer, useMemo, useCallback, useEffect } from 'react'

import { formatDateWithTime, humanizeDuration, timeDifference, luxonDate } from 'global/lib/datetime'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import * as datetime from 'global/lib/datetime'
import * as dmarcLib from 'global/lib/domain/dmarc'
import attackTypeValidator from 'global/lib/attackTypeValidator/attackTypeValidator'
import { isSuccess } from 'global/redux/toolkit/api'

import { getPDFEmployeesReport } from 'ets/redux/features/reports/employees/employeesSlice'
import { getPDFThreatsReport } from 'ets/redux/features/reports/threats/threatsSlice'
import { getPDFAttacksByDomainReport } from 'ets/redux/features/reports/attacksByDomain/attacksByDomainSlice'
import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'
import getRiskTypeByScore from 'ets/lib/getRiskTypeByScore'

import { Page1CoverConfig } from 'ets/components/lib/PDFReport/pages/Page1Cover'
import { Page2SummaryConfig } from 'ets/components/lib/PDFReport/pages/Page2Summary'
import { Page3EmployeesConfig, Employee } from 'ets/components/lib/PDFReport/pages/Page3Employees'
import { Page4ThreatsConfig, Threat } from 'ets/components/lib/PDFReport/pages/Page4Threats'
import { Page5DomainsConfig } from 'ets/components/lib/PDFReport/pages/Page5Domains'
import { Page6AttacksConfig } from 'ets/components/lib/PDFReport/pages/Page6Attacks'
import { PDFFooterProps } from 'ets/components/lib/PDFReport/PDFFooter'
import { Domain } from 'ets/types/domainTypes'

export interface UsePDFReportLogicParams {
  isUIReportsLoaded: boolean
  setPDFRenderingStatus: (newStatus: boolean) => void
}

export type ShouldRenderPDF = boolean
export type SetPDFRenderingStatus = (newStatus: boolean) => void
export interface PDFConfig {
  filename: string
  page1: Page1CoverConfig | false
  page2: Page2SummaryConfig | false
  page3: Page3EmployeesConfig | false
  page4: Page4ThreatsConfig | false
  page5: Page5DomainsConfig | false
  page6: Page6AttacksConfig | false
  footer: PDFFooterProps
}
export type PDFReportLogicProps = [ShouldRenderPDF, SetPDFRenderingStatus, PDFConfig]

const TOP_COUNT = 10

export default function usePDFReportLogic(params: UsePDFReportLogicParams): PDFReportLogicProps {
  const { isUIReportsLoaded, setPDFRenderingStatus } = params
  const {
    reportName,
    reportDate,
    isPDFEmployeesLoaded,
    isPDFThreatsLoaded,
    isPDFAttacksLoaded,
    employeesWithThreatsCount,
    scanStats,
    domainsReport,
    pdfEmployeesReport,
    pdfThreatsReport,
    pdfDomainsReport,
    pdfAttacksReport
  } = useAppSelector(_stores => ({
    reportName: _stores.accessToken.accessToken?.name || '',
    reportDate: _stores.accessToken.accessToken?.created,
    isPDFEmployeesLoaded: isSuccess(_stores.reports.employees.pdfApiStatus),
    isPDFThreatsLoaded: isSuccess(_stores.reports.threats.pdfApiStatus),
    isPDFAttacksLoaded: isSuccess(_stores.reports.attacksByDomain.pdfApiStatus),
    employeesWithThreatsCount: _stores.reports.chartReport.employeesWithThreatsPerMonth.report?.identityTotal,
    scanStats: _stores.scan.stats,
    domainsReport: _stores.reports.domains.data?.report,
    pdfEmployeesReport: _stores.reports.employees.pdfData?.report,
    pdfThreatsReport: _stores.reports.threats.pdfData?.report,
    pdfDomainsReport: _stores.reports.domains.pdfData?.report,
    pdfAttacksReport: _stores.reports.attacksByDomain.pdfData?.report
  }))
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    isPDFReportsStartedToLoad: false,
    threatTypesFoundBlob: false,
    totalThreatsFoundBlob: false,
    employeesWithThreatsBlob: false
  })
  const dispatch = useAppDispatch()

  // init
  useEffect(() => {
    // unmount
    return () => {
      // revoke blob urls to free up memory
      const blobs: string[] = ['threatTypesFoundBlob', 'totalThreatsFoundBlob', 'employeesWithThreatsBlob']
      blobs.forEach((blobUrl: string) => {
        if (state[blobUrl]) {
          URL.revokeObjectURL(state[blobUrl])
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isPDFReportsLoaded = useMemo(() => {
    return isPDFAttacksLoaded && isPDFThreatsLoaded && isPDFEmployeesLoaded
  }, [isPDFAttacksLoaded, isPDFThreatsLoaded, isPDFEmployeesLoaded])

  const shouldRenderPDF = useMemo(() => {
    return (
      isUIReportsLoaded &&
      state.threatTypesFoundBlob &&
      state.totalThreatsFoundBlob &&
      state.employeesWithThreatsBlob &&
      isPDFReportsLoaded
    )
  }, [
    isUIReportsLoaded,
    state.threatTypesFoundBlob,
    state.totalThreatsFoundBlob,
    state.employeesWithThreatsBlob,
    isPDFReportsLoaded
  ])

  const generateBlobUrl = useCallback((chartId: any, stateId: any) => {
    const ctx: any = document.querySelector(`[data-chart-panel="${chartId}"] canvas`)

    if (ctx) {
      ctx.toBlob((blob: any) => {
        setState({ [stateId]: URL.createObjectURL(blob) })
      })
    }
  }, [])

  // genereate blob urls for images
  useEffect(() => {
    if (isPDFReportsLoaded && !state.threatTypesFoundBlob) {
      generateBlobUrl('threat-types-found', 'threatTypesFoundBlob')
      generateBlobUrl('total-threats-found', 'totalThreatsFoundBlob')
      generateBlobUrl('employees-with-threats', 'employeesWithThreatsBlob')
    }
  }, [generateBlobUrl, state.threatTypesFoundBlob, isPDFReportsLoaded])

  // load PDF related reports
  useEffect(() => {
    if (isUIReportsLoaded && !state.isPDFReportsStartedToLoad) {
      dispatch(getPDFEmployeesReport())
      dispatch(getPDFThreatsReport())
      dispatch(getPDFAttacksByDomainReport())
      setState({ isPDFReportsStartedToLoad: true })
    }
  }, [dispatch, isUIReportsLoaded, state.isPDFReportsStartedToLoad])

  const page1Config: Page1CoverConfig | false = useMemo(() => {
    if (!shouldRenderPDF) {
      return false
    }

    return {
      reportName,
      reportDate: datetime.formatDate(reportDate)
    }
  }, [shouldRenderPDF, reportName, reportDate])

  const page2Config: Page2SummaryConfig | false = useMemo(() => {
    if (!shouldRenderPDF) {
      return false
    }

    const dmarCounts = dmarcLib.dmarcCounts(domainsReport?.data || [], true)

    return {
      scanStats: {
        completedOn: formatDateWithTime(scanStats.finishedOn),
        duration: humanizeDuration(
          timeDifference({ initialDate: scanStats.finishedOn, subtractedDate: scanStats.createdOn }).milliseconds
        ),
        emailsScanned: scanStats.emailCount.toLocaleString(),
        threatsDetected: scanStats.spAttackCount.toLocaleString()
      },
      employeesWithThreatsCount,
      threatTypesFoundBlob: state.threatTypesFoundBlob,
      totalThreatsFoundBlob: state.totalThreatsFoundBlob,
      employeesWithThreatsBlob: state.employeesWithThreatsBlob,
      dmarcCounts: [
        {
          id: 'not_configured',
          count: dmarCounts[dmarcLib.DMARC_STATES.UNPROTECTED],
          color: 'dmarcRed'
        },
        {
          id: 'reporting_mode',
          count: dmarCounts[dmarcLib.DMARC_STATES.REPORTING],
          color: 'dmarcYellow'
        },
        {
          id: 'enforcement_mode',
          count: dmarCounts[dmarcLib.DMARC_STATES.PROTECTED],
          color: 'dmarcGreen'
        }
      ]
    }
  }, [
    shouldRenderPDF,
    state.threatTypesFoundBlob,
    state.totalThreatsFoundBlob,
    state.employeesWithThreatsBlob,
    scanStats,
    employeesWithThreatsCount,
    domainsReport
  ])

  const page3Config: Page3EmployeesConfig | false = useMemo(() => {
    if (!shouldRenderPDF) {
      return false
    }

    const { riskLevels } = pdfEmployeesReport
    const employees = pdfEmployeesReport.data
      .filter((employee: Employee) => employee.spFraudCount > 0)
      .map((employee: Employee) => ({
        ...employee,
        riskType: getRiskTypeByScore(employee.spScore)
      }))

    return {
      riskLevels: {
        allEmployees: riskLevels.high + riskLevels.medium + riskLevels.low,
        highRisk: riskLevels.high,
        mediumRisk: riskLevels.medium,
        lowRisk: riskLevels.low
      },
      topCount: String(employees.length === TOP_COUNT ? `${TOP_COUNT} ` : ''),
      employees
    }
  }, [shouldRenderPDF, pdfEmployeesReport])

  const page4Config: Page4ThreatsConfig | false = useMemo(() => {
    if (!shouldRenderPDF) {
      return false
    }

    const threats = pdfThreatsReport.data.map((threat: Threat) => ({
      ...threat,
      formattedDate: formatDateWithTime(threat.date),
      validatedAttackType: attackTypeValidator(threat.taxonomy)
    }))

    return {
      topCount: String(threats.length === TOP_COUNT ? `${TOP_COUNT} ` : ''),
      threats
    }
  }, [shouldRenderPDF, pdfThreatsReport])

  const page5Config: Page5DomainsConfig | false = useMemo(() => {
    if (!shouldRenderPDF) {
      return false
    }

    const domains =
      pdfDomainsReport?.data.slice(0, 10).map((domain: Domain) => {
        const [dmarcState] = domain.dmarcState

        return {
          ...domain,
          policy: domain.dmarc.policy,
          dmarcState,
          dmarcImage: `${dmarcLib.DMARC_STATE_IMAGES[dmarcState]}Png`
        }
      }) || []

    return {
      topCount: String(domains?.length === TOP_COUNT ? `${TOP_COUNT} ` : ''),
      domains
    }
  }, [shouldRenderPDF, pdfDomainsReport])

  const page6Config: Page6AttacksConfig | false = useMemo(() => {
    if (!shouldRenderPDF) {
      return false
    }

    const attacks = pdfAttacksReport.data

    return {
      topCount: String(attacks.length === TOP_COUNT ? `${TOP_COUNT} ` : ''),
      attacks
    }
  }, [shouldRenderPDF, pdfAttacksReport])

  const footerConfig: PDFFooterProps = useMemo(() => {
    return {
      reportUrl: `${window.location.href}?${analyticsLib.QUERY_PARAMS.PDF}=true`
    }
  }, [])

  const pdfFilename = useMemo(() => {
    if (!shouldRenderPDF) {
      return ''
    }

    return `${reportName.slice(0, 3)}_ETS_report_${luxonDate().toFormat('yyyyMMdd')}.pdf`
  }, [shouldRenderPDF, reportName])

  return useMemo(
    () => [
      shouldRenderPDF,
      setPDFRenderingStatus,
      {
        filename: pdfFilename,
        page1: page1Config as Page1CoverConfig,
        page2: page2Config as Page2SummaryConfig,
        page3: page3Config as Page3EmployeesConfig,
        page4: page4Config,
        page5: page5Config,
        page6: page6Config,
        footer: footerConfig
      }
    ],
    [
      shouldRenderPDF,
      setPDFRenderingStatus,
      pdfFilename,
      page1Config,
      page2Config,
      page3Config,
      page4Config,
      page5Config,
      page6Config,
      footerConfig
    ]
  )
}
