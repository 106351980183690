import { useMemo, useCallback, useState, useEffect } from 'react'

import { isFailed, isPending, isSuccess, getErrorMessage } from 'global/redux/toolkit/api'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { useAppDispatch, useAppSelector } from 'admin/redux/toolkit/hooks'
import { sendThreatsFolderView, resetSendThreatsFolderView } from 'admin/redux/features/admin/adminSlice'

type InProgress = boolean
type Error = boolean
type ErrorMsg = string | undefined
type Success = boolean
type SendThreatsReport = (scanUUID: string, accessTokenId: string, accountId: string, email: string) => void

export default function useThreatsFolderDialogLogic(): [InProgress, Error, ErrorMsg, Success, SendThreatsReport] {
  const {
    isSendThreatsReportPending,
    isSendThreatsReportSuccess,
    isSendThreatsReportFailed,
    apiErrorMsg
  } = useAppSelector(_stores => ({
    isSendThreatsReportFailed: isFailed(_stores.admin.sendThreatsFolderViewApiStatus),
    isSendThreatsReportPending: isPending(_stores.admin.sendThreatsFolderViewApiStatus),
    isSendThreatsReportSuccess: isSuccess(_stores.admin.sendThreatsFolderViewApiStatus),
    apiErrorMsg: getErrorMessage(_stores.admin.sendThreatsFolderViewApiStatus)
  }))
  const [payloadIsValid, setPayloadIsValid] = useState(true)

  const dispatch = useAppDispatch()

  // Init
  useEffect(() => {
    return () => {
      dispatch(resetSendThreatsFolderView())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSendThreatsReport = useCallback(
    (scanUUID: string, accessTokenId: string, accountId: string, email: string) => {
      const isValid = scanUUID && accessTokenId && accountId && email
      setPayloadIsValid(!!isValid)

      if (isValid) {
        const payload = {
          scanUUID,
          accessTokenId,
          accountId,
          email
        }

        analyticsLib.trackAppEvent(analyticsLib.EVENTS.ADMIN_DASH_SEND_REPORT, { payload })
        dispatch(sendThreatsFolderView(payload))
      } else {
        setPayloadIsValid(false)
      }
    },
    [dispatch, setPayloadIsValid]
  )

  return useMemo(() => {
    const isLoading = isSendThreatsReportPending
    const error = isSendThreatsReportFailed || !payloadIsValid
    const errorMsg = !payloadIsValid ? 'request_report_invalid' : apiErrorMsg || undefined
    const success = isSendThreatsReportSuccess
    return [isLoading, error, errorMsg, success, onSendThreatsReport]
  }, [
    isSendThreatsReportPending,
    isSendThreatsReportFailed,
    isSendThreatsReportSuccess,
    onSendThreatsReport,
    payloadIsValid,
    apiErrorMsg
  ])
}
