import * as React from 'react'
import PropTypes from 'prop-types'

import { Dialog, DialogTitle, DialogContent, Typography, Zoom } from '@barracuda-internal/bds-core'

import DEFAULTS from 'global/configs/theme/defaults'
import { useFormatMessage } from 'global/lib/localization'

import styles from 'admin/components/lib/dialogs/deactivate/deactivateDialogStyles'

export interface DeactivateDialogProps {
  accessTokenId: string
}

const BASE_I18N_KEY = 'admin.app.dialogs.deactivate_dialog'

export const DeactivateDialog: React.FC<DeactivateDialogProps> = ({ accessTokenId }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return (
    <Dialog
      style={{ zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX }}
      maxWidth="md"
      fullWidth
      open
      TransitionComponent={Zoom}
      data-field="dialog"
    >
      <DialogTitle className={classes.titleWrapper} disableTypography data-field="title-wrapper">
        <Typography className={classes.title} data-field="dialog-title">
          {formatMessage('title')}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} data-field="dialog-content">
        <Typography className={classes.text} data-field="dialog-subtitle">
          {formatMessage('text', { accessTokenId, span: (text: string) => <span key={text}>{text}</span> })}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

DeactivateDialog.propTypes = {
  accessTokenId: PropTypes.string.isRequired
}

export default DeactivateDialog
