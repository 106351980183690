import React, { useMemo } from 'react'

import { BlobProvider, Document, Font } from '@react-pdf/renderer'
import { useIntl } from 'react-intl'

import usePDFReportLogic, { UsePDFReportLogicParams } from 'ets/components/lib/PDFReport/usePDFReportLogic'

import Page1Cover, { Page1CoverConfig } from 'ets/components/lib/PDFReport/pages/Page1Cover'
import Page2Summary, { Page2SummaryConfig } from 'ets/components/lib/PDFReport/pages/Page2Summary'
import Page3Employees, { Page3EmployeesConfig } from 'ets/components/lib/PDFReport/pages/Page3Employees'
import Page4Threats, { Page4ThreatsConfig } from 'ets/components/lib/PDFReport/pages/Page4Threats'
import Page5Domains, { Page5DomainsConfig } from 'ets/components/lib/PDFReport/pages/Page5Domains'
import Page6Attacks, { Page6AttacksConfig } from 'ets/components/lib/PDFReport/pages/Page6Attacks'

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

Font.register({
  family: 'Roboto',
  format: 'truetype',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf',
      fontWeight: 'normal'
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAw.ttf',
      fontWeight: 'bold'
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu52xP.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBhc9.ttf',
      fontWeight: 'bold',
      fontStyle: 'italic'
    }
  ]
})

export type PDFReportProps = UsePDFReportLogicParams

const CustomDocument: any = Document

const PDFReport: React.FC<PDFReportProps> = props => {
  const intl = useIntl()
  const [shouldRenderPDF, setPDFRenderingStatus, pdfConfig] = usePDFReportLogic(props)

  const report = useMemo(() => {
    return (
      <CustomDocument>
        <Page1Cover intl={intl} pdfConfig={{ ...(pdfConfig.page1 as Page1CoverConfig) }} />
        <Page2Summary
          intl={intl}
          pdfConfig={{ ...(pdfConfig.page2 as Page2SummaryConfig) }}
          footerConfig={{ ...pdfConfig.footer }}
        />
        <Page3Employees
          intl={intl}
          pdfConfig={{ ...(pdfConfig.page3 as Page3EmployeesConfig) }}
          footerConfig={{ ...pdfConfig.footer }}
        />
        <Page4Threats
          intl={intl}
          pdfConfig={{ ...(pdfConfig.page4 as Page4ThreatsConfig) }}
          footerConfig={{ ...pdfConfig.footer }}
        />
        <Page5Domains
          intl={intl}
          pdfConfig={{ ...(pdfConfig.page5 as Page5DomainsConfig) }}
          footerConfig={{ ...pdfConfig.footer }}
        />
        <Page6Attacks
          intl={intl}
          pdfConfig={{ ...(pdfConfig.page6 as Page6AttacksConfig) }}
          footerConfig={{ ...pdfConfig.footer }}
        />
      </CustomDocument>
    )
  }, [pdfConfig, intl])

  return useMemo(() => {
    if (!shouldRenderPDF) {
      return null
    }

    return (
      <BlobProvider document={report}>
        {({ blob, url, loading }) => {
          setPDFRenderingStatus(loading)

          if (loading) {
            return null
          }

          return (
            // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label
            <a
              href={url || ''}
              download={pdfConfig.filename}
              onClick={e => {
                if (window.navigator && window.navigator.msSaveBlob) {
                  e.preventDefault()
                  window.navigator.msSaveBlob(blob, pdfConfig.filename)
                }
              }}
            />
          )
        }}
      </BlobProvider>
    )
  }, [report, shouldRenderPDF, setPDFRenderingStatus, pdfConfig.filename])
}

export default PDFReport
