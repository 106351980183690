import { makeStyles, createStyles } from '@material-ui/core/styles'

import { COLORS } from 'ets/configs/styles/defaults'

const button = {
  height: 32,
  marginLeft: 10
}

const commonStyles = createStyles({
  text: {
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '16px',
    whiteSpace: 'nowrap',
    cursor: 'default'
  },
  smallText: {
    fontSize: 12
  },
  largerText: {
    fontSize: 16,
    letterSpacing: 0,
    lineHeight: '19px',
    whiteSpace: 'nowrap',
    cursor: 'default'
  },
  largerBoldText: {
    fontSize: 18,
    letterSpacing: 0,
    whiteSpace: 'nowrap',
    cursor: 'default',
    fontWeight: 'bold',
    lineHeight: '21px'
  }
})

export default makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  headerCard: {
    position: 'relative',
    marginTop: theme.spacing(2),
    minWidth: 960,
    width: '100%'
  },
  progressArea: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 36,
    fontWeight: 900,
    letterSpacing: 0.77,
    lineHeight: '42px',
    cursor: 'default'
  },
  newScanButton: {
    ...button,
    width: 112
  },
  smallText: {
    ...commonStyles.smallText,
    color: theme.palette.text.secondary
  },
  text: {
    ...commonStyles.text,
    color: theme.palette.text.secondary
  },
  blackText: {
    ...commonStyles.text,
    color: theme.palette.text.primary
  },
  largerBlackText: {
    ...commonStyles.largerText,
    color: theme.palette.text.primary
  },
  largerBlackBoldText: {
    ...commonStyles.largerBoldText,
    color: theme.palette.text.primary
  },
  largerRedBoldText: {
    ...commonStyles.largerBoldText,
    color: COLORS.ERROR
  },
  statusTitle: {
    ...commonStyles.text,
    color: theme.palette.text.secondary,
    marginBottom: 3
  },
  blackBoldText: {
    ...commonStyles.text,
    color: theme.palette.text.primary,
    fontWeight: 'bold'
  },
  timeDuration: {
    ...commonStyles.text,
    color: theme.palette.text.secondary,
    marginBottom: 3,
    placeSelf: 'flex-end'
  },
  timeDurationWrapper: {
    display: 'flex'
  },
  rightContent: {
    marginLeft: 50
  },
  progressWrapper: {
    marginTop: 20
  },
  deleteIcon: {
    right: -12
  },
  loadingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
  progressBar: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-root': {
        display: 'block'
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiTypography-root': {
        display: 'block',
        marginRight: theme.spacing(0.25)
      }
    },
    [theme.breakpoints.up('lg')]: {
      '& .MuiTypography-root': {
        display: 'block'
      }
    }
  },
  linearProgress: {
    marginTop: theme.spacing(1),
    height: theme.spacing(1),
    backgroundColor: COLORS.INPUT_BACKGROUNDS,

    '& > div': {
      backgroundColor: COLORS.GREEN
    }
  },
  tableArea: {
    marginTop: -10,
    minHeight: 413,

    '& .k-grid': {
      border: 0
    },

    '& .k-grid-content': {
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none'
    },

    '& ::-webkit-scrollbar': {
      display: 'none'
    }
  },
  tableTitle: {
    ...commonStyles.largerText,
    marginLeft: theme.spacing(5)
  },
  dataTable: {
    '& th': {
      fontWeight: theme.font.weight.medium
    },
    '& .k-grid-norecords': {
      height: 335
    }
  },
  noRecordText: {
    ...commonStyles.largerText,
    color: theme.palette.text.secondary
  }
}))
