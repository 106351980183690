import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { Typography, Paper, Alert } from '@barracuda-internal/bds-core'
import { Edit as CreateIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { Grid, GridColumn } from '@progress/kendo-react-grid'

import Breadcrumbs from 'global/components/lib/breadcrumbs/Breadcrumbs'
import ColumnMenu from 'global/components/lib/grid/columnMenu/ColumnMenu'
import { Cell, CellIcon, CellText, CellAction } from 'global/components/lib/grid/cell'
import TextFilter from 'global/components/lib/grid/filter/TextFilter'
import cellsStyles from 'global/components/lib/grid/cell/cellStyles'
import gridStyles from 'global/components/lib/grid/gridStyles'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import * as datetime from 'global/lib/datetime'
import highlightText from 'global/lib/highlightText/highlightText'
import { useFormatMessage } from 'global/lib/localization'

import NewIncidentDialog from 'fir/components/lib/newIncidentDialog/NewIncidentDialog'
import insightsLogic, { InsightsProps } from 'fir/components/pages/insights/InsightsLogic'
import useEmailsByRegionCountryLogic, { EmailsByRegionCountryProps } from './useEmailsByRegionCountryLogic'

import styles from './EmailsByRegionCountryStyles'

const BASE_I18N_KEY = 'fir.app.insights.emails_by_region_country'

const EmailsByRegionCountry: React.FC<InsightsProps & EmailsByRegionCountryProps> = () => {
  const classes = styles()
  const gridClasses = gridStyles()
  const cellClasses = cellsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const intl = useIntl()
  const [emailsByRegionCountryLogic] = useEmailsByRegionCountryLogic()

  return useMemo(() => {
    const {
      breadcrumbList,
      columnsConfig,
      columnMenuConfig,
      dateRange,
      emailsTotalCount = 0,
      expandConfig,
      filterConfig,
      GRID_COLUMNS,
      gridData,
      groupConfig,
      highlights,
      isColumnActive,
      isMoreResultsLoading = false,
      isPageInProgress,
      newIncidentDialogConfig,
      onClosePageAlert,
      onCreateIncident,
      pageConfig,
      showCountryPageError = false,
      sortConfig
    } = emailsByRegionCountryLogic

    return (
      <>
        {isPageInProgress && <LinearProgress />}
        {newIncidentDialogConfig.isOpened && <NewIncidentDialog {...newIncidentDialogConfig} />}
        <div className={classes.breadcrumb}>
          <Breadcrumbs list={breadcrumbList} />
        </div>
        {showCountryPageError && (
          <Alert variant="filled" severity="error" onClose={onClosePageAlert} className={classes.pageAlert}>
            {formatMessage('page_error')}
          </Alert>
        )}
        <Paper className={classes.emailsTotalCountWrapper} elevation={1}>
          <Typography variant="h6" className={classes.emailsTotalDates}>
            {formatMessage('emails_in')} {datetime.formatDate(dateRange.start)} - {datetime.formatDate(dateRange.end)}
          </Typography>
          <div className={classes.emailsTotalCount}>{emailsTotalCount}</div>
        </Paper>

        <Paper className={classes.wrapper} elevation={1}>
          {isMoreResultsLoading && <LinearProgress />}
          <Grid
            {...({} as any)}
            className={gridClasses.gridLayout}
            sortable
            {...sortConfig}
            pageable
            {...pageConfig}
            groupable
            {...groupConfig}
            {...expandConfig}
            {...filterConfig}
            data={gridData}
          >
            <GridColumn
              field={GRID_COLUMNS.RECEIVED}
              {...columnsConfig[GRID_COLUMNS.RECEIVED]}
              title={formatMessage('received_date')}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <CellText>{dataItem.formattedCreated}</CellText>
                </Cell>
              )}
            />
            <GridColumn
              field={GRID_COLUMNS.SENDER}
              {...columnsConfig[GRID_COLUMNS.SENDER]}
              title={formatMessage('sender')}
              headerClassName={isColumnActive(GRID_COLUMNS.SENDER) ? 'active' : ''}
              filterable
              filter="text"
              columnMenu={(columnProps: any) => (
                <ColumnMenu
                  {...columnProps}
                  {...columnMenuConfig}
                  filterUI={({ firstFilterProps }: any) => (
                    <TextFilter intl={intl} firstFilterProps={firstFilterProps} />
                  )}
                />
              )}
              cell={({ dataItem }: { dataItem: any }) => {
                if (!dataItem.sender) {
                  return null
                }

                const [senderName, senderAddress] = dataItem.sender.split('<')

                return (
                  <Cell>
                    <CellText>
                      {senderName && <b>{highlightText(senderName, highlights.sender)}</b>}
                      {senderAddress && (
                        <>
                          <br />
                          {highlightText(`<${senderAddress}`, highlights.address)}
                        </>
                      )}
                    </CellText>
                  </Cell>
                )
              }}
            />
            <GridColumn
              field={GRID_COLUMNS.UNIQUE_RECIPIENTS}
              {...columnsConfig[GRID_COLUMNS.UNIQUE_RECIPIENTS]}
              title={formatMessage('unique_recipients')}
              headerClassName={isColumnActive(GRID_COLUMNS.UNIQUE_RECIPIENTS) ? 'active' : ''}
              sortable={false}
              filterable
              filter="numeric"
              columnMenu={(columnProps: any) => <ColumnMenu {...columnProps} {...columnMenuConfig} />}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <CellText>{dataItem.uniqueRecipients}</CellText>
                </Cell>
              )}
            />
            <GridColumn
              field={GRID_COLUMNS.SUBJECT}
              {...columnsConfig[GRID_COLUMNS.SUBJECT]}
              title={formatMessage('subject')}
              headerClassName={isColumnActive(GRID_COLUMNS.SUBJECT) ? 'active' : ''}
              filterable
              filter="text"
              columnMenu={(columnProps: any) => (
                <ColumnMenu
                  {...columnProps}
                  {...columnMenuConfig}
                  filterUI={({ firstFilterProps }: any) => (
                    <TextFilter intl={intl} firstFilterProps={firstFilterProps} />
                  )}
                />
              )}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell>
                  <CellText ellipsis>{highlightText(dataItem.subject, highlights.subject)}</CellText>
                </Cell>
              )}
            />
            <GridColumn
              {...columnsConfig[GRID_COLUMNS.CREATE_INCIDENT]}
              groupable={false}
              sortable={false}
              headerClassName={cellClasses.createIncidentCell}
              cell={({ dataItem }: { dataItem: any }) => (
                <Cell className={cellClasses.createIncidentCell}>
                  <CellAction
                    onClick={() => {
                      onCreateIncident(dataItem.emailId)
                    }}
                  >
                    <CellIcon icon={CreateIcon} />
                    <CellText>{formatMessage('create_incident')}</CellText>
                  </CellAction>
                </Cell>
              )}
            />
          </Grid>
        </Paper>
      </>
    )
  }, [cellClasses.createIncidentCell, classes, emailsByRegionCountryLogic, formatMessage, gridClasses.gridLayout, intl])
}

export default insightsLogic(EmailsByRegionCountry)
