import { useMemo, useCallback, useReducer } from 'react'

import { process } from '@progress/kendo-data-query'

import { SearchFieldProps, useSearchFieldLogic } from 'global/components/lib/searchField/SearchField'
import { BDSGridPagerConfig, BDSGridSortConfig } from 'global/types/dataTables/dataTables'
import { ATTACK_TYPES, ETS_ATTACK_TYPES } from 'global/lib/attackTypeValidator/attackTypeValidator'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { config } from 'global/lib/config'
import { formatDateWithTime, isBeforeDate, luxonDate } from 'global/lib/datetime'
import { isPending } from 'global/redux/toolkit/api'
import useUserDataLib from 'global/lib/userData/useUserData'

import { update as updateThreatsTable } from 'ets/redux/features/dataTables/threats/threatsSlice'
import { getThreatsReport, resetThreatsReport } from 'ets/redux/features/reports/threats/threatsSlice'
import { IsUserInputDisabledForTable } from 'ets/components/pages/dashboard/isUserInputDisabledForTableType'
import useEmailDetailsSpAttackInterface, {
  UseEmailDetailsSpAttackInterface
} from 'ets/components/lib/dialogs/emailDetailsDialog/interfaces/useEmailDetailsSpAttackInterface'

import {
  TABLE_CELL_HEIGHT,
  TABLE_HEADER_HEIGHT,
  TABLE_FOOTER_HEIGHT
} from 'ets/components/pages/dashboard/threats/dashboardThreatsStyles'
import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'
import { ColumnsConfig } from 'ets/redux/types/dataTables'
import { FEATURES, isMyFeatureOn } from 'global/lib/splitio/splitio'

const SEARCH_FIELD_ID = 'threats-search'

const TIMESPAN = {
  THIRTY_DAYS: 30,
  SIXTY_DAYS: 60,
  NINETY_DAYS: 90,
  ONE_TWENTY_DAYS: 120,
  ONE_YEAR: 365
}

export interface SelectedAttack {
  id: string
  formattedDate: string
}

export interface UseDashboardThreatsLogic {
  attackSelectorConfig: any
  timespanSelectorConfig: any
  columnsConfig: ColumnsConfig
  GRID_COLUMNS: {
    [key: string]: string
  }
  highlightKeywords: string[]
  inProgress: boolean
  isReportLoaded: boolean
  isUserInputDisabled: boolean
  isViewEmailsDisabled: boolean
  pageConfig: BDSGridPagerConfig
  searchFieldConfig: SearchFieldProps
  selectedAttack: SelectedAttack | null
  selectedUser: string | null
  sortConfig: BDSGridSortConfig | {}
  tableData: {
    total: number
    data: any[]
  }
  tableTotal: number | string
}

export interface UseDashboardThreatsLogicProps {
  isUserInputDisabledForTable: IsUserInputDisabledForTable
  fixTableHeight: (tableId: string, newHeight: number) => void
}

export default function useDashboardThreatsLogic(
  props: UseDashboardThreatsLogicProps
): [UseDashboardThreatsLogic, UseEmailDetailsSpAttackInterface] {
  const { isUserInputDisabledForTable, fixTableHeight } = props
  const dispatch = useAppDispatch()
  const [userDataLib] = useUserDataLib()
  const {
    accessTokenId,
    finishedOn,
    inProgress,
    isReportLoaded,
    loadedOffsets,
    reportData,
    splitStore,
    threatsTable
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    finishedOn: _stores.scan.stats.finishedOn,
    inProgress: isPending(_stores.reports.threats.apiStatus),
    isReportLoaded: !!_stores.reports.threats.data.accessTokenId,
    loadedOffsets: _stores.reports.threats.loadedOffsets,
    reportData: _stores.reports.threats.data,
    splitStore: _stores.splitio,
    threatsTable: _stores.dataTables.threats
  }))

  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    searchString: '',
    selectedAttack: null,
    isAttackSelectorOpened: false,
    selectedAttackType: threatsTable.ADVANCED_THREATS,
    isTimespanSelectorOpened: false,
    selectedTimespan: TIMESPAN.ONE_YEAR
  })

  const [emailDetailDialogConfig, emailDetailDialogActions] = useEmailDetailsSpAttackInterface()

  // TODO: BNFIR-4234: may need to remove this depending on what happens with the results of the investigation.
  const accountId = useMemo(() => userDataLib.getAccountByAccessToken(accessTokenId)?.accountId, [
    accessTokenId,
    userDataLib
  ])
  // END TODO

  const openAttackDetailDialog = useCallback(
    (attackId: string) => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.VIEW_THREATS_DETAILS, { accessTokenId })
      emailDetailDialogActions.onOpen(attackId)
    },
    [accessTokenId, emailDetailDialogActions]
  )

  const updateTableData = useCallback(() => {
    dispatch(updateThreatsTable({ skip: 0 }))
    dispatch(resetThreatsReport())
    dispatch(getThreatsReport())
  }, [dispatch])

  const isUserInputDisabled = useMemo(() => {
    return isUserInputDisabledForTable(threatsTable, reportData, isReportLoaded) || inProgress
  }, [reportData, isReportLoaded, threatsTable, isUserInputDisabledForTable, inProgress])

  const isViewEmailsDisabled = useMemo(() => {
    const maxRetention = luxonDate()
      .minus({ days: config.MAX_EMAIL_RETENTION_DAYS })
      .toISO()
    return isBeforeDate({ initialDate: finishedOn, subtractedDate: maxRetention })
  }, [finishedOn])

  const [debouncedOnChange, validateSearchString] = useSearchFieldLogic(
    (search: string) => {
      dispatch(updateThreatsTable({ search: search.trim() }))
      setState({ searchString: search.trim() })
      updateTableData()
    },
    inProgress,
    SEARCH_FIELD_ID
  )

  const searchFieldConfig = useMemo(() => {
    return {
      id: SEARCH_FIELD_ID,
      value: state.searchString,
      onChange: (e: any) => {
        const validatedSearchString = validateSearchString(e.target.value)
        if (threatsTable.search !== validatedSearchString) {
          debouncedOnChange(validatedSearchString)
          setState({ searchString: validatedSearchString })
        }
      },
      disabled: isUserInputDisabled
    }
  }, [state, isUserInputDisabled, threatsTable, validateSearchString, debouncedOnChange])

  const tableTotal = useMemo(() => {
    return isReportLoaded ? reportData.report.totalCount : threatsTable.DEMO_DATA.ALL
  }, [reportData, isReportLoaded, threatsTable])

  const tableLabelTotal = useMemo(() => {
    if (isReportLoaded && threatsTable.skip === 0 && inProgress) {
      return '-'
    }

    return tableTotal
  }, [isReportLoaded, threatsTable.skip, inProgress, tableTotal])

  const tableData = useMemo(() => {
    const { skip, take } = threatsTable

    const { data } = process(
      (reportData.report.data || []).map((report: any) => ({
        ...(report && {
          ...report,
          formattedDate: formatDateWithTime(report.date),
          seeDetails: openAttackDetailDialog
        })
      })),
      { skip, take }
    )

    const fixedCount = Math.max(reportData.report.totalCount, 4)
    const newHeight =
      fixedCount <= threatsTable.ITEMS_PER_PAGE
        ? fixedCount * TABLE_CELL_HEIGHT + TABLE_HEADER_HEIGHT
        : TABLE_CELL_HEIGHT * threatsTable.ITEMS_PER_PAGE + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT

    fixTableHeight('threats', newHeight)

    return {
      data: data.filter(report => report.id),
      total: tableTotal
    }
  }, [reportData, openAttackDetailDialog, threatsTable, tableTotal, fixTableHeight])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = threatsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateThreatsTable(e.page))

        if (!loadedOffsets.includes(e.page.skip)) {
          dispatch(getThreatsReport())
        }
      }
    }
  }, [threatsTable, tableData, dispatch, loadedOffsets])

  const sortConfig: BDSGridSortConfig | {} = useMemo(() => {
    if (!tableData.total) {
      return {}
    }

    return {
      sortable: !isUserInputDisabled && {
        allowUnsort: false,
        mode: 'single'
      },
      sort: threatsTable.sort,
      onSortChange: (e: any) => {
        dispatch(updateThreatsTable({ sort: e.sort }))
        updateTableData()
      }
    }
  }, [threatsTable, dispatch, tableData.total, isUserInputDisabled, updateTableData])

  const attackSelectorConfig = useMemo(() => {
    const dashboardMenu = document.querySelector('[data-menu="true"]')
    const onCloseAttackSelector = () => {
      setState({ isAttackSelectorOpened: false })
      if (dashboardMenu) {
        dashboardMenu.removeEventListener('click', onCloseAttackSelector)
      }
    }

    // TODO: BNFIR-4234: may need to remove this depending on what happens with the results of the investigation.
    let updatedMenuItems

    if (isMyFeatureOn(splitStore, FEATURES.ETS_BEC_IMPERSONATION, accountId)) {
      updatedMenuItems = Object.values(ETS_ATTACK_TYPES).filter(item => item !== ETS_ATTACK_TYPES.IMPERSONATION)
    } else {
      updatedMenuItems = Object.values(ETS_ATTACK_TYPES).filter(item => item !== ETS_ATTACK_TYPES.IMPERSONATION_BEC)
    }
    // END TODO

    return {
      menuItems: [
        {
          id: threatsTable.ADVANCED_THREATS,
          isSelected: state.selectedAttackType === threatsTable.ADVANCED_THREATS
        },
        {
          id: threatsTable.ALL_THREATS,
          isSelected: state.selectedAttackType === threatsTable.ALL_THREATS
        },
        // TODO: BNFIR-4234: if we do not proceed replace `updatedMenuItems` with `ETS_ATTACK_TYPES`
        ...Object.values(updatedMenuItems).map((attackType: string) => ({
          id: attackType,
          isSelected: state.selectedAttackType === attackType
        }))
      ],
      open: state.isAttackSelectorOpened,
      onOpen: () => {
        setState({ isAttackSelectorOpened: true })
        if (dashboardMenu) {
          dashboardMenu.addEventListener('click', onCloseAttackSelector)
        }
      },
      onClose: onCloseAttackSelector,
      disabled: isUserInputDisabled,
      onChange: (e: any) => {
        if (state.selectedAttackType !== e.target.value) {
          // TODO: If we do not proceed with  BNFIR-4234 uncomment this line
          // const filterValue = e.target.value === ETS_ATTACK_TYPES.SCAMMING ? ATTACK_TYPES.SCAMMING : e.target.value

          // TODO: BNFIR-4234: may need to remove this depending on what happens with the results of the investigation.
          let filterValue

          switch (e.target.value) {
            case ETS_ATTACK_TYPES.SCAMMING:
              filterValue = ATTACK_TYPES.SCAMMING
              break
            case ETS_ATTACK_TYPES.IMPERSONATION_BEC:
              filterValue = ATTACK_TYPES.IMPERSONATION
              break
            default:
              filterValue = e.target.value
          }
          // END TODO

          setState({ selectedAttackType: e.target.value })

          dispatch(
            updateThreatsTable({
              filter: threatsTable.FILTER_CONFIG[filterValue].filterQuery
            })
          )

          updateTableData()
        }
      },
      value: state.selectedAttackType
    }
  }, [
    state.selectedAttackType,
    state.isAttackSelectorOpened,
    threatsTable.FILTER_CONFIG,
    dispatch,
    threatsTable.ALL_THREATS,
    threatsTable.ADVANCED_THREATS,
    updateTableData,
    isUserInputDisabled,
    accountId,
    splitStore
  ])

  const timespanSelectorConfig = useMemo(() => {
    const dashboardMenu = document.querySelector('[data-menu="true"]')
    const onCloseTimespanSelector = () => {
      setState({ isTimespanSelectorOpened: false })
      if (dashboardMenu) {
        dashboardMenu.removeEventListener('click', onCloseTimespanSelector)
      }
    }

    return {
      menuItems: [
        { id: TIMESPAN.THIRTY_DAYS, isSelected: state.selectedTimespan === TIMESPAN.THIRTY_DAYS },
        { id: TIMESPAN.SIXTY_DAYS, isSelected: state.selectedTimespan === TIMESPAN.SIXTY_DAYS },
        { id: TIMESPAN.NINETY_DAYS, isSelected: state.selectedTimespan === TIMESPAN.NINETY_DAYS },
        {
          id: TIMESPAN.ONE_TWENTY_DAYS,
          isSelected: state.selectedTimespan === TIMESPAN.ONE_TWENTY_DAYS
        },
        { id: TIMESPAN.ONE_YEAR, isSelected: state.selectedTimespan === TIMESPAN.ONE_YEAR }
      ],
      open: state.isTimespanSelectorOpened,
      onOpen: () => {
        setState({ isTimespanSelectorOpened: true })
        if (dashboardMenu) {
          dashboardMenu.addEventListener('click', onCloseTimespanSelector)
        }
      },
      onClose: onCloseTimespanSelector,
      disabled: isUserInputDisabled,
      onChange: (e: any) => {
        if (state.selectedTimespan !== e.target.value) {
          setState({ selectedTimespan: e.target.value })
          dispatch(updateThreatsTable({ timespan: e.target.value }))
          updateTableData()
        }
      },
      value: state.selectedTimespan
    }
  }, [state.selectedTimespan, state.isTimespanSelectorOpened, dispatch, updateTableData, isUserInputDisabled])

  return useMemo(() => {
    return [
      {
        attackSelectorConfig,
        columnsConfig: threatsTable.columnsConfig,
        GRID_COLUMNS: threatsTable.GRID_COLUMNS,
        highlightKeywords: [threatsTable.search],
        inProgress,
        isReportLoaded,
        isUserInputDisabled,
        isViewEmailsDisabled,
        pageConfig,
        searchFieldConfig,
        selectedAttack: state.selectedAttack,
        selectedUser: state.selectedUser,
        sortConfig,
        tableTotal: tableLabelTotal,
        tableData,
        timespanSelectorConfig
      },
      [emailDetailDialogConfig, emailDetailDialogActions]
    ]
  }, [
    attackSelectorConfig,
    threatsTable.columnsConfig,
    threatsTable.GRID_COLUMNS,
    threatsTable.search,
    inProgress,
    isReportLoaded,
    isUserInputDisabled,
    isViewEmailsDisabled,
    pageConfig,
    searchFieldConfig,
    state.selectedAttack,
    state.selectedUser,
    sortConfig,
    tableLabelTotal,
    tableData,
    emailDetailDialogConfig,
    emailDetailDialogActions,
    timespanSelectorConfig
  ])
}
