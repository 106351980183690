import { useMemo, useCallback, useReducer } from 'react'

import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { setNotifyExternalEmailValues } from 'sen/redux/features/remediation/remediationSlice'

export interface NotificationEmailATOTemplate {
  subject: string
  body: string
}

export interface FormConfig {
  template: NotificationEmailATOTemplate
  onChange: OnFormChange
  onSave: OnSave
}

export type InProgress = boolean
export type Error = string | undefined
export type OnFormChange = (field: keyof NotificationEmailATOTemplate) => (e: any) => void
export type OnSave = () => void

export interface UseNotificationEmailDialogLogicProps {
  onClose: () => void
  selectedSender: {
    displayName: string
    email: string
  }
}

export default function useNotificationEmailDialogLogic({
  onClose,
  selectedSender
}: UseNotificationEmailDialogLogicProps): [FormConfig] {
  const dispatch = useAppDispatch()

  const { accessTokenId, createIncidentId, createIncident } = useAppSelector(_stores => ({
    createIncidentId: _stores.remediation?.currentIncident?.id || '',
    createIncident: _stores.remediation?.currentIncident,
    accessTokenId: _stores.accessToken.accessToken?.id || ''
  }))

  const defaultExternalNotificationBodyString: string =
    'Hi there,\n\n' +
    'I would like to inform you that you have recently received an email that we believe is malicious from one of our corporate accounts.' +
    'We apologize for the inconvenience and recommend that you avoid clicking links inside this email and/or opening its attachments.\n\n' +
    'Details:\n' +
    `The email came from: ${createIncident?.sender.displayName} <${createIncident?.sender.email}>\n` +
    'The email\'s subject contained: "<<subject>>"\n' +
    'The email was sent: <<date>>\n\n' +
    `Thanks,\n${selectedSender?.displayName}\n${selectedSender?.email}`

  const defaultExternalNotificationSubjectString = `Warning: you have recently received a malicious email from ${createIncident?.sender.email}`

  const [templateState, setTemplateState] = useReducer(
    (_state: Partial<NotificationEmailATOTemplate>, newState: NotificationEmailATOTemplate) => ({
      ..._state,
      ...newState
    }),
    {
      subject: defaultExternalNotificationSubjectString || '',
      body: defaultExternalNotificationBodyString || ''
    }
  )

  const onFormChange: OnFormChange = useCallback(field => {
    return e => {
      setTemplateState({ [field]: e.target.value || '' } as NotificationEmailATOTemplate)
    }
  }, [])

  const onSave: OnSave = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.ACCOUNT_COMPROMISE_WIZARD_NOTIFY_EXTERNAL, {
      accessTokenId,
      incidentId: createIncidentId,
      notificationSender: selectedSender,
      notificationBody: templateState.body,
      notificationSubject: templateState.subject
    })

    dispatch(
      setNotifyExternalEmailValues({
        incidentId: createIncidentId,
        notificationSender: selectedSender,
        notificationBody: templateState.body,
        notificationSubject: templateState.subject
      })
    )

    onClose()
  }, [createIncidentId, selectedSender, templateState, dispatch, accessTokenId, onClose])

  return useMemo(() => {
    return [
      {
        template: templateState,
        onChange: onFormChange,
        onSave
      }
    ]
  }, [templateState, onFormChange, onSave])
}
