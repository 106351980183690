// TODO: Bring in from BDS when we are able to use V2

import React, { useMemo } from 'react'

import {
  DateRange,
  DateRangePicker as MUIDateRangePicker,
  DateRangePickerProps as MUIDateRangePickerProps,
  SingleInputDateRangeField,
  PickersShortcutsItem
} from '@mui/x-date-pickers-pro'

import { Calendar as CalendarIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Typography } from '@barracuda-internal/bds-core'

/**
 * Props for the DateRangePicker component.
 */

export type DateRangePickerProps = {
  /**
   * If `true`, the selected shortcut item label will be shown inside the input
   */
  selectedShortcutLabel?: string
  /**
   * The shortcut items of the date range picker.
   */
  shortcutsItems?: PickersShortcutsItem<DateRange<any>>[]
  /**
   * If `true`, the date range picker will be displayed as a single input.
   */
  singleInput?: boolean
  /**
   * Specifies if an error occurred in the date range picker.
   */
  error?: boolean
  /**
   * The error message to display when an error occurs.
   *
   */
  errorMessage?: string | undefined
} & MUIDateRangePickerProps<any>

export type CustomDateRangePickerProps = MUIDateRangePickerProps<any>

export const DateRangePicker = ({
  selectedShortcutLabel,
  shortcutsItems,
  singleInput = false,
  error = false,
  errorMessage,
  ...rest
}: DateRangePickerProps): JSX.Element => {
  return useMemo(
    () => (
      <MUIDateRangePicker
        sx={{ minWidth: '300px' }}
        slots={singleInput ? { field: SingleInputDateRangeField } : {}}
        slotProps={{
          textField: {
            error: !!error,
            helperText: errorMessage,
            size: 'small',
            sx: {
              minWidth: '380px',
              textAlign: 'center'
            },
            InputProps: {
              endAdornment: singleInput ? <CalendarIcon color="secondary" /> : null,
              startAdornment: selectedShortcutLabel && (
                <Typography style={{ minWidth: '110px', marginRight: '5px' }}>{selectedShortcutLabel}</Typography>
              )
            }
          },
          shortcuts: {
            items: shortcutsItems,
            changeImportance: 'set',
            sx: {
              '.MuiChip-root': {
                borderRadius: '0px',
                backgroundColor: 'white',
                height: '48px'
              }
            }
          },
          actionBar: { actions: [] }
        }}
        calendars={2}
        {...rest}
      />
    ),
    [error, errorMessage, rest, selectedShortcutLabel, shortcutsItems, singleInput]
  )
}

export default DateRangePicker
