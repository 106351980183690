import { useEffect } from 'react'

import * as cloudLib from 'global/lib/cloud/cloud'
import { config } from 'global/lib/config'

export default function useSignupLogic(): void {
  useEffect(() => {
    cloudLib.bccSignin(config.SCAN_TYPES.FORENSICS)
  }, [])
}
