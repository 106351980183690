import React, { useMemo } from 'react'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'
import { DataTable, DataTableColumn, Paper, Typography, Grid } from '@barracuda-internal/bds-core'

import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import SearchField from 'global/components/lib/searchField/SearchField'
import { Cell, CellText } from 'global/components/lib/grid/cell'
import { useFormatMessage } from 'global/lib/localization'
import Pager from 'global/components/lib/grid/pager/Pager'
import { EtsModifiedScan } from 'global/types/api/scan'
import CellLabelType from 'global/components/lib/grid/cell/CellLabelType'

import useEmailThreatScannerLogic from 'admin/components/pages/dashboard/tabs/emailThreatScanner/useEmailThreatScannerLogic'
import Metrics from 'admin/components/pages/dashboard/metrics/Metrics'
import styles from 'admin/components/pages/dashboard/tabs/emailThreatScanner/emailThreatScannerStyles'
import ScanDialog from 'admin/components/lib/dialogs/scanDialog/ScanDialog'
import ImpersonateDialog from 'admin/components/lib/dialogs/impersonateDialog/ImpersonateDialog'
import ThreatsFolderDialog from 'admin/components/lib/dialogs/threatsFolder/ThreatsFolderDialog'

const BASE_I18N_KEY = 'admin.app.ets'
const BASE_I18N_TABLE_KEY = 'admin.app.ets.table'

const EmailThreatScanner: React.FC = () => {
  const [
    inProgress,
    cards,
    userFlags,
    accessTokens,
    tableConfig,
    scanDialogConfig,
    impersonateDialogConfig,
    threatsFolderDialogConfig,
    openReport,
    onOpenImpersonate
  ] = useEmailThreatScannerLogic()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <Grid>
        <Metrics inProgress={inProgress} cardData={cards} BASE_I18N_KEY={BASE_I18N_KEY} data-field="metrics" />
        <Grid item data-field="scans-grid-title">
          <Typography className={classes.title} data-field="scans-title">
            {formatMessage('scans_title')}
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle2" data-field="scans-subtitle">
            {formatMessage('scans_subtitle')}
          </Typography>
        </Grid>
        <Grid container spacing={2} data-section="scans-table" direction="column" data-field="root-grid">
          <Grid item>
            <Paper className={classes.tableCard} elevation={0}>
              {!tableConfig.isLoaded && (
                <Grid container justifyContent="center" className={classes.progressWrapper}>
                  <CircularProgress data-field="paper-loader" />
                </Grid>
              )}

              {tableConfig.isLoaded && (
                <Grid className={classes.tableWrapper} container direction="column" data-field="table-wrapper">
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <SearchField
                        {...tableConfig.searchFieldConfig}
                        placeholder={formatMessage('search_field.placeholder')}
                        data-field="search-field"
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    className={`${classes.tableArea} ${!tableConfig.tableData.total ? 'empty-table' : ''}`}
                    data-table="scans"
                    data-testid="scans"
                    item
                    xs={12}
                    data-field="table-grid"
                  >
                    {tableConfig.inProgress && <LinearProgress data-field="table-loader" />}
                    <DataTable
                      className={`${
                        tableConfig.tableData.total ? classes.dataTable : classes.emptyDataTable
                      } scans-table ${classes.gridLayout}`}
                      data={tableConfig.tableData}
                      {...tableConfig.pageConfig}
                      {...tableConfig.sortConfig}
                      pager={Pager}
                      data-field="table"
                    >
                      <DataTableNoRecords>
                        <Typography data-field="no-records">
                          {!tableConfig.tableData.total && tableFormatMessage('empty_table')}
                        </Typography>
                      </DataTableNoRecords>
                      <DataTableColumn
                        field={tableConfig.columns.CREATED_ON}
                        {...tableConfig.columnsConfig[tableConfig.columns.CREATED_ON]}
                        title={tableFormatMessage(`${tableConfig.columns.CREATED_ON}`)}
                        cell={({ dataItem }: { dataItem: EtsModifiedScan }) => (
                          <Cell className={classes.cell}>
                            <CellText ellipsis data-field="created-on">
                              {dataItem.createdOnFormatted}
                            </CellText>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        field={tableConfig.columns.TRIGGERED_BY}
                        {...tableConfig.columnsConfig[tableConfig.columns.TRIGGERED_BY]}
                        title={tableFormatMessage(`${tableConfig.columns.TRIGGERED_BY}`)}
                        cell={({ dataItem }: { dataItem: EtsModifiedScan }) => (
                          <Cell className={classes.cell}>
                            <CellText data-field="triggered-by">
                              {dataItem.triggeredBy || tableFormatMessage('not_associated')}
                              <br />
                              {dataItem.triggeredBy && (
                                <span
                                  className={classes.link}
                                  role="presentation"
                                  data-field="impersonate-action"
                                  data-action="impersonate"
                                  onClick={() => {
                                    onOpenImpersonate(dataItem.triggeredBy as string)
                                  }}
                                >
                                  {' '}
                                  {tableFormatMessage('impersonate')}
                                </span>
                              )}
                            </CellText>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        field={tableConfig.columns.USER_TAGS}
                        {...tableConfig.columnsConfig[tableConfig.columns.USER_TAGS]}
                        title={tableFormatMessage(`${tableConfig.columns.USER_TAGS}`)}
                        cell={({ dataItem }: { dataItem: EtsModifiedScan }) => (
                          <Cell className={classes.cell}>
                            <CellText data-field="user-tags">
                              {dataItem.triggeredBy &&
                                userFlags[dataItem.triggeredBy] &&
                                Object.entries(userFlags[dataItem.triggeredBy]).map(([key, val]) => (
                                  <Grid key={key}>
                                    <Typography className={classes.userFlagKey}>{key}: </Typography>
                                    <Typography className={classes.userFlagValue}>{val}</Typography>
                                  </Grid>
                                ))}
                            </CellText>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        field={tableConfig.columns.STATUS}
                        {...tableConfig.columnsConfig[tableConfig.columns.STATUS]}
                        title={tableFormatMessage(`${tableConfig.columns.STATUS}`)}
                        cell={({ dataItem }: { dataItem: EtsModifiedScan }) => (
                          <Cell className={classes.cell}>
                            {dataItem.bootstrapInProgress ? (
                              <CellText data-field="status">{tableFormatMessage('in_progress')}</CellText>
                            ) : (
                              <CellText data-field="status">
                                {tableFormatMessage('finished_on')}
                                <br />
                                {dataItem.finishedOnFormatted}
                              </CellText>
                            )}
                          </Cell>
                        )}
                      />

                      <DataTableColumn
                        field={tableConfig.columns.OFFICE_365_NAME}
                        {...tableConfig.columnsConfig[tableConfig.columns.OFFICE_365_NAME]}
                        title={tableFormatMessage(`${tableConfig.columns.OFFICE_365_NAME}`)}
                        cell={({ dataItem }: { dataItem: EtsModifiedScan }) => (
                          <Cell className={classes.cell}>
                            <CellText data-field="office-365-name">
                              {dataItem.accessTokenName}
                              <br />
                              <br />
                              <span
                                className={classes.link}
                                role="presentation"
                                data-action="open-details"
                                onClick={() => {
                                  tableConfig.onOpenDetails(dataItem, accessTokens[dataItem.accessTokenId])
                                }}
                              >
                                {' '}
                                {tableFormatMessage('other_details')}
                              </span>
                            </CellText>
                          </Cell>
                        )}
                      />

                      <DataTableColumn
                        field={tableConfig.columns.REPORT}
                        {...tableConfig.columnsConfig[tableConfig.columns.REPORT]}
                        title={tableFormatMessage(`${tableConfig.columns.REPORT}`)}
                        cell={({ dataItem }: { dataItem: EtsModifiedScan }) => (
                          <Cell className={classes.cell}>
                            <CellText data-field="report">
                              <span
                                className={classes.link}
                                role="presentation"
                                data-action="open-report"
                                onClick={() => {
                                  openReport(dataItem)
                                }}
                              >
                                {' '}
                                {tableFormatMessage('view_report')}
                              </span>
                              {!dataItem.inProgress && (
                                <>
                                  <br />
                                  <br />
                                  <span
                                    className={classes.link}
                                    role="presentation"
                                    data-action="open-threats-folder"
                                    onClick={() => {
                                      tableConfig.onOpenThreatsFolderDialog(
                                        dataItem.accountName || '',
                                        dataItem.id,
                                        dataItem.accessTokenId,
                                        dataItem.accountId
                                      )
                                    }}
                                  >
                                    {' '}
                                    {tableFormatMessage('request_folder_report')}
                                  </span>
                                </>
                              )}
                            </CellText>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        field={tableConfig.columns.MAILBOXES}
                        {...tableConfig.columnsConfig[tableConfig.columns.MAILBOXES]}
                        headerClassName={classes.rightAlignedText}
                        title={tableFormatMessage(`${tableConfig.columns.MAILBOXES}`)}
                        cell={({ dataItem }: { dataItem: EtsModifiedScan }) => (
                          <Cell className={classes.cell}>
                            <CellLabelType align="right">
                              <CellText data-field="mailboxes">{dataItem.mailboxCount}</CellText>
                            </CellLabelType>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        field={tableConfig.columns.SP_ATTACKS}
                        {...tableConfig.columnsConfig[tableConfig.columns.SP_ATTACKS]}
                        headerClassName={classes.rightAlignedText}
                        title={tableFormatMessage(`${tableConfig.columns.SP_ATTACKS}`)}
                        cell={({ dataItem }: { dataItem: EtsModifiedScan }) => (
                          <Cell className={classes.cell}>
                            <CellLabelType align="right">
                              <CellText data-field="sp-attacks">{dataItem.spAttacksCount}</CellText>
                            </CellLabelType>
                          </Cell>
                        )}
                      />
                      <DataTableColumn
                        field={tableConfig.columns.EMAILS}
                        {...tableConfig.columnsConfig[tableConfig.columns.EMAILS]}
                        headerClassName={classes.rightAlignedText}
                        title={tableFormatMessage(`${tableConfig.columns.EMAILS}`)}
                        cell={({ dataItem }: { dataItem: EtsModifiedScan }) => (
                          <Cell className={classes.cell}>
                            <CellLabelType align="right">
                              <CellText data-field="emails">{dataItem.emailCount}</CellText>
                            </CellLabelType>
                          </Cell>
                        )}
                      />
                    </DataTable>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
          {scanDialogConfig.open && scanDialogConfig.scan && (
            <ScanDialog
              scan={scanDialogConfig.scan}
              accessToken={scanDialogConfig.accessToken}
              product={scanDialogConfig.product}
              onClose={scanDialogConfig.onClose}
            />
          )}
          {impersonateDialogConfig.open && impersonateDialogConfig.email && (
            <ImpersonateDialog email={impersonateDialogConfig.email} />
          )}
          {threatsFolderDialogConfig.open && threatsFolderDialogConfig.scanUUID && (
            <ThreatsFolderDialog
              accountName={threatsFolderDialogConfig.accountName}
              scanUUID={threatsFolderDialogConfig.scanUUID}
              accessTokenId={threatsFolderDialogConfig.accessTokenId}
              accountId={threatsFolderDialogConfig.accountId}
              email={threatsFolderDialogConfig.email}
              onClose={threatsFolderDialogConfig.onClose}
            />
          )}
        </Grid>
      </Grid>
    ),
    [
      classes,
      formatMessage,
      tableFormatMessage,
      inProgress,
      cards,
      tableConfig,
      userFlags,
      accessTokens,
      scanDialogConfig,
      impersonateDialogConfig,
      openReport,
      onOpenImpersonate,
      threatsFolderDialogConfig
    ]
  )
}

export default EmailThreatScanner
