import { User } from 'global/types/api/userType'
import { RootState } from 'global/redux/toolkit/store'
import { setSuccessCallbacks, setFailedCallbacks, logout } from 'global/redux/features/auth/authSlice'
import accessTokenLib from 'global/lib/accessToken/accessToken'
import { setCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'
import { config } from 'global/lib/config'
import * as authLib from 'global/lib/auth/auth'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { validSerialStates } from 'global/components/lib/authLayer/validateUser'

import routesConfig from 'fir/lib/routes/routesConfig'
import { setUser } from 'fir/redux/features/user/userSlice'

export interface DetermineRoute {
  user: User
  completedMFA?: boolean
}

async function determineRoute({ user, completedMFA = false }: DetermineRoute, { dispatch }: any) {
  await dispatch(setUser({ user }))

  // Redirect to remediation if profile completed otherwise goto signup
  if (user.state === config.USER_STATES.PROFILE_COMPLETED) {
    const defaultAccessTokenId = accessTokenLib.getDefaultFirAccessTokenId()

    if (defaultAccessTokenId) {
      dispatch(setCurrentAccessToken(defaultAccessTokenId))
    }
    if (!user.mfa || completedMFA) {
      routesConfig.REMEDIATION.goto()
    }
  } else {
    routesConfig.SIGNIN_SIGNUP.goto()
  }
}

setSuccessCallbacks({
  async signin(user: User, thunkApi: any) {
    await determineRoute({ user }, thunkApi)
  },

  async signinOtp(user: User, thunkApi: any) {
    await determineRoute({ user, completedMFA: true }, thunkApi)
  },

  async signup(user: User, { dispatch }: any) {
    await dispatch(setUser({ user }))
  },

  async saveProfile(user: User, { dispatch }: any) {
    await dispatch(setUser({ user }))
  },

  async getUser(user: User, { dispatch, getState }: any) {
    const { auth } = getState() as RootState

    // If none of the accounts have a valid serial state, redirect to start trial
    if (user && !user.accounts.some(account => validSerialStates.includes(account?.bccSerialNumbers?.fir?.state))) {
      routesConfig.START_TRIAL.goto()
      return
    }

    // Redirect to connect O365 if user needs to connect O365
    if (auth.NEEDS_TO_CONNECT_O365_STATES.includes(user?.state)) {
      routesConfig.SIGNIN_CONNECT.goto()
      return
    }

    await dispatch(setUser({ user }))

    // set the user for analytics
    authLib.initializeUserInServices()
    const { isPublicRoute } = getState().app.activePath

    const defaultAccessTokenId = accessTokenLib.getDefaultFirAccessTokenId()

    if (defaultAccessTokenId) {
      await dispatch(setCurrentAccessToken(defaultAccessTokenId))
    }

    // Transition the user to the report area -
    // if the session is valid and trying to reach PublicRoute
    if (user && defaultAccessTokenId && isPublicRoute) {
      routesConfig.REMEDIATION.goto()
    }
  },

  async getForensicsDemoUser(user: User, { dispatch }: any) {
    if (user) {
      await dispatch(setUser({ user }))

      // set the user for intercom and mixpanel
      analyticsLib.setUser(user)

      const defaultAccessTokenId = accessTokenLib.getDefaultFirAccessTokenId()
      if (defaultAccessTokenId) {
        await dispatch(setCurrentAccessToken(defaultAccessTokenId))
        routesConfig.REMEDIATION.goto()
      }
    }
  },

  async logout() {
    routesConfig.SIGNIN_SIGNUP.goto()
  }
})

setFailedCallbacks({
  async signinOtp() {
    routesConfig.SIGNIN_SIGNUP.goto()
  },
  // When the refresh token is invalid, we need to ask customer to re-login to get a new fresh token.
  async refreshToken(thunkApi: any) {
    thunkApi.dispatch(logout())
  }
})
