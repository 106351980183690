import { ProductSerials } from 'global/types/api/serialNumberType'
import { Products } from 'global/types/redux/productsType'

export interface AccessToken {
  bcsSubscription?: []
  id: string
  name: string
  provider: string
  providerApp: string
  adminSecret: string | null
  reportSecret: ReportSecret | null
  created: string
  settings: AccessTokenSettings
  products: Products[]
  features: string[]
  serialNumbers: ProductSerials
  tenantId: string
}

export interface ReportSecret {
  active: any
  expires: string
  value: string
}

export type BCCAccount = string
export type EssAccount = string

export interface ShareSecret {
  value: string | undefined
}

export interface CurrentAccessToken {
  bccAccount?: BCCAccount
  bcsSubsciption?: []
  essAccount?: EssAccount
  guestAccessToken?: AccessToken
  shareSecret?: ShareSecret
  accessToken: AccessToken | null
  guestAccess: boolean
  reportSecret?: ReportSecret
}

export interface AccessTokenSettings {
  forensicsBootstrapProgress?: SettingsValue
  ForensicsOnboardingInQueue?: SettingsValue
  forensicsEndUserNotification?: string
  forensicsEndUserNotificationTemplate?: string
  forensicsIncidentAlertAdmin?: string
  forensicsIncidentAlertEndUser?: string
  forensicsIncidentAutoRemediateEmails?: string
  forensicsIncidentContentShieldPolicy?: string
  forensicsIncidentContinuousRemediation?: string
  forensicsIncidentDeleteEmails?: string
  forensicsAutomaticIncidentIgnorePhishline?: string
  forensicsIncidentSenderPolicy?: string
  forensicsIncidentSenderPolicyAction?: string
  forensicsIncidentSenderPolicyType?: string
  forensicsAutomaticIncidentAlertAdmin?: string
  forensicsAutomaticIncidentDeleteEmails?: string
  forensicsAutomaticIncidentContinuousRemediation?: string
  forensicsAutomaticIncidentAlertEndUser?: string
  forensicsAutomaticIncidentNotifyAddress?: string
  forensicsNotifyAddress?: string
  forensicsPostDeliveryNotifications?: string
  forensicsPostDeliveryNotifyAddress?: string
  forensicsPostDeliverySyncAddress?: string
  forensicsUserReportedNotifications?: string
  forensicsUserReportedNotifyAddress?: string
  forensicsUserReportedSyncAddress?: string
  SentinelOnboardingInQueue?: SettingsValue
  spAttackQuarantine?: number
  spAttackAlertEndUser?: string
  spAttackAlertAdmin?: string
  notifyAddress?: string
  spAttackAlertImpersonatedUser?: string
  atoAttackAlertAdmin?: string
  atoNotifyAddress?: string
  keywordHighlighting?: boolean
}

export enum SettingsValue {
  on = 'true',
  off = 'false'
}
