import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError } from 'global/lib/api/restClient'
import { config } from 'global/lib/config'
import {
  buildReportQueryFor,
  buildReportQueryForAccessTokens
} from 'global/redux/features/dataTables/buildQueryForTable'
import { authenticate } from 'global/lib/auth/auth'
import { Products } from 'global/types/redux/productsType'
import { ProductAssigment, ProductLicense } from 'global/types/api/product'

import apiRoutes from 'admin/lib/api/apiRoutes'
import { RootState } from 'admin/redux/toolkit/store'
import { ThreatsFolderViewPayload } from 'admin/redux/types/scans'

export interface ScanTotalPayload {
  scanType: 'ets' | 'sentinel'
}
export interface ImpersonatePayload {
  email: string
  host: string
  path?: string
}
export interface DeactivatePayload {
  accountId: string
  accessTokenId: string
  email?: string
  product?: Products
}
export interface SentinelSerialNumberPayload {
  accountId: string
  accessTokenId: string
  product: Products
  serialNumber: string
}

export interface ListAccessTokensPayload {
  product: string
}

export interface GetProductAssignmentsPayload {
  accessTokenId: number
  product: Products
}
export interface GetProductLicensesPayload {
  accountId: number
  product: Products
}
export interface GetRemediationStatsPayload {
  accessTokenId: string
}

export interface GetUsersPayload {
  bccAccountId: number
}

export const scanTotals = createAsyncThunk('ADMIN/scanTotals', async function doScanTotals(
  payload: ScanTotalPayload,
  { rejectWithValue }
) {
  try {
    const resp = await restClient(apiRoutes.SCAN_TOTALS, { data: payload })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getScans = createAsyncThunk('ADMIN/getScans', async function doGetScans(
  payload: ScanTotalPayload,
  { rejectWithValue, getState }
) {
  try {
    let query
    if (payload.scanType === 'ets') {
      query = buildReportQueryFor((getState() as RootState).dataTables.etsScans)
    } else {
      query = buildReportQueryFor((getState() as RootState).dataTables.senScans)
    }

    const resp = await restClient(apiRoutes.GET_SCANS, {
      data: { query: { ...query }, ...payload }
    })

    return { report: resp.data, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const impersonate = createAsyncThunk('ADMIN/impersonate', async function doImpersonate(
  payload: ImpersonatePayload,
  { rejectWithValue }
) {
  const host = payload.host || ''
  const path = payload.path || ''
  const { email } = payload

  try {
    const resp = await restClient(apiRoutes.IMPERSONATE, {
      data: { email }
    })

    await authenticate(resp.data.user)

    window.location.href = `${host}/${path}`

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const deactivate = createAsyncThunk('ADMIN/deactivate', async function doDeactivate(
  payload: DeactivatePayload,
  { rejectWithValue }
) {
  try {
    // forensics is the default case
    let route = apiRoutes.DEACTIVATE_FORENSICS
    let redirect = '/admin/dashboard/incident-response/accounts'

    // DFP
    if (payload.product === config.PRODUCTS.DFP) {
      route = apiRoutes.DEACTIVATE_DOMAIN_FRAUD
      redirect = '/admin/dashboard/domain-fraud/accounts'
    }

    // sentinel
    if (payload.product === config.PRODUCTS.SENTINEL) {
      route = apiRoutes.DEACTIVATE_SENTINEL
      redirect = '/admin/dashboard/impersonation-protection/accounts'
    }

    const resp = await restClient(route, {
      data: { accountId: payload.accountId, accessTokenId: payload.accessTokenId }
    })

    window.location.href = redirect

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const deactivateAndReset = createAsyncThunk('ADMIN/deactivateAndReset', async function doDeactivate(
  payload: DeactivatePayload,
  { rejectWithValue }
) {
  try {
    const route = apiRoutes.DEACTIVATE_AND_RESET

    // redirect to forensics
    let redirect = '/admin/dashboard/email-threat-scanner/scans'

    // redirect to sentinel
    if (payload.product === 'sentinel') {
      redirect = '/admin/dashboard/impersonation-protection/accounts'
    }

    const resp = await restClient(route, {
      data: { accountId: payload.accountId, accessTokenId: payload.accessTokenId, email: payload.email }
    })

    window.location.href = redirect

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const listAccessTokens = createAsyncThunk('ADMIN/listAccessTokens', async function doListAccessTokens(
  payload: ListAccessTokensPayload,
  { rejectWithValue, getState }
) {
  try {
    // Since both DFP and FIR share this apiThunk we need to determine which datatable to use to retrieve the query
    const tableId = payload.product === 'dfp' ? 'dfpAccessTokens' : 'firAccessTokens'

    const query = buildReportQueryForAccessTokens((getState() as RootState).dataTables[tableId])

    const resp = await restClient(apiRoutes.LIST_ACCESS_TOKENS, {
      data: { query: { ...query }, ...payload }
    })

    return { report: resp.data, offset: (query.page - 1) * 10 }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getProductAssignments = createAsyncThunk(
  'ADMIN/getProductAssignments',
  async function doGetProductAssignments(payload: GetProductAssignmentsPayload, { rejectWithValue }) {
    const { accessTokenId, product } = payload

    try {
      const resp = await restClient(apiRoutes.GET_PRODUCT_ASSIGNMENTS, {
        data: { accessTokenId: accessTokenId.toString() }
      })

      return resp.data.productAssignments
        .filter((productAssignment: ProductAssigment) => {
          return productAssignment.product === product
        })
        .shift()
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getProductLicenses = createAsyncThunk('ADMIN/getProductLicenses', async function doGetProductLicenses(
  payload: GetProductLicensesPayload,
  { rejectWithValue }
) {
  const { accountId, product } = payload

  try {
    const resp = await restClient(apiRoutes.GET_PRODUCT_LICENSES, {
      data: { accountId }
    })

    return resp.data.productLicenses
      .filter((productLicense: ProductLicense) => {
        return productLicense.product === product
      })
      .shift()
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getRemediationStats = createAsyncThunk('ADMIN/getRemediationStats', async function doGetRemediationStats(
  payload: GetRemediationStatsPayload,
  { rejectWithValue }
) {
  const { accessTokenId } = payload

  try {
    const resp = await restClient(apiRoutes.GET_REMEDIATION_STATS, {
      data: { accessTokenId }
    })

    return resp.data.remediationStats
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getUsers = createAsyncThunk('ADMIN/getUsers', async function doGetUsers(
  payload: GetUsersPayload,
  { rejectWithValue }
) {
  const { bccAccountId } = payload

  try {
    const resp = await restClient(apiRoutes.GET_USERS, {
      data: { bccAccountId }
    })

    return resp.data.users
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const sendThreatsFolderView = createAsyncThunk(
  'ADMIN/getThreatFolderView',
  async function doSendThreatsFolderView(payload: ThreatsFolderViewPayload, { rejectWithValue }) {
    const { accountId, accessTokenId, email, scanUUID } = payload

    try {
      const resp = await restClient(apiRoutes.THREATS_FOLDER_VIEW, {
        data: { accountId, accessTokenId, email, scanUUID }
      })

      return resp.data
    } catch (e) {
      const error = e as { data?: { errors?: { details?: { message: string }[] } } }
      if (
        error.data?.errors?.details?.some((detail: { message: string }) =>
          [
            'valid scan uuid is required',
            'valid access token id is required',
            'valid account id is required',
            'valid email is required'
          ].includes(detail.message)
        )
      ) {
        return rejectWithValue('request_report_invalid')
      }
      return rejectWithValue('request_report_failed')
    }
  }
)
