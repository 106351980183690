import React, { useMemo } from 'react'

import { Page, View, Text, StyleSheet } from '@react-pdf/renderer'
import { snakeCase } from 'lodash'

import { COLORS } from 'ets/configs/styles/defaults'
import baseStyles from 'ets/components/lib/PDFReport/pdfReportStyles'
import PDFFooter, { PDFFooterProps } from 'ets/components/lib/PDFReport/PDFFooter'

const THREATS_TABLE_WIDTHS = ['3%', '17%', '10%', '25%', '35%', '10%']
const TABLE_CELL_HEIGHT = 65

export interface Threat {
  id: string
  count: number
  displayName: string
  sender: {
    displayName: string
    email: string
  }
  subject: string
  title: string
  email: string
  validatedAttackType: string
  date: string
  taxonomy: string
  formattedDate: string
}

export interface Page4ThreatsConfig {
  topCount: string
  threats: Threat[]
}

export interface Page4ThreatsProps {
  pdfConfig: Page4ThreatsConfig
  intl: any
  footerConfig: PDFFooterProps
}

const styles = StyleSheet.create({
  ...baseStyles,
  subtitle: {
    ...baseStyles.subtitle,
    marginTop: 10
  },
  tableheaderRow: baseStyles.tableheaderRow,
  countColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: THREATS_TABLE_WIDTHS[0]
  },
  receivedColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: THREATS_TABLE_WIDTHS[1]
  },
  recipientsColumnHeader: {
    ...baseStyles.tableHeaderText,
    textAlign: 'center',
    width: THREATS_TABLE_WIDTHS[2]
  },
  sampleRecipientColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: THREATS_TABLE_WIDTHS[3]
  },
  emailColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: THREATS_TABLE_WIDTHS[4]
  },
  attackTypeColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: THREATS_TABLE_WIDTHS[4]
  },
  tableRow: {
    ...baseStyles.tableRow,
    height: TABLE_CELL_HEIGHT
  },
  countColumn: {
    ...baseStyles.tableText,
    color: COLORS.PDF.LIGHT_GRAY,
    width: THREATS_TABLE_WIDTHS[0]
  },
  receivedColumn: {
    ...baseStyles.tableText,
    width: THREATS_TABLE_WIDTHS[1]
  },
  recipientsColumn: {
    ...baseStyles.tableText,
    textAlign: 'center',
    fontWeight: 'bold',
    width: THREATS_TABLE_WIDTHS[2]
  },
  sampleRecipientColumn: {
    ...baseStyles.tableText,
    width: THREATS_TABLE_WIDTHS[3]
  },
  emailColumn: {
    ...baseStyles.tableText,
    width: THREATS_TABLE_WIDTHS[4]
  },
  attackTypeColumn: {
    ...baseStyles.tableText,
    fontWeight: 'bold',
    width: THREATS_TABLE_WIDTHS[5]
  },
  boldCellText: baseStyles.boldCellText,
  cellText: baseStyles.cellText
})

const BASE_I18N_KEY = 'ets.app.pdf.threats'
const BASE_TABLES_I18N_KEY = 'ets.app.data_tables'
const BASE_ATTACK_TYPES_I18N_KEY = 'app.attack_types'

const CustomPager: any = Page

const Page4Threats: React.FC<Page4ThreatsProps> = ({ pdfConfig, footerConfig, intl }) => {
  return useMemo(() => {
    return (
      <CustomPager size="A4" style={styles.page}>
        <Text style={styles.title}>{intl.formatMessage({ id: `${BASE_I18N_KEY}.title` })}</Text>
        <Text style={styles.subtitle}>
          {intl.formatMessage({ id: `${BASE_I18N_KEY}.subtitle` }, { count: pdfConfig.topCount })}
        </Text>
        <View style={styles.tableheaderRow}>
          <Text style={styles.countColumnHeader} />
          {['received', 'recipients', 'sampleRecipient', 'email', 'attackType'].map((columnType: string) => (
            <Text key={columnType} style={styles[`${columnType}ColumnHeader`]}>
              {intl.formatMessage({ id: `${BASE_TABLES_I18N_KEY}.threats.${snakeCase(columnType)}` })}
            </Text>
          ))}
        </View>
        {pdfConfig.threats?.length > 0 ? (
          <View style={styles.tableView}>
            {pdfConfig.threats.map((threat: Threat, idx: number) => (
              <View key={threat.id} style={styles.tableRow}>
                <Text style={styles.countColumn}>{idx + 1}</Text>
                <Text style={styles.receivedColumn}>{threat.formattedDate}</Text>
                <Text style={styles.recipientsColumn}>{threat.count.toLocaleString()}</Text>
                <View style={styles.sampleRecipientColumn}>
                  <Text style={styles.boldCellText}>{threat.displayName}</Text>
                  {threat.title && <Text style={styles.cellText}>{threat.title}</Text>}
                  {threat.email && <Text style={styles.cellText}>{threat.email}</Text>}
                </View>
                <View style={styles.emailColumn}>
                  <Text style={styles.boldCellText}>{threat.subject}</Text>
                  {threat.sender.displayName && <Text style={styles.cellText}>{threat.sender.displayName}</Text>}
                  {threat.sender.email && threat.sender.displayName !== threat.sender.email && (
                    <Text style={styles.cellText}>{threat.sender.email}</Text>
                  )}
                </View>
                <Text style={styles.attackTypeColumn}>
                  {intl.formatMessage({
                    id: `${BASE_ATTACK_TYPES_I18N_KEY}.${threat.validatedAttackType}.text`
                  })}
                </Text>
              </View>
            ))}
          </View>
        ) : (
          <View style={styles.emptyTableWrap}>
            <Text style={styles.emptyTableText}>{intl.formatMessage({ id: `${BASE_I18N_KEY}.empty_table` })}</Text>
          </View>
        )}
        <PDFFooter page={3} intl={intl} {...footerConfig} />
      </CustomPager>
    )
  }, [pdfConfig, footerConfig, intl])
}

export default Page4Threats
