import { initSplitSdk, destroySplitSdk, getTreatments, selectTreatmentValue } from '@splitsoftware/splitio-redux'

import { config } from 'global/lib/config'
import { reduxStore } from 'global/lib/reduxStore'

import { Account } from 'global/types/api/accountType'

let savedAccountId: string

export const FEATURES = {
  // public
  EGD_IP_IR_CONVERGED_EMAIL_LOG_ROLLOUT: 'EGD_IP_IR_Converged_Email_Log_Rollout',
  ETS_BEC_IMPERSONATION: 'ETS_BEC_Impersonation',
  IP_LICENSING_INFORMATION_ROLLOUT: 'IP_Licensing_Information_Rollout',
  IR_V2_AUTOMATED_WORKFLOWS_UI: 'IR_V2_Automated_Workflows_UI',
  IP_LICENSING_COMPLIANCE: 'IP_Licensing_Compliance'
} as { [key: string]: string }

export interface SplitioConfig {
  accessTokenId: string
  account: Account | null
  cb?: () => void
}

export enum SplitioStatuses {
  on = 'on',
  off = 'off'
}

export interface PublicSplitioConfig {
  key: string
  features: { [key: string]: SplitioStatuses }
  cb?: () => void
  publicAttributes?: {
    [key: string]: any
  }
}

async function onReady(cb: any) {
  await reduxStore.dispatch(getTreatments({ splitNames: [...Object.values(FEATURES)] }))

  if (cb) {
    cb()
  }
}

export function initSplitio({ accessTokenId, account, cb }: SplitioConfig) {
  const shouldInitSplitio = account && savedAccountId !== account.accountId && accessTokenId !== config.NO_TOKEN_TOKEN
  if (!shouldInitSplitio && cb) {
    cb()
  }

  if (account && shouldInitSplitio) {
    savedAccountId = account.accountId

    const sdkConfig: any = {
      core: {
        authorizationKey: config.splitioApiKey,
        key: account.accountId,
        trafficType: 'Account'
      },
      features: {
        [FEATURES.IP_LICENSING_COMPLIANCE]: SplitioStatuses.on
      },
      scheduler: {
        featuresRefreshRate: 60
      }
    }

    return initSplitSdk({ config: sdkConfig, onReady: () => onReady(cb) })
  }

  return function warn() {
    // eslint-disable-next-line no-console
    console.warn(`Splitio service with accountId: ${account?.accountId} can't be initialized`)
  }
}

export function initPublicSplitio({ key, features, cb }: PublicSplitioConfig) {
  const sdkConfig: any = {
    core: {
      authorizationKey: config.splitioApiKey,
      key,
      trafficType: 'Account'
    },
    features,
    scheduler: {
      featuresRefreshRate: 60
    }
  }

  return initSplitSdk({ config: sdkConfig, onReady: () => onReady(cb) })
}

export function isMyFeatureOn(splitStore: any, feature: string, key?: string) {
  return selectTreatmentValue(splitStore, feature, key) === SplitioStatuses.on
}

export function destroySplitio() {
  reduxStore.dispatch(destroySplitSdk())
}
