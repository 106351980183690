import { useMemo, useCallback, useReducer, useState, useRef } from 'react'

import queryString from 'query-string'
import { debounce } from 'lodash'

import { useEffectOnInit, useEffectOnlyOnce, useEffectWhenRendered } from 'global/lib/useCustomEffect'
import { config } from 'global/lib/config'

import {
  getCustomNotificationEmailTemplate,
  resetCustomEmailNotificationEmailTemplate,
  saveCustomNotificationTemplate
} from 'sen/redux/features/settings/settingsSlice'
import {
  GetCustomNotificationEmailTemplateRequest,
  NotificationEmailTemplate,
  SaveCustomNotificationTemplateRequest
} from 'sen/redux/features/settings/types'
import apiRoutes from 'sen/lib/api/apiRoutes'
import { useAppSelector, useAppDispatch } from 'sen/redux/toolkit/hooks'
import { isPending, isSuccess } from 'global/redux/toolkit/api'

export type InProgress = boolean
export type Error = string | undefined
export type OnFormChange = (field: keyof NotificationEmailTemplate) => (e: any) => void
export type OnFormReset = (field: keyof NotificationEmailTemplate) => () => void
export type CarriageConverter = (fields: Partial<NotificationEmailTemplate>) => NotificationEmailTemplate
export type OnSave = () => void

export interface FormConfig {
  template: NotificationEmailTemplate
  onChange: OnFormChange
  onReset: OnFormReset
  onSave: OnSave
  isSaving: boolean
}

export interface ContentConfig {
  from: string
  subject: string
  previewLink: string
}

export interface UseCustomizeAlertDialogLogicProps {
  onClose: () => void
}

export default function useCustomizeAlertDialogLogic({
  onClose
}: UseCustomizeAlertDialogLogicProps): [InProgress, FormConfig, ContentConfig, Error] {
  const dispatch = useAppDispatch()
  const {
    isLoading,
    isSaveCustomNotificationTemplate,
    isSaveCustomNotificationTemplateSuccess,
    customNotificationEmail,
    settingsError,
    accessToken,
    user
  } = useAppSelector(_stores => ({
    isLoading: isPending(_stores.settings.getCustomNotificationEmailTemplateApiStatus),
    isSaveCustomNotificationTemplate: isPending(_stores.settings.saveCustomNotificationTemplateApiStatus),
    isSaveCustomNotificationTemplateSuccess: isSuccess(_stores.settings.saveCustomNotificationTemplateApiStatus),
    customNotificationEmail: _stores.settings.customNotificationEmail,
    settingsError: _stores.settings.customNotificationEmailError,
    accessToken: _stores.accessToken.accessToken,
    user: _stores.user.data
  }))
  const isMounted = useRef(true)
  const [previewLink, setPreviewLink] = useState<string>('')
  const [templateState, setTemplateState] = useReducer(
    (_state: Partial<NotificationEmailTemplate>, newState: NotificationEmailTemplate) => ({ ..._state, ...newState }),
    {
      fromDisplayName: '',
      introduction: '',
      signature: ''
    }
  )

  // init
  useEffectOnInit(() => {
    dispatch(getCustomNotificationEmailTemplate({ ...customNotificationEmailTemplateParams }))

    return () => {
      isMounted.current = false
      dispatch(resetCustomEmailNotificationEmailTemplate())
      debouncedRenderPreviewLink.cancel()
    }
  }, [])

  // set template's state variable
  useEffectOnlyOnce(
    () => {
      setTemplateState({ ...br2nl(customNotificationEmail.template as NotificationEmailTemplate) })
    },
    [customNotificationEmail],
    !!customNotificationEmail.template
  )

  // Update the mail preview
  useEffectWhenRendered(() => {
    debouncedRenderPreviewLink()
  }, [templateState.introduction, templateState.signature])

  // reload the template data after successfully saved
  useEffectWhenRendered(() => {
    if (isSaveCustomNotificationTemplateSuccess) {
      onClose()
      // dispatch(getCustomNoficationEmailTemplate(customNoficationEmailTemplateParams))
    }
  }, [isSaveCustomNotificationTemplateSuccess])

  // convert html email body to human readable
  const br2nl: CarriageConverter = useCallback(fields => {
    return (Object.keys(fields) as (keyof NotificationEmailTemplate)[]).reduce((all, field) => {
      return {
        ...all,
        [field]: fields[field]?.replace(/<br\s*[/]?>/gi, '\n') || ''
      }
    }, {} as NotificationEmailTemplate)
  }, [])

  // convert human readable email body to html
  const nl2br: CarriageConverter = useCallback(fields => {
    return (Object.keys(fields) as (keyof NotificationEmailTemplate)[]).reduce((all, field) => {
      return {
        ...all,
        [field]: fields[field]?.replace(/(?:\r\n|\r|\n)/g, '<br>') || ''
      }
    }, {} as NotificationEmailTemplate)
  }, [])

  const customNotificationEmailTemplateParams: GetCustomNotificationEmailTemplateRequest = useMemo(() => {
    return {
      templateName: config.SP_SETTINGS.EMAIL_TEMPLATE_NAMES.SP_QUARANTINED_USER,
      identityEmail: user?.email || '',
      identityDisplayName: user?.displayName || '',
      emailDeleted: accessToken?.settings?.spAttackQuarantine === 2,
      emailQuarantined: accessToken?.settings?.spAttackQuarantine === 1
    }
  }, [user, accessToken])

  const inProgress: InProgress = useMemo(() => {
    return isLoading
  }, [isLoading])

  const onFormChange: OnFormChange = useCallback(field => {
    return e => {
      setTemplateState({ [field]: e.target.value || '' } as NotificationEmailTemplate)
    }
  }, [])

  const onFormReset: OnFormReset = useCallback(
    field => {
      return () => {
        setTemplateState({
          ...br2nl({ [field]: customNotificationEmail.defaults[field] || '' })
        })
      }
    },
    [customNotificationEmail.defaults, br2nl]
  )

  const onSave: OnSave = useCallback(() => {
    const params: SaveCustomNotificationTemplateRequest = {
      ...templateState,
      templateName: config.SP_SETTINGS.EMAIL_TEMPLATE_NAMES.SP_QUARANTINED_USER
    }

    dispatch(saveCustomNotificationTemplate(params))
  }, [dispatch, templateState])

  // render previewLink
  const renderPreviewLink = useCallback(() => {
    if (inProgress) {
      return ''
    }

    const {
      templateName,
      identityEmail,
      identityDisplayName,
      emailQuarantined,
      emailDeleted
    } = customNotificationEmailTemplateParams
    const { introduction, signature } = nl2br(templateState)

    const queryParams = queryString.stringify({
      accessTokenId: accessToken?.id,
      templateName,
      identityEmail,
      identityDisplayName,
      emailQuarantined,
      emailDeleted,
      introduction,
      signature
    })

    if (isMounted.current) {
      setPreviewLink(`${apiRoutes.GET_NOTIFICATION_PREVIEW_EMAIL_BODY.path({})}?${queryParams}`)
    }
    return null
  }, [inProgress, accessToken, customNotificationEmailTemplateParams, templateState, nl2br])

  // Debounce the callback
  const debouncedRenderPreviewLink = useMemo(() => debounce(renderPreviewLink, 500), [renderPreviewLink])

  return useMemo(() => {
    return [
      inProgress,
      {
        template: templateState,
        onChange: onFormChange,
        onReset: onFormReset,
        onSave,
        isSaving: isSaveCustomNotificationTemplate
      },
      {
        from: `${templateState.fromDisplayName} ${config.SP_SETTINGS.EMAIL_FROM}`,
        subject: customNotificationEmail?.preview?.subject || '',
        previewLink
      },
      settingsError
    ]
  }, [
    inProgress,
    isSaveCustomNotificationTemplate,
    templateState,
    onFormChange,
    onFormReset,
    settingsError,
    customNotificationEmail,
    previewLink,
    onSave
  ])
}
