import React, { useMemo } from 'react'

import { v4 as uuidv4 } from 'uuid'

import { Card, Typography, DataTable, DataTableColumn, Grid } from '@barracuda-internal/bds-core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import MaskLayer from 'global/components/lib/maskLayer/MaskLayer'
import SearchField from 'global/components/lib/searchField/SearchField'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import highlightText from 'global/lib/highlightText/highlightText'
import Pager from 'global/components/lib/grid/pager/Pager'
import { useFormatMessage } from 'global/lib/localization'
import EmailDetailsDialog from 'global/components/lib/dialogs/emailDetailsDialog/EmailDetailsDialog'

import UserEmailsDialog from 'ets/components/lib/dialogs/userEmailsDialog/UserEmailsDialog'
import styles from 'ets/components/pages/dashboard/attackingDomains/dashboardAttackingDomainsStyles'
import useDashboardAttackingDomainsLogic, {
  UseDashboardAttackingDomainsLogicProps
} from 'ets/components/pages/dashboard/attackingDomains/useDashboardAttackingDomainsLogic'
import useEmailDetailsSpAttackInterface from 'ets/components/lib/dialogs/emailDetailsDialog/interfaces/useEmailDetailsSpAttackInterface'

const BASE_I18N_KEY = 'ets.app.dashboard.attacking_domains'
const BASE_PROGRESS_I18N_KEY = 'ets.app.dashboard.in_progress'
const BASE_I18N_TABLE_KEY = 'ets.app.data_tables.attacking_domains'

const DashboardAttackingDomains: React.FC<UseDashboardAttackingDomainsLogicProps> = ({
  isUserInputDisabledForTable,
  fixTableHeight
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageProgress = useFormatMessage(BASE_PROGRESS_I18N_KEY)
  const formatMessageTable = useFormatMessage(BASE_I18N_TABLE_KEY)
  const classes = styles()
  const [dashboardAttackingDomainsLogic] = useDashboardAttackingDomainsLogic({
    isUserInputDisabledForTable,
    fixTableHeight
  })
  const [emailDetailDialogConfig, emailDetailDialogActions] = useEmailDetailsSpAttackInterface()

  return useMemo(() => {
    const {
      isReportLoaded,
      inProgress,
      tableTotal,
      searchFieldConfig,
      GRID_COLUMNS,
      columnsConfig,
      tableData,
      pageConfig,
      sortConfig,
      selectedDomain,
      isUserEmailDialogOpened,
      closeUserEmailDialog,
      highlightKeywords
    } = dashboardAttackingDomainsLogic

    return (
      <Grid
        className={classes.root}
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        data-field="root-grid"
      >
        <UserEmailsDialog
          open={isUserEmailDialogOpened}
          domain={selectedDomain}
          onClose={closeUserEmailDialog}
          onSeeDetails={emailDetailDialogActions.onOpen}
        />
        <Grid container alignItems="center" justifyContent="center" data-field="container-grid">
          <Grid item xs={12}>
            <Card className={classes.tableWrapper} data-field="table-wrapper">
              <Grid container direction="column">
                <Grid item xs={12} data-field="table-total">
                  <Typography className={classes.tableTotal} align="left">
                    {formatMessage('table_total', { total: tableTotal })}
                  </Typography>
                </Grid>
                <Grid className={classes.searchField} item xs={12} data-field="table-search">
                  <SearchField {...searchFieldConfig} placeholder={formatMessage('search_field_placeholder')} />
                </Grid>
                <Grid
                  className={`${classes.tableArea} ${!tableData.total ? 'empty-table' : ''}`}
                  data-table="attacking-domains"
                  item
                  xs={12}
                >
                  {isReportLoaded && inProgress && (
                    <LinearProgress data-field="table-loader" className={classes.tableLoadIndicator} />
                  )}
                  <DataTable
                    className={`${
                      tableData.total ? classes.dataTable : classes.emptyDataTable
                    } attacking-domains-table`}
                    data={tableData}
                    {...pageConfig}
                    {...sortConfig}
                    pager={Pager}
                    data-field="table"
                  >
                    <DataTableNoRecords data-field="table-no-records">
                      <Typography>{!tableData.total && formatMessage('empty_table')}</Typography>
                    </DataTableNoRecords>
                    <DataTableColumn
                      width={320}
                      field={GRID_COLUMNS.DOMAIN}
                      {...columnsConfig[GRID_COLUMNS.DOMAIN as any]}
                      title={formatMessageTable(`${GRID_COLUMNS.DOMAIN}`)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellText className={classes.boldCell} data-field="table-domain-cell">
                            {highlightText(dataItem.domain, highlightKeywords)}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      width={180}
                      field={GRID_COLUMNS.EMAILS_WITH_TREATS}
                      {...columnsConfig[GRID_COLUMNS.EMAILS_WITH_TREATS as any]}
                      title={formatMessageTable(`${GRID_COLUMNS.EMAILS_WITH_TREATS}`)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellText ellipsis className={classes.boldCell} data-field="table-emails-cell">
                            {dataItem.emailsWithThreats}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={GRID_COLUMNS.EMPTY}
                      {...columnsConfig[GRID_COLUMNS.EMPTY_COLUMN as any]}
                      cell={() => (
                        <Cell className={classes.cell} data-field="table-empty-cell">
                          <CellText ellipsis className={classes.normalCell}>
                            &nbsp;
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      width={100}
                      field={GRID_COLUMNS.ACTION}
                      headerClassName={classes.rightAlignedText}
                      {...columnsConfig[GRID_COLUMNS.ACTION as any]}
                      title={formatMessageTable(`${GRID_COLUMNS.ACTION}`)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell} data-field="table-action-cell">
                          <Typography
                            onClick={
                              dataItem.emailsWithThreats ? () => dataItem.seeDetails(dataItem.domain) : undefined
                            }
                            className={dataItem.emailsWithThreats ? classes.blueNormalCell : undefined}
                            data-action="open-user-emails-action"
                          >
                            {formatMessage('see_details')}
                          </Typography>
                        </Cell>
                      )}
                    />
                  </DataTable>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        {!isReportLoaded && (
          <MaskLayer data-field="mask-layer">
            {formatMessageProgress('title', { br: () => <br key={uuidv4()} /> })}
          </MaskLayer>
        )}

        {emailDetailDialogActions.open && (
          <EmailDetailsDialog data={emailDetailDialogConfig} buttonText={formatMessage('button')} etsAttackTypes />
        )}
      </Grid>
    )
  }, [
    dashboardAttackingDomainsLogic,
    classes,
    formatMessage,
    formatMessageProgress,
    formatMessageTable,
    emailDetailDialogConfig,
    emailDetailDialogActions
  ])
}

export default DashboardAttackingDomains
