/* eslint-disable camelcase */
import React, { useMemo, useCallback, useState, RefObject, useEffect, createRef } from 'react'
import DOMPurify from 'dompurify'

import reactHtmlParser from 'react-html-parser'
import { isEqual, isUndefined } from 'lodash'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useTabs from 'global/lib/useTabs/useTabs'
import highlightHtml from 'global/lib/highlightHtml/highlightHtml'
import { Header, EmailStatistics } from 'global/components/lib/dialogs/emailDetailsDialog/emailDetailDialogTypes'
import { useAppDispatch, useAppSelector } from 'global/redux/toolkit/hooks'
import { updateCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'
import { AccessToken } from 'global/types/api/accessTokenType'
import { config } from 'global/lib/config'
import { isSuccess } from 'global/redux/toolkit/api'

import { updateAccessToken } from 'sen/redux/features/settings/settingsSlice'

export const TABS = {
  EMAIL: 'email',
  FAILURE_REPORT: 'failure_report',
  HEADERS: 'headers',
  THREAT_DETAILS: 'threat_details'
}

export interface FrameRef {
  node: HTMLIFrameElement
}

export interface Attachment {
  contentType: string
  isInline: boolean
  name: string
  size: string
}

export interface EmailInfo {
  content: string
  attachments?: Attachment[]
  headers?: Header[]
  failureReport?: Header[]
  statistics?: EmailStatistics
}

export interface EmailContentDetailsProps {
  emailInfo?: EmailInfo | undefined
  disabledTabs?: string[]
}

export interface UseEmailContentDetailsLogicProps {
  attachmentCount: number | undefined
  copyHeadersToClipboard: () => string
  copyFailureReportToClipboard: () => string
  handleResize: (iframe: RefObject<FrameRef>) => void
  height: number
  highlightedHeaders: string[]
  iframeRef: RefObject<FrameRef>
  onTabSelected: (e: any, newTab: number) => void
  selectedTab?: number
  shouldHighlightAttachment: (attachmentName: string) => boolean
  shouldHighlightAnalysis: (field: string) => boolean
  shouldHighlightEnvelope: (title: string) => boolean
  showKeywordHighlighting: boolean
  switchKeywordHighlighting: boolean
  tabs: string[]
  tabContent?: any
  tabHighlighting: any
  updateKeywordHighlighting: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const AUTHENTICATION_HEADER = 'Authentication-Results'
const AUTHENTICATION_HEADER_EGD = 'Authentication-Results-Original'
export const THREATS = {
  DMARC: 'dmarc',
  SPF: 'spf',
  DKIM: 'dkim'
}

export default function useEmailContentDetailsLogic({
  emailInfo,
  disabledTabs
}: EmailContentDetailsProps): [
  UseEmailContentDetailsLogicProps['attachmentCount'],
  UseEmailContentDetailsLogicProps['copyHeadersToClipboard'],
  UseEmailContentDetailsLogicProps['copyFailureReportToClipboard'],
  UseEmailContentDetailsLogicProps['handleResize'],
  UseEmailContentDetailsLogicProps['height'],
  UseEmailContentDetailsLogicProps['highlightedHeaders'],
  UseEmailContentDetailsLogicProps['iframeRef'],
  UseEmailContentDetailsLogicProps['onTabSelected'],
  UseEmailContentDetailsLogicProps['selectedTab'],
  UseEmailContentDetailsLogicProps['shouldHighlightAttachment'],
  UseEmailContentDetailsLogicProps['shouldHighlightAnalysis'],
  UseEmailContentDetailsLogicProps['shouldHighlightEnvelope'],
  UseEmailContentDetailsLogicProps['showKeywordHighlighting'],
  UseEmailContentDetailsLogicProps['switchKeywordHighlighting'],
  UseEmailContentDetailsLogicProps['tabContent'],
  UseEmailContentDetailsLogicProps['tabHighlighting'],
  UseEmailContentDetailsLogicProps['tabs'],
  UseEmailContentDetailsLogicProps['updateKeywordHighlighting']
] {
  const {
    accessToken,
    accessTokenId,
    attackAttributes,
    isAttackDetailsLoaded,
    isUpdateAccessTokenSuccess,
    updatedAccessTokenSettings
  } = useAppSelector((_stores: any) => ({
    accessToken: _stores.accessToken.accessToken,
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    attackAttributes: _stores.attack?.details?.attackAttributes || {},
    isAttackDetailsLoaded: isSuccess(_stores.attack?.spAttackApiStatus),
    isUpdateAccessTokenSuccess: isSuccess(_stores.settings.updateAccessTokenApiStatus),
    updatedAccessTokenSettings: _stores.settings.accessTokenSettings
  }))

  const dispatch = useAppDispatch()
  const [height, setHeight] = useState(500)
  const [selectedTab, onTabSelected] = useTabs(0)
  const iframeRef = createRef<FrameRef>()

  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    highlighting_analysis_attributes,
    highlighting_keywords,
    highlighting_suspicious_attachments,
    highlighting_suspicious_headers
  } = attackAttributes
  /* eslint-enable @typescript-eslint/naming-convention */

  const handleResize = useCallback((iframe: RefObject<FrameRef>) => {
    const iframeHeight = iframe.current?.node.contentDocument?.body.scrollHeight ?? 0
    if (iframeHeight !== 0) {
      setHeight(iframeHeight + 50)
    }
  }, [])

  useEffect(() => handleResize(iframeRef), [handleResize, iframeRef])

  const initKeywordHighlighting = useMemo(() => {
    if (!isAttackDetailsLoaded || !config.domainConfig.isSentinel) {
      return false
    }
    if (!accessToken?.settings.keywordHighlighting) {
      return true
    }
    return accessToken?.settings.keywordHighlighting === 'True'
  }, [accessToken, isAttackDetailsLoaded])

  const [switchKeywordHighlighting, setSwitchKeywordHighlighting] = useState(initKeywordHighlighting)

  // successfully updated accessToken's settings
  useEffect(() => {
    if (isUpdateAccessTokenSuccess && !isEqual(accessToken?.settings, updatedAccessTokenSettings)) {
      dispatch(
        updateCurrentAccessToken({
          ...accessToken,
          settings: updatedAccessTokenSettings
        } as AccessToken)
      )
    }
  }, [isUpdateAccessTokenSuccess, accessToken, dispatch, updatedAccessTokenSettings])

  const showKeywordHighlighting = useMemo(() => {
    if (isAttackDetailsLoaded && config.domainConfig.isSentinel) {
      return [
        Object.values(highlighting_analysis_attributes).filter(value => value === true),
        highlighting_keywords,
        highlighting_suspicious_attachments,
        highlighting_suspicious_headers
      ].some(highlighting => highlighting.length > 0)
    }
    return false
  }, [
    highlighting_analysis_attributes,
    highlighting_keywords,
    highlighting_suspicious_attachments,
    highlighting_suspicious_headers,
    isAttackDetailsLoaded
  ])

  const updateKeywordHighlighting = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSwitchKeywordHighlighting(e.target.checked)
      dispatch(updateAccessToken({ settings: { keywordHighlighting: e.target.checked } }))
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.TOGGLE_HIGHLIGHTING, {
        accessTokenId,
        keywordHighlighting: e.target.checked
      })
    },
    [accessTokenId, dispatch]
  )

  const shouldHighlightAttachment = useCallback(
    (attachmentName: string) => {
      if (!switchKeywordHighlighting) {
        return false
      }
      const suspiciousAttachments = highlighting_suspicious_attachments.map(
        (attachment: Attachment) => attachment.name || ''
      )
      if (suspiciousAttachments.includes(attachmentName)) {
        return true
      }
      return false
    },
    [highlighting_suspicious_attachments, switchKeywordHighlighting]
  )

  const shouldHighlightAnalysis = useCallback(
    (field: string) => {
      if (!switchKeywordHighlighting) {
        return false
      }
      switch (field) {
        case 'senderIpAddress':
          return highlighting_analysis_attributes.sender_ip_address
        case 'domain':
          return highlighting_analysis_attributes.sender_domain
        case 'senderIpReputation':
          return highlighting_analysis_attributes.ip_fraud_score
        case 'senderDomainRegistrationDate':
          return highlighting_analysis_attributes.sender_domain_age
        default:
          return false
      }
    },
    [highlighting_analysis_attributes, switchKeywordHighlighting]
  )

  const shouldHighlightEnvelope = useCallback(
    (title: string) => {
      if (!switchKeywordHighlighting) {
        return false
      }
      if (
        (title === 'from' && highlighting_analysis_attributes.sender_address) ||
        (title === 'reply_to' && highlighting_analysis_attributes.reply_to_address)
      ) {
        return true
      }
      return false
    },
    [highlighting_analysis_attributes, switchKeywordHighlighting]
  )

  const internetMessageHeaders = useMemo(() => {
    return emailInfo?.headers || []
  }, [emailInfo])

  const copyHeadersToClipboard = useCallback(() => {
    return internetMessageHeaders.reduce((all: string, header: Header) => {
      return `${all}${all.length ? '\n' : ''}${header.name}: ${header.value}`
    }, '')
  }, [internetMessageHeaders])

  const copyFailureReportToClipboard = useCallback(() => {
    return (
      emailInfo?.failureReport?.reduce((all: string, header: Header) => {
        return `${all}${all.length ? '\n' : ''}${header.name}: ${header.value}`
      }, '') || ''
    )
  }, [emailInfo])

  const parseThreats = useMemo(() => {
    if (!emailInfo || !internetMessageHeaders) {
      return []
    }

    // Use Microsoft header when we dont't have EGD header
    const threatsHeader = internetMessageHeaders.reduce((result: Header | null, header: Header) => {
      // Check if the header is from EGD and header value has EGD_HOST_NAME, if yes, return it
      if (header.name === AUTHENTICATION_HEADER_EGD && header.value.includes(config.egdAuthResultDomain)) {
        return header
      }

      // If we haven't find EGD header that has EGD_HOST_NAME in it yet, and we have the header from Microsoft, we return Microsoft header
      if (!result && header.name === AUTHENTICATION_HEADER) {
        return header
      }

      return result
    }, null)

    if (!threatsHeader) {
      return []
    }

    function collectThreatsDetails(threat: string) {
      if (!threatsHeader) {
        return ''
      }

      const threatString = (
        threatsHeader.value.split(';').find((threatValue: string) => {
          return threatValue.trim().includes(threat)
        }) || ''
      )
        .toLowerCase()
        .replace(`${threat}=`, '')

      return {
        type: threat,
        value: threatString,
        passed: threatString.includes('pass')
      }
    }

    return Object.values(THREATS).reduce((all: any, threat: string) => {
      const details = collectThreatsDetails(threat)

      if (details) {
        return [...all, details]
      }

      return all
    }, [])
  }, [emailInfo, internetMessageHeaders])

  const fixedDisabledTabs: string[] = useMemo(() => {
    const baseDisabledTabs: string[] = [
      !emailInfo?.headers?.length ? TABS.HEADERS : '',
      !emailInfo?.failureReport?.length ? TABS.FAILURE_REPORT : ''
    ]

    return [...(disabledTabs || []), ...baseDisabledTabs]
  }, [disabledTabs, emailInfo])

  const tabs: string[] = useMemo(() => {
    return Object.values(TABS).filter((tab: string) => !fixedDisabledTabs.includes(tab))
  }, [fixedDisabledTabs])

  const tabContent = useMemo(() => {
    if (!emailInfo || isUndefined(selectedTab)) {
      return null
    }

    switch (tabs[selectedTab]) {
      case TABS.EMAIL:
        return reactHtmlParser(
          DOMPurify.sanitize(
            switchKeywordHighlighting && !!highlighting_keywords
              ? highlightHtml(highlighting_keywords, emailInfo.content)
              : emailInfo.content
          )
        )
      case TABS.HEADERS:
        return internetMessageHeaders
      case TABS.FAILURE_REPORT:
        return emailInfo?.failureReport
      case TABS.THREAT_DETAILS:
        return parseThreats
      default:
        return null
    }
  }, [
    emailInfo,
    highlighting_keywords,
    internetMessageHeaders,
    parseThreats,
    selectedTab,
    switchKeywordHighlighting,
    tabs
  ])

  const tabHighlighting = useMemo(() => {
    return {
      [TABS.EMAIL]:
        showKeywordHighlighting &&
        switchKeywordHighlighting &&
        (highlighting_analysis_attributes.reply_to_address ||
          highlighting_analysis_attributes.sender_address ||
          !!highlighting_keywords.length ||
          !!highlighting_suspicious_attachments.length),
      [TABS.HEADERS]: showKeywordHighlighting && switchKeywordHighlighting && !!highlighting_suspicious_headers.length
    }
  }, [
    highlighting_analysis_attributes,
    highlighting_keywords,
    highlighting_suspicious_attachments,
    highlighting_suspicious_headers,
    showKeywordHighlighting,
    switchKeywordHighlighting
  ])

  return useMemo(() => {
    return [
      emailInfo?.attachments?.length,
      copyHeadersToClipboard,
      copyFailureReportToClipboard,
      handleResize,
      height,
      highlighting_suspicious_headers || [],
      iframeRef,
      onTabSelected,
      selectedTab,
      shouldHighlightAttachment,
      shouldHighlightAnalysis,
      shouldHighlightEnvelope,
      showKeywordHighlighting,
      switchKeywordHighlighting,
      tabContent,
      tabHighlighting,
      tabs,
      updateKeywordHighlighting
    ]
  }, [
    emailInfo,
    copyHeadersToClipboard,
    copyFailureReportToClipboard,
    handleResize,
    height,
    highlighting_suspicious_headers,
    iframeRef,
    onTabSelected,
    selectedTab,
    shouldHighlightAttachment,
    shouldHighlightAnalysis,
    shouldHighlightEnvelope,
    showKeywordHighlighting,
    switchKeywordHighlighting,
    tabContent,
    tabHighlighting,
    tabs,
    updateKeywordHighlighting
  ])
}
