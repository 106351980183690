import React, { useMemo } from 'react'

import { Tooltip } from '@barracuda-internal/bds-core'

import { COLORS } from 'global/configs/theme/defaults'
import attackTypeValidator, { ATTACK_TYPES, ETS_ATTACK_TYPES } from 'global/lib/attackTypeValidator/attackTypeValidator'
import TextLabel from 'global/components/lib/textLabel/TextLabel'
import styles from 'global/components/lib/attackTypeLabel/attackTypeLabelStyles'
import { useFormatMessage } from 'global/lib/localization'
import { FEATURES, isMyFeatureOn } from 'global/lib/splitio/splitio'
import useUserDataLib from 'global/lib/userData/useUserData'

import { useAppSelector } from 'ets/redux/toolkit/hooks'

export interface AttackTypeLabelProps {
  attackType: string | undefined
  etsAttackTypes?: boolean
}

const BASE_ATTACK_TYPES_I18N_KEY = 'app.attack_types'

const ATTACK_COLORS: { [key: string]: string } = {
  [ATTACK_TYPES.CONVERSATION_HIJACKING]: COLORS.MAGENTA_600,
  [ATTACK_TYPES.EXTORTION]: COLORS.SCALE_GRAY_800,
  [ATTACK_TYPES.IMPERSONATION]: COLORS.BLUE_600,
  // TODO: BNFIR-4234: may need to remove this depending on what happens with the results of the investigation.
  [ETS_ATTACK_TYPES.IMPERSONATION_BEC]: COLORS.BLUE_600,
  // END TODO
  [ATTACK_TYPES.PHISHING]: COLORS.TEAL_600,
  [ATTACK_TYPES.SCAMMING]: COLORS.HUMPBACK_GRAY_600,
  [ETS_ATTACK_TYPES.SCAMMING]: COLORS.HUMPBACK_GRAY_600
}

export const AttackTypeLabel: React.FC<AttackTypeLabelProps> = ({ attackType, etsAttackTypes = false }) => {
  const formatMessage = useFormatMessage(BASE_ATTACK_TYPES_I18N_KEY)
  const classes = styles()
  const [userDataLib] = useUserDataLib()
  const { accessTokenId, splitStore } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    splitStore: _stores.splitio
  }))

  // TODO: BNFIR-4234: may need to remove this depending on what happens with the results of the investigation.
  const accountId = useMemo(() => userDataLib.getAccountByAccessToken(accessTokenId)?.accountId, [
    accessTokenId,
    userDataLib
  ])
  // END TODO

  return useMemo(() => {
    if (!attackType) {
      return null
    }

    let validatedAttackType = attackTypeValidator(attackType)

    // BNFIR-4233 trying out scamming/graymail as a label on ETS
    // if sales/customers like it we can rip out the `etsAttackTypes` check and use it for IP too
    if (etsAttackTypes && attackType === 'spam') {
      validatedAttackType = ETS_ATTACK_TYPES.SCAMMING
    }

    // TODO: BNFIR-4234: may need to remove this depending on what happens with the results of the investigation.
    if (etsAttackTypes && attackType === 'becSpoofing') {
      validatedAttackType = isMyFeatureOn(splitStore, FEATURES.ETS_BEC_IMPERSONATION, accountId)
        ? ETS_ATTACK_TYPES.IMPERSONATION_BEC
        : ETS_ATTACK_TYPES.IMPERSONATION
    }
    // END TODO

    if (!validatedAttackType) {
      return null
    }

    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        arrow
        title={
          <div className={classes.tooltipContent}>
            <b>{formatMessage(`${validatedAttackType}.text`)}</b>
            <br />
            <br />
            {formatMessage(`${validatedAttackType}.tooltip`)}
          </div>
        }
        placement="top-start"
      >
        <data>
          <TextLabel bgColor={ATTACK_COLORS[validatedAttackType]} data-field="attack-type-label">
            {formatMessage(`${validatedAttackType}.text`)}
          </TextLabel>
        </data>
      </Tooltip>
    )
  }, [
    accountId,
    attackType,
    classes.tooltip,
    classes.tooltipArrow,
    classes.tooltipContent,
    etsAttackTypes,
    formatMessage,
    splitStore
  ])
}

export default AttackTypeLabel
