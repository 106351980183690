import React, { useMemo } from 'react'

import { Card, CardContent, IconButton } from '@barracuda-internal/bds-core'
import { Close as CloseIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import useCtaBannerLogic, {
  EMAILS_BY_REGION_CTA_COOKIE,
  POTENTIAL_INCIDENTS_CTA_COOKIE,
  REMEDIATION_CTA_COOKIE,
  USER_REPORTED_CTA_COOKIE
} from 'fir/components/lib/ctaBanner/useCTABannerLogic'
import EmailsByRegionCTA from 'fir/components/lib/ctaBanner/pages/EmailsByRegionCTA'
import PotentialIncidentsCTA from 'fir/components/lib/ctaBanner/pages/PotentialIncidentsCTA'
import RemediationCTA from 'fir/components/lib/ctaBanner/pages/RemediationCTA'
import UserReportedCTA from 'fir/components/lib/ctaBanner/pages/UserReportedCTA'

import styles from 'fir/components/lib/ctaBanner/CTABannerStyles'

export type CtaCookieType =
  | 'forensics-geoip-cta'
  | 'forensics-potential-incidents-cta'
  | 'forensics-incidents-cta'
  | 'forensics-user-reported-cta'

export interface Props {
  ctaCookie: CtaCookieType
}

const CTABanner: React.FC<Props> = ({ ctaCookie }) => {
  const classes = styles()
  const [ctaBannerLogic] = useCtaBannerLogic()

  return useMemo(
    () => (
      <Card>
        <IconButton
          aria-label="hide call to action"
          className={classes.close}
          onClick={() => {
            ctaBannerLogic.onClose(ctaCookie)
          }}
        >
          <CloseIcon />
        </IconButton>
        <CardContent className={classes.wrapper}>
          {ctaCookie === EMAILS_BY_REGION_CTA_COOKIE && <EmailsByRegionCTA />}
          {ctaCookie === POTENTIAL_INCIDENTS_CTA_COOKIE && <PotentialIncidentsCTA />}
          {ctaCookie === REMEDIATION_CTA_COOKIE && <RemediationCTA />}
          {ctaCookie === USER_REPORTED_CTA_COOKIE && <UserReportedCTA {...ctaBannerLogic.contentConfig} />}
        </CardContent>
      </Card>
    ),
    [classes, ctaBannerLogic, ctaCookie]
  )
}

export default CTABanner
