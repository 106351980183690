import React, { useMemo } from 'react'
import { toLower, capitalize } from 'lodash'
import { DateTime } from 'luxon'
import {
  Box,
  Button,
  Divider,
  Drawer,
  ListSubheader,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@barracuda-internal/bds-core'

import { ScheduledReportsDrawerConfig } from 'global/components/features/scheduledReports/ScheduledReports'
import useScheduledReportsDrawerLogic from 'global/components/features/scheduledReports/scheduledReportsDrawer/useScheduledReportsDrawerLogic'
import { useFormatMessage } from 'global/lib/localization'
import { Frequency, FrequencyMonthly, ReportFormat, TimeRange, WeekDays } from 'global/types/api/scheduledReports'

import styles from './scheduledReportsDrawerStyles'

type ScheduledReportsDrawerProps = {
  isScheduleDialogOpen: boolean
  scheduledReportsDrawerConfig: ScheduledReportsDrawerConfig
  toggleScheduleDialog: (e: any) => void
}

const BASE_I18N_KEY = 'app.scheduled_reports.schedule_report_dialog'
const FREQUENCY_LIST = [Frequency.daily, Frequency.weekly, Frequency.monthly]
const FREQUENCY_MONTHLY_LIST = [
  FrequencyMonthly.first,
  FrequencyMonthly.second,
  FrequencyMonthly.third,
  FrequencyMonthly.fourth,
  FrequencyMonthly.last,
  FrequencyMonthly.specificDate
]

const REPORT_FORM_LIST = [ReportFormat.CSV, ReportFormat.PDF]
const TIME_RANGE_LIST = [
  TimeRange.lastDay,
  TimeRange.last3Days,
  TimeRange.last7Days,
  TimeRange.last30Days,
  TimeRange.last90Days,
  TimeRange.last6Months
]
const WEEK_DAYS_LIST = [WeekDays.su, WeekDays.mo, WeekDays.tu, WeekDays.we, WeekDays.th, WeekDays.fr, WeekDays.sa]

const ScheduledReportsDrawer: React.FC<ScheduledReportsDrawerProps> = ({
  isScheduleDialogOpen,
  scheduledReportsDrawerConfig,
  toggleScheduleDialog
}) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const { onCreateUpdateScheduledReport, reportNameList } = scheduledReportsDrawerConfig
  const [scheduledReportsDrawerLogic] = useScheduledReportsDrawerLogic()

  const {
    dateOptions,
    handleFrequencyButtonClick,
    handleValueChange,
    handleWeekdaysButtonClick,
    invalidRecipientsErrorText,
    maxReportNameLength,
    reportName,
    setInvalidRecipientsErrorText,
    shouldShowWeekdaysList,
    timeOptions,
    updatedReportDrawerConfig
  } = scheduledReportsDrawerLogic

  const {
    format,
    frequency,
    frequencyDayOfMonth,
    frequencyDayOfWeek,
    frequencyMonthly,
    frequencyTime,
    isReportNameFixed,
    lastModifiedOn,
    recipients,
    relativeDateRange,
    reportUuid,
    uuid
  } = updatedReportDrawerConfig

  return useMemo(
    () => (
      <Drawer
        anchor="right"
        variant="temporary"
        ModalProps={{ keepMounted: false }}
        open={isScheduleDialogOpen}
        onClose={e => {
          toggleScheduleDialog(e)
          setInvalidRecipientsErrorText('')
        }}
      >
        <Box className={classes.container}>
          {/** Editing an existing schedule */}
          {isReportNameFixed && uuid && (
            <>
              <Typography variant="h5">{formatMessage('title.update_schedule')}</Typography>
              <Typography variant="caption">
                {formatMessage('last_updated')}
                {/* eslint-disable-next-line quotes */}
                {DateTime.fromISO(lastModifiedOn).toFormat("LLL dd, yyyy 'at' hh:mm:ss a")}
              </Typography>
              {reportName}
            </>
          )}
          {/** Creating a new schedule from unified reporting */}
          {isReportNameFixed && !uuid && (
            <>
              <Typography variant="h5">{formatMessage('title.new_schedule')}</Typography>
              {reportName}
            </>
          )}
          {/** Creating a new schedule from schedule reports */}
          {!isReportNameFixed && !uuid && (
            <>
              <Typography variant="h5">{formatMessage('title.new_schedule')}</Typography>
              <TextField
                value={reportUuid}
                name="reportUuid"
                fullWidth
                onChange={handleValueChange}
                label={formatMessage('label.report_name')}
                select
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxWidth: '200px'
                      }
                    }
                  }
                }}
              >
                <ListSubheader className={classes.listSubheader}>
                  {formatMessage('reportNameListSubheader.my_reports')}
                </ListSubheader>
                {reportNameList.customReports
                  .sort((reportA, reportB) => reportA.reportName.localeCompare(reportB.reportName))
                  .map(report => (
                    <MenuItem className={classes.menuItems} key={report.reportId} value={report.reportId}>
                      {report.reportName.length > maxReportNameLength
                        ? `${report.reportName.slice(0, maxReportNameLength)}...`
                        : report.reportName}
                    </MenuItem>
                  ))}
                <Divider component="li" />
                <ListSubheader className={classes.listSubheader}>
                  {formatMessage('reportNameListSubheader.barracuda_reports')}
                </ListSubheader>
                {reportNameList.barracudaReports
                  .sort((reportA, reportB) => reportA.reportName.localeCompare(reportB.reportName))
                  .map(report => (
                    <MenuItem className={classes.menuItems} key={report.reportId} value={report.reportId}>
                      {report.reportName}
                    </MenuItem>
                  ))}
              </TextField>
            </>
          )}
          <TextField
            fullWidth
            value={relativeDateRange}
            name="relativeDateRange"
            onChange={handleValueChange}
            label={formatMessage('label.time_range')}
            select
          >
            {TIME_RANGE_LIST.map((range: any) => (
              <MenuItem className={classes.menuItems} key={range} value={range}>
                {capitalize(toLower(range.replace(/_/g, ' ')))}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            value={format}
            name="format"
            onChange={handleValueChange}
            label={formatMessage('label.format')}
            select
          >
            {REPORT_FORM_LIST.map((reportFormat: string) => (
              <MenuItem className={classes.menuItems} key={reportFormat} value={reportFormat}>
                {reportFormat} format
              </MenuItem>
            ))}
          </TextField>
          <Typography variant="body2" color="secondary">
            {formatMessage('frequency')}
          </Typography>
          <ToggleButtonGroup size="medium" value={frequency} exclusive onChange={handleFrequencyButtonClick}>
            {FREQUENCY_LIST.map(freq => (
              <ToggleButton key={freq} value={freq} className={classes.frequencyButton}>
                {capitalize(toLower(freq))}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {frequency === Frequency.monthly && (
            <div className={classes.frequencyGroup}>
              <Typography variant="body2" color="secondary" className={classes.frequencyText}>
                {formatMessage('every')}
              </Typography>
              <TextField
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: 20
                  }
                }}
                className={classes.frequencyTextField}
                value={frequencyMonthly}
                name="frequencyMonthly"
                onChange={handleValueChange}
                select
              >
                {FREQUENCY_MONTHLY_LIST.map((every: any) => (
                  <MenuItem className={classes.menuItems} key={every} value={every}>
                    {capitalize(toLower(every.replace(/_/g, ' ')))}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          )}
          {frequencyMonthly === FrequencyMonthly.specificDate && frequency === Frequency.monthly && (
            <div className={classes.frequencyGroup}>
              <Typography variant="subtitle2" color="secondary" className={classes.frequencyText}>
                {formatMessage('day')}
              </Typography>
              <TextField
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: 20
                  }
                }}
                value={frequencyDayOfMonth}
                name="frequencyDayOfMonth"
                onChange={handleValueChange}
                select
              >
                {dateOptions.map((dateOption: string, index: number) => (
                  <MenuItem
                    className={classes.menuItems}
                    key={dateOption}
                    value={dateOption === formatMessage('last_day_of_month') ? 0 : index + 1}
                  >
                    {dateOption}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          )}
          {shouldShowWeekdaysList && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start'
              }}
            >
              <div className={classes.frequencyGroup}>
                <Typography variant="body2" color="secondary" className={classes.toggleButtonFrequencyText}>
                  {formatMessage('on')}
                </Typography>
                <ToggleButtonGroup
                  size="medium"
                  value={frequencyDayOfWeek}
                  exclusive
                  onChange={handleWeekdaysButtonClick}
                >
                  {WEEK_DAYS_LIST.map((day, index) => (
                    <ToggleButton key={day} value={index} className={classes.weekDayButton}>
                      {day}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
            </Box>
          )}
          <div className={classes.frequencyGroup}>
            <Typography variant="subtitle2" color="secondary" className={classes.frequencyText}>
              {formatMessage('at')}
            </Typography>
            <TextField
              fullWidth
              InputProps={{
                style: {
                  borderRadius: 20
                }
              }}
              value={frequencyTime}
              name="frequencyTime"
              onChange={handleValueChange}
              select
              helperText={formatMessage('helper_text.delays_may_occur')}
              FormHelperTextProps={{
                className: classes.helperText
              }}
            >
              {timeOptions.map((timeOption: any) => (
                <MenuItem className={classes.menuItems} key={timeOption} value={timeOption}>
                  {`${timeOption} ${DateTime.local().offsetNameShort}`}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <TextField
            fullWidth
            error={!!invalidRecipientsErrorText}
            helperText={invalidRecipientsErrorText}
            value={recipients}
            name="recipients"
            onChange={handleValueChange}
            label={formatMessage('label.recipients')}
            multiline
            maxRows={100}
          />
          <div className={classes.actions}>
            <Button
              onClick={e => {
                toggleScheduleDialog(e)
                setInvalidRecipientsErrorText('')
              }}
              size="large"
              color="primary"
              variant="text"
            >
              {formatMessage('button.cancel')}
            </Button>
            <Button
              className={classes.saveButton}
              size="large"
              variant="contained"
              color="primary"
              disabled={!!invalidRecipientsErrorText || !reportUuid || !recipients.length}
              onClick={e => {
                onCreateUpdateScheduledReport(uuid, updatedReportDrawerConfig)
                toggleScheduleDialog(e)
              }}
            >
              {uuid ? formatMessage('button.save') : formatMessage('button.schedule')}
            </Button>
          </div>
        </Box>
      </Drawer>
    ),
    [
      classes.actions,
      classes.container,
      classes.frequencyButton,
      classes.frequencyGroup,
      classes.frequencyText,
      classes.frequencyTextField,
      classes.helperText,
      classes.listSubheader,
      classes.menuItems,
      classes.saveButton,
      classes.toggleButtonFrequencyText,
      classes.weekDayButton,
      dateOptions,
      format,
      formatMessage,
      frequency,
      frequencyDayOfMonth,
      frequencyDayOfWeek,
      frequencyMonthly,
      frequencyTime,
      handleFrequencyButtonClick,
      handleValueChange,
      handleWeekdaysButtonClick,
      invalidRecipientsErrorText,
      isReportNameFixed,
      isScheduleDialogOpen,
      lastModifiedOn,
      maxReportNameLength,
      onCreateUpdateScheduledReport,
      recipients,
      relativeDateRange,
      reportName,
      reportNameList.barracudaReports,
      reportNameList.customReports,
      reportUuid,
      setInvalidRecipientsErrorText,
      shouldShowWeekdaysList,
      timeOptions,
      toggleScheduleDialog,
      updatedReportDrawerConfig,
      uuid
    ]
  )
}

export default ScheduledReportsDrawer
