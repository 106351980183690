import { useEffect, useMemo } from 'react'

import { useParams } from 'react-router-dom'

import { setShareSecret } from 'global/redux/features/accessToken/accessTokenSlice'
import { getGuestScanStatus } from 'global/redux/features/scan/scanSlice'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'

export interface SharedLogic {
  isAccessTokenSet: boolean
}

export default function useSharedLogic() {
  const dispatch = useAppDispatch()
  const urlParams: { shareSecret?: string } = useParams()
  const { isShareSecretSet, isAccessTokenSet } = useAppSelector(_stores => ({
    isShareSecretSet: !!_stores.accessToken.shareSecret?.value,
    isAccessTokenSet: _stores.accessToken.isAccessTokenSet
  }))

  const user = useAppSelector(_stores => _stores.user)

  // init
  useEffect(() => {
    dispatch(setShareSecret({ value: urlParams.shareSecret }))
  }, [dispatch, urlParams.shareSecret])

  // Load the scan data if shareSecret is set
  useEffect(() => {
    if (isShareSecretSet) {
      if (user) {
        analyticsLib.setUser(user.data)
      }

      analyticsLib.trackAppEvent(analyticsLib.EVENTS.SHARED_REPORT_PAGEVIEW)

      dispatch(getGuestScanStatus())
    }
  }, [dispatch, isShareSecretSet, user])

  return useMemo(() => [{ isAccessTokenSet }], [isAccessTokenSet])
}
