import { useRef, useLayoutEffect } from 'react'

const isBrowser = typeof window !== 'undefined'

const DEFAULT_THROTTLE = 100

let throttleTimeout: any = null

export interface OnScroll {
  cb: ({ currPos, prevPos }: { currPos: any; prevPos: any }) => void
  deps?: any[]
  element?: Element
  useWindow?: boolean
  throttle?: number
}

function getScrollPosition({ element, useWindow }: any) {
  if (!isBrowser) return { x: 0, y: 0 }

  if (useWindow) {
    return { x: window.scrollX, y: Math.abs(window.scrollY) }
  }

  //  element.scrollTop will be used to calculation if isScrolledToBottom
  if (element) {
    return { x: element.scrollLeft, y: element.scrollTop }
  }

  return { x: 0, y: 0 }
}

export default function useScrollPosition({
  cb,
  deps = [],
  element,
  useWindow = false,
  throttle = DEFAULT_THROTTLE
}: OnScroll): void {
  const position = useRef(getScrollPosition({ element, useWindow }))

  useLayoutEffect(() => {
    const callBack = () => {
      const currPos = getScrollPosition({ element, useWindow })
      cb({ currPos, prevPos: position.current })
      position.current = currPos
      throttleTimeout = null
    }

    const handleScroll = () => {
      if (throttleTimeout === null) {
        if (throttle > 0) {
          throttleTimeout = setTimeout(callBack, throttle)
        } else {
          callBack()
        }
      }
    }
    if (element) {
      element.addEventListener('scroll', handleScroll)
    } else {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll)
      } else {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [cb, element, useWindow, deps, throttle])
}
