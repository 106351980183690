/* eslint-disable @typescript-eslint/naming-convention */
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export default makeStyles(theme => makeWorkflowFlowStyles(theme))

export function makeWorkflowFlowStyles(theme: Theme) {
  return createStyles({
    flowStyles: {
      '& .react-flow__connection-path': {
        strokeWidth: '6px'
      },
      '& .react-flow__controls': {
        postion: 'absolute',
        width: '40px',
        right: '20px',
        left: 'unset',
        top: '35px',
        bottom: 'unset'
      },
      '& .react-flow__controls-button': {
        width: '30px',
        height: '30px'
      },
      '& .react-flow__controls-button svg': {
        maxWidth: '16px',
        maxHeight: '16px'
      },
      '& .react-flow__edge.selected .react-flow__edge-path': {
        stroke: theme.colors.barracudaBlack30
      },
      '& .react-flow__edge-path': {
        stroke: theme.colors.barracudaBlack70,
        strokeWidth: '6px'
      },
      '& .react-flow__edge-path:hover': {
        cursor: 'pointer'
      },
      '& .react-flow__handle': {
        background: theme.colors.barracudaBlack70,
        height: '16px',
        width: '16px'
      },
      '& .react-flow__handle.selected': {
        background: theme.colors.barracudaBlack30
      },
      '& .react-flow__handle-bottom': {
        bottom: '-9px'
      },
      '& .react-flow__handle-top': {
        top: '-9px'
      },
      '& .react-flow__node': {
        background: '#F7F7F7',
        border: '4px solid',
        width: 180,
        wordBreak: 'break-word'
      },
      '& .react-flow__node.selected': {
        opacity: '0.5 !important'
      },
      '& .react-flow__node.react-flow__node-default': {
        borderColor: theme.colors.skyBlue
      },
      '& .react-flow__node.react-flow__node-input': {
        borderColor: theme.colors.barracudaTeal
      },
      '& .react-flow__node.react-flow__node-output': {
        borderColor: theme.colors.starfishOrange
      }
    }
  })
}
