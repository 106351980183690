import React from 'react'

import { useTheme } from '@material-ui/core/styles'

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Zoom
} from '@barracuda-internal/bds-core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'

import DEFAULTS from 'global/configs/theme/defaults'
import { useErrorFormatMessage, useFormatMessage } from 'global/lib/localization'

// I only need the title and text styles, doesn't make sense to repeat code
// this will dry up in BDSV2
import styles from 'admin/components/lib/dialogs/scanDialog/scanDialogStyles'

import { ThreatsFolderDialogConfig } from 'admin/components/pages/dashboard/tabs/emailThreatScanner/useEmailThreatScannerLogic'

import useThreatsFolderDialog from './useThreatsFolderDialogLogic'

const BASE_I18N_KEY = 'admin.app.dialogs.threats_folder_dialog'

export interface ThreatsFolderDialogProps {
  accountName: ThreatsFolderDialogConfig['accountName']
  scanUUID: ThreatsFolderDialogConfig['scanUUID']
  accessTokenId: ThreatsFolderDialogConfig['accessTokenId']
  accountId: ThreatsFolderDialogConfig['accountId']
  email: ThreatsFolderDialogConfig['email']
  onClose: ThreatsFolderDialogConfig['onClose']
}

export const ThreatsFolderDialog: React.FC<ThreatsFolderDialogProps> = ({
  accountName,
  scanUUID,
  accessTokenId,
  accountId,
  email,
  onClose
}) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatErrorMessage = useErrorFormatMessage()

  const [inProgress, error, errorMsg, isSuccess, onSendThreatsReport] = useThreatsFolderDialog()
  const classes = styles()
  const theme = useTheme()

  return (
    <Dialog
      style={{ zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX }}
      maxWidth="md"
      PaperProps={{ style: { minHeight: isSuccess ? 'auto' : '300px' } }}
      fullWidth
      open
      TransitionComponent={Zoom}
      data-field="dialog"
    >
      {isSuccess ? (
        <DialogContent data-field="dialog-content" style={{ overflow: 'hidden' }}>
          <Grid container spacing={2} direction="column" style={{ display: 'flex' }}>
            <Typography className={classes.title} data-field="dialog-title">
              {formatMessage('title', { name: accountName })}
            </Typography>
            <Grid item className={classes.contentRow}>
              <Typography className={classes.label} data-field="dialog-subtitle">
                {formatMessage('success_message', { email })}
              </Typography>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={onClose}
              data-field="dialog-dismiss-button"
            >
              &nbsp;&nbsp;{formatMessage('close')}&nbsp;&nbsp;
            </Button>
          </DialogActions>
        </DialogContent>
      ) : (
        <>
          {error && errorMsg && (
            <Alert data-field="error" variant="filled" severity="error" style={{ marginBottom: theme.spacing(2) }}>
              {formatErrorMessage(errorMsg)}
            </Alert>
          )}
          {inProgress && <LinearProgress data-field="loader" style={{ marginBottom: theme.spacing(2) }} />}
          <DialogContent data-field="dialog-content" style={{ overflow: 'hidden' }}>
            <Grid container spacing={2} direction="column" style={{ display: 'flex' }}>
              <Typography className={classes.title} data-field="dialog-title">
                {formatMessage('title', { name: accountName })}
              </Typography>
              <Grid item className={classes.contentRow}>
                <Typography className={classes.label} data-field="dialog-subtitle">
                  {formatMessage('account_id')}
                </Typography>
                <Typography className={classes.label} data-field="dialog-subtitle">
                  {accountId}
                </Typography>
              </Grid>
              <Grid item className={classes.contentRow}>
                <Typography className={classes.label} data-field="dialog-subtitle">
                  {formatMessage('access_token_id')}
                </Typography>
                <Typography className={classes.label} data-field="dialog-subtitle">
                  {accessTokenId}
                </Typography>
              </Grid>
              <Grid item className={classes.contentRow}>
                <Typography className={classes.label} data-field="dialog-subtitle">
                  {formatMessage('scan_uuid')}
                </Typography>
                <Typography className={classes.label} data-field="dialog-subtitle">
                  {scanUUID}
                </Typography>
              </Grid>
              <Grid item className={classes.contentRow}>
                <Typography className={classes.label} data-field="dialog-subtitle">
                  {formatMessage('recipient')}
                </Typography>
                <Typography className={classes.label} data-field="dialog-subtitle">
                  {email}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={inProgress}
              color="primary"
              variant="outlined"
              size="large"
              onClick={onClose}
              data-field="dialog-dismiss-button"
            >
              &nbsp;&nbsp;{formatMessage('cancel')}&nbsp;&nbsp;
            </Button>
            <Button
              disabled={inProgress}
              color="primary"
              variant="contained"
              size="large"
              onClick={() => onSendThreatsReport(scanUUID, accessTokenId, accountId, email)}
              data-field="dialog-send-button"
            >
              &nbsp;&nbsp;{formatMessage('send')}&nbsp;&nbsp;
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default ThreatsFolderDialog
