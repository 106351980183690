import { useEffect, useMemo, useCallback, useReducer, useRef } from 'react'
import { process } from '@progress/kendo-data-query'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useUserDataLib from 'global/lib/userData/useUserData'
import { formatDateWithTime, formatDate } from 'global/lib/datetime'
import { IncidentEmailSearch, RemediationStatuses } from 'global/types/api/remediation'
import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import { isPending, isSuccess, getErrorMessage } from 'global/redux/toolkit/api'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import {
  update as updateIncidentsEmailsTable,
  reset as resetIncidentsEmailsTable
} from 'sen/redux/features/dataTables/incidents/incidentsEmailsSlice'
import {
  createIncident,
  getInboxRules,
  getIncidentTaskStatus,
  getRecipients,
  resetIncidentTaskStatus,
  resetSearchForIncidentEmails,
  searchForIncidentEmails
} from 'sen/redux/features/remediation/remediationSlice'
import { INCIDENT_QUERY } from 'sen/components/lib/dialogs/newIncidentDialog/steps/useNewIncidentFormLogic'
import useNewIncidentDialogWizardLogic, {
  ButtonStatus
} from 'sen/components/lib/dialogs/newIncidentDialog/useNewIncidentDialogWizardLogic'
import useEmailDetailsIncidentInterface, {
  UseEmailDetailsIncidentInterface
} from 'sen/components/lib/dialogs/emailDetailsDialog/interfaces/useEmailDetailsIncidentInterface'

export type Error = string | undefined
export type IsLoaded = boolean
export type InProgress = boolean

export interface TableConfig {
  tableData: {
    total: number
    data: any[]
  }
  pageConfig: BDSGridPagerConfig
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
  resultsLimit: number
}

export interface FormConfig {
  onSearchWithSubject: (emailSubject: string) => void
  onEmailPreview: (incident: IncidentEmailSearch) => void
  isEmptySubject: boolean | any
  senderEmail: string
}

export interface ModifiedIncidentEmailSearch extends IncidentEmailSearch {
  formattedDate: string
}

export interface State {
  isUpdatingSearch: boolean
}

export interface EmailDetailsDialogCustomTitleConfig {
  displayName: string
  dateSent: string
}

const REFRESH_FREQ = 5000

export default function useConfirmIncidentLogic(): [
  ButtonStatus,
  TableConfig,
  IsLoaded,
  FormConfig,
  Error,
  InProgress,
  UseEmailDetailsIncidentInterface,
  EmailDetailsDialogCustomTitleConfig
] {
  const {
    accessTokenId,
    compromisedAccount,
    createIncidentPending,
    createIncidentSuccess,
    currentIncidentId,
    error,
    formValues,
    getRecipientsError,
    getRecipientsPending,
    getRecipientsSuccess,
    incidentsEmailsTable,
    incidentTaskStatus,
    reportData,
    searchForIncidentEmailsIsPending
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken?.accessToken?.id || '',
    compromisedAccount: _stores.ato.compromisedAccount,
    createIncidentPending: isPending(_stores.remediation?.createIncidentApiStatus),
    createIncidentSuccess: isSuccess(_stores.remediation?.createIncidentApiStatus),
    currentIncidentId: _stores.remediation?.currentIncident?.id || '',
    error: getErrorMessage(_stores.remediation?.searchForIncidentEmailsApiStatus),
    formValues: _stores.remediation.incidentEmailSearchFormValues,
    getRecipientsError: getErrorMessage(_stores.remediation.getRecipientsApiStatus),
    getRecipientsPending: isPending(_stores.remediation.getRecipientsApiStatus),
    getRecipientsSuccess: isSuccess(_stores.remediation.getRecipientsApiStatus),
    incidentsEmailsTable: _stores.dataTables.incidentsEmails,
    incidentTaskStatus: _stores.remediation?.createIncidentStatus,
    reportData: _stores.remediation.incidentsEmails,
    searchForIncidentEmailsIsPending: isPending(_stores.remediation?.searchForIncidentEmailsApiStatus)
  }))
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    isCreateInProgress: false
  })
  const dispatch = useAppDispatch()
  const [userDataLib] = useUserDataLib()
  const [emailDetailDialogConfig, emailDetailDialogActions, selectedIncident] = useEmailDetailsIncidentInterface({
    senderEmail: formValues.senderEmail
  })

  const [multiStepConfig] = useNewIncidentDialogWizardLogic()
  const interval = useRef<ReturnType<typeof setInterval> | null>()
  const isMovedToQuarantine = useRef(false)
  const currentStep = 1
  const currentPage = 'Confirm Incident Page'

  // Init
  useEffect(() => {
    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
      isMovedToQuarantine.current = false
      dispatch(resetIncidentTaskStatus())
      dispatch(resetIncidentsEmailsTable())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // incidents emails table
  const tableData = useMemo(() => {
    const { skip, take } = incidentsEmailsTable

    const { data } = process(
      (reportData?.report?.data || []).map((report: IncidentEmailSearch) => ({
        ...(report && {
          ...report,
          formattedDate: formatDateWithTime(report.dateSent || ''),
          recipientCount: report.recipients.filter(recipients => recipients.displayName).length
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.emailId),
      total: reportData?.report?.count || 0
    }
  }, [reportData, incidentsEmailsTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = incidentsEmailsTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateIncidentsEmailsTable(e.page))
      }
    }
  }, [incidentsEmailsTable, tableData, dispatch])

  const onSearchWithSubject = useCallback(
    (emailSubject: string) => {
      dispatch(resetIncidentsEmailsTable())
      dispatch(resetSearchForIncidentEmails())

      analyticsLib.trackAppEvent(analyticsLib.EVENTS.ACCOUNT_COMPROMISE_INCIDENT_WIZARD_SUBJECT_SEARCH, {
        accessTokenId,
        subject: emailSubject,
        senderEmail: formValues.senderEmail
      })

      dispatch(
        searchForIncidentEmails({
          senderEmail: formValues.senderEmail,
          emailSubject,
          timeframe: formValues.timeframe,
          query: INCIDENT_QUERY.INITIAL_EMAILS
        })
      )
    },
    [dispatch, formValues, accessTokenId]
  )

  const onEmailPreview = useCallback(
    (incident: any) => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.ACCOUNT_COMPROMISE_INCIDENT_WIZARD_EMAIL_PREVIEW, {
        accessTokenId,
        emailId: incident.emailId,
        subject: incident.emailSubject,
        senderEmail: formValues.senderEmail
      })

      emailDetailDialogActions.onOpen(incident)
    },
    [accessTokenId, formValues.senderEmail, emailDetailDialogActions]
  )

  // dialog action
  const onDispatchNextStep = useCallback(() => {
    if (!formValues.emailSubject) {
      const options = 'gotoInboxRules'
      dispatch(getInboxRules(formValues.senderEmail))
      multiStepConfig.onNextStep(currentStep, currentPage, options)
    } else if (formValues.emailSubject) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.ACCOUNT_COMPROMISE_INCIDENT_WIZARD_CREATE_INCIDENT, {
        accessTokenId,
        subject: formValues.emailSubject,
        senderEmail: formValues.senderEmail
      })
      dispatch(
        createIncident({
          emailSubject: formValues.emailSubject,
          timeframe: formValues.timeframe,
          sender: {
            displayName: compromisedAccount ? compromisedAccount.displayName : formValues.senderEmail,
            email: compromisedAccount ? compromisedAccount.emailAddress : formValues.senderEmail,
            firstName: '',
            id: '',
            lastName: '',
            title: ''
          },
          createdBy: userDataLib.getUser().email
        })
      )
    }
  }, [multiStepConfig, dispatch, formValues, userDataLib, compromisedAccount, accessTokenId])

  // Check that the incident was created, set state, and check the incident task status
  useEffect(() => {
    if (createIncidentSuccess && !!currentIncidentId && !getRecipientsPending && !interval.current) {
      setState({ isCreateInProgress: true })

      interval.current = setInterval(() => {
        dispatch(getIncidentTaskStatus({ incidentId: currentIncidentId, taskName: 'incident_create' }))
      }, REFRESH_FREQ)
    }
  }, [state.isCreateInProgress, createIncidentSuccess, currentIncidentId, getRecipientsPending, dispatch])

  // Task status returns complete and get recipients for quarantine step
  useEffect(() => {
    if (
      incidentTaskStatus === RemediationStatuses.completed &&
      !isMovedToQuarantine.current &&
      !(getRecipientsPending || getRecipientsSuccess)
    ) {
      if (interval.current) {
        clearInterval(interval.current)
      }

      isMovedToQuarantine.current = true

      setState({ isCreateInProgress: false })

      dispatch(getRecipients({ incidentId: currentIncidentId, distinctRecipient: false, wizardTable: true }))
    }
  }, [
    state.isCreateInProgress,
    incidentTaskStatus,
    dispatch,
    currentIncidentId,
    getRecipientsSuccess,
    getRecipientsPending
  ])

  // Move to the quarantine step
  useEffect(() => {
    if (isMovedToQuarantine.current && getRecipientsSuccess) {
      setTimeout(() => {
        multiStepConfig.onNextStep(currentStep, currentPage, 'gotoQuarantine')
      }, REFRESH_FREQ)
    }
    if (getRecipientsError && isMovedToQuarantine.current) {
      isMovedToQuarantine.current = false
    }
  }, [multiStepConfig, getRecipientsSuccess, getRecipientsPending, getRecipientsError])

  const onDispatchPrevStep = useCallback(() => {
    multiStepConfig.onPrevStep(currentStep, currentPage, currentPage)
  }, [multiStepConfig])

  const searchInProgress = useMemo(() => {
    return state.isCreateInProgress
  }, [state.isCreateInProgress])

  const tableDisabled = useMemo(() => {
    return (
      searchForIncidentEmailsIsPending ||
      createIncidentPending ||
      getRecipientsPending ||
      searchInProgress ||
      isMovedToQuarantine.current
    )
  }, [searchForIncidentEmailsIsPending, createIncidentPending, getRecipientsPending, searchInProgress])

  return useMemo(() => {
    return [
      {
        disabled: tableDisabled,
        disableNext: tableDisabled,
        cancel: 'cancel',
        onPrev: 'back',
        onNext: formValues.emailSubject ? 'yes_malicious_emails' : 'no_malicious_emails',
        onNextStep: onDispatchNextStep,
        onPrevStep: onDispatchPrevStep
      },
      {
        tableData,
        pageConfig,
        columns: incidentsEmailsTable.GRID_COLUMNS,
        columnsConfig: incidentsEmailsTable.columnsConfig,
        resultsLimit: INCIDENT_QUERY.INITIAL_EMAILS.limit
      },
      !!reportData?.accessTokenId,
      {
        onSearchWithSubject,
        onEmailPreview,
        isEmptySubject: !formValues.emailSubject,
        senderEmail: formValues.senderEmail
      },
      error || getRecipientsError,
      tableDisabled,
      [emailDetailDialogConfig, emailDetailDialogActions, selectedIncident],
      {
        displayName: compromisedAccount?.displayName ? compromisedAccount.displayName : formValues.senderEmail,
        dateSent: formatDate(selectedIncident?.dateSent)
      }
    ]
  }, [
    compromisedAccount,
    emailDetailDialogActions,
    emailDetailDialogConfig,
    error,
    formValues.emailSubject,
    formValues.senderEmail,
    getRecipientsError,
    incidentsEmailsTable,
    onDispatchNextStep,
    onDispatchPrevStep,
    onEmailPreview,
    onSearchWithSubject,
    pageConfig,
    reportData,
    selectedIncident,
    tableData,
    tableDisabled
  ])
}
