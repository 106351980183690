import { useMemo, useState, useCallback } from 'react'

export type SelectedRadioButton = string | undefined
export type OnSelectRadioButton = (_e: any, newTab: string) => void

export interface BaseRadioButtonsConfig {
  selectedRadioButton: SelectedRadioButton
  onSelectRadioButton: OnSelectRadioButton
}

export default function useRadioButtons(initialTab?: string): [SelectedRadioButton, OnSelectRadioButton] {
  const [selectedRadioButton, setSelectedRadioButton] = useState<SelectedRadioButton>(initialTab)

  const onSelectRadioButton = useCallback((_e: any, newTab: any) => {
    setSelectedRadioButton(newTab)
  }, [])

  return useMemo(() => {
    return [selectedRadioButton, onSelectRadioButton]
  }, [selectedRadioButton, onSelectRadioButton])
}
