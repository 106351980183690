import { concat, isObject, isRegExp, slice, toInteger, toString } from 'lodash'

import { hasUnicode, reFlags, reUnicode } from './hasUnicode'

const DEFAULT_TRUNC_LENGTH = 30
const DEFAULT_TRUNC_OMISSION = '...'
const DEFAULT_TRUNC_POSITION = 'end'

function unicodeToArray(string: string) {
  return string.match(reUnicode) || []
}

function asciiToArray(string: string) {
  return string.split('')
}

function stringToArray(string: string) {
  return hasUnicode(string) ? unicodeToArray(string) : asciiToArray(string)
}

function unicodeSize(string: string) {
  reUnicode.lastIndex = 0
  let result = reUnicode.lastIndex
  while (reUnicode.test(string)) {
    ++result
  }
  return result
}

function stringSize(string: string) {
  return hasUnicode(string) ? unicodeSize(string) : string.length
}

type TruncateOptions = {
  separator?: RegExp | string
  length?: number
  omission?: string
  position?: 'start' | 'middle' | 'end'
}

export function spliceWithOmission(string: string | string[], length: number, position: string, omission: string) {
  switch (position) {
    case 'start':
      return concat(omission, slice(string, -length))
    case 'middle':
      return concat(slice(string, 0, Math.floor(length / 2)), omission, slice(string, -Math.floor(length / 2)))
    default:
      return concat(slice(string, 0, length), omission)
  }
}

export const truncate = (string: string, options?: TruncateOptions) => {
  let length = DEFAULT_TRUNC_LENGTH
  let omission = DEFAULT_TRUNC_OMISSION
  let position = DEFAULT_TRUNC_POSITION
  let separator

  if (isObject(options)) {
    separator = 'separator' in options ? options.separator : separator
    length = 'length' in options ? toInteger(options.length) : length
    omission = 'omission' in options ? toString(options.omission) : omission
    position = 'position' in options ? toString(options.position) : position
  }

  // checks if the string has unicode characters
  let strSymbols
  let strLength = string.length
  if (hasUnicode(string)) {
    strSymbols = stringToArray(string)
    strLength = strSymbols.length
  }

  // checks if the length is less than the string length
  if (length >= strLength) {
    return string
  }

  // checks if the length is less than the omission length
  let truncatedLength = length - stringSize(omission)
  if (truncatedLength < 1) {
    return omission
  }

  let result = spliceWithOmission(strSymbols || string, truncatedLength, position, omission).join('')

  if (separator === undefined) {
    return result
  }

  // if there is a separator, we need to check if it is a RegExp
  if (strSymbols) {
    truncatedLength += result.length - truncatedLength
  }

  // if it is a RegExp, we need to find the last match
  if (isRegExp(separator)) {
    if (string.slice(truncatedLength).search(separator)) {
      let match: RegExpExecArray | null = null
      const substring = result

      // if the separator is not global, we need to make it global
      if (!separator.global) {
        separator = RegExp(separator.source, `${toString(reFlags.exec(separator.source))}g`)
      }

      // reset the index of the separator
      separator.lastIndex = 0
      let newEnd
      // eslint-disable-next-line no-constant-condition
      while (true) {
        // we need to find the last match
        match = separator.exec(substring)
        // if there is no match, we break the loop
        if (!match) break
        newEnd = match.index
      }

      result = result.slice(0, newEnd === undefined ? truncatedLength : newEnd)
    }
    // if it is a string, we need to find the last occurrence
  } else if (string.indexOf(toString(separator), truncatedLength) !== truncatedLength) {
    const index = result.lastIndexOf(separator)
    if (index > -1) {
      result = result.slice(0, index)
    }
  }

  return result
}
