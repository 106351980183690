import React, { useMemo } from 'react'

import { v4 as uuidv4 } from 'uuid'

import { Card, Typography, DataTable, DataTableColumn, Grid } from '@barracuda-internal/bds-core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import MaskLayer from 'global/components/lib/maskLayer/MaskLayer'
import SearchField from 'global/components/lib/searchField/SearchField'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import highlightText from 'global/lib/highlightText/highlightText'
import Pager from 'global/components/lib/grid/pager/Pager'
import { useFormatMessage } from 'global/lib/localization'
import EmailDetailsDialog from 'global/components/lib/dialogs/emailDetailsDialog/EmailDetailsDialog'

import { FILTER_CONFIG } from 'ets/lib/getRiskTypeByScore'
import UserEmailsDialog from 'ets/components/lib/dialogs/userEmailsDialog/UserEmailsDialog'
import styles from 'ets/components/pages/dashboard/employees/dashboardEmployeesStyles'
import useDashboardEmployeesLogic, {
  UseDashboardEmployeesLogicProps,
  TabConfig
} from 'ets/components/pages/dashboard/employees/useDashboardEmployeeLogic'
import useEmailDetailsSpAttackInterface from 'ets/components/lib/dialogs/emailDetailsDialog/interfaces/useEmailDetailsSpAttackInterface'

const BASE_I18N_KEY = 'ets.app.dashboard.employees'
const BASE_PROGRESS_I18N_KEY = 'ets.app.dashboard.in_progress'
const BASE_RISK_I18N_KEY = 'ets.app'

const BASE_I18N_TABLE_KEY = 'ets.app.data_tables.employees'

const DashboardEmployees: React.FC<UseDashboardEmployeesLogicProps> = ({
  isUserInputDisabledForTable,
  fixTableHeight
}) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageProgress = useFormatMessage(BASE_PROGRESS_I18N_KEY)
  const formatMessageRisk = useFormatMessage(BASE_RISK_I18N_KEY)
  const formatMessageTable = useFormatMessage(BASE_I18N_TABLE_KEY)
  const [dashboardEmployeesLogic] = useDashboardEmployeesLogic({ isUserInputDisabledForTable, fixTableHeight })
  const [emailDetailDialogConfig, emailDetailDialogActions] = useEmailDetailsSpAttackInterface()

  return useMemo(() => {
    const {
      tabs,
      isReportLoaded,
      inProgress,
      tableTotal,
      searchFieldConfig,
      GRID_COLUMNS,
      columnsConfig,
      tableData,
      pageConfig,
      sortConfig,
      selectedUser,
      isUserEmailDialogOpened,
      closeUserEmailDialog,
      highlightKeywords
    } = dashboardEmployeesLogic

    return (
      <Grid
        className={classes.root}
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        data-field="root-grid"
      >
        <UserEmailsDialog
          open={isUserEmailDialogOpened}
          userName={selectedUser}
          onClose={closeUserEmailDialog}
          onSeeDetails={emailDetailDialogActions.onOpen}
        />
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={2}
          direction="row"
          data-field="container-grid"
        >
          {tabs.map((tabConfig: TabConfig) => (
            <Grid key={tabConfig.id} item xs={3} data-field={`tab-${tabConfig.id}`}>
              <Card
                className={`${classes.employeesPanel} ${tabConfig.isSelected ? 'selected' : ''} ${
                  tabConfig.disabled ? classes.disabledTab : ''
                }`}
                onClick={tabConfig.onClick}
                elevation={1}
              >
                <Typography className={classes.tabTitle} display="inline">
                  {formatMessage(`tabs.${tabConfig.id}`)}
                </Typography>
                <Typography className={classes.tabCount} display="inline">
                  {tabConfig.count}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Card className={classes.tableWrapper} data-field="table-wrapper">
              <Grid container direction="column">
                <Grid item xs={12}>
                  <Typography className={classes.tableTotal} align="left" data-field="table-total">
                    {formatMessage('table_total', { total: tableTotal })}
                  </Typography>
                </Grid>
                <Grid className={classes.searchField} item xs={12}>
                  <SearchField
                    {...searchFieldConfig}
                    placeholder={formatMessage('search_field_placeholder')}
                    data-field="table-search"
                  />
                </Grid>
                <Grid
                  className={`${classes.tableArea} ${!tableData.total ? 'empty-table' : ''}`}
                  data-table="employees"
                  item
                  xs={12}
                >
                  {isReportLoaded && inProgress && (
                    <LinearProgress data-field="table-loader" className={classes.tableLoadIndicator} />
                  )}
                  <DataTable
                    className={`${tableData.total ? classes.dataTable : classes.emptyDataTable} employees-table`}
                    data={tableData}
                    {...pageConfig}
                    {...sortConfig}
                    pager={Pager}
                    data-field="table"
                  >
                    <DataTableNoRecords data-field="table-no-records">
                      <Typography>{!tableData.total && formatMessage('empty_table')}</Typography>
                    </DataTableNoRecords>
                    <DataTableColumn
                      field={GRID_COLUMNS.NAME}
                      {...columnsConfig[GRID_COLUMNS.NAME as any]}
                      title={formatMessageTable(GRID_COLUMNS.NAME)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellText className={classes.boldCell} data-field="table-name-cell">
                            {highlightText(dataItem.displayName, highlightKeywords)}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      width={250}
                      field={GRID_COLUMNS.EMAIL}
                      {...columnsConfig[GRID_COLUMNS.EMAIL as any]}
                      title={formatMessageTable(GRID_COLUMNS.EMAIL)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellText ellipsis className={classes.normalCell} data-field="table-email-cell">
                            {highlightText(dataItem.email, highlightKeywords)}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={GRID_COLUMNS.TITLE}
                      {...columnsConfig[GRID_COLUMNS.TITLE as any]}
                      title={formatMessageTable(GRID_COLUMNS.TITLE)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellText ellipsis className={classes.boldCell} data-field="table-title-cell">
                            {highlightText(dataItem.title, highlightKeywords)}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      width={110}
                      field={GRID_COLUMNS.RISK_LEVEL}
                      {...columnsConfig[GRID_COLUMNS.RISK_LEVEL as any]}
                      title={formatMessageTable(GRID_COLUMNS.RISK_LEVEL)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellText
                            ellipsis
                            className={
                              classes[dataItem.riskType === FILTER_CONFIG.HIGH_RISK.id ? 'redBoldCell' : 'boldCell']
                            }
                            data-field="table-risk-level-cell"
                          >
                            {formatMessageRisk(`risk_levels.${dataItem.riskType}`)}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      width={190}
                      field={GRID_COLUMNS.HIGH_RISK_FACTORS}
                      {...columnsConfig[GRID_COLUMNS.HIGH_RISK_FACTORS as any]}
                      title={formatMessageTable(GRID_COLUMNS.HIGH_RISK_FACTORS)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellText className={classes.normalCell} data-field="table-risk-factors-cell">
                            {(dataItem.risks || '').split(',').map((risk: string) => {
                              if (risk.length) {
                                return (
                                  <span key={risk} className={classes.riskType}>
                                    {formatMessageRisk(`high_risk_factors.${risk.toLowerCase()}`)}
                                  </span>
                                )
                              }

                              return null
                            })}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      width={135}
                      field={GRID_COLUMNS.THREATS_FOUND}
                      headerClassName={classes.centerAlignedText}
                      {...columnsConfig[GRID_COLUMNS.THREATS_FOUND as any]}
                      title={formatMessageTable(GRID_COLUMNS.THREATS_FOUND)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <Typography
                            className={classes.largerRedBoldTextCell}
                            align="center"
                            data-field="table-threats-cell"
                          >
                            {dataItem.threatsFound}
                          </Typography>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      width={100}
                      field={GRID_COLUMNS.ACTION}
                      {...columnsConfig[GRID_COLUMNS.ACTION as any]}
                      title={formatMessageTable(GRID_COLUMNS.ACTION)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell} data-field="table-action-cell">
                          <CellText
                            onClick={
                              dataItem.spFraudCount ? () => dataItem.seeDetails(dataItem.displayName) : undefined
                            }
                            className={classes[dataItem.spFraudCount ? 'blueNormalCell' : 'disabledText']}
                            data-action="open-user-emails-action"
                          >
                            {formatMessage('see_details')}
                          </CellText>
                        </Cell>
                      )}
                    />
                  </DataTable>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        {!isReportLoaded && (
          <MaskLayer data-field="mask-layer">
            {formatMessageProgress('title', { br: () => <br key={uuidv4()} /> })}
          </MaskLayer>
        )}

        {emailDetailDialogActions.open && (
          <EmailDetailsDialog data={emailDetailDialogConfig} buttonText={formatMessage('button')} etsAttackTypes />
        )}
      </Grid>
    )
  }, [
    dashboardEmployeesLogic,
    classes,
    formatMessage,
    formatMessageProgress,
    formatMessageTable,
    formatMessageRisk,
    emailDetailDialogConfig,
    emailDetailDialogActions
  ])
}

export default DashboardEmployees
