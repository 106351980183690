import { MouseEvent as ReactMouseEvent, useCallback, useEffect, useMemo, useReducer } from 'react'
import {
  addEdge,
  Connection,
  Edge,
  Elements,
  getConnectedEdges,
  isEdge,
  Node,
  removeElements
} from 'react-flow-renderer'

import { AlertProps } from 'global/components/lib/alerts/Alert'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { useFormatMessage } from 'global/lib/localization'
import { isFailed, isSuccess } from 'global/redux/toolkit/api'

import { resetSettingsDemoAlert, resetSettingsFailures } from 'fir/redux/features/settings/settingsSlice'
import {
  createUpdateWorkflow,
  getWorkflowBlueprints,
  setWorkflowBlueprint,
  resetCreateUpdate,
  updateBuilder
} from 'fir/redux/features/workflows/workflowsSlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { ForensicsSettings } from 'fir/redux/types/Settings'
import { Builder, NODE_TYPES, Workflow, WorkflowBlueprints } from 'fir/redux/types/Workflows'

export interface UseWorkflowDialogParams {
  existingWorkflow: Workflow
  isNewWorkflow: boolean
  isOpened: boolean
  onClose: (uuid?: string) => void
}

interface WorkflowDialogConfig extends UseWorkflowDialogParams {
  elements: Elements
  showDeleteButton: boolean
  showError: boolean
  showSuccess: boolean
  workflowBlueprints: WorkflowBlueprints
  workflowBlueprintSelected: boolean
  workflowBlueprintName: string
  workflowDescription: string
  workflowId: string
  workflowName: string
  workflowBuilder: Builder
  workflowDialogErrorText: string
}

export interface WorkflowDialogProps {
  alertConfig: AlertProps
  handleWorkflowDescription: (e: any) => void
  handleWorkflowName: (e: any) => void
  isCreateUpdateDisabled: boolean
  isWorkflowNameValid: boolean
  onChangeBlueprint: (e: any) => void
  onClickElement: (event: ReactMouseEvent, element: Node | Edge) => void
  onClickElementDelete: () => void
  onConnect: (connect: Edge | Connection) => void
  onElementsRemove: (elementsToRemove: Elements) => void
  onNodeDragStop: (e: any, node: Node) => void
  validateWorkflow: () => void
  onSubmitWorkflow: () => void
  workflowDialogConfig: WorkflowDialogConfig
}

const BASE_I18N_KEY = 'fir.app.automated_workflows.workflow_dialog'
const INIT_STATE = {
  isWorkflowNameChanged: false,
  clickedElement: [],
  showDeleteButton: true,
  showError: false,
  showSuccess: false,
  workflowBlueprintId: '',
  workflowBlueprintName: 'Blank Template',
  workflowBlueprintSelected: false,
  workflowDescription: '',
  workflowDialogErrorText: '',
  workflowId: '',
  workflowName: ''
}

export default function useWorkflowDialogLogic(params: UseWorkflowDialogParams): [WorkflowDialogProps] {
  const { isNewWorkflow, isOpened, onClose } = params
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  // Redux Toolkit stores
  // TODO: Delete { accessTokenId: string; workflows: any } after implementing strong type for workflows store, look into this issue during AW V2
  const {
    accessTokenId,
    isFirDemoUser,
    settings,
    workflows
  }: { accessTokenId: string; isFirDemoUser: boolean; settings: ForensicsSettings; workflows: any } = useAppSelector(
    _stores => ({
      accessTokenId: _stores.accessToken.accessToken?.id || ' ',
      isFirDemoUser: _stores.user.isFirDemoUser,
      settings: _stores.settings,
      workflows: _stores.workflows
    })
  )

  // workflows state
  const {
    existingWorkflow,
    isSaveSettingsFailed,
    isSaveSettingsSuccess,
    isSaveWorkflowFailed,
    isSaveWorkflowSuccess,
    workflowBlueprints,
    workflowBuilder
  } = {
    existingWorkflow: workflows.workflow,
    isSaveSettingsFailed: isFailed(settings.saveAccessTokenSettingsApiStatus),
    isSaveSettingsSuccess: isSuccess(settings.saveAccessTokenSettingsApiStatus),
    isSaveWorkflowFailed: isFailed(workflows.createUpdateWorkflowAPIStatus),
    isSaveWorkflowSuccess: isSuccess(workflows.createUpdateWorkflowAPIStatus),
    workflowBlueprints: workflows.workflowBlueprints,
    workflowBuilder: workflows.builder
  }

  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), INIT_STATE)

  // init & reset workflow dialog on unmount
  useEffect(() => {
    dispatch(getWorkflowBlueprints({ accessTokenId }))

    return () => {
      dispatch(resetCreateUpdate())
      dispatch(resetSettingsDemoAlert())
      setState(INIT_STATE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // pre-populate dialog if workflow exists
  useEffect(() => {
    if (existingWorkflow.uuid) {
      setState({
        isWorkflowNameChanged: true,
        workflowDescription: existingWorkflow.description,
        workflowId: existingWorkflow.uuid,
        workflowName: existingWorkflow.name
      })
    }
  }, [existingWorkflow])

  // Clear dialog validation when trigger or action is added
  useEffect(() => {
    if (workflowBuilder.actionCount > 0 || workflowBuilder.triggerCount > 0) {
      setState({ workflowDialogErrorText: '' })
    }
  }, [workflowBuilder.actionCount, workflowBuilder.triggerCount])

  // handle save success and failure
  useEffect(() => {
    if (isSaveWorkflowSuccess) {
      onClose()
    }
    if (isSaveSettingsSuccess) {
      setState({ showSuccess: true })
      dispatch(resetSettingsFailures())
    }
    if (isSaveWorkflowFailed && !state.showError) {
      setState({ showError: true })
    }
    if (isSaveSettingsFailed && !state.showError) {
      setState({ showError: true })
    }

    setTimeout(() => {
      setState({ showError: false, showSuccess: false })
    }, 5000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isSaveSettingsFailed, isSaveSettingsSuccess, isSaveWorkflowFailed, isSaveWorkflowSuccess, onClose])

  const onChangeBlueprint = useCallback(
    (e: any) => {
      const selectedBlueprint = workflowBlueprints.data.find((blueprint: any) => blueprint.name === e.target.value)

      // Reset builder and state if Blank Template is selected
      if (selectedBlueprint.name === INIT_STATE.workflowBlueprintName) {
        setState(INIT_STATE)
        dispatch(resetCreateUpdate())
      } else {
        setState({
          workflowBlueprintName: selectedBlueprint.name,
          workflowBlueprintSelected: true,
          workflowBlueprintId: selectedBlueprint.uuid
        })

        dispatch(
          setWorkflowBlueprint({
            workflow: {
              revision: selectedBlueprint.revision,
              description: selectedBlueprint.description,
              diagram: selectedBlueprint.diagram,
              editor: 'forensics@barracuda.com',
              name: selectedBlueprint.name,
              nodes: selectedBlueprint.nodes,
              uuid: workflowBuilder.workflowTree.uuid,
              workflowBlueprint: selectedBlueprint
            }
          })
        )
      }
    },
    [dispatch, workflowBlueprints.data, workflowBuilder.workflowTree]
  )

  const alertConfig = useMemo(() => {
    return {
      alertContent: state.showSuccess
        ? formatMessage(`${isFirDemoUser ? 'errors.demo_user' : 'alerts.webhook_save_success'}`)
        : formatMessage(`${isFirDemoUser ? 'errors.demo_user' : 'errors.save_error'}`),
      closeAction: () => {
        setState({ showError: false })
      },
      showClose: true,
      severity: state.showSuccess ? ('success' as const) : ('error' as const)
    }
  }, [formatMessage, isFirDemoUser, state.showSuccess])

  const handleWorkflowDescription = (e: any) => {
    setState({ workflowDescription: e.target.value })
  }

  const handleWorkflowName = (e: any) => {
    setState({ isWorkflowNameChanged: true, workflowName: e.target.value, workflowDialogErrorText: '' })
  }

  const isCreateUpdateDisabled = useMemo(() => {
    // Determine if workflow created from blueprint has valid condition data
    let isValidBlueprint = true
    const validateWorkflowBlueprint = !workflowBuilder.conditionValue.endsWith('_noInput')
    const isWorkflowBlueprint = existingWorkflow.workflowBlueprint?.uuid

    if (validateWorkflowBlueprint && isWorkflowBlueprint) {
      const conditionData = workflowBuilder.workflowNodes[workflowBuilder.conditionId]?.node.config

      isValidBlueprint =
        conditionData?.conditionValue.length > 0 &&
        conditionData?.conditionOperator.length > 0 &&
        workflowBuilder.isConditionDataValid
    }

    return (
      workflowBuilder.triggerCount !== 1 ||
      workflowBuilder.actionCount < 1 ||
      !state.workflowName.length ||
      !isValidBlueprint
    )
  }, [
    state.workflowName.length,
    existingWorkflow.workflowBlueprint,
    workflowBuilder.actionCount,
    workflowBuilder.conditionId,
    workflowBuilder.conditionValue,
    workflowBuilder.isConditionDataValid,
    workflowBuilder.triggerCount,
    workflowBuilder.workflowNodes
  ])

  const isWorkflowNameValid = useMemo(() => {
    return !state.isWorkflowNameChanged || (state.isWorkflowNameChanged && !!state.workflowName.length)
  }, [state.isWorkflowNameChanged, state.workflowName.length])

  const onClickElement = useCallback(
    (event: ReactMouseEvent, element: Node | Edge) => {
      // hide the delete button if any actions or conditions have been added and the user selects the trigger
      setState({
        clickedElement: [element],
        showDeleteButton: element.id !== workflowBuilder.triggerId || workflowBuilder.conditionCount === 0
      })
    },
    [workflowBuilder.conditionCount, workflowBuilder.triggerId]
  )

  const onConnect = useCallback(
    (connect: Edge | Connection) => {
      if (
        !isEdge(connect) &&
        connect.source &&
        connect.target &&
        workflowBuilder.workflowTree.addLink(
          workflowBuilder.workflowNodes[connect.source],
          workflowBuilder.workflowNodes[connect.target]
        )
      ) {
        // eslint-disable-next-line no-param-reassign
        connect = {
          ...connect,
          id: `edge-${connect.source}-${connect.target}`
        }
      }

      // update workflow builder redux
      dispatch(
        updateBuilder({
          builder: {
            elements: addEdge(connect, workflowBuilder.elements as Elements)
          }
        })
      )
    },
    [dispatch, workflowBuilder.workflowTree, workflowBuilder.workflowNodes, workflowBuilder.elements]
  )

  const onElementsRemove = useCallback(
    (elementsToRemove: Elements) => {
      let validDelete = true
      elementsToRemove.forEach((element: any) => {
        if (isEdge(element)) {
          workflowBuilder.workflowTree.removeLink(
            workflowBuilder.workflowNodes[element.source],
            workflowBuilder.workflowNodes[element.target]
          )
        } else {
          const nodeType = workflowBuilder.workflowNodes[element.id].node.type.value
          if (NODE_TYPES.triggers.indexOf(nodeType) > -1 && workflowBuilder.conditionCount > 0) {
            // don't update builder or remove element if selected node is a trigger node and there are condition nodes
            validDelete = false
          } else {
            // decrement count used for positioning and clear eventId
            dispatch(
              updateBuilder({
                builder: {
                  [`${nodeType}Count`]:
                    workflowBuilder[`${nodeType}Count`] > 0 ? --workflowBuilder[`${nodeType}Count`] : 0,
                  [`${nodeType}Id`]: '',
                  [`${nodeType}Value`]: ''
                }
              })
            )
          }
        }
      })

      // update workflow builder redux
      if (validDelete) {
        dispatch(
          updateBuilder({
            builder: {
              elements: removeElements(elementsToRemove, workflowBuilder.elements as Elements)
            }
          })
        )
      }
    },
    [dispatch, workflowBuilder]
  )

  const onClickElementDelete = useCallback(() => {
    const edges = workflowBuilder.elements.filter((element: Node | Edge) => isEdge(element))

    if (isEdge(state.clickedElement)) {
      onElementsRemove(state.clickedElement)
    } else {
      const edgesToRemove = getConnectedEdges(state.clickedElement, edges)

      onElementsRemove([...state.clickedElement, ...edgesToRemove])
    }
  }, [onElementsRemove, state.clickedElement, workflowBuilder.elements])

  const onNodeDragStop = useCallback(
    (e: any, node: Node) => {
      dispatch(
        updateBuilder({
          builder: {
            elements: workflowBuilder.elements.map((element: Node | Edge) => {
              if (element.id === node.id) {
                return node
              }
              return element
            })
          }
        })
      )
    },
    [dispatch, workflowBuilder.elements]
  )

  const onSubmitWorkflow = useCallback(() => {
    const tree = workflowBuilder.workflowTree.getSerializable()
    const { nodes } = tree
    const workflow = nodes.reduce((all: any, node: any) => {
      const workflowData = node.config
        ? [
            {
              [node.eventType]: {
                conditionOperator: node.config?.conditionOperator,
                conditionValue: node.config?.conditionValue
              }
            }
          ]
        : [node.eventType]

      const workflowValue = all.has(node.type) ? all.get(node.type).concat(workflowData) : workflowData

      all.set(node.type, workflowValue)
      return all
    }, new Map())

    const appEventData = {
      accessTokenId,
      page: 'automated-workflows',
      ...(state.workflowBlueprintId && { workflowBlueprintId: state.workflowBlueprintId }),
      workflowAction: Object.fromEntries(workflow).action,
      workflowCondition: Object.fromEntries(workflow).condition,
      workflowTrigger: Object.fromEntries(workflow).trigger
    }

    if (state.workflowBlueprintId) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.AUTOMATED_WORKFLOW_CREATE_FROM_BLUEPRINT, appEventData)
    } else {
      analyticsLib.trackAppEvent(
        state.workflowId
          ? analyticsLib.EVENTS.AUTOMATED_WORKFLOW_EDIT_WORKFLOW
          : analyticsLib.EVENTS.AUTOMATED_WORKFLOW_CREATE_WORKFLOW,
        appEventData
      )
    }

    dispatch(
      createUpdateWorkflow({
        accessTokenId,
        workflow: {
          description: state.workflowDescription,
          diagram: JSON.stringify(workflowBuilder.elements),
          name: state.workflowName,
          nodes: tree.nodes,
          uuid: state.workflowId || tree.uuid,
          workflowBlueprint: {
            uuid: existingWorkflow.workflowBlueprint?.uuid,
            revision: existingWorkflow.workflowBlueprint?.revision
          }
        }
      })
    )
  }, [
    accessTokenId,
    dispatch,
    state.workflowBlueprintId,
    state.workflowDescription,
    state.workflowId,
    state.workflowName,
    existingWorkflow.workflowBlueprint,
    workflowBuilder.elements,
    workflowBuilder.workflowTree
  ])

  const validateWorkflow = useCallback(() => {
    if (isCreateUpdateDisabled) {
      if (state.workflowName.length === 0) {
        setState({
          isWorkflowNameChanged: true,
          workflowDialogErrorText: formatMessage('errors.no_workflow_name')
        })
        return
      }
      if (workflowBuilder.triggerCount !== 1) {
        setState({ workflowDialogErrorText: formatMessage('errors.no_trigger') })
        return
      }
      if (workflowBuilder.actionCount < 1 && workflowBuilder.triggerCount === 1) {
        setState({ workflowDialogErrorText: formatMessage('errors.no_condition_or_action') })
      }
    } else {
      onSubmitWorkflow()
    }
  }, [
    formatMessage,
    isCreateUpdateDisabled,
    onSubmitWorkflow,
    state.workflowName.length,
    workflowBuilder.actionCount,
    workflowBuilder.triggerCount
  ])

  return useMemo(
    () => [
      {
        alertConfig,
        handleWorkflowDescription,
        handleWorkflowName,
        isCreateUpdateDisabled,
        isWorkflowNameValid,
        onChangeBlueprint,
        onClickElement,
        onClickElementDelete,
        onConnect,
        onElementsRemove,
        onNodeDragStop,
        onSubmitWorkflow,
        validateWorkflow,
        workflowDialogConfig: {
          ...state,
          blueprintUpdate: state.blueprintUpdateValue,
          elements: workflowBuilder.elements,
          existingWorkflow,
          isNewWorkflow,
          isOpened,
          onClose,
          workflowBlueprintName: state.workflowBlueprintName,
          workflowBlueprints,
          workflowBlueprintSelected: state.workflowBlueprintSelected,
          workflowBuilder
        }
      }
    ],
    [
      alertConfig,
      existingWorkflow,
      isNewWorkflow,
      isOpened,
      isCreateUpdateDisabled,
      isWorkflowNameValid,
      onChangeBlueprint,
      onClickElement,
      onClickElementDelete,
      onClose,
      onConnect,
      onElementsRemove,
      onNodeDragStop,
      onSubmitWorkflow,
      state,
      validateWorkflow,
      workflowBlueprints,
      workflowBuilder
    ]
  )
}
