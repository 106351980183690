import React, { useMemo } from 'react'

import { Page, View, Text, StyleSheet } from '@react-pdf/renderer'
import { snakeCase } from 'lodash'

import { COLORS } from 'ets/configs/styles/defaults'
import baseStyles from 'ets/components/lib/PDFReport/pdfReportStyles'
import PDFFooter, { PDFFooterProps } from 'ets/components/lib/PDFReport/PDFFooter'

export interface Attack {
  domain: string
  spFraudCount: number
}

export interface Page6AttacksConfig {
  topCount: string
  attacks: Attack[]
}

export interface Page6AttacksProps {
  pdfConfig: Page6AttacksConfig
  intl: any
  footerConfig: PDFFooterProps
}

const ATTAKS_TABLE_WIDTHS = ['3%', '28%', '67%']
const TABLE_CELL_HEIGHT = 65

const styles = StyleSheet.create({
  ...baseStyles,
  subtitle: {
    ...baseStyles.subtitle,
    marginTop: 10
  },
  tableheaderRow: baseStyles.tableheaderRow,
  countColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: ATTAKS_TABLE_WIDTHS[0]
  },
  domainColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: ATTAKS_TABLE_WIDTHS[1]
  },
  emailsWithThreatsColumnHeader: {
    ...baseStyles.tableHeaderText,
    width: ATTAKS_TABLE_WIDTHS[2]
  },
  tableRow: {
    ...baseStyles.tableRow,
    height: TABLE_CELL_HEIGHT
  },
  countColumn: {
    ...baseStyles.tableText,
    color: COLORS.PDF.LIGHT_GRAY,
    width: ATTAKS_TABLE_WIDTHS[0]
  },
  domainColumn: {
    ...baseStyles.tableText,
    width: ATTAKS_TABLE_WIDTHS[1]
  },
  emailsWithThreatsColumn: {
    ...baseStyles.tableText,
    fontWeight: 'bold',
    width: ATTAKS_TABLE_WIDTHS[2]
  }
})

const BASE_I18N_KEY = 'ets.app.pdf.attacks'

const CustomPager: any = Page

const Page6Attacks: React.FC<Page6AttacksProps> = ({ pdfConfig, footerConfig, intl }) => {
  return useMemo(() => {
    return (
      <CustomPager size="A4" style={styles.page}>
        <Text style={styles.title}>{intl.formatMessage({ id: `${BASE_I18N_KEY}.title` })}</Text>
        <Text style={styles.subtitle}>
          {intl.formatMessage({ id: `${BASE_I18N_KEY}.subtitle` }, { count: pdfConfig.topCount })}
        </Text>
        <View style={styles.tableheaderRow}>
          <Text style={styles.countColumnHeader} />
          {['domain', 'emailsWithThreats'].map((columnType: string) => (
            <Text key={columnType} style={styles[`${columnType}ColumnHeader`]}>
              {intl.formatMessage({ id: `${BASE_I18N_KEY}.table.${snakeCase(columnType)}` })}
            </Text>
          ))}
        </View>
        {pdfConfig.attacks?.length ? (
          <View style={styles.tableView}>
            {pdfConfig.attacks.map((attack: Attack, idx: number) => (
              <View key={attack.domain} style={styles.tableRow}>
                <Text style={styles.countColumn}>{idx + 1}</Text>
                <Text style={styles.domainColumn}>{attack.domain}</Text>
                <Text style={styles.emailsWithThreatsColumn}>{attack.spFraudCount}</Text>
              </View>
            ))}
          </View>
        ) : (
          <View style={styles.emptyTableWrap}>
            <Text style={styles.emptyTableText}>{intl.formatMessage({ id: `${BASE_I18N_KEY}.empty_table` })}</Text>
          </View>
        )}

        <PDFFooter page={5} intl={intl} {...footerConfig} />
      </CustomPager>
    )
  }, [pdfConfig, footerConfig, intl])
}

export default Page6Attacks
