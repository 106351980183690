import React, { useCallback, useMemo } from 'react'

import {
  EmailLogProvider,
  EmailLog,
  IEmailLogEventListener,
  EmailLogLanguage
} from '@barracuda-internal/converged-email-log'

import useConvergedMessageLogLogic from './useConvergedMessageLogLogic'

interface ConvergedMessageLogProps {
  bccId: string
}

const ConvergedMessageLog: React.FC<ConvergedMessageLogProps> = ({ bccId }) => {
  const [convergedMessageLogsLogic] = useConvergedMessageLogLogic()
  const { emailLogApiClient, mixpanelConfig } = convergedMessageLogsLogic

  // Testing only
  const onEmailLogEvent: IEmailLogEventListener = useCallback(evt => {
    // eslint-disable-next-line no-console
    console.log(evt)
  }, [])

  return useMemo(
    () => (
      <EmailLogProvider
        bccAccountId={bccId}
        emailLogApiClient={emailLogApiClient}
        language={EmailLogLanguage.EN}
        onEmailLogEvent={onEmailLogEvent}
        mixpanelConfig={mixpanelConfig}
        uiSettingsStorageKey="__CML_UI_SETTINGS"
      >
        <EmailLog />
      </EmailLogProvider>
    ),
    [bccId, emailLogApiClient, mixpanelConfig, onEmailLogEvent]
  )
}

export default ConvergedMessageLog
