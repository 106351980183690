import React, { useMemo } from 'react'

import {
  Alert,
  Button,
  Checkbox,
  Mui,
  Paper,
  Pagination,
  Tooltip,
  Typography,
  Link
} from '@barracuda-internal/bds-core'
import { Close as CloseIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { v4 as uuidv4 } from 'uuid'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import tableStyles from 'global/components/lib/dataTable/dataTableStyles'
import { useFormatMessage } from 'global/lib/localization'

import useUserDetailsByIncidentLogic, {
  DataItem
} from 'fir/components/pages/remediation/incidentDetails/userDetailsByIncident/useUserDetailsByIncidentLogic'
import TrainingGroupDialog from 'fir/components/pages/remediation/incidentDetails/userDetailsByIncident/trainingGroupDialog/TrainingGroupDialog'
import styles from 'fir/components/pages/remediation/incidentDetails/userDetailsByIncident/UserDetailsByIncidentStyles'
import { INCIDENT_USER_STATUS } from 'fir/redux/types/Remediation'

const { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } = Mui

const BASE_I18N_KEY = 'fir.app.remediation.users_by_incident'

const UsersDetailsByIncident: React.FC = () => {
  const classes = styles()
  const tableClasses = tableStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const TABLE_COLUMNS = useMemo(
    () => [
      { field: 'email', title: formatMessage('user_details_table.email') },
      { field: 'userType', title: formatMessage('user_details_table.userType') },
      { field: 'clickedLinks', title: formatMessage('user_details_table.clicked_links') },
      { field: 'isRead', title: formatMessage('user_details_table.opened') },
      { field: 'replied', title: formatMessage('user_details_table.replied') },
      { field: 'forwarded', title: formatMessage('user_details_table.forwarded') }
    ],
    [formatMessage]
  )

  const userDetailStatus = useMemo(
    () => ({
      [INCIDENT_USER_STATUS.YES]: formatMessage('user_details_table.values.yes'),
      [INCIDENT_USER_STATUS.NO]: formatMessage('user_details_table.values.no'),
      [INCIDENT_USER_STATUS.UNKNOWN]: formatMessage('user_details_table.values.unknown'),
      [INCIDENT_USER_STATUS.OFF]: formatMessage('user_details_table.values.off'),
      [INCIDENT_USER_STATUS.CONTINUOUS_REMEDIATION]: formatMessage('user_details_table.values.continuous_remediation')
    }),
    [formatMessage]
  )

  const [UserDetailsByIncidentLogic] = useUserDetailsByIncidentLogic()

  return useMemo(() => {
    const {
      addressBookName,
      exportPath,
      handleChangePage,
      handleClick,
      handleRequestSort,
      handleSelectAllClick,
      hasESS,
      isClickedLinksPending,
      isCreateAddressBookEnabled,
      isCreateAddressBookSuccess,
      isPageInProgress,
      isSelected,
      itemsChangePagination,
      numSelected,
      onCloseAlert,
      onExportData,
      openTrainingGroupDialog,
      order,
      orderBy,
      securityTrainingCampaignLink,
      tableData,
      totalPage,
      trainingGroupDialogConfig
    } = UserDetailsByIncidentLogic

    return (
      <>
        {trainingGroupDialogConfig.isOpened && <TrainingGroupDialog {...trainingGroupDialogConfig} />}
        <iframe className={classes.exportIframe} src={exportPath} title="export-users" />
        <Paper className={classes.wrapper} elevation={1}>
          <div className={classes.header}>
            <div className={classes.title}>
              <Typography variant="h5">{formatMessage('labels.page_title')}</Typography>
              <Typography variant="subtitle1">{formatMessage('labels.page_subtitle')}</Typography>
              {isClickedLinksPending && (
                <>
                  <br />
                  <Typography variant="subtitle2">{formatMessage('labels.clicked_links_pending')}</Typography>
                </>
              )}
            </div>
            <Tooltip placement="left" title={isCreateAddressBookEnabled ? formatMessage('tooltips.export_csv') : ''}>
              <span className={classes.button}>
                <Button color="primary" variant="contained" onClick={onExportData}>
                  {formatMessage('labels.export_csv')}
                </Button>
              </span>
            </Tooltip>
            {isCreateAddressBookEnabled && (
              <Tooltip placement="left" title={formatMessage('tooltips.create_training_group')}>
                <span className={classes.button}>
                  <Button
                    color="secondary"
                    disabled={!numSelected}
                    variant="contained"
                    onClick={openTrainingGroupDialog}
                  >
                    {formatMessage('labels.create_training_group')}
                  </Button>
                </span>
              </Tooltip>
            )}
          </div>
          {isPageInProgress && <LinearProgress />}
          {isCreateAddressBookSuccess && (
            <Alert
              severity="success"
              action={
                <>
                  <Link className={classes.link} color="inherit" target="_blank" href={securityTrainingCampaignLink}>
                    {formatMessage('training_group_dialog.create_training')}
                  </Link>
                  <CloseIcon onClick={onCloseAlert} />
                </>
              }
            >
              {formatMessage('alert.success', { addressBookName })}
            </Alert>
          )}
          <TableContainer style={{ width: '96vw' }}>
            <Table aria-label="enhanced table" aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  {isCreateAddressBookEnabled && (
                    <TableCell className={tableClasses.tableCell} style={{ width: 70 }} padding="checkbox">
                      <Checkbox
                        indeterminate={numSelected > 0 && numSelected < tableData.totalCount}
                        checked={tableData.totalCount > 0 && numSelected === tableData.totalCount}
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                  )}
                  {TABLE_COLUMNS.map((headCell: any) => (
                    <TableCell
                      className={`${tableClasses.tableHead} ${orderBy === headCell.field &&
                        !numSelected &&
                        tableClasses.activeColumn}`}
                      key={headCell.field}
                      sortDirection={orderBy === headCell.field ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.field}
                        direction={orderBy === headCell.field ? order : 'asc'}
                        onClick={e => handleRequestSort(e, headCell.field)}
                      >
                        {headCell.title}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.data.map((dataItem: DataItem) => {
                  const isItemSelected = isSelected(dataItem.email)
                  return (
                    <TableRow className={tableClasses.tabelRow} key={uuidv4()} hover tabIndex={-1}>
                      {isCreateAddressBookEnabled && (
                        <Tooltip
                          placement="right"
                          title={
                            dataItem.userType === 'External' ? formatMessage('tooltips.external_user_not_included') : ''
                          }
                        >
                          <TableCell className={tableClasses.tableCell} padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              disabled={dataItem.userType === 'External'}
                              onClick={event => handleClick(event, dataItem.email)}
                            />
                          </TableCell>
                        </Tooltip>
                      )}
                      <TableCell
                        className={`${tableClasses.tableCell} ${orderBy === 'email' &&
                          !numSelected &&
                          tableClasses.activeColumn}`}
                        scope="row"
                      >
                        {dataItem.email}
                      </TableCell>
                      <TableCell
                        className={`${tableClasses.tableCell} ${orderBy === 'userType' &&
                          !numSelected &&
                          tableClasses.activeColumn}`}
                        scope="row"
                      >
                        {dataItem.userType}
                      </TableCell>
                      {dataItem.userType !== 'External' ? (
                        <Tooltip
                          placement="right"
                          title={
                            hasESS
                              ? {
                                  [INCIDENT_USER_STATUS.UNKNOWN]: formatMessage('tooltips.clicked_links_unknown'),
                                  [INCIDENT_USER_STATUS.OFF]: formatMessage('tooltips.clicked_links_off')
                                }[dataItem.clickedLinks] || ''
                              : formatMessage('tooltips.clicked_links_disabled')
                          }
                        >
                          <TableCell
                            className={`${tableClasses.tableCell} ${orderBy === 'clickedLinks' &&
                              !numSelected &&
                              tableClasses.activeColumn}`}
                          >
                            {userDetailStatus[dataItem.clickedLinks]}
                          </TableCell>
                        </Tooltip>
                      ) : (
                        <TableCell
                          className={`${tableClasses.tableCell} ${orderBy === 'clickedLinks' &&
                            !numSelected &&
                            tableClasses.activeColumn}`}
                        />
                      )}
                      <TableCell
                        className={`${tableClasses.tableCell} ${orderBy === 'isRead' &&
                          !numSelected &&
                          tableClasses.activeColumn}`}
                      >
                        {dataItem.userType !== 'External' && userDetailStatus[dataItem.isRead]}
                      </TableCell>
                      <TableCell
                        className={`${tableClasses.tableCell} ${orderBy === 'replied' &&
                          !numSelected &&
                          tableClasses.activeColumn}`}
                      >
                        {dataItem.userType !== 'External' && userDetailStatus[dataItem.replied]}
                      </TableCell>
                      <TableCell
                        className={`${tableClasses.tableCell} ${orderBy === 'forwarded' &&
                          !numSelected &&
                          tableClasses.activeColumn}`}
                      >
                        {dataItem.userType !== 'External' && userDetailStatus[dataItem.forwarded]}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={tableClasses.paginationContainer}>
            <Pagination
              count={totalPage}
              showFirstButton
              showLastButton
              onChange={(e, pageNumber) => handleChangePage(e, pageNumber)}
            />
            <div> {itemsChangePagination}</div>
          </div>
        </Paper>
      </>
    )
  }, [classes, formatMessage, TABLE_COLUMNS, tableClasses, userDetailStatus, UserDetailsByIncidentLogic])
}

export default UsersDetailsByIncident
