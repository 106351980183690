import React, { useCallback, useMemo, useState } from 'react'

import { DateTime } from 'luxon'
import validator from 'validator'

import { Tooltip, Typography } from '@barracuda-internal/bds-core'
import { formatNumber } from 'global/lib/number'
import { useFormatMessage } from 'global/lib/localization'
import { useAppDispatch, useAppSelector } from 'global/redux/toolkit/hooks'
import { update } from 'global/redux/features/scheduledReports/scheduledReportsSlice'
import { Frequency, FrequencyMonthly, ScheduledReportsDrawerType } from 'global/types/api/scheduledReports'

const BASE_I18N_KEY = 'app.scheduled_reports.schedule_report_dialog'
const MAX_REPORT_NAME_LENGTH = 45

export interface ScheduledReportsDrawerLogic {
  dateOptions: string[]
  handleFrequencyButtonClick: (e: any, newAlignment: string) => void
  handleValueChange: (e: any) => void
  handleWeekdaysButtonClick: (e: any, newAlignment: number) => void
  invalidRecipientsErrorText: string
  maxReportNameLength: number
  reportName: React.ReactElement
  setInvalidRecipientsErrorText: (newValue: string) => void
  shouldShowWeekdaysList: boolean
  timeOptions: string[]
  updatedReportDrawerConfig: ScheduledReportsDrawerType
}

export default function useScheduledReportsDrawerLogic(): [ScheduledReportsDrawerLogic] {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [invalidRecipientsErrorText, setInvalidRecipientsErrorText] = useState<string>('')

  const { scheduledReport } = useAppSelector(_stores => ({
    scheduledReport: _stores.scheduledReports.scheduledReport
  }))

  const { frequency, frequencyMonthly } = scheduledReport

  const timeOptions = useMemo(
    () => Array.from({ length: 24 }, (_, i) => DateTime.fromObject({ hour: i, minute: 0 }).toFormat('HH:mm')),
    []
  )

  const shouldShowWeekdaysList = useMemo(() => {
    if (frequency === Frequency.daily) {
      return false
    }

    if (frequency === Frequency.monthly && frequencyMonthly === FrequencyMonthly.specificDate) {
      return false
    }

    return true
  }, [frequency, frequencyMonthly])

  const generateDatesWithOrdinals = useCallback(() => {
    const dateList = Array.from({ length: 31 }, (_, index) => formatNumber(index + 1, '0o'))
    dateList.push(formatMessage('last_day_of_month'))
    return dateList
  }, [formatMessage])

  const handleValueChange = useCallback(
    (e: {
      target: {
        name: string
        value: string
      }
    }) => {
      switch (e.target.name) {
        case 'recipients': {
          // Need to trim recipient, BE will raise error if email address has whitespace at the beginning of the string
          const recipientsList = e.target.value.split(',').map((recipient: string) => recipient.trim())
          const hasInvalidEmail = recipientsList.some((recipient: string) => {
            return !validator.isEmail(recipient.trim())
          })
          if (hasInvalidEmail) {
            setInvalidRecipientsErrorText(formatMessage('helper_text.invalid_email'))
          } else {
            setInvalidRecipientsErrorText('')
          }

          // RecipientsList length check should be after hasInvalidEmail check, if the recipientsList contains invalid emails and exceeds 50, we show a 50 limit error.
          if (recipientsList.length > 50) {
            setInvalidRecipientsErrorText(formatMessage('helper_text.limit_50_emails'))
          }

          dispatch(update({ scheduledReport: { ...scheduledReport, [e.target.name]: recipientsList } }))
          break
        }
        case 'frequencyTime':
          dispatch(
            update({
              scheduledReport: {
                ...scheduledReport,
                [e.target.name]: e.target.value,
                zoneId: DateTime.local().zoneName
              }
            })
          )

          break
        default:
          dispatch(update({ scheduledReport: { ...scheduledReport, [e.target.name]: e.target.value } }))
          break
      }
    },
    [dispatch, formatMessage, scheduledReport]
  )

  const handleFrequencyButtonClick = useCallback(
    (_: any, newAlignment: string) => {
      dispatch(update({ scheduledReport: { ...scheduledReport, frequency: newAlignment as Frequency } }))
    },
    [dispatch, scheduledReport]
  )

  const handleWeekdaysButtonClick = useCallback(
    (_: any, newAlignment: number) => {
      dispatch(update({ scheduledReport: { ...scheduledReport, frequencyDayOfWeek: newAlignment } }))
    },
    [dispatch, scheduledReport]
  )

  const updatedReportDrawerConfig = useMemo(
    () => ({
      ...scheduledReport,
      frequencyTime: DateTime.fromFormat(scheduledReport.frequencyTime, 'HH:mm', {
        zone: scheduledReport.zoneId
      })
        .setZone(DateTime.local().zoneName)
        .toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }),
      enabled: scheduledReport.uuid ? scheduledReport.enabled : true,
      zoneId: DateTime.local().zoneName
    }),
    [scheduledReport]
  )

  const reportName = useMemo(
    () => (
      <Tooltip
        title={scheduledReport.reportName.length > MAX_REPORT_NAME_LENGTH ? scheduledReport.reportName : ''}
        placement="bottom"
        interactive
      >
        <Typography variant="h6">
          {scheduledReport.reportName.length > MAX_REPORT_NAME_LENGTH
            ? `${scheduledReport.reportName.slice(0, MAX_REPORT_NAME_LENGTH)}...`
            : scheduledReport.reportName}
        </Typography>
      </Tooltip>
    ),
    [scheduledReport.reportName]
  )

  return useMemo(
    () => [
      {
        dateOptions: generateDatesWithOrdinals(),
        handleFrequencyButtonClick,
        handleValueChange,
        handleWeekdaysButtonClick,
        invalidRecipientsErrorText,
        maxReportNameLength: MAX_REPORT_NAME_LENGTH,
        reportName,
        setInvalidRecipientsErrorText,
        shouldShowWeekdaysList,
        timeOptions,
        updatedReportDrawerConfig
      }
    ],
    [
      generateDatesWithOrdinals,
      handleFrequencyButtonClick,
      handleValueChange,
      handleWeekdaysButtonClick,
      invalidRecipientsErrorText,
      reportName,
      shouldShowWeekdaysList,
      timeOptions,
      updatedReportDrawerConfig
    ]
  )
}
