import React, { useMemo } from 'react'

import { v4 as uuidv4 } from 'uuid'

import {
  Card,
  Typography,
  DataTable,
  DataTableColumn,
  Select,
  MenuItem,
  SelectWrapper,
  Grid,
  Tooltip
} from '@barracuda-internal/bds-core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import MaskLayer from 'global/components/lib/maskLayer/MaskLayer'
import SearchField from 'global/components/lib/searchField/SearchField'
import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import CellLabelType from 'global/components/lib/grid/cell/CellLabelType'
import AttackTypeLabel from 'global/components/lib/attackTypeLabel/AttackTypeLabel'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import highlightText from 'global/lib/highlightText/highlightText'
import IMAGES from 'global/configs/theme/images.config'
import Pager from 'global/components/lib/grid/pager/Pager'
import { useFormatMessage } from 'global/lib/localization'
import EmailDetailsDialog from 'global/components/lib/dialogs/emailDetailsDialog/EmailDetailsDialog'

import styles from 'ets/components/pages/dashboard/threats/dashboardThreatsStyles'
import useDashboardThreatsLogic, {
  UseDashboardThreatsLogicProps
} from 'ets/components/pages/dashboard/threats/useDashboardThreatsLogic'

const BASE_I18N_KEY = 'ets.app.dashboard.threats'
const BASE_PROGRESS_I18N_KEY = 'ets.app.dashboard.in_progress'
const BASE_I18N_TABLE_KEY = 'ets.app.data_tables.threats'
const BASE_ATTACK_TYPES_KEY = 'app.attack_types'

const DashboardThreats: React.FC<UseDashboardThreatsLogicProps> = ({ isUserInputDisabledForTable, fixTableHeight }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageProgress = useFormatMessage(BASE_PROGRESS_I18N_KEY)
  const formatMessageAttack = useFormatMessage(BASE_ATTACK_TYPES_KEY)
  const formatMessageTable = useFormatMessage(BASE_I18N_TABLE_KEY)
  const classes = styles()
  const [dashboardThreatsLogic, emailDetailsSpAttackInterface] = useDashboardThreatsLogic({
    isUserInputDisabledForTable,
    fixTableHeight
  })
  const [emailDetailDialogConfig, emailDetailDialogActions] = emailDetailsSpAttackInterface

  return useMemo(() => {
    const {
      attackSelectorConfig,
      columnsConfig,
      GRID_COLUMNS,
      highlightKeywords,
      inProgress,
      isReportLoaded,
      isViewEmailsDisabled,
      pageConfig,
      searchFieldConfig,
      sortConfig,
      tableData,
      tableTotal,
      timespanSelectorConfig
    } = dashboardThreatsLogic

    return (
      <Grid
        className={classes.root}
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        data-field="root-grid"
      >
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Card className={classes.tableWrapper}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <Typography className={classes.tableTotal} align="left" data-field="table-total">
                    {formatMessage('table_total', { total: tableTotal })}
                  </Typography>
                </Grid>
                <Grid container direction="row">
                  <Grid className={classes.searchField} item xs data-field="table-search">
                    <SearchField {...searchFieldConfig} placeholder={formatMessage('search_field_placeholder')} />
                  </Grid>
                  <Grid className={classes.searchField} item data-field="select-wrapper-timespan">
                    <SelectWrapper fullWidth size="medium" style={{ marginTop: '-8px' }}>
                      <Select
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                          }
                        }}
                        className={classes.pickList}
                        open={timespanSelectorConfig.open}
                        onOpen={timespanSelectorConfig.onOpen}
                        onClose={timespanSelectorConfig.onClose}
                        disabled={timespanSelectorConfig.disabled}
                        onChange={timespanSelectorConfig.onChange}
                        value={timespanSelectorConfig.value}
                        renderValue={(): JSX.Element => (
                          <div className={timespanSelectorConfig.disabled ? classes.disabledPicklist : ''}>
                            <img className={classes.pickListIcon} alt="" src={IMAGES.iconFilter} />
                            <Typography className={classes.pickListText}>
                              {formatMessage(`timespan.${timespanSelectorConfig.value}`)}
                            </Typography>
                          </div>
                        )}
                        data-field="select-timespan"
                      >
                        {timespanSelectorConfig.menuItems.map((menuItem: any) => (
                          <MenuItem key={menuItem.id} value={menuItem.id}>
                            {formatMessage(`timespan.${menuItem.id}`)}
                          </MenuItem>
                        ))}
                      </Select>
                    </SelectWrapper>
                  </Grid>
                  <Grid className={classes.searchField} item data-field="select-wrapper-threats">
                    <SelectWrapper fullWidth size="medium" style={{ marginTop: '-8px' }}>
                      <Select
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                          }
                        }}
                        className={classes.pickList}
                        open={attackSelectorConfig.open}
                        onOpen={attackSelectorConfig.onOpen}
                        onClose={attackSelectorConfig.onClose}
                        disabled={attackSelectorConfig.disabled}
                        onChange={attackSelectorConfig.onChange}
                        value={attackSelectorConfig.value}
                        renderValue={(): JSX.Element => (
                          <div className={attackSelectorConfig.disabled ? classes.disabledPicklist : ''}>
                            <img className={classes.pickListIcon} alt="" src={IMAGES.iconFilter} />
                            <Typography className={classes.pickListText}>
                              {formatMessageAttack(`${attackSelectorConfig.value}.text`)}
                            </Typography>
                          </div>
                        )}
                        data-field="select-threats"
                      >
                        {attackSelectorConfig.menuItems.map((menuItem: any) => (
                          <MenuItem key={menuItem.id} value={menuItem.id}>
                            {formatMessageAttack(`${menuItem.id}.text`)}
                          </MenuItem>
                        ))}
                      </Select>
                    </SelectWrapper>
                  </Grid>
                </Grid>
                <Grid
                  className={`${classes.tableArea} ${!tableData.total ? 'empty-table' : ''}`}
                  data-table="threats"
                  item
                  xs={12}
                >
                  {isReportLoaded && inProgress && (
                    <LinearProgress data-field="table-loader" className={classes.tableLoadIndicator} />
                  )}
                  <DataTable
                    className={`${tableData.total ? classes.dataTable : classes.emptyDataTable} threats-table`}
                    data={tableData}
                    {...pageConfig}
                    {...sortConfig}
                    pager={Pager}
                    data-field="table"
                  >
                    <DataTableNoRecords data-field="table-no-records">
                      <Typography>{!tableData.total && formatMessage('empty_table')}</Typography>
                    </DataTableNoRecords>
                    <DataTableColumn
                      width={110}
                      field={GRID_COLUMNS.RECEIVED}
                      {...columnsConfig[GRID_COLUMNS.RECEIVED as any]}
                      title={formatMessageTable(`${GRID_COLUMNS.RECEIVED}`)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellText className={classes.normalCell} data-field="table-received-cell">
                            {highlightText(dataItem.formattedDate, highlightKeywords)}
                          </CellText>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      width={105}
                      field={GRID_COLUMNS.RECIPIENTS}
                      headerClassName={classes.centerAlignedText}
                      {...columnsConfig[GRID_COLUMNS.RECIPIENTS as any]}
                      title={formatMessageTable(`${GRID_COLUMNS.RECIPIENTS}`)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <Typography
                            className={classes.recipientsCell}
                            align="center"
                            data-field="table-recipients-cell"
                          >
                            {dataItem.count}
                          </Typography>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={GRID_COLUMNS.SAMPLE_RECIPIENT}
                      {...columnsConfig[GRID_COLUMNS.SAMPLE_RECIPIENT as any]}
                      title={formatMessageTable(`${GRID_COLUMNS.SAMPLE_RECIPIENT}`)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellText ellipsis className={classes.boldCell} data-field="table-sample-cell-name">
                            {highlightText(dataItem.displayName, highlightKeywords)}
                          </CellText>
                          {dataItem.title && (
                            <>
                              <br />
                              <CellText ellipsis className={classes.normalCell} data-field="table-sample-cell-title">
                                {highlightText(dataItem.title, highlightKeywords)}
                              </CellText>
                            </>
                          )}
                          {dataItem.email && (
                            <>
                              <br />
                              <CellText ellipsis className={classes.lightCell} data-field="table-sample-cell-email">
                                {highlightText(dataItem.email, highlightKeywords)}
                              </CellText>
                            </>
                          )}
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      field={GRID_COLUMNS.EMAIL}
                      {...columnsConfig[GRID_COLUMNS.EMAIL as any]}
                      title={formatMessageTable(`${GRID_COLUMNS.EMAIL}`)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellText ellipsis className={classes.boldCell} data-field="table-email-cell-subject">
                            {highlightText(dataItem.subject, highlightKeywords)}
                          </CellText>
                          {dataItem.sender.displayName && (
                            <>
                              <br />
                              <CellText ellipsis className={classes.normalCell} data-field="table-email-cell-name">
                                {highlightText(dataItem.sender.displayName, highlightKeywords)}
                              </CellText>
                            </>
                          )}
                          {dataItem.sender.email && (
                            <>
                              <br />
                              <CellText ellipsis className={classes.lightCell} data-field="table-email-cell-sender">
                                {highlightText(dataItem.sender.email, highlightKeywords)}
                              </CellText>
                            </>
                          )}
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      width={200}
                      field={GRID_COLUMNS.ATTACK_TYPE}
                      {...columnsConfig[GRID_COLUMNS.ATTACK_TYPE as any]}
                      title={formatMessageTable(`${GRID_COLUMNS.ATTACK_TYPE}`)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell}>
                          <CellLabelType data-field="table-attack-cell">
                            <AttackTypeLabel attackType={dataItem.taxonomy} etsAttackTypes />
                          </CellLabelType>
                        </Cell>
                      )}
                    />
                    <DataTableColumn
                      width={100}
                      field={GRID_COLUMNS.ACTION}
                      {...columnsConfig[GRID_COLUMNS.ACTION as any]}
                      title={formatMessageTable(`${GRID_COLUMNS.ACTION}`)}
                      cell={({ dataItem }: { dataItem: any }) => (
                        <Cell className={classes.cell} data-field="table-action-cell">
                          {isViewEmailsDisabled ? (
                            <Tooltip title={formatMessage('details_unavailable')}>
                              <div>
                                <CellText className={classes.disabledText}>{formatMessage('see_details')}</CellText>
                              </div>
                            </Tooltip>
                          ) : (
                            <CellText
                              onClick={() => dataItem.seeDetails(dataItem.threatId, dataItem.formattedDate)}
                              className={classes.blueNormalCell}
                              data-action="open-email-details-action"
                            >
                              {formatMessage('see_details')}
                            </CellText>
                          )}
                        </Cell>
                      )}
                    />
                  </DataTable>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        {!isReportLoaded && (
          <MaskLayer data-field="mask-layer">
            {formatMessageProgress('title', { br: () => <br key={uuidv4()} /> })}
          </MaskLayer>
        )}

        {emailDetailDialogActions.open && (
          <EmailDetailsDialog data={emailDetailDialogConfig} buttonText={formatMessage('button')} etsAttackTypes />
        )}
      </Grid>
    )
  }, [
    dashboardThreatsLogic,
    emailDetailDialogConfig,
    emailDetailDialogActions,
    classes,
    formatMessage,
    formatMessageTable,
    formatMessageAttack,
    formatMessageProgress
  ])
}

export default DashboardThreats
