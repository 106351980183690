import React from 'react'
import { createRoot } from 'react-dom/client'
import deepmerge from 'deepmerge'
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { BDSProvider } from '@barracuda-internal/bds-core'
import * as bdsTheme from '@barracuda-internal/bds-core/dist/styles/themes/baseMui'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import theme from 'global/theme'
import browserHistory from 'global/lib/routes/browserHistory'
import { CustomBrowserRouter } from 'global/lib/routes/CustomBrowserRouter'
import initLocalization, { Localization } from 'global/lib/localization'
import initStore from 'global/lib/reduxStore'

import Routes from 'sen/components/lib/routes/Routes'
import appReducers from 'sen/redux/reducers'
import MESSAGES_EN from 'sen/configs/i18n/en'

const rootElement = document.getElementById('root')

const root = rootElement ? createRoot(rootElement) : null

export function SENApp(): void {
  const i18nConfig: Localization = initLocalization(MESSAGES_EN)
  const appTheme = deepmerge(bdsTheme.default, theme)

  if (root) {
    return root.render(
      <Provider store={initStore(appReducers)}>
        <IntlProvider locale={i18nConfig.locale} messages={i18nConfig.messages}>
          <BDSProvider useBdsTheme>
            <MuiThemeProvider theme={createTheme(appTheme)}>
              <CssBaseline />
              <CustomBrowserRouter history={browserHistory}>{Routes}</CustomBrowserRouter>
            </MuiThemeProvider>
          </BDSProvider>
        </IntlProvider>
      </Provider>
    )
  }
}
