import React from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'

import RedirectRoute from 'global/components/lib/routes/RedirectRoute'

import routesConfig from 'admin/lib/routes/routesConfig'
import PrivateRoute from 'admin/components/lib/routes/PrivateRoute'
import Layout from 'admin/components/lib/layout/Layout'
import Dashboard from 'admin/components/pages/dashboard/Dashboard'
import { config } from 'global/lib/config'
import { UiRoute } from 'global/lib/routes/routesConfig'

let redirectPath
switch (true) {
  case config.domainConfig.isForensics:
    redirectPath = routesConfig.DASHBOARD_INCIDENT_RESPONSE.path
    break
  case config.domainConfig.isSentinel:
    redirectPath = routesConfig.DASHBOARD_IMPERSONATION_PROTECTION.path
    break
  case config.domainConfig.isDfp:
    redirectPath = routesConfig.DASHBOARD_DOMAIN_FRAUD.path
    break
  default:
    redirectPath = routesConfig.DASHBOARD_EMAIL_THREAT_SCANNER.path
    break
}

export type RouteParams = [UiRoute, React.FC<any>]

const privateRoutes: RouteParams[] = [
  [routesConfig.DASHBOARD_ETS, Dashboard],
  [routesConfig.DASHBOARD_EMAIL_THREAT_SCANNER, Dashboard],
  [routesConfig.DASHBOARD_SENTINEL, Dashboard],
  [routesConfig.DASHBOARD_IMPERSONATION_PROTECTION, Dashboard],
  [routesConfig.DASHBOARD_FORENSICS, Dashboard],
  [routesConfig.DASHBOARD_INCIDENT_RESPONSE, Dashboard],
  [routesConfig.DASHBOARD_DOMAIN_FRAUD, Dashboard]
]

const AppRoutes = (
  <Layout>
    <Routes>
      {/* Admin routes */}
      {privateRoutes
        .filter(routeparams => routeparams[0])
        .map(routeParams => {
          const [routeConfig, Component] = routeParams

          return (
            <Route
              key={routeConfig.id}
              path={routeConfig.path}
              element={<PrivateRoute route={routeConfig} Component={Component} />}
            />
          )
        })}

      {/* Redirect routes */}
      <Route
        path={routesConfig.DASHBOARD_INCIDENT_RESPONSE_ROOT.path}
        element={<RedirectRoute to={path => `${path}/accounts`} />}
      />
      <Route
        path={routesConfig.DASHBOARD_FORENSICS_ACCOUNTS.path}
        element={<RedirectRoute to={path => path.replace('/forensics/', '/incident-response/')} />}
      />
      <Route
        path={routesConfig.DASHBOARD_EMAIL_THREAT_SCANNER_ROOT.path}
        element={<RedirectRoute to={path => `${path}/scans`} />}
      />
      <Route
        path={routesConfig.DASHBOARD_ETS_SCANS.path}
        element={<RedirectRoute to={path => path.replace('/ets/', '/email-threat-scanner/')} />}
      />
      <Route
        path={routesConfig.DASHBOARD_IMPERSONATION_PROTECTION_ROOT.path}
        element={<RedirectRoute to={path => `${path}/accounts`} />}
      />
      <Route
        path={routesConfig.DASHBOARD_SENTINEL_ACCOUNTS.path}
        element={<RedirectRoute to={path => path.replace('/sentinel/', '/impersonation-protection/')} />}
      />
      <Route
        path={routesConfig.DASHBOARD_DOMAIN_FRAUD_ROOT.path}
        element={<RedirectRoute to={path => `${path}/accounts`} />}
      />

      <Route path="*" element={<Navigate to={redirectPath} replace />} />
    </Routes>
  </Layout>
)

export default AppRoutes
