import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { capitalize, isArray, snakeCase, toLower } from 'lodash'
import { DateTime } from 'luxon'
import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_SortingState as MRTSortingState,
  MRT_GroupingState as MRTGroupingState
} from 'material-react-table'

import { Box, Checkbox, CheckboxLabel, List, ListItem } from '@barracuda-internal/bds-core'
import { FilterOperation, FieldType } from '@barracuda/reporting'

import ConditionCheckboxFilter from 'global/components/lib/dataTable/columnFilters/ConditionCheckboxFilter'
import ConditionInputFilter from 'global/components/lib/dataTable/columnFilters/ConditionInputFilter'
import { DataTableProps, DateRangeConfig, PageConfig } from 'global/components/lib/dataTable/DataTable'
import DateFilter from 'global/components/lib/dataTable/columnFilters/DateFilter'
import InputCheckboxFilter from 'global/components/lib/dataTable/columnFilters/InputCheckboxFilter'
import MinMaxFilter from 'global/components/lib/dataTable/columnFilters/MinMaxFilter'
import { SearchFieldProps, useSearchFieldLogic } from 'global/components/lib/searchField/SearchField'
import { onKeyDown } from 'global/lib/keyEvents'
import { useFormatMessage } from 'global/lib/localization'
import { isFailed, isIdle, isPending, isSuccess } from 'global/redux/toolkit/api'
import { Column, Filter, RelativeDateRange } from 'global/types/api/unifiedReporting'
import styles from 'global/components/lib/sideMenu/sideMenuStyles'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import {
  resetSearch,
  update as updateUnifiedReportingDataTable
} from 'sen/redux/features/dataTables/unifiedReporting/unifiedReportingSlice'
import { getColumnConfig } from 'sen/redux/features/dataTables/unifiedReporting/columnConfigs'

type State = {
  displayTopResultsValue: number | undefined
  globalSearchValue: string
  isConvertedFromRelativeDateRange: boolean
}

type ColumnList = {
  category: string
  columnName: string
  content: JSX.Element
}
interface ColumnValue {
  label: string
  value: string | null
}

const SEARCH_FIELD_ID = 'datatable-search-field'
const BASE_UR_I18N_KEY = 'app.unified_reporting'
const SEN_UR_I18N_KEY = 'sen.app.unified_reporting'

export default function useUnifiedReportingDatatableLogic(): [DataTableProps] {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_UR_I18N_KEY)
  const formatSENMessage = useFormatMessage(SEN_UR_I18N_KEY)
  const classes = styles()

  // We have to use useState to pass updater function to MRT
  const [expanded, setExpanded] = useState<true | Record<string, boolean>>(true)
  const [columnFilterText, setColumnFilterText] = useState<string>('')
  const [sorting, setSorting] = useState<MRTSortingState>([])
  const [visibleColumnsState, setVisibleColumnsState] = useState<{
    [key: string]: boolean
  }>({})
  // end of useStates

  const {
    absoluteDateRangeEnd,
    absoluteDateRangeStart,
    columns,
    data,
    dataTableFilters,
    dataTableGroupBy,
    dataTablePage,
    dataTableSearchQuery,
    dataTableSorting,
    isGetReportsDataPending,
    isLoadingReports,
    relativeDateRange,
    report,
    total
  } = useAppSelector(_stores => ({
    absoluteDateRangeEnd: _stores.dataTables.unifiedReporting.absoluteDateRangeEnd,
    absoluteDateRangeStart: _stores.dataTables.unifiedReporting.absoluteDateRangeStart,
    columns: _stores.dataTables.unifiedReporting.columns,
    data: _stores.unifiedReporting.reportData.table.data,
    dataTableFilters: _stores.dataTables.unifiedReporting.filters,
    dataTableGroupBy: _stores.dataTables.unifiedReporting.groupBy,
    dataTablePage: _stores.dataTables.unifiedReporting.page,
    dataTableSearchQuery: _stores.dataTables.unifiedReporting.searchQuery,
    dataTableSorting: _stores.dataTables.unifiedReporting.sortBy,
    isGetReportsDataPending: isPending(_stores.unifiedReporting.getUnifiedReportingReportDataApiStatus),
    isGetReportsIdle: isIdle(_stores.unifiedReporting.getUnifiedReportingReportsApiStatus),
    isGetReportsSuccess: isSuccess(_stores.unifiedReporting.getUnifiedReportingReportsApiStatus),
    isGetReportsFailed: isFailed(_stores.unifiedReporting.getUnifiedReportingReportsApiStatus),
    isLoadingReports: isPending(_stores.unifiedReporting.getUnifiedReportingReportsApiStatus),
    relativeDateRange: _stores.dataTables.unifiedReporting.relativeDateRange,
    report: _stores.unifiedReporting.report,
    total: _stores.unifiedReporting.reportData.table.totalCount
  }))

  // NB: We need this here to keep the grouping state in sync with the data table state
  const [grouping, setGrouping] = useState<MRTGroupingState>(dataTableGroupBy || [])

  const [{ displayTopResultsValue, globalSearchValue, isConvertedFromRelativeDateRange }, setState] = useReducer(
    (_state: State, newState: Partial<State>) => ({ ..._state, ...newState }),
    {
      displayTopResultsValue: undefined,
      globalSearchValue: '',
      isConvertedFromRelativeDateRange: false
    }
  )

  const [debouncedOnChange, validateSearchString] = useSearchFieldLogic(
    (search: string) => {
      setState({
        globalSearchValue: search
      })
    },
    isGetReportsDataPending,
    SEARCH_FIELD_ID
  )

  const displayResultsValues = useMemo(() => {
    return [
      {
        label: formatMessage('data_table_header.display_top_results.dropdown_menu_items.top_5'),
        value: 5
      },
      {
        label: formatMessage('data_table_header.display_top_results.dropdown_menu_items.top_10'),
        value: 10
      },
      {
        label: formatMessage('data_table_header.display_top_results.dropdown_menu_items.top_15'),
        value: 15
      }
    ]
  }, [formatMessage])

  /*
   * ***************************************************
   * All useEffect hooks are at the bottom of the file *
   * ***************************************************
   */

  /*
   * ****************
   * Column configs *
   * ****************
   */

  const formatColumnValues = useCallback(
    (values: (string | null)[], columnHeader: string): ColumnValue[] =>
      values.map(value => ({
        label: formatSENMessage(`column_values.${snakeCase(report.baseDataType)}.${columnHeader}.${value}`),
        value
      })),
    [report.baseDataType, formatSENMessage]
  )

  const formatConditions = useCallback(
    (conditions: string[]) => {
      return conditions.map(condition => {
        return {
          label: formatMessage(`data_table.operators.${condition.toLowerCase()}`),
          value: condition
        }
      })
    },
    [formatMessage]
  )

  const handleRemoveColumnFilter = useCallback(
    (columnId: string) => {
      dispatch(
        updateUnifiedReportingDataTable({
          filters: dataTableFilters.filter(columnFilter => columnFilter.fieldName !== columnId)
        })
      )
    },
    [dataTableFilters, dispatch]
  )

  const onColumnFilterChange = useCallback(
    (columnFilter: Filter) => {
      const newFilters = dataTableFilters.filter(filter => filter.fieldName !== columnFilter.fieldName)
      newFilters.push(columnFilter)
      dispatch(
        updateUnifiedReportingDataTable({
          filters: newFilters
        })
      )
    },
    [dataTableFilters, dispatch]
  )

  const renderColumnActionsMenuItems = useCallback(
    (column: Column) => {
      const columnFilter = dataTableFilters.find(filter => filter.fieldName === column.accessorKey)
      switch (column.fieldType) {
        case FieldType.DATE:
          return [
            <DateFilter
              columnFilter={columnFilter}
              columnId={column.accessorKey}
              conditions={formatConditions([
                FilterOperation.GREATER,
                FilterOperation.BETWEEN,
                FilterOperation.LESS,
                FilterOperation.DATE_EQUALS
              ])}
              key="dateFilter"
              setColumnFilter={onColumnFilterChange}
            />
          ]

        case FieldType.BOOLEAN:
          return [
            <ConditionCheckboxFilter
              columnFilter={columnFilter}
              columnId={column.accessorKey}
              columnValues={[
                {
                  label: formatMessage('data_table.boolean.true'),
                  value: 'true'
                },
                {
                  label: formatMessage('data_table.boolean.false'),
                  value: 'false'
                }
              ]}
              conditionLabel={formatMessage('data_table.column_filters.condition_label')}
              conditions={formatConditions([FilterOperation.IN, FilterOperation.NOT_IN])}
              filterTitle={formatMessage('data_table.column_filters.filter_title')}
              handleRemoveColumnFilter={handleRemoveColumnFilter}
              key="conditionCheckboxFilter"
              selectAllLabel={formatMessage('data_table.column_filters.select_all_label')}
              setColumnFilters={onColumnFilterChange}
            />
          ]

        case FieldType.ENUM:
          return [
            <InputCheckboxFilter
              columnFilter={columnFilter}
              columnId={column.accessorKey}
              columnValues={formatColumnValues(column.possibleValues as (string | null)[], column.header)}
              filterTitle={formatMessage('data_table.column_filters.filter_title')}
              handleRemoveColumnFilter={handleRemoveColumnFilter}
              key="inputCheckboxFilter"
              selectAllLabel={formatMessage('data_table.column_filters.select_all_label')}
              setColumnFilters={onColumnFilterChange}
            />
          ]

        case FieldType.NUMBER:
          return [
            <MinMaxFilter
              columnFilter={columnFilter}
              columnId={column.accessorKey}
              key="minMaxFilter"
              placeholder={formatMessage('data_table.column_filters.min_max_filter.placeholder')}
              setColumnFilter={onColumnFilterChange}
            />
          ]

        case FieldType.KEYWORD:
        case FieldType.TEXT:
          return [
            <ConditionInputFilter
              columnId={column.accessorKey}
              columnFilter={columnFilter}
              conditionLabel={formatMessage('data_table.column_filters.condition_label')}
              conditions={formatConditions([
                FilterOperation.CONTAINS,
                FilterOperation.NOT_CONTAINS,
                FilterOperation.BLANK,
                FilterOperation.NOT_BLANK,
                FilterOperation.EQUALS
              ])}
              key="conditionInputFilter"
              setColumnFilter={onColumnFilterChange}
              handleRemoveColumnFilter={handleRemoveColumnFilter}
            />
          ]

        default:
          return []
      }
    },
    [
      dataTableFilters,
      formatColumnValues,
      formatConditions,
      formatMessage,
      handleRemoveColumnFilter,
      onColumnFilterChange
    ]
  )

  const formatCellText = useCallback(
    ({ cell, column }: { cell: any; column: Column }) => {
      switch (column.fieldType) {
        case FieldType.DATE:
          return DateTime.fromISO(cell.getValue() as string).toLocaleString(DateTime.DATETIME_MED)

        case FieldType.BOOLEAN:
          return cell.getValue() ? formatMessage('data_table.boolean.true') : formatMessage('data_table.boolean.false')

        case FieldType.ENUM:
          return (
            cell.getValue() &&
            formatSENMessage(`column_values.${snakeCase(report.baseDataType)}.${column.header}.${cell.getValue()}`)
          )

        case FieldType.NUMBER:
          return cell.getValue()

        case FieldType.TEXT:
        case FieldType.KEYWORD:
          return cell.getValue()

        default:
          return cell.getValue()
      }
    },
    [report.baseDataType, formatSENMessage, formatMessage]
  )

  // This code block is defining `MRTColumns` using the `useMemo` hook to optimize performance.
  // MRTColumns is an array of column definitions for a table, derived from the `getColumnConfig` function.
  // Each column definition is an object that includes:
  // - `accessorKey`: a unique key to access the specific column data
  // - `header`: a formatted internationalized message for the column header, based on the `baseDataType`
  // - `renderColumnActionsMenuItems`: a function to render the actions menu items for each column
  // - `Cell`: a function to render the cell value. If the column's filter type is 'DATE', it formats the value as a localized date string. Otherwise, it uses the rendered cell value.
  const MRTColumns = useMemo<MRTColumnDef<any>[]>(
    () =>
      getColumnConfig(report.baseDataType).map(column => ({
        accessorKey: column.accessorKey,
        header: formatSENMessage(`column_headers.${snakeCase(report.baseDataType)}.${column.header}`),
        renderColumnActionsMenuItems: () => renderColumnActionsMenuItems(column),
        GroupedCell: ({ cell, row }: { cell: any; row: any }) => (
          <Box>
            <strong>
              {formatCellText({
                cell,
                column
              })}
            </strong>{' '}
            ({row.subRows.length})
          </Box>
        ),
        Cell: ({ cell, row }) => formatCellText({ cell, column })
      })),
    [formatCellText, formatSENMessage, renderColumnActionsMenuItems, report.baseDataType]
  )

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // Shape the updated data for MRT and shape the updated data to send to the backend
      const updatedVisibleColumnState = {
        ...visibleColumnsState,
        [event.target.name]: event.target.checked
      }
      const updatedHiddenColumns = Object.keys(updatedVisibleColumnState).filter(
        hiddenColKey => !updatedVisibleColumnState[hiddenColKey]
      )

      setVisibleColumnsState(updatedVisibleColumnState)
      dispatch(
        updateUnifiedReportingDataTable({
          hiddenColumns: updatedHiddenColumns
        })
      )
    },
    [dispatch, visibleColumnsState]
  )

  const columnMenuItems = useMemo(() => {
    const columnItems = getColumnConfig(report.baseDataType).reduce((acc, col) => {
      const columnName = formatSENMessage(`column_headers.${snakeCase(report.baseDataType)}.${col.header}`)

      // Creates an array of objects, that match the search
      if (!col.hideColumnFromList && columnName.toLowerCase().indexOf(columnFilterText.toLowerCase()) > -1) {
        acc.push({
          content: (
            <ListItem className={classes.dropdownList} key={String(col.accessorKey)} disableGutters>
              <CheckboxLabel
                control={
                  <Checkbox
                    checked={visibleColumnsState[String(col.accessorKey)]}
                    onChange={handleCheckboxChange}
                    name={String(col.accessorKey)}
                    size="small"
                    color="primary"
                  />
                }
                label={formatSENMessage(`column_headers.${snakeCase(report.baseDataType)}.${col.header}`)}
              />
            </ListItem>
          ),
          category: col.category,
          columnName
        })
      }

      return acc
    }, [] as ColumnList[])

    // Sort the list by name
    columnItems.sort((a, b) => {
      if (a.columnName < b.columnName) {
        return -1
      }

      if (a.columnName > b.columnName) {
        return 1
      }

      return 0
    })

    // Groups columns by category
    const categorizedColumns = columnItems.reduce(
      (group, columnItem) => {
        const { category } = columnItem

        // eslint-disable-next-line no-param-reassign
        group[category] = group[category] ?? []
        group[category].push(columnItem.content)

        return group
      },
      {} as {
        [key: string]: JSX.Element[]
      }
    )

    // Sort the categories in the list and return
    return Object.entries(categorizedColumns)
      .sort(([a], [b]) =>
        formatMessage(`column_categories.${[a]}`).localeCompare(formatMessage(`column_categories.${[b]}`))
      )
      .map((item: any) => {
        return {
          id: item[0],
          label: formatMessage(`column_categories.${item[0]}`),
          count: item[1].length,
          content: (
            <List component="div" disablePadding>
              {item[1]}
            </List>
          )
        }
      })
  }, [
    classes.dropdownList,
    columnFilterText,
    formatSENMessage,
    formatMessage,
    handleCheckboxChange,
    report.baseDataType,
    visibleColumnsState
  ])

  const columnConfig = useMemo(
    () => ({
      columns: MRTColumns,
      columnMenuItems,
      columnMenuTitle: formatMessage('column_menu.title'),
      columnMenuSubtitle: formatMessage('column_menu.subtitle'),
      columnVisibility: visibleColumnsState,
      handleColumnMenuFilterChange: setColumnFilterText
    }),
    [MRTColumns, columnMenuItems, formatMessage, visibleColumnsState]
  )

  /*
   * ********************
   * Date Range configs *
   * ********************
   */
  const onDateRangeChange = useCallback(
    (range: [DateTime | null, DateTime | null], ctx: any) => {
      const today = DateTime.now()
      let updatedRelativeDateRange = null
      let updatedRange = range
      // let updatedChartPeriodOptions = [ChartPeriod.daily]
      // if absolute date range is converted from relative date range other then selected from calendar, keep the relativeDateRange and absolute date range as it is.
      // Otherwise the rest of the logic will cause relativeDateRange to null.
      if (isConvertedFromRelativeDateRange) {
        updatedRelativeDateRange = relativeDateRange
        updatedRange = [null, null]
        setState({
          isConvertedFromRelativeDateRange: false
        })
      }
      // Check if date range is from shortCut, if yes, update relativeDateRange, if not , then relativeDateRange is null
      if (ctx && ctx.shortcut) {
        updatedRelativeDateRange = snakeCase(ctx.shortcut.label).toUpperCase()
        updatedRange = [null, null]
      }

      if (updatedRelativeDateRange || updatedRange[1] || updatedRange[0]) {
        const isToday = updatedRange[1]?.startOf('day').equals(today.startOf('day'))
        let updatedAbsoluteDateRangeEnd

        if (isToday) {
          updatedAbsoluteDateRangeEnd = today
            .set({ millisecond: 999 })
            .toUTC()
            .toISO({
              suppressMilliseconds: true
            })
        } else {
          updatedAbsoluteDateRangeEnd = updatedRange[1]
            ?.set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 999
            })
            .toUTC()
            .toISO({
              suppressMilliseconds: true
            })
        }

        dispatch(
          updateUnifiedReportingDataTable({
            absoluteDateRangeEnd: updatedRange[1] && updatedAbsoluteDateRangeEnd,
            absoluteDateRangeStart:
              updatedRange[0] &&
              updatedRange[0]
                .set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0
                })
                .toUTC()
                .toISO({
                  suppressMilliseconds: true
                }),
            relativeDateRange: updatedRelativeDateRange
          })
        )
      }
    },
    [dispatch, isConvertedFromRelativeDateRange, relativeDateRange]
  )

  const dateRange = useMemo((): [DateTime, DateTime] => {
    const today = DateTime.now()

    if (!absoluteDateRangeStart || !absoluteDateRangeEnd) {
      setState({
        isConvertedFromRelativeDateRange: true
      })
      // Calculate the start and end date based on the relativeDateRange and shows them on the date range input box
      switch (relativeDateRange) {
        case RelativeDateRange.LAST_6_MONTHS:
          return [today.minus({ days: 180 }), today]
        case RelativeDateRange.LAST_24_HOURS:
          return [today.minus({ hours: 24 }), today]
        case RelativeDateRange.LAST_3_DAYS:
          return [today.minus({ days: 3 }), today]
        case RelativeDateRange.LAST_7_DAYS:
          return [today.minus({ days: 7 }), today]
        case RelativeDateRange.LAST_30_DAYS:
          return [today.minus({ days: 30 }), today]
        case RelativeDateRange.LAST_90_DAYS:
          return [today.minus({ days: 90 }), today]
        default:
          return [DateTime.fromISO(absoluteDateRangeStart || ''), DateTime.fromISO(absoluteDateRangeEnd || '')]
      }
    }

    return [DateTime.fromISO(absoluteDateRangeStart), DateTime.fromISO(absoluteDateRangeEnd)]
  }, [absoluteDateRangeEnd, absoluteDateRangeStart, relativeDateRange])

  const dateRangeError: {
    error: boolean
    errorMessage: string
  } = useMemo(() => {
    if (DateTime.now().diff(DateTime.fromISO(absoluteDateRangeStart || ''), ['months']).months > 6) {
      return {
        error: true,
        errorMessage: formatMessage('data_table.date_range_retention_error')
      }
    }

    if ((!relativeDateRange || relativeDateRange === 'RESET') && (!absoluteDateRangeEnd || !absoluteDateRangeStart)) {
      return {
        error: true,
        errorMessage: formatMessage('data_table.date_range_empty_date_error')
      }
    }

    return {
      error: false,
      errorMessage: ''
    }
  }, [absoluteDateRangeEnd, absoluteDateRangeStart, formatMessage, relativeDateRange])

  const dateRangeConfig: DateRangeConfig = useMemo(() => {
    let relativeDateRangeText
    switch (relativeDateRange) {
      case 'LAST_24_HOURS':
        relativeDateRangeText = 'Last 24 hours'
        break
      case 'RESET':
        relativeDateRangeText = ''
        break
      default:
        relativeDateRangeText = capitalize(toLower(relativeDateRange as string)).replace(/_/g, ' ')
    }

    return {
      dateRange,
      error: dateRangeError.error,
      errorMessage: dateRangeError.errorMessage,
      label: formatMessage('data_table.date_range'),
      maxDate: DateTime.now(),
      minDate: DateTime.now().minus({
        days: 180
      }),
      onDateRangeChange,
      selectedShortcutLabel: relativeDateRangeText
    }
  }, [
    dateRange,
    dateRangeError.error,
    dateRangeError.errorMessage,
    formatMessage,
    onDateRangeChange,
    relativeDateRange
  ])

  /*
   * **********************
   * Filtering configs    *
   * **********************
   */
  const handleClearColumnFilters = useCallback(() => {
    dispatch(
      updateUnifiedReportingDataTable({
        filters: []
      })
    )
  }, [dispatch])

  const columnFilters = useMemo(
    () =>
      dataTableFilters.map(filter => {
        return {
          id: filter.fieldName,
          value: filter.value
        }
      }),
    [dataTableFilters]
  )

  const formatDateFilterChipLabel = useCallback((value: string, operation: string) => {
    switch (operation) {
      case FilterOperation.GREATER:
        return `After ${DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT)}`
      case FilterOperation.BETWEEN:
        return `${DateTime.fromISO(value[0]).toLocaleString(DateTime.DATE_SHORT)} to ${DateTime.fromISO(
          value[1]
        ).toLocaleString(DateTime.DATE_SHORT)}`
      case FilterOperation.LESS:
        return `Before ${DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT)}`
      case FilterOperation.DATE_EQUALS:
        return `On ${DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT)}`
      default:
        return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT)
    }
  }, [])

  const formatFilterChipLabel = useCallback(
    (filter: Filter) => {
      const column = columns.find(col => col.accessorKey === filter.fieldName)
      if (column) {
        switch (column.fieldType) {
          case FieldType.DATE:
            return formatDateFilterChipLabel(filter.value, filter.operation)

          case FieldType.BOOLEAN:
            return `${formatMessage(
              `data_table.operators.${filter.operation.toLowerCase()}`
            )} ${filter.value.map((val: any) => formatMessage(`data_table.boolean.${val}`)).join(', ')}`

          case FieldType.ENUM:
            return `${formatMessage(`data_table.operators.${filter.operation.toLowerCase()}`)} ${
              isArray(filter.value)
                ? filter.value
                    .map(val =>
                      formatSENMessage(`column_values.${snakeCase(report.baseDataType)}.${column.header}.${val}`)
                    )
                    .join(', ')
                : formatSENMessage(`column_values.${snakeCase(report.baseDataType)}.${column.header}.${filter.value}`)
            }`

          case FieldType.NUMBER:
            return filter.value.join(' to ')

          case FieldType.KEYWORD:
          case FieldType.TEXT:
            return `${formatMessage(`data_table.operators.${filter.operation.toLowerCase()}`)} ${
              filter.value ? filter.value : ''
            }`

          default:
            return filter.value
        }
      }
      return ''
    },
    [report.baseDataType, columns, formatDateFilterChipLabel, formatSENMessage, formatMessage]
  )

  const columnFilterChips = useMemo(
    () =>
      dataTableFilters.map(filter => ({
        id: filter.fieldName,
        value: formatFilterChipLabel(filter),
        condition: formatMessage(`data_table.operators.${filter.operation.toLowerCase()}`)
      })),
    [dataTableFilters, formatFilterChipLabel, formatMessage]
  )

  const filterConfig = useMemo(
    () => ({
      enableGlobalFilter: true,
      columnFilters,
      columnFilterChips,
      handleRemoveColumnFilter,
      handleClearColumnFilters
    }),
    [columnFilterChips, columnFilters, handleClearColumnFilters, handleRemoveColumnFilter]
  )

  /*
   * ************************
   * Grouping configs       *
   * ************************
   */
  const groupConfig = useMemo(() => {
    return {
      enableGrouping: true,
      enableColumnDragging: true,
      grouping: dataTableGroupBy,
      onGroupingChange: setGrouping
    }
  }, [dataTableGroupBy])

  /*
   * ************************
   * Helper functions       *
   * ************************
   */
  // On change event handler for Top x Results to display
  const onDisplayCountChange = useCallback(
    (e: any) => {
      setState({
        displayTopResultsValue: e.target.value
      })
      dispatch(
        updateUnifiedReportingDataTable({
          topDisplayCount: e.target.value
        })
      )
    },
    [dispatch]
  )

  const recursiveFlattenData = useCallback((structuredData: any[]) => {
    let flattenedData = []
    if (structuredData) {
      flattenedData = structuredData.reduce((acc: any, datum: any) => {
        if (datum.data) {
          acc.push(...recursiveFlattenData(datum.data))
        } else {
          acc.push(datum)
        }
        return acc
      }, [])
    }
    return flattenedData
  }, [])

  /*
   * ********************
   * Page configs       *
   * ********************
   */
  const handlePageChange = useCallback(
    (page: number) => {
      dispatch(
        updateUnifiedReportingDataTable({
          page: {
            skip: (page - 1) * dataTablePage.take,
            take: dataTablePage.take
          }
        })
      )
    },
    [dataTablePage.take, dispatch]
  )

  const handleItemsPerPageChange = useCallback(
    (itemsPerPage: number) => {
      dispatch(
        updateUnifiedReportingDataTable({
          page: {
            skip: 0,
            take: itemsPerPage
          }
        })
      )
    },
    [dispatch]
  )

  const pageConfig: PageConfig = useMemo(
    () => ({
      onPageChange: handlePageChange,
      onItemsPerPageChange: handleItemsPerPageChange,
      skip: dataTablePage.skip,
      take: dataTablePage.take,
      total
    }),
    [dataTablePage.skip, dataTablePage.take, handleItemsPerPageChange, handlePageChange, total]
  )

  /*
   * ********************
   * Search configs    *
   * ********************
   */
  const searchFieldConfig: SearchFieldProps = useMemo(
    () => ({
      id: SEARCH_FIELD_ID,
      value: globalSearchValue,
      onChange: (e: any) => {
        const validatedSearchString = validateSearchString(e.target.value)

        debouncedOnChange(validatedSearchString)
        setState({
          globalSearchValue: validatedSearchString
        })
      },
      onSearch: () => {
        dispatch(
          updateUnifiedReportingDataTable({
            searchQuery: globalSearchValue
          })
        )
      },
      onKeyDown: onKeyDown(['Enter'], () => {
        dispatch(
          updateUnifiedReportingDataTable({
            searchQuery: globalSearchValue
          })
        )
      }),
      disabled: isGetReportsDataPending
    }),
    [globalSearchValue, isGetReportsDataPending, validateSearchString, debouncedOnChange, dispatch]
  )

  const searchConfig = useMemo(
    () => ({
      searchPlaceholder: formatMessage('data_table.search'),
      searchFieldConfig
    }),
    [formatMessage, searchFieldConfig]
  )

  /*
   * ********************
   * Sorting configs    *
   * ********************
   */
  const formatSorting = useCallback((dtSorting: string[]) => {
    return dtSorting.map((sort: string) => {
      const [id, desc] = sort.split(',')
      return {
        id,
        desc: desc === 'desc'
      }
    })
  }, [])

  const sortConfig = useMemo(
    () => ({
      sorting: formatSorting(dataTableSorting),
      setSorting
    }),
    [dataTableSorting, formatSorting]
  )

  /*
   * ********************
   * useEffects         *
   * ********************
   */

  // This useEffect shapes the data for the MRT to show/hide columns
  useEffect(() => {
    const allColumns: any = {}

    columns.forEach(column => {
      allColumns[column.accessorKey] = !report.config.hiddenColumns.includes(column.accessorKey)
    })

    setVisibleColumnsState({
      ...allColumns
    })
  }, [report.config.hiddenColumns, columns])

  // Sorting config
  useEffect(() => {
    dispatch(
      updateUnifiedReportingDataTable({
        sortBy: sorting.map((sort: any) => `${sort.id},${sort.desc ? 'desc' : 'asc'}`)
      })
    )
  }, [dispatch, sorting])

  useEffect(() => {
    onDateRangeChange(dateRange, undefined)
    // We need to use this exception because we don't want to trigger a re-render when onDateRangeChange is recreated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange])

  // Grouping config
  useEffect(() => {
    dispatch(
      updateUnifiedReportingDataTable({
        groupBy: grouping
      })
    )
  }, [dispatch, grouping])

  // Search config
  // TODO: remove this when the search field state is moved to the data table logic
  // Update the search field value when the datatables search query changes
  useEffect(() => {
    if (dataTableSearchQuery) {
      // reset search query when a different report is loaded otherwise persist current search query
      if (isLoadingReports) {
        dispatch(resetSearch())
        setState({
          globalSearchValue: ''
        })
      } else {
        setState({
          globalSearchValue: dataTableSearchQuery
        })
      }
    }
  }, [dataTableSearchQuery, dispatch, isLoadingReports])

  return useMemo(
    () => [
      {
        columnConfig,
        data: recursiveFlattenData(data),
        dateRangeConfig,
        displayTopResultsConfig: {
          menuItems: displayResultsValues,
          value: displayTopResultsValue || report.config.topDisplayCount,
          label: formatMessage('data_table_header.display_top_results.dropdown_header'),
          onDisplayCountChange
        },
        expandConfig: {
          positionExpandColumn: 'first',
          enableExpanding: true,
          enableExpandAll: true,
          expanded,
          onExpandChange: setExpanded
        },
        filterConfig,
        groupConfig,
        isLoading: isLoadingReports && isGetReportsDataPending,
        pageConfig,
        rowActionsConfig: {}, // TODO: add row actions?
        searchConfig,
        sortConfig
      }
    ],
    [
      columnConfig,
      data,
      dateRangeConfig,
      displayResultsValues,
      displayTopResultsValue,
      expanded,
      filterConfig,
      formatMessage,
      groupConfig,
      isGetReportsDataPending,
      isLoadingReports,
      onDisplayCountChange,
      pageConfig,
      recursiveFlattenData,
      report.config.topDisplayCount,
      searchConfig,
      sortConfig
    ]
  )
}
