import React, { useMemo } from 'react'

import { Button, Typography, Grid, Fade, Popover, Switch, TextField } from '@barracuda-internal/bds-core'
import { Link as LinkIcon, SortDescending as ExpandMoreIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import IMAGES from 'global/configs/theme/images.config'
import useCommonAnchorElLogic from 'global/components/lib/anchorElLogic/useAnchorElLogic'
import * as datetime from 'global/lib/datetime'
import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'
import CopyToClipboard from 'global/components/lib/copyToClipboard/CopyToClipboard'

import styles from 'ets/components/pages/dashboard/shareMenu/shareMenuStyles'
import DEFAULTS from 'ets/configs/styles/defaults'
import useShareMenuLogic from 'ets/components/pages/dashboard/shareMenu/useShareMenuLogic'
import PDFReport from 'ets/components/lib/PDFReport/PDFReport'
import useDashboardLogic from 'ets/components/pages/dashboard/useDashboardLogic'

const BASE_I18N_KEY = 'ets.app.dashboard.header'

export const ShareMenu: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [dashboardLogic] = useDashboardLogic()
  const [pdfConfig] = useShareMenuLogic()
  const [anchorEl, handleClick, handleClose] = useCommonAnchorElLogic()

  return useMemo(() => {
    const {
      getReportShareLink,
      getShareLinkExpiration,
      isShareChecked,
      isShareInProgress,
      onShare,
      isButtonHidden,
      sendShareTrackingEvent
    } = dashboardLogic

    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          className={`${classes.shareButton} ${isButtonHidden && classes.hideMastheadButton}`}
          data-action="share"
          endIcon={<ExpandMoreIcon />}
          onClick={event => {
            handleClick(event)
            sendShareTrackingEvent()
          }}
        >
          {formatMessage('share')}
        </Button>
        <Popover
          style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
          id="share-popover"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          keepMounted
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          PaperProps={{
            style: {
              width: '330px',
              paddingBottom: 5,
              overflow: 'hidden'
            }
          }}
        >
          {(isShareInProgress || pdfConfig.isPDFRendering) && <LinearProgress />}
          <Grid container className={classes.shareDropdown} alignItems="center" data-field="share-dropdown">
            <Grid container alignItems="center">
              <Grid item className={classes.menuIcon} xs={1}>
                <LinkIcon />
              </Grid>

              <Grid item xs={4}>
                <Typography className={classes.menuText}>{formatMessage('share_link')}</Typography>
              </Grid>

              <Grid item xs={5}>
                <Grid container alignItems="center" justifyContent="flex-end" className={classes.smallText}>
                  {isShareChecked ? formatMessage('switch.enabled') : formatMessage('switch.disabled')}
                </Grid>
              </Grid>

              <Grid item xs={2}>
                <Grid container alignItems="center" justifyContent="flex-end">
                  <Switch
                    onChange={onShare}
                    checked={isShareChecked}
                    disabled={isShareInProgress}
                    size="small"
                    color="primary"
                  />
                </Grid>
              </Grid>
            </Grid>

            {isShareChecked && (
              <Grid container className={classes.shareUrlGroup} direction="row">
                <Grid item xs={10}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <TextField
                        inputProps={{
                          readOnly: true,
                          style: {
                            fontSize: '14px',
                            height: '16px'
                          }
                        }}
                        FormHelperTextProps={{
                          style: {
                            fontStyle: 'italic',
                            marginLeft: '0px',
                            whiteSpace: 'nowrap'
                          }
                        }}
                        className={classes.shareUrl}
                        id="share-url"
                        defaultValue={getReportShareLink()}
                        helperText={formatMessage('share_expires', {
                          expiration: datetime.formatDate(
                            getShareLinkExpiration(),
                            config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT
                          )
                        })}
                        variant="outlined"
                        disabled={isShareInProgress}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid className={classes.copyToClipboard} item xs>
                  <Grid container alignItems="center">
                    <Grid item>
                      <CopyToClipboard
                        textToCopy={getReportShareLink()}
                        positionCopiedMessageToRight
                        customButtonText={formatMessage('copy')}
                        tooltip={formatMessage('copy_to_clipboard')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid
              className={classes[pdfConfig.isButtonDisabled ? 'menuWrapperDisabled' : 'menuWrapper']}
              container
              alignItems="center"
              role="presentation"
              onClick={pdfConfig.onClick}
            >
              <Grid item className={classes.menuIcon} xs={1}>
                <img alt="" src={IMAGES.pdfIcon} />
              </Grid>

              <Grid item xs={4}>
                <Typography
                  className={classes.menuText}
                  style={{ cursor: pdfConfig.isButtonDisabled ? 'default' : 'pointer' }}
                >
                  {formatMessage('pdf_export')}
                </Typography>
              </Grid>
              {pdfConfig.isPDFReportRequested && (
                <div data-pdf-report="true">
                  <PDFReport
                    isUIReportsLoaded={pdfConfig.isUIReportsLoaded}
                    setPDFRenderingStatus={pdfConfig.setPDFRenderingStatus}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Popover>
      </>
    )
  }, [
    dashboardLogic,
    classes,
    formatMessage,
    anchorEl,
    handleClose,
    pdfConfig.isPDFRendering,
    pdfConfig.isButtonDisabled,
    pdfConfig.onClick,
    pdfConfig.isPDFReportRequested,
    pdfConfig.isUIReportsLoaded,
    pdfConfig.setPDFRenderingStatus,
    handleClick
  ])
}

export default ShareMenu
