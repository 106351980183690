import React from 'react'
import { Routes, Route } from 'react-router-dom'

import routesConfig from 'ets/lib/routes/routesConfig'
import Layout from 'ets/components/lib/layout/Layout'

import PublicRoute from 'ets/components/lib/routes/PublicRoute'
import PrivateRoute from 'ets/components/lib/routes/PrivateRoute'

import SigninSignupPage from 'ets/components/pages/signinSignup/SigninSignup'
import SigninConnect from 'ets/components/pages/signinSignup/SigninConnect'
import SignupComplete from 'ets/components/pages/signinSignup/SignupComplete'
import SignupPolicy from 'ets/components/pages/signinSignup/SignupPolicy'

import HowManyScans from 'ets/components/pages/howManyScans/HowManyScans'

import Dashboard from 'ets/components/pages/dashboard/Dashboard'
import EmptyReport from 'ets/components/pages/emptyReport/EmptyReport'
import Shared from 'ets/components/pages/shared/Shared'

import UnregisteredPage from 'ets/components/pages/unregisteredPage/UnregisteredPage'
import MyLink from 'ets/components/pages/myLink/MyLink'
import MyLinkAWS from 'ets/components/pages/myLink/MyLinkAWS'
import Techdata from 'ets/components/pages/techdata/Techdata'
import Arrow from 'ets/components/pages/arrow/Arrow'
import Synnex from 'ets/components/pages/synnex/Synnex'
import { UiRoute } from 'global/lib/routes/routesConfig'

type DisableUserCheck = boolean
export type RouteParams = [UiRoute, React.FC<any>, DisableUserCheck?]

const publicRoutes: RouteParams[] = [
  [routesConfig.SIGNIN_SIGNUP, SigninSignupPage],
  [routesConfig.SIGNIN_CONNECT, SigninConnect, true],
  [routesConfig.SIGNUP_COMPLETE, SignupComplete],
  [routesConfig.SIGNUP_COMPLETE_POLICY, SignupPolicy],
  [routesConfig.SHARED, Shared],
  [routesConfig.MYLINK, MyLink, true],
  [routesConfig.MYLINK_AWS_VAR, MyLinkAWS, true],
  [routesConfig.TECHDATA, Techdata, true],
  [routesConfig.ARROW, Arrow, true],
  [routesConfig.SYNNEX, Synnex, true],
  [routesConfig.HOW_MANY_SCANS, HowManyScans, true],
  [routesConfig.HOW_MANY_SCANS_PARTNER_PORTAL, HowManyScans, true]
]

const AppRoutes = (
  <Routes>
    {/* Public routes */}
    {publicRoutes
      .filter(routeparams => routeparams[0])
      .map(routeParams => {
        const [routeConfig, Component, disableUserCheck] = routeParams

        return (
          <Route
            key={routeConfig.id}
            path={routeConfig.path}
            element={<PublicRoute route={routeConfig} Component={Component} disableUserCheck={disableUserCheck} />}
          />
        )
      })}

    <Route
      path={routesConfig.DASHBOARD.path}
      element={<PrivateRoute route={routesConfig.DASHBOARD} Component={Dashboard} />}
    />
    <Route
      path={routesConfig.EMPTY_REPORT.path}
      element={<PrivateRoute route={routesConfig.EMPTY_REPORT} Component={EmptyReport} />}
    />

    <Route path="*" element={<PublicRoute route={routesConfig.SIGNIN_SIGNUP} Component={UnregisteredPage} />} />
  </Routes>
)

export default AppRoutes
