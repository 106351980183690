import React, { useMemo } from 'react'

import { Button, DialogContent, Grid } from '@barracuda-internal/bds-core'

import TabPanel from 'global/components/lib/tabPanel/TabPanel'
import Tabs from 'global/components/lib/Tabs/Tabs'
import { useFormatMessage } from 'global/lib/localization'

import Layout from 'sen/components/lib/layout/Layout'
import SpearPhishingTab from 'sen/components/pages/settings/spearPhishingTab/SpearPhishingTab'
import AtoTab from 'sen/components/pages/settings/atoTab/AtoTab'
import AllowedSendersTab from 'sen/components/pages/settings/allowedSendersTab/AllowedSendersTab'
import SyslogIntegrationTab from 'sen/components/pages/settings/syslogIntegrationTab/SyslogIntegrationTab'
import useSettingsLogic from 'sen/components/pages/settings/useSettingsLogic'
import sentintelContentLoader from 'sen/components/lib/useSentinelContentCondition/SentinelContentLoader'

import settingsStyles from './settingsStyles'

const BASE_I18N_KEY = 'sen.app.settings'

const Settings: React.FC<{}> = () => {
  const settingsClasses = settingsStyles()
  const [settingsLogic] = useSettingsLogic()
  const { backButton, isSaveButtonDisabled, onSave, settingsTabsConfig, showSaveButton, tabsConfig } = settingsLogic
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Layout
        pageTitle={formatMessage('page_title')}
        rightContent={
          <>
            {backButton.showBackButton && (
              <Button
                className={settingsClasses.mastHeadButton}
                onClick={backButton.handleBackButton}
                color="primary"
                variant="outlined"
              >
                {formatMessage('buttons.back')}
              </Button>
            )}
            {showSaveButton && (
              <Button
                className={settingsClasses.mastHeadButton}
                onClick={onSave}
                disabled={isSaveButtonDisabled}
                color="primary"
                variant="contained"
              >
                {formatMessage('buttons.save')}
              </Button>
            )}
          </>
        }
      >
        <Grid container>
          <Grid item xs={3}>
            <Tabs {...tabsConfig} />
          </Grid>
          <Grid item xs={9}>
            <DialogContent>
              {[SpearPhishingTab, AtoTab, AllowedSendersTab, SyslogIntegrationTab].map((TabComponent, index) => (
                <TabPanel
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  index={index}
                  value={settingsTabsConfig.activeTab as number}
                >
                  <TabComponent updateTabSettings={settingsTabsConfig.updateTabSettings} />
                </TabPanel>
              ))}
            </DialogContent>
          </Grid>
        </Grid>
      </Layout>
    ),
    [
      backButton.handleBackButton,
      backButton.showBackButton,
      formatMessage,
      isSaveButtonDisabled,
      onSave,
      settingsClasses.mastHeadButton,
      settingsTabsConfig.activeTab,
      settingsTabsConfig.updateTabSettings,
      showSaveButton,
      tabsConfig
    ]
  )
}

export default sentintelContentLoader({
  default: () => <Settings />,
  scanInProgress: () => <Settings />
})
