import { useMemo, useEffect, useCallback } from 'react'

import { useAppSelector } from 'global/redux/toolkit/hooks'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useTabs, { BaseTabsConfig } from 'global/lib/useTabs/useTabs'
import { UiRoute } from 'global/lib/routes/routesConfig'
import { useFormatMessage } from 'global/lib/localization'

export type I18nKey = string
export type TabConfig = [UiRoute[], I18nKey, React.FC?]
export interface UseTabContentSelectorLogicParams {
  BASE_I18N_KEY: string
  tabs: TabConfig[]
  analyticsEventName?: string
  changePageTitle?: boolean
}

export default function useTabContentSelectorLogic(params: UseTabContentSelectorLogicParams): [BaseTabsConfig] {
  const formatMessage = useFormatMessage('admin.app')

  const { tabs, analyticsEventName } = params
  const { accessTokenId, activePathId, activePathParams } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    activePathId: _stores.app.activePath?.id,
    activePathParams: _stores.app.activePath?.params
  }))

  const [selectedTab, onTabSelected] = useTabs(
    tabs.reduce((currentIdx, tab, idx) => {
      const [uiRoutes] = tab
      return uiRoutes.some(uiRoute => uiRoute.id === activePathId) ? idx : currentIdx
    }, 0)
  )

  // init
  useEffect(() => {
    if (params.changePageTitle) {
      const i18nKey = tabs[selectedTab || 0][1]
      document.title = formatMessage(`tabs.${i18nKey}_title`)
    }
    if (analyticsEventName) {
      analyticsLib.trackAppEvent(analyticsEventName, {
        accessTokenId,
        tab: selectedTab,
        url: window.location.href
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsEventName])

  const handleTabSelect = useCallback(
    (e: any, newTab: any) => {
      onTabSelected(e, newTab)
      const [uiRoute, i18nKey] = tabs[newTab]
      if (params.changePageTitle) {
        document.title = formatMessage(`tabs.${i18nKey}_title`)
      }
      uiRoute[0].softGoto(activePathParams)
    },
    [tabs, onTabSelected, activePathParams, formatMessage, params.changePageTitle]
  )

  return useMemo(() => {
    return [
      {
        selectedTab,
        onTabSelected: handleTabSelect
      }
    ]
  }, [selectedTab, handleTabSelect])
}
