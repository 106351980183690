export const CUSTOM_TEMPLATE_NAME = 'Custom'

/* eslint-disable @typescript-eslint/naming-convention */
export const DEFAULT_NOTIFICATION_PREVIEW_CONTEXT = {
  introduction: '',
  sender_email: 'SENDER EMAIL',
  sender_name: 'SENDER NAME',
  signature: '',
  body_text: 'MESSAGE BODY TEXT',
  body_links: 'MESSAGE BODY URL',
  subject: 'SUBJECT'
}
/* eslint-enable @typescript-eslint/naming-convention */

export const DEFAULT_TEMPLATE_NAME = 'Default'

export enum SenderPolicyActions {
  block = 'block',
  quarantine = 'quarantine'
}

export enum SenderPolicyTypes {
  domains = 'domains',
  senders = 'senders'
}

export enum SiemApps {
  forensics = 'FIR',
  sentinel = 'SEN'
}

export interface CreateCustomNotificationTemplate {
  accessTokenId: string
  template: CustomTemplate
}

export interface CustomNotificationPreviewResponse {
  editEmailFormValues: EditEmailFormValues
  notificationPreview: NotificationPreview
}

export interface CustomTemplate {
  [key: string]: string | undefined
  introduction?: string | undefined
  name: string
  signature?: string | undefined
  subject?: string | undefined
}

export interface DeleteCustomNotificationTemplate {
  accessTokenId: string
  templateName: string
}

export interface EditEmailFormValues {
  formIntro: string
  formSignature: string
  formSubject: string
}

/* eslint-disable @typescript-eslint/naming-convention */
export interface ForensicsNotificationPreview {
  customSubject?: string | null
  emailDeleted: boolean
  introduction?: string | null
  sender_email?: string
  sender_name?: string
  body_text?: string
  body_links?: string
  signature?: string | null
  subject?: string
}
/* eslint-enable @typescript-eslint/naming-convention */
export interface ForensicsSettings {
  [key: string]: any
  forensicsAutomaticIncidentAlertAdmin?: boolean
  forensicsAutomaticIncidentAlertEndUser?: boolean
  forensicsAutomaticIncidentDeleteEmails?: boolean
  forensicsAutomaticIncidentContinuousRemediation?: boolean
  forensicsAutomaticIncidentNotifyAddress?: string
  forensicsAutomatedWorkflowDeleteEmails?: boolean
  forensicsAutomatedWorkflowNotifyAddress?: string
  forensicsAutomatedWorkflowMSTeamsWebhook?: string
  forensicsAutomatedWorkflowSlackWebhook?: string
  forensicsAutomatedWorkflowSyncAddress?: boolean
  forensicsEndUserNotificationTemplate?: string
  forensicsIncidentAlertAdmin?: boolean
  forensicsIncidentAlertEndUser?: boolean
  forensicsIncidentAutoRemediateEmails?: boolean
  forensicsIncidentContentShieldPolicy?: boolean
  forensicsIncidentContinuousRemediation?: boolean
  forensicsIncidentDeleteEmails?: boolean
  forensicsAutomaticIncidentIgnorePhishline?: boolean
  forensicsIncidentSenderPolicy?: boolean
  forensicsIncidentSenderPolicyAction?: string
  forensicsIncidentSenderPolicyType?: string
  forensicsNotifyAddress?: string
  forensicsPostDeliveryNotifications?: boolean
  forensicsPostDeliveryNotifyAddress?: string
  forensicsPostDeliverySyncAddress?: boolean
  forensicsUserReportedIgnoreSatCampaigns?: boolean
  forensicsUserReportedNotifications?: boolean
  forensicsUserReportedNotifyAddress?: string
  forensicsUserReportedSyncAddress?: boolean
}

export interface GetNotificationPreview {
  accessTokenId: string
  context: ForensicsNotificationPreview
}

export interface GetSiemSettings {
  accessTokenId: string
  app?: string
}

export interface NotificationPreview {
  body: string
  subject: string
}

export interface SaveAccessTokenSettings {
  accessTokenId: string
  settings: ForensicsSettings
}

export interface SaveSiemSettings {
  accessTokenId: string
  app?: string
  siemSettings: SiemSettings
}

export interface SiemSettings {
  isActive?: boolean
  syslogHost?: string
  syslogPort?: number
}
