import { useCallback, useEffect, useMemo, useReducer } from 'react'
import { useSelector } from 'react-redux'
import validator from 'validator'

import { config } from 'global/lib/config'
import { AlertProps } from 'global/components/lib/alerts/Alert'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import useFeatureLib from 'global/lib/feature/useFeature'
import { useErrorFormatMessage } from 'global/lib/localization'
import useProductLib from 'global/lib/product/useProduct'
import useUserDataLib from 'global/lib/userData/useUserData'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { SettingsValue } from 'global/types/api/accessTokenType'
import { isPending } from 'global/redux/toolkit/api'

import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import {
  displayCustomizeEmailAlertDialog,
  getForensicsSiemSettings,
  resetClearErrors,
  resetSettingsDemoAlert,
  resetSettingsFailures,
  resetSiemSettings,
  saveAccessTokenSettings,
  updateAccessTokenSettings
} from 'fir/redux/features/settings/settingsSlice'
import { ForensicsSettings, SenderPolicyActions } from 'fir/redux/types/Settings'

const DEFAULT_MS_TEAMS_WEBHOOK = 'https://test-19.test.logic.azure.com:443/workflows/'
const DELAY_SAVE_INTERVAL = 1000
const { VALID_SLACK_WEBHOOK_STARTS, VALID_MSTEAMS_WEBHOOK_CONTAINS } = config.VALIDATORS

export const REMEDIATION = 'remediation'
export const POLICY = 'policy'
export const SIEM = 'syslog'

interface Tracking {
  field: string
  tab: string
  value: string
}

export interface SettingsTabsParams {
  activeSettings: string
}

export interface SettingsTabsLogicProps {
  activeSettings: string
  alertConfig: AlertProps
  contentConfig: any
  errorMsg?: string
  isCustomizeEmailDialogOpen?: boolean
  isSaveDisabled?: boolean
  isSaveLoading?: boolean
  openCustomizeEmailAlertDialog?: () => void
}

export default function useSettingsTabsLogic(params: SettingsTabsParams): [SettingsTabsLogicProps] {
  const dispatch = useAppDispatch()
  const { notificationEmail }: any = useSelector((_stores: any) => ({
    notificationEmail: _stores.user.data.email
  }))

  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    checkNotificationEmail: true,
    notificationEmail,
    // placeholders for first render don't show error hint
    slackWebhook: VALID_SLACK_WEBHOOK_STARTS,
    msteamsWebhook: DEFAULT_MS_TEAMS_WEBHOOK
  })

  const formatErrorMessage = useErrorFormatMessage()
  const [accessTokenLib] = useAccessTokenLib()
  const [featureLib] = useFeatureLib()
  const [productLib] = useProductLib()
  const [userDataLib] = useUserDataLib()
  let timer: any = null

  // Redux Toolkit stores
  const { accessTokenId, errorMessage, settings } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    settings: _stores.settings,
    errorMessage: _stores.settings.errorMsg
  }))

  // settings state
  const { forensicsSettings, isClearSettingsErrors, isCustomizeEmailAlertOpened, isSaveSettingsLoading } = {
    forensicsSettings: settings.forensics,
    isClearSettingsErrors: settings.isClearSettingsErrors,
    isCustomizeEmailAlertOpened: settings.isCustomizeEmailAlertOpened,
    isSaveSettingsLoading: isPending(settings.saveAccessTokenSettingsApiStatus)
  }

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    setInitialSettingsConfig()

    if (settings.forensics.forensicsAutomatedWorkflowMSTeamsWebhook) {
      setState({
        msteamsWebhook: settings.forensics.forensicsAutomatedWorkflowMSTeamsWebhook
      })
    }

    dispatch(getForensicsSiemSettings({ accessTokenId }))

    return () => {
      dispatch(resetSiemSettings())
      dispatch(resetSettingsFailures())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.activeSettings])

  // set isInvalidEmail to false on tab change
  useEffect(() => {
    if (isClearSettingsErrors) {
      setState({ checkNotificationEmail: false })
      dispatch(resetClearErrors())
    }
  }, [dispatch, isClearSettingsErrors])

  const updateAndSaveSettingsTrackAppEvent = useCallback(
    (fieldName: string, tab: string, value: string) => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.updateSettings(fieldName), {
        accessTokenId,
        page: 'settings',
        tab,
        value
      })
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.SAVE_SETTINGS, {
        accessTokenId,
        page: 'settings',
        tab
      })
    },
    [accessTokenId]
  )

  const delaySaveAndTrackSettings = useCallback((payload: ForensicsSettings, tracking: Tracking) => {
    clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timer = setTimeout(() => {
      dispatch(saveAccessTokenSettings({ accessTokenId, settings: payload }))
      updateAndSaveSettingsTrackAppEvent(tracking.field, tracking.tab, tracking.value)
    }, DELAY_SAVE_INTERVAL)
  }, [])

  const isBasicProduct = useMemo(() => {
    return productLib.getForensicsSerialBundleForAccessToken(accessTokenId) === config.BUNDLES.BUNDLE1
  }, [accessTokenId, productLib])

  const isInvalidEmail = useMemo(() => {
    return (
      state.checkNotificationEmail &&
      (typeof state.notificationEmail !== 'string' || !validator.isEmail(state.notificationEmail))
    )
  }, [state.checkNotificationEmail, state.notificationEmail])

  const isInvalidSlackWebhook = useMemo(() => {
    return !state.slackWebhook.startsWith(VALID_SLACK_WEBHOOK_STARTS) && state.slackWebhook !== ''
  }, [state.slackWebhook])

  const isInvalidMSTeamsWebhook = useMemo(() => {
    return state.msteamsWebhook.search(VALID_MSTEAMS_WEBHOOK_CONTAINS) === -1 && state.msteamsWebhook !== ''
  }, [state.msteamsWebhook])

  const isSaveDisabled = useMemo(() => {
    return isInvalidEmail || isSaveSettingsLoading
  }, [isInvalidEmail, isSaveSettingsLoading])

  const updateAndSaveForensicsSettingsOptions = useCallback(
    (payload: ForensicsSettings, tracking: Tracking, saveDelay = false) => {
      let settingsToUpdate = payload

      // If the forensicsNotifyAddress changes update forensicsUserReportedNotifyAddress and forensicsPostDeliveryNotifyAddress
      if (payload.forensicsNotifyAddress) {
        settingsToUpdate = {
          ...payload,
          forensicsUserReportedNotifyAddress: forensicsSettings.forensicsUserReportedSyncAddress
            ? payload.forensicsNotifyAddress
            : forensicsSettings.forensicsUserReportedNotifyAddress,
          forensicsPostDeliveryNotifyAddress: forensicsSettings.forensicsPostDeliverySyncAddress
            ? payload.forensicsNotifyAddress
            : forensicsSettings.forensicsPostDeliveryNotifyAddress
        }
      }

      // If the forensicsAutomaticIncidentNotifyAddress changes update forensicsAutomatedWorkflowNotifyAddress
      if (payload.forensicsAutomaticIncidentNotifyAddress) {
        if (isBasicProduct) {
          settingsToUpdate = {
            ...payload,
            forensicsUserReportedNotifyAddress: forensicsSettings.forensicsUserReportedSyncAddress
              ? payload.forensicsAutomaticIncidentNotifyAddress
              : forensicsSettings.forensicsUserReportedNotifyAddress
          }
        } else {
          settingsToUpdate = {
            ...payload,
            forensicsAutomatedWorkflowNotifyAddress: forensicsSettings.forensicsAutomatedWorkflowSyncAddress
              ? payload.forensicsAutomaticIncidentNotifyAddress
              : forensicsSettings.forensicsAutomatedWorkflowNotifyAddress
          }
        }
      }

      dispatch(updateAccessTokenSettings(settingsToUpdate))

      if (saveDelay) {
        const isUpdatingMSTeamsWebhook = tracking.field.indexOf('MSTeamsWebhook') !== -1
        const isUpdatingNotifyAddress = tracking.field.indexOf('NotifyAddress') !== -1
        const isUpdatingSlackWebhook = tracking.field.indexOf('SlackWebhook') !== -1
        const validNotifyAddress =
          isUpdatingNotifyAddress &&
          typeof payload[tracking.field] === 'string' &&
          validator.isEmail(payload[tracking.field])
        const validSlackWebhook =
          isUpdatingSlackWebhook &&
          (payload[tracking.field].startsWith(VALID_SLACK_WEBHOOK_STARTS) || payload[tracking.field].length === 0)
        const validMSTeamsWebhook =
          isUpdatingMSTeamsWebhook &&
          (!(payload[tracking.field].search(VALID_MSTEAMS_WEBHOOK_CONTAINS) === -1) ||
            payload[tracking.field].length === 0)

        // only want to save if not an email/slack field or if the email/slack field is valid
        if (
          (!isUpdatingNotifyAddress || validNotifyAddress) &&
          (!isUpdatingSlackWebhook || validSlackWebhook) &&
          (!isUpdatingMSTeamsWebhook || validMSTeamsWebhook)
        ) {
          // URL Decode parameters when sending new Microsoft Teams workflow webhook
          if (payload.forensicsAutomatedWorkflowMSTeamsWebhook) {
            settingsToUpdate = {
              ...payload,
              forensicsAutomatedWorkflowMSTeamsWebhook: decodeURIComponent(
                payload.forensicsAutomatedWorkflowMSTeamsWebhook
              )
            }
          }
          delaySaveAndTrackSettings(settingsToUpdate, tracking)
        }
      } else {
        dispatch(saveAccessTokenSettings({ accessTokenId, settings: settingsToUpdate }))
        updateAndSaveSettingsTrackAppEvent(tracking.field, tracking.tab, tracking.value)
      }
    },
    [
      accessTokenId,
      delaySaveAndTrackSettings,
      dispatch,
      forensicsSettings,
      isBasicProduct,
      updateAndSaveSettingsTrackAppEvent
    ]
  )

  const contentConfig = useMemo(() => {
    return {
      automatedWorkflowSettingsOptions: {
        forensicsSettings,
        isInvalidEmail,
        isInvalidSlackWebhook,
        isInvalidMSTeamsWebhook,
        updateAutomatedWorkflowSettingsOptions: (formProp: string, isCheckbox = false) => (e: any) => {
          const settingValue = isCheckbox ? e.target.checked.toString() : e.target.value
          if (formProp === 'forensicsAutomatedWorkflowNotifyAddress') {
            setState({ checkNotificationEmail: true, notificationEmail: e.target.value })
          } else if (formProp === 'forensicsAutomatedWorkflowSlackWebhook') {
            setState({ slackWebhook: e.target.value })
          } else if (formProp === 'forensicsAutomatedWorkflowMSTeamsWebhook') {
            setState({ msteamsWebhook: e.target.value })
          } else if (formProp === 'forensicsAutomatedWorkflowSyncAddress') {
            setState({ checkNotificationEmail: !e.target.checked })
            if (e.target.checked) {
              setState({ notificationEmail: forensicsSettings.forensicsAutomaticIncidentNotifyAddress })
            }
          }
          updateAndSaveForensicsSettingsOptions(
            {
              [formProp]: e.target[isCheckbox ? 'checked' : 'value'],
              ...(formProp === 'forensicsAutomatedWorkflowSyncAddress' &&
                e.target.checked && {
                  forensicsAutomatedWorkflowNotifyAddress: forensicsSettings.forensicsAutomaticIncidentNotifyAddress
                })
            },
            { field: formProp, tab: 'automated-workflows', value: settingValue },
            e.target.type === 'text'
          )
        }
      },
      automaticRemediationSettingsOptions: {
        forensicsSettings,
        isBasicProduct,
        isInvalidEmail,
        openCustomizeEmailAlertDialog: () => {
          dispatch(displayCustomizeEmailAlertDialog(true))
        },
        updateAutomaticRemediationSettingsOptions: (formProp: string, isCheckbox = false) => (e: any) => {
          const settingValue = isCheckbox ? e.target.checked.toString() : e.target.value
          if (formProp === 'forensicsAutomaticIncidentDeleteEmails') {
            updateAndSaveForensicsSettingsOptions(
              {
                [formProp]: e.target.value === 'true',
                ...((e.target.value === 'false' && { forensicsAutomaticIncidentContinuousRemediation: false }) as any)
              },
              { field: formProp, tab: 'automatic-remediation', value: settingValue }
            )
          } else {
            if (formProp === 'forensicsAutomaticIncidentNotifyAddress') {
              setState({ checkNotificationEmail: true, notificationEmail: e.target.value })
            } else if (formProp === 'forensicsAutomaticIncidentAlertAdmin') {
              setState({ checkNotificationEmail: e.target.checked })
            }
            updateAndSaveForensicsSettingsOptions(
              {
                [formProp]: e.target[isCheckbox ? 'checked' : 'value']
              },
              { field: formProp, tab: 'automatic-remediation', value: settingValue },
              e.target.type === 'text'
            )
          }
        }
      },
      policySettingsOptions: {
        forensicsSettings,
        updatePolicySettingsOptions: (formProp: string, isCheckbox = false) => (e: any) => {
          const settingValue = isCheckbox ? e.target.checked.toString() : e.target.value
          updateAndSaveForensicsSettingsOptions(
            { [formProp]: e.target[isCheckbox ? 'checked' : 'value'] },
            { field: formProp, tab: 'policy-options', value: settingValue }
          )
        },
        isEntitledESS: featureLib.hasESSFeature(accessTokenId),
        isEntitledBCS: featureLib.hasBCSFeature(accessTokenId)
      },
      potentialIncidentSettingsOptions: {
        forensicsSettings,
        isInvalidEmail,
        updatePotentialIncidentSettingsOptions: (formProp: string, isCheckbox = false) => (e: any) => {
          const settingValue = isCheckbox ? e.target.checked.toString() : e.target.value
          if (formProp === 'forensicsPostDeliveryNotifyAddress') {
            setState({ checkNotificationEmail: true, notificationEmail: e.target.value })
          } else if (formProp === 'forensicsPostDeliverySyncAddress') {
            setState({ checkNotificationEmail: !e.target.checked })
            if (e.target.checked) {
              setState({ notificationEmail: forensicsSettings.forensicsNotifyAddress })
            }
          }
          updateAndSaveForensicsSettingsOptions(
            {
              [formProp]: e.target[isCheckbox ? 'checked' : 'value'],
              ...(formProp === 'forensicsPostDeliverySyncAddress' &&
                e.target.checked && {
                  forensicsPostDeliveryNotifyAddress: forensicsSettings.forensicsNotifyAddress
                }),
              ...((formProp === 'forensicsPostDeliveryNotifications' &&
                !e.target.checked && { forensicsPostDeliverySyncAddress: false }) as any)
            },
            { field: formProp, tab: 'potential-incidents', value: settingValue },
            e.target.type === 'text'
          )
        }
      },
      remediationSettingsOptions: {
        forensicsSettings,
        isInvalidEmail,
        openCustomizeEmailAlertDialog: () => {
          dispatch(displayCustomizeEmailAlertDialog(true))
        },
        updateRemediationSettingsOptions: (formProp: string, isCheckbox = false) => (e: any) => {
          const settingValue = isCheckbox ? e.target.checked.toString() : e.target.value
          if (formProp === 'forensicsNotifyAddress') {
            setState({ checkNotificationEmail: true, notificationEmail: e.target.value })
          } else if (formProp === 'forensicsIncidentAlertAdmin') {
            setState({ checkNotificationEmail: e.target.checked })
          }
          updateAndSaveForensicsSettingsOptions(
            {
              [formProp]: e.target[isCheckbox ? 'checked' : 'value'],
              ...((formProp === 'forensicsIncidentDeleteEmails' &&
                !e.target.checked && { forensicsIncidentContinuousRemediation: false }) as any)
            },
            { field: formProp, tab: 'manual-remediation', value: settingValue },
            e.target.type === 'text'
          )
        }
      },
      userReportedSettingsOptions: {
        forensicsSettings,
        isBasicProduct,
        isInvalidEmail,
        updateUserReportedSettingsOptions: (formProp: string, isCheckbox = false) => (e: any) => {
          const settingValue = isCheckbox ? e.target.checked.toString() : e.target.value
          if (formProp === 'forensicsUserReportedNotifyAddress') {
            setState({ checkNotificationEmail: true, notificationEmail: e.target.value })
          } else if (formProp === 'forensicsUserReportedSyncAddress') {
            setState({ checkNotificationEmail: !e.target.checked })
            if (e.target.checked) {
              setState({ notificationEmail: forensicsSettings.forensicsNotifyAddress })
            }
          }
          updateAndSaveForensicsSettingsOptions(
            {
              [formProp]: e.target[isCheckbox ? 'checked' : 'value'],
              ...(formProp === 'forensicsUserReportedSyncAddress' &&
                e.target.checked && {
                  forensicsUserReportedNotifyAddress: isBasicProduct
                    ? forensicsSettings.forensicsAutomaticIncidentNotifyAddress
                    : forensicsSettings.forensicsNotifyAddress
                }),
              ...((formProp === 'forensicsUserReportedNotifications' &&
                !e.target.checked && { forensicsUserReportedSyncAddress: false }) as any)
            },
            { field: formProp, tab: 'user-reported', value: settingValue },
            e.target.type === 'text'
          )
        }
      }
    }
  }, [
    forensicsSettings,
    isInvalidEmail,
    isInvalidSlackWebhook,
    isInvalidMSTeamsWebhook,
    isBasicProduct,
    featureLib,
    accessTokenId,
    updateAndSaveForensicsSettingsOptions,
    dispatch
  ])

  const openCustomizeEmailAlertDialog = useCallback(() => {
    dispatch(resetSettingsFailures())
    dispatch(displayCustomizeEmailAlertDialog(true))
  }, [dispatch])

  const setInitialSettingsConfig = useCallback(() => {
    const currentSettings = accessTokenLib.getCurrentSettings() || {}
    const defaultNotifyAddress = userDataLib.getUser().email
    const hasBCS = featureLib.hasBCSFeature(accessTokenId)
    const hasESS = featureLib.hasESSFeature(accessTokenId)
    const forensicsAutomaticIncidentNotifyAddress =
      currentSettings.forensicsAutomaticIncidentNotifyAddress || defaultNotifyAddress
    const forensicsNotifyAddress = isBasicProduct
      ? forensicsAutomaticIncidentNotifyAddress
      : currentSettings.forensicsNotifyAddress || defaultNotifyAddress
    const forensicsAutomatedWorkflowNotifyAddress =
      currentSettings.forensicsAutomatedWorkflowNotifyAddress || defaultNotifyAddress
    const forensicsPostDeliveryNotifyAddress =
      currentSettings.forensicsPostDeliveryNotifyAddress || defaultNotifyAddress
    const forensicsUserReportedNotifyAddress =
      currentSettings.forensicsUserReportedNotifyAddress || defaultNotifyAddress
    const addDefaultNotifyEmails = !(
      currentSettings.forensicsAutomatedWorkflowNotifyAddress &&
      currentSettings.forensicsAutomaticIncidentNotifyAddress &&
      currentSettings.forensicsNotifyAddress &&
      currentSettings.forensicsPostDeliveryNotifyAddress &&
      currentSettings.forensicsUserReportedNotifyAddress
    )

    function isSettingEnabled(setting: string) {
      return setting ? setting === SettingsValue.on : false
    }

    const initializedSettings = {
      forensicsAutomatedWorkflowNotifyAddress: isSettingEnabled(currentSettings.forensicsAutomatedWorkflowSyncAddress)
        ? forensicsAutomaticIncidentNotifyAddress
        : forensicsAutomatedWorkflowNotifyAddress,
      forensicsAutomatedWorkflowSlackWebhook: currentSettings.forensicsAutomatedWorkflowSlackWebhook,
      forensicsAutomatedWorkflowMSTeamsWebhook: currentSettings.forensicsAutomatedWorkflowMSTeamsWebhook,
      forensicsAutomatedWorkflowSyncAddress: isSettingEnabled(currentSettings.forensicsAutomatedWorkflowSyncAddress),
      forensicsEndUserNotificationTemplate: currentSettings.forensicsEndUserNotificationTemplate,
      forensicsIncidentDeleteEmails: isSettingEnabled(currentSettings.forensicsIncidentDeleteEmails),
      forensicsIncidentContentShieldPolicy:
        !!hasBCS && isSettingEnabled(currentSettings.forensicsIncidentContentShieldPolicy),
      forensicsIncidentContinuousRemediation: isSettingEnabled(currentSettings.forensicsIncidentContinuousRemediation),
      forensicsIncidentAlertEndUser: isSettingEnabled(currentSettings.forensicsIncidentAlertEndUser),
      forensicsIncidentAlertAdmin: isSettingEnabled(currentSettings.forensicsIncidentAlertAdmin),
      forensicsIncidentAutoRemediateEmails: isSettingEnabled(currentSettings.forensicsIncidentAutoRemediateEmails),
      forensicsAutomaticIncidentIgnorePhishline: isSettingEnabled(
        currentSettings.forensicsAutomaticIncidentIgnorePhishline
      ),
      forensicsIncidentSenderPolicy: !!hasESS && isSettingEnabled(currentSettings.forensicsIncidentSenderPolicy),
      ...(currentSettings.forensicsIncidentSenderPolicyAction && {
        forensicsIncidentSenderPolicyAction:
          currentSettings.forensicsIncidentSenderPolicy === SettingsValue.on
            ? currentSettings.forensicsIncidentSenderPolicyAction
            : SenderPolicyActions.quarantine
      }),
      ...(currentSettings.forensicsIncidentSenderPolicyType && {
        forensicsIncidentSenderPolicyType: currentSettings.forensicsIncidentSenderPolicyType
      }),
      forensicsAutomaticIncidentAlertAdmin: isSettingEnabled(currentSettings.forensicsAutomaticIncidentAlertAdmin),
      forensicsAutomaticIncidentDeleteEmails: isSettingEnabled(currentSettings.forensicsAutomaticIncidentDeleteEmails),
      forensicsAutomaticIncidentContinuousRemediation: isSettingEnabled(
        currentSettings.forensicsAutomaticIncidentContinuousRemediation
      ),
      forensicsAutomaticIncidentAlertEndUser: isSettingEnabled(currentSettings.forensicsAutomaticIncidentAlertEndUser),
      forensicsAutomaticIncidentNotifyAddress,
      forensicsNotifyAddress,
      forensicsPostDeliveryNotifications: isSettingEnabled(currentSettings.forensicsPostDeliveryNotifications),
      forensicsPostDeliveryNotifyAddress: isSettingEnabled(currentSettings.forensicsPostDeliverySyncAddress)
        ? forensicsNotifyAddress
        : forensicsPostDeliveryNotifyAddress,
      forensicsPostDeliverySyncAddress: isSettingEnabled(currentSettings.forensicsPostDeliverySyncAddress),
      forensicsUserReportedIgnoreSatCampaigns: isSettingEnabled(
        currentSettings.forensicsUserReportedIgnoreSatCampaigns
      ),
      forensicsUserReportedNotifications: isSettingEnabled(currentSettings.forensicsUserReportedNotifications),
      forensicsUserReportedNotifyAddress: isSettingEnabled(currentSettings.forensicsUserReportedSyncAddress)
        ? forensicsNotifyAddress
        : forensicsUserReportedNotifyAddress,
      forensicsUserReportedSyncAddress: isSettingEnabled(currentSettings.forensicsUserReportedSyncAddress)
    }

    if (addDefaultNotifyEmails) {
      dispatch(saveAccessTokenSettings({ accessTokenId, settings: initializedSettings }))
    } else {
      dispatch(updateAccessTokenSettings(initializedSettings))
    }
  }, [accessTokenId, accessTokenLib, dispatch, featureLib, isBasicProduct, userDataLib])

  const alertConfig = useMemo(() => {
    return {
      alertContent: errorMessage ? formatErrorMessage(errorMessage) : undefined,
      closeAction: () => {
        dispatch(resetSettingsDemoAlert())
      },
      pageAlert: true,
      showClose: true
    }
  }, [dispatch, errorMessage, formatErrorMessage])

  return useMemo(
    () => [
      {
        activeSettings: params.activeSettings,
        alertConfig,
        contentConfig,
        errorMsg: errorMessage,
        isCustomizeEmailDialogOpen: isCustomizeEmailAlertOpened,
        isSaveDisabled,
        isSaveLoading: isSaveSettingsLoading,
        openCustomizeEmailAlertDialog
      }
    ],
    [
      alertConfig,
      contentConfig,
      errorMessage,
      isCustomizeEmailAlertOpened,
      isSaveDisabled,
      isSaveSettingsLoading,
      openCustomizeEmailAlertDialog,
      params.activeSettings
    ]
  )
}
