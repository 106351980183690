import React, { ReactNode, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import {
  Typography,
  Button,
  DataTable,
  DataTableColumn,
  Link,
  TextField,
  Grid,
  Tooltip,
  Box
} from '@barracuda-internal/bds-core'
import { Edit as EditIcon, DeleteForever as DeleteForeverIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { GridNoRecords as DataTableNoRecords } from '@progress/kendo-react-grid'

import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import Pager from 'global/components/lib/grid/pager/Pager'
import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'

import styles from 'fir/components/lib/settingsTabs/pages/allowedSenders/allowedSendersTabStyles'
import settingsStyles from 'fir/components/lib/settingsTabs/SettingsStyles'
import useAllowedSendersTabLogic from 'fir/components/lib/settingsTabs/pages/allowedSenders/useAllowedSendersTabLogic'

export interface AllowedSendersTabProps {
  onClose: () => void
}

const BASE_I18N_KEY = 'fir.app.settings_dialog'

export const AllowedSendersSettings: React.FC<any> = () => {
  const allowedSendersClasses = styles()
  const settingsClasses = settingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [isTabInProgress, tabView, tableConfig, allowedSender, formHandlers, formStates] = useAllowedSendersTabLogic()

  return useMemo(
    () => (
      <Grid container className={allowedSendersClasses.tabContent} direction="column">
        <Grid container className={settingsClasses.wrapper} direction="column">
          <div>
            <Typography data-field="header" variant="h5">
              {formatMessage('allowed_senders.labels.allowed_senders')}
            </Typography>
            <Typography variant="subtitle2">
              <div>
                <span className={`${settingsClasses.opacity}`}>
                  {formatMessage('allowed_senders.labels.allowed_senders_subtitle')}
                </span>{' '}
                <Link target="_blank" href={config.LINKS.CAMPUS_ALLOWED_SENDERS}>
                  {formatMessage('learn_more')}...
                </Link>
              </div>
            </Typography>
          </div>

          {isTabInProgress && <LinearProgress className={settingsClasses.loadingIndicator} />}
          {tabView.isTableView && (
            <Grid container direction="column">
              <Grid className={allowedSendersClasses.addButton} container alignItems="center" justifyContent="flex-end">
                <Button
                  onClick={() => {
                    tabView.toggle()
                  }}
                  disabled={isTabInProgress}
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  {formatMessage('allowed_senders.add_allowed_sender')}
                </Button>
              </Grid>

              <Grid className={allowedSendersClasses.tableArea} data-table="mailboxes" item xs={12}>
                <DataTable
                  className={`${
                    isTabInProgress || tableConfig.tableData.total
                      ? allowedSendersClasses.dataTable
                      : allowedSendersClasses.emptyDataTable
                  } allowed-senders-table`}
                  data={tableConfig.tableData}
                  {...tableConfig.pageConfig}
                  pager={Pager}
                  rowRender={(trElement, props) => {
                    const trProps = {
                      ...(props.dataItem.isDeleting && { className: `${trElement.props.className} deleted-row` })
                    }

                    return React.cloneElement(trElement, { ...trProps }, trElement.props.children as ReactNode)
                  }}
                >
                  <DataTableNoRecords>
                    <Typography>
                      {!tableConfig.tableData.total && formatMessage('allowed_senders.empty_table')}
                    </Typography>
                  </DataTableNoRecords>
                  <DataTableColumn
                    field={tableConfig.columns.SENDER_EMAIL_OR_DOMAIN}
                    {...tableConfig.columnsConfig[tableConfig.columns.SENDER_EMAIL_OR_DOMAIN]}
                    title={formatMessage(`allowed_senders.table_columns.${tableConfig.columns.SENDER_EMAIL_OR_DOMAIN}`)}
                    cell={({ dataItem }: { dataItem: any }) => (
                      <Cell className={allowedSendersClasses.cell}>
                        <CellText ellipsis className={allowedSendersClasses.boldCell}>
                          {dataItem.pattern}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    field={tableConfig.columns.COMMENT}
                    {...tableConfig.columnsConfig[tableConfig.columns.COMMENT]}
                    title={formatMessage(`allowed_senders.table_columns.${tableConfig.columns.COMMENT}`)}
                    cell={({ dataItem }: { dataItem: any }) => (
                      <Cell className={allowedSendersClasses.cell}>
                        <CellText ellipsis className={allowedSendersClasses.normalCell}>
                          {dataItem.remarks}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    width={50}
                    field={tableConfig.columns.EDIT}
                    {...tableConfig.columnsConfig[tableConfig.columns.EDIT as any]}
                    title=" "
                    cell={({ dataItem }: { dataItem: any }) => (
                      <Cell className={allowedSendersClasses.cell}>
                        <CellText>
                          {!isTabInProgress && (
                            <Tooltip title={formatMessage('allowed_senders.edit_allowed_sender')}>
                              <Box>
                                <EditIcon onClick={dataItem.onEdit} className={allowedSendersClasses.icon} />
                              </Box>
                            </Tooltip>
                          )}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    width={50}
                    field={tableConfig.columns.DELETE}
                    {...tableConfig.columnsConfig[tableConfig.columns.DELETE as any]}
                    title=" "
                    cell={({ dataItem }: { dataItem: any }) => (
                      <Cell className={allowedSendersClasses.cell}>
                        <CellText>
                          {!isTabInProgress && (
                            <Tooltip title={formatMessage('allowed_senders.delete_allowed_sender')}>
                              <Box>
                                <DeleteForeverIcon onClick={dataItem.onDelete} className={allowedSendersClasses.icon} />
                              </Box>
                            </Tooltip>
                          )}
                        </CellText>
                      </Cell>
                    )}
                  />
                </DataTable>
              </Grid>
              <Grid container className={allowedSendersClasses.footer}>
                <Typography>
                  {formatMessage('allowed_senders.caution', {
                    b: (txt: string) => <b key={uuidv4()}>{txt}</b>
                  })}
                </Typography>
              </Grid>
            </Grid>
          )}

          {!tabView.isTableView && (
            <Grid container direction="column">
              <Typography className={allowedSendersClasses.title}>
                {formatMessage(
                  allowedSender.id ? 'allowed_senders.edit_allowed_sender' : 'allowed_senders.add_new_allowed_sender'
                )}
              </Typography>
              <Grid container className={allowedSendersClasses.formRow} alignItems="center" direction="column">
                <Grid container>
                  <Grid item xs={6}>
                    <TextField
                      autoFocus
                      required
                      label={formatMessage('allowed_senders.sender_email_or_domain')}
                      className={allowedSendersClasses.textField}
                      onChange={(e: any) => {
                        formHandlers.onUpdateAllowedSender({ pattern: e.target?.value?.trim() })
                      }}
                      value={allowedSender.pattern}
                      disabled={isTabInProgress}
                      error={!formStates.isValidAllowedSender}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={allowedSendersClasses.formRow} alignItems="center" direction="column">
                <Grid container>
                  <Grid item xs={6}>
                    <TextField
                      label={formatMessage('allowed_senders.comment')}
                      className={allowedSendersClasses.textField}
                      onChange={(e: any) => {
                        formHandlers.onUpdateAllowedSender({ remarks: e.target.value })
                      }}
                      value={allowedSender.remarks}
                      disabled={isTabInProgress}
                    />
                    <Grid
                      container
                      className={allowedSendersClasses.buttonsContainer}
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      {!tabView.isTableView && (
                        <Button
                          className={settingsClasses.formButton}
                          color="primary"
                          variant="text"
                          size="large"
                          onClick={() => {
                            tabView.toggle()
                          }}
                        >
                          {formatMessage('allowed_senders.back')}
                        </Button>
                      )}
                      {!tabView.isTableView && (
                        <Button
                          className={settingsClasses.formButton}
                          onClick={formHandlers.onSave}
                          disabled={formStates.isSaveButtonDisabled}
                          color="primary"
                          variant="contained"
                          size="large"
                        >
                          {formatMessage('allowed_senders.save')}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    ),
    [
      allowedSendersClasses,
      settingsClasses,
      formatMessage,
      isTabInProgress,
      tableConfig,
      tabView,
      formHandlers,
      formStates,
      allowedSender
    ]
  )
}

export default AllowedSendersSettings
