import { useCallback, useMemo, useState } from 'react'

// TODO: Import Account type directly when BDS exports it
import { Account as BDSAccountSwitcherAccount } from '@barracuda-internal/bds-core/dist/UnifiedComponents/AccountSwitcher/AccountSwitcher'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { config } from 'global/lib/config'
import useFeatureLib from 'global/lib/feature/useFeature'
import useUserDataLib from 'global/lib/userData/useUserData'
import { NavbarContextMenuO365Props } from 'global/components/lib/layout/navbar'
import accessTokenLib from 'global/lib/accessToken/accessToken'
import {
  setCurrentBccAccount,
  reset as resetAccessToken,
  setCurrentAccessToken
} from 'global/redux/features/accessToken/accessTokenSlice'

import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'
import gotoReportPage from 'ets/lib/gotoReportPage'
import routesConfig from 'ets/lib/routes/routesConfig'

export interface EtsLayoutLogic {
  initAccount: string
  navbarContextMenuO365Props?: NavbarContextMenuO365Props
  onSelectAccountCallback: (selectedAccount: BDSAccountSwitcherAccount) => void
}

export default function useLayoutLogic(): EtsLayoutLogic[] {
  const dispatch = useAppDispatch()
  const [userDataLib] = useUserDataLib()
  const [featureLib] = useFeatureLib()

  const { bccAccount, defaultBccAccount, tenantId } = useAppSelector(_stores => ({
    bccAccount: _stores.accessToken.bccAccount || '',
    defaultBccAccount: _stores.user?.data?.defaultAccountBccId || '',
    tenantId: _stores.accessToken.accessToken?.tenantId || ''
  }))

  // When in start trial page, grab the defaultBccAccount to show in the account switcher because the bccAccount has not set yet
  const [initAccount, setInitAccount] = useState(tenantId || bccAccount || defaultBccAccount)
  const accounts = userDataLib.getAccounts()

  // TODO BNFIR-3227 test more and see if further optimization of this logic is needed
  const onSelectAccountCallback = useCallback(
    async (selectedAccount: BDSAccountSwitcherAccount) => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_SWITCH_ACCOUNT, {
        selectedAccountId: selectedAccount.id
      })

      setInitAccount(selectedAccount.id)

      // If the account has a nestedAccounts property, it indicates that this account is a bccAccount (parent account) without an accessTokenId.
      if (selectedAccount.nestedAccounts) {
        dispatch(resetAccessToken())
        dispatch(setCurrentBccAccount(selectedAccount.id))
        routesConfig.SIGNIN_CONNECT.goto()
      }
      // also need to verify if bccId changes when switching from bccId to accessTokenId
      else if (selectedAccount.id !== tenantId || selectedAccount.id !== accessTokenLib.getCurrentBccAccount()) {
        const mostRecentToken = userDataLib.getEtsAccessTokensByTenantId(selectedAccount.id)[0].id
        await dispatch(setCurrentAccessToken(mostRecentToken))

        routesConfig.DASHBOARD.goto({
          reportId: mostRecentToken
        })
      }
    },
    [dispatch, tenantId, userDataLib]
  )

  const navbarContextMenuO365Props: NavbarContextMenuO365Props = useMemo(() => {
    return {
      successCb: (data: any, scanType: string) => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_CONNECT_O365_SUCCESSFUL, {
          accessTokenId: data.accessToken.id
        })
        if (scanType === config.SCAN_TYPES.SENTINEL) {
          window.location.replace(`${config.domains.sentinel}/report/${data.accessToken.id}`)
        } else {
          gotoReportPage({ accessTokenId: data.accessToken.id, user: data.user, scan: data.scan })
        }
      },
      showScanTypeSelection:
        (accounts && featureLib.hasSentinelFeatureForAnyAccessToken()) || featureLib.hasETSReadOnlyForAnyAccessToken(),
      accounts
    }
  }, [accounts, featureLib])

  return useMemo(
    () => [
      {
        initAccount,
        navbarContextMenuO365Props,
        onSelectAccountCallback
      }
    ],
    [initAccount, navbarContextMenuO365Props, onSelectAccountCallback]
  )
}
