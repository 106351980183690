const API_ROUTES = {
  LOGOUT: {
    method: 'POST',
    path: '/logout'
  },
  account: {
    CHECK_BCS_SUBSCRIPTION: {
      method: 'POST',
      path: '/account/check-BCS-subscription'
    },
    SET_SERIAL_NUMBER: {
      method: 'POST',
      path: '/account/set-serial-number'
    }
  },
  admin: {
    REVERT_IMPERSONATION: {
      method: 'POST',
      path: '/admin/revert-impersonation'
    }
  },
  analytics: {
    MIXPANEL_TRACK_EVENT: {
      method: 'POST',
      path: '/analytics/mixpanel-track-event'
    }
  },
  app: {
    MAIN_CONFIGURATION: {
      method: 'GET',
      path: '/app/configuration'
    }
  },
  auth: {
    GET_USER: {
      method: 'GET',
      path: '/auth/user'
    },
    SIGNIN: {
      method: 'POST',
      path: '/auth/signin'
    },
    SIGNIN_OTP: {
      method: 'POST',
      path: '/auth/signin/otp'
    },
    ACTIVATE_DEVELOPER_INTERFACE: {
      method: 'POST',
      path: '/auth/developer-interface/activate'
    },
    REFRESH_TOKEN: {
      method: 'POST',
      path: '/auth/bcc/refreshToken'
    }
  },
  automatedWorkflows: {
    CREATE_UPDATE_WORKFLOW: {
      method: 'POST',
      path: '/automatedWorkflows/workflow/create-update'
    },
    GET_WORKFLOW: {
      method: 'POST',
      path: '/automatedWorkflows/workflow/get-workflow'
    },
    GET_WORKFLOW_BLUEPRINTS: {
      method: 'POST',
      path: '/automatedWorkflows/workflow/get-workflow-blueprints'
    },
    GET_WORKFLOW_RUNS: {
      method: 'POST',
      path: '/automatedWorkflows/workflow/get-workflow-runs'
    },
    GET_WORKFLOWS: {
      method: 'POST',
      path: '/automatedWorkflows/workflow/get-workflows'
    },
    TOGGLE_WORKFLOW_STATUS: {
      method: 'POST',
      path: '/automatedWorkflows/workflow/toggle-workflow-status'
    }
  },
  cloudService: {
    CONNECT: {
      method: 'GET',
      path: '/cloud/office365/:source/:scanType/connect'
    }
  },
  insights: {
    DELETE_POST_DELIVERY: {
      method: 'POST',
      path: '/insights/post-delivery/delete'
    },
    DISMISS_POST_DELIVERY: {
      method: 'POST',
      path: '/insights/post-delivery/dismiss'
    },
    DELETE_RELATED: {
      method: 'POST',
      path: '/insights/related/delete'
    },
    DISMISS_RELATED: {
      method: 'POST',
      path: '/insights/related/dismiss'
    },
    POST_DELIVERY: {
      method: 'POST',
      path: '/insights/post-delivery'
    },
    RELATED: {
      method: 'POST',
      path: '/insights/related'
    }
  },
  remediationService: {
    FORENSICS_ESS_ACCOUNT: {
      method: 'POST',
      path: '/remediation/forensics/ess-account'
    },
    FORENSICS_CREATE_INCIDENT: {
      method: 'POST',
      path: '/remediation/forensics/create-incident'
    },
    FORENSICS_CREATE_INCIDENT_TAGS: {
      method: 'POST',
      path: '/remediation/forensics/create-incident-tags'
    },
    FORENSICS_DELETE_INCIDENT_TAG: {
      method: 'POST',
      path: '/remediation/forensics/delete-incident-tag'
    },
    FORENSICS_GET_INCIDENT: {
      method: 'POST',
      path: '/remediation/forensics/incident-details'
    },
    FORENSICS_GET_INCIDENT_CSV: {
      method: 'GET',
      path: '/remediation/forensics/get-incident-csv?accessTokenId=:accessTokenId&incidentId=:incidentId'
    },
    FORENSICS_GET_INCIDENT_DETAILS: {
      method: 'POST',
      path: '/remediation/forensics/get-details'
    },
    FORENSICS_GET_INCIDENT_EMAILS: {
      method: 'POST',
      path: '/remediation/forensics/get-recipients'
    },
    FORENSICS_GET_INCIDENT_LINK_PROTECT_DETAILS: {
      method: 'POST',
      path: '/remediation/forensics/get-link-protect-details'
    },
    FORENSICS_GET_INCIDENT_LINK_PROTECT_CSV: {
      method: 'GET',
      path: '/remediation/forensics/get-link-protect-csv?accessTokenId=:accessTokenId&incidentId=:incidentId'
    },
    FORENSICS_GET_INCIDENT_USER_DETAILS: {
      method: 'POST',
      path: '/remediation/forensics/get-recipients-details'
    },
    FORENSICS_GET_INCIDENTS: {
      method: 'POST',
      path: '/remediation/forensics/incidents'
    },
    FORENSICS_GET_INCIDENTS_TAGS: {
      method: 'POST',
      path: '/remediation/forensics/incidents-tags'
    },
    FORENSICS_INCIDENT_DELETE_EXISTING_EMAILS: {
      method: 'POST',
      path: '/remediation/forensics/incident-delete-existing-emails'
    },
    FORENSICS_RESTART_CONTINUOUS_REMEDIATION: {
      method: 'POST',
      path: '/remediation/forensics/continuous-remediation-restart'
    },
    FORENSICS_START_CONTINUOUS_REMEDIATION: {
      method: 'POST',
      path: '/remediation/forensics/continuous-remediation-start'
    },
    FORENSICS_STOP_CONTINUOUS_REMEDIATION: {
      method: 'POST',
      path: '/remediation/forensics/continuous-remediation-stop'
    }
  },
  searchService: {
    ACCOUNT_SEARCH_INFORMATION: {
      method: 'POST',
      path: '/search/account-info'
    },
    EMAIL_INFORMATION: {
      method: 'POST',
      path: '/search/email-info'
    },
    EMAILS_QUERY: {
      method: 'POST',
      path: '/search/emails-query'
    },
    FORENSICS_BOOTSTRAP_PROGRESS: {
      method: 'POST',
      path: '/search/forensics-bootstrap-progress'
    },
    GEOIP_AGGREGATE: {
      method: 'POST',
      path: '/search/geoip'
    }
  },
  securityTrainingService: {
    FORENSICS_GET_SECURITY_TRAINING_INSTANCES: {
      method: 'POST',
      path: '/security-training/forensics/instances'
    },
    FORENSICS_CREATE_SECURITY_TRAINING_ADDRESS_BOOK: {
      method: 'POST',
      path: '/security-training/forensics/address-book'
    }
  },
  settings: {
    FORENSICS_NOTIFICATION_PREVIEW: {
      method: 'POST',
      path: '/settings/template/forensics-notification-preview'
    },
    FORENSICS_CUSTOM_NOTIFICATION_TEMPLATES: {
      method: 'POST',
      path: '/settings/template/forensics-custom-notification/all'
    },
    FORENSICS_CREATE_CUSTOM_NOTIFICATION_TEMPLATE: {
      method: 'POST',
      path: '/settings/template/forensics-custom-notification/create'
    },
    FORENSICS_DELETE_CUSTOM_NOTIFICATION_TEMPLATE: {
      method: 'POST',
      path: '/settings/template/forensics-custom-notification/delete'
    },
    GET_ACCESS_TOKEN_SETTINGS: {
      method: 'POST',
      path: '/settings/access-token'
    },
    UPDATE_ACCESS_TOKEN_SETTINGS: {
      method: 'POST',
      path: '/settings/access-token/update'
    }
  },
  siem: {
    GET_SIEM_SETTINGS: {
      method: 'POST',
      path: '/settings/syslog/get-config'
    },
    SAVE_SIEM_SETTINGS: {
      method: 'POST',
      path: '/settings/syslog/save-config'
    },
    TEST_SIEM_SETTINGS: {
      method: 'POST',
      path: '/settings/syslog/test-config'
    }
  },
  signup: {
    SAVE_PROFILE: {
      method: 'POST',
      path: '/signup/save-profile'
    },
    SIGNUP: {
      method: 'POST',
      path: '/signup/create'
    },
    SIGNUP_FLAGS: {
      method: 'POST',
      path: '/signup/flags'
    },
    SIGNUP_SAVE_PROFILE: {
      method: 'POST',
      path: '/signup/save-profile'
    }
  },
  statsService: {
    REMEDIATION_EMAIL_THREATS_STATS: {
      method: 'POST',
      path: '/stats/forensics/threats-remediated'
    },
    REMEDIATION_INCIDENTS_STATS: {
      method: 'POST',
      path: '/stats/forensics/created-incidents'
    },
    REMEDIATION_TOP_USERS_STATS: {
      method: 'POST',
      path: '/stats/forensics/top-users'
    },
    USER_REPORTED_TOP_REPORTERS_STATS: {
      method: 'POST',
      path: '/stats/forensics/top-reporters'
    },
    WORKFLOW_RUNS_STATS: {
      method: 'POST',
      path: '/stats/forensics/workflow-runs'
    },
    WORKFLOWS_TOP_RUNS_STATS: {
      method: 'POST',
      path: '/stats/forensics/top-workflows'
    }
  },
  userReported: {
    UPDATE_USER_REPORTED_EMAILS_STATE: {
      method: 'POST',
      path: '/insights/user-submitted/update-state'
    },
    USER_REPORTED_GROUPED_SEARCH: {
      method: 'POST',
      path: '/insights/user-submitted/grouped-by-search'
    }
  }
}

export default API_ROUTES
