import React from 'react'
import PropTypes from 'prop-types'

import styles from 'global/components/lib/layout/navbar/navbarStyles'

export type NavbarContentWrapperProps = {
  children?: null | JSX.Element | JSX.Element[]
}

const NavbarContentWrapper: React.FC<NavbarContentWrapperProps> = ({ children }) => {
  const classes = styles()

  return <div className={classes.contentWrapper}>{children}</div>
}

export default NavbarContentWrapper
