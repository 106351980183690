import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'

import { process } from '@progress/kendo-data-query'

import { isFailed, isPending, isSuccess } from 'global/redux/toolkit/api'
import { SentinelModifiedScan, ScanAccessToken, ScanAccessTokens, ScanResults } from 'global/types/api/scan'
import { SearchFieldProps, useSearchFieldLogic } from 'global/components/lib/searchField/SearchField'
import { BDSGridPagerConfig, BDSGridSortConfig } from 'global/types/dataTables/dataTables'
import useTablePeriodicCheck from 'global/lib/useTablePeriodicCheck/useTablePeriodicCheck'
import { formatDate } from 'global/lib/datetime'
import { config } from 'global/lib/config'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'

import { ColumnsConfig } from 'admin/redux/types/dataTables'
import { useAppDispatch, useAppSelector } from 'admin/redux/toolkit/hooks'
import {
  deactivate,
  deactivateAndReset,
  getScans,
  impersonate,
  resetScans,
  reset as resetAdminSlice
} from 'admin/redux/features/admin/adminSlice'
import {
  update as updateScansTable,
  reset as resetScansTable
} from 'admin/redux/features/dataTables/senScans/senScansSlice'
import { ScanDialogProps } from 'admin/components/lib/dialogs/scanDialog/ScanDialog'
import { ImpersonateDialogProps } from 'admin/components/lib/dialogs/impersonateDialog/ImpersonateDialog'
import { ScaryDialogProps } from 'admin/components/lib/dialogs/deactivate/ScaryDialog'
import { DeactivateDialogProps } from 'admin/components/lib/dialogs/deactivate/DeactivateDialog'

export type InProgress = boolean
export interface TableConfig {
  columns: { [key: string]: string }
  columnsConfig: ColumnsConfig
  inProgress: boolean
  isLoaded: boolean
  onOpenImpersonate: (scan: SentinelModifiedScan) => void
  onOpenReport: (scan: SentinelModifiedScan) => void
  onOpenScan: (scan: SentinelModifiedScan, accessToken: ScanAccessToken) => void
  onOpenScary: (scan: SentinelModifiedScan, deactivateType: string) => void
  pageConfig: BDSGridPagerConfig
  searchFieldConfig: SearchFieldProps
  sortConfig: BDSGridSortConfig | {}
  tableData: {
    data: SentinelModifiedScan[]
    total: number
  }
}

export interface ScaryDialogConfig {
  accessTokenId: ScaryDialogProps['accessTokenId'] | undefined
  deactivateType: string
  onClose: (confirmed: boolean, deactivateType?: string) => void
  open: boolean
  product: string
  updateDeactivateType: () => void
}
export interface DeactivateDialogConfig {
  open: boolean
  accessTokenId: DeactivateDialogProps['accessTokenId'] | undefined
}
export interface ScanDialogConfig {
  open: boolean
  onClose: () => void
  scan: ScanDialogProps['scan'] | undefined
  accessToken: ScanDialogProps['accessToken'] | undefined
  product: ScanDialogProps['product']
}
export interface ImpersonateDialogConfig {
  open: boolean
  email: ImpersonateDialogProps['email'] | undefined | null
}

export type AllAccountLogic = [
  ScanAccessTokens,
  TableConfig,
  ScaryDialogConfig,
  DeactivateDialogConfig,
  ScanDialogConfig,
  ImpersonateDialogConfig
]

const PRODUCT = config.PRODUCTS.SENTINEL
const SEARCH_FIELD_ID = 'scans-table-search'

export default function useAllAccountLogic(): AllAccountLogic {
  const dispatch = useAppDispatch()
  const {
    deactivateFailed,
    deactivateAndResetFailed,
    getScansInProgress,
    impersonateFailed,
    loadedScansOffsets,
    scans,
    scansTable
  } = useAppSelector(_stores => ({
    deactivateFailed: isFailed(_stores.admin.deactivateApiStatus),
    deactivateAndResetFailed: isFailed(_stores.admin.deactivateAndResetApiStatus),
    getScansInProgress: isPending(_stores.admin.getScansApiStatus),
    impersonateFailed: isFailed(_stores.admin.impersonateApiStatus),
    loadedScansOffsets: _stores.admin.loadedScansOffsets,
    scans: _stores.admin.scans,
    scansTable: _stores.dataTables.senScans
  }))
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    deactivateType: 'single',
    searchString: ''
  })

  const [initTableRefresh] = useTablePeriodicCheck()
  const [isScanDialogOpened, toggleScanDialog] = useDialogLogic()
  const [isScaryDialogOpened, toggleScaryDiaog] = useDialogLogic()
  const [isDeactivateDialogOpened, toggleDeactivateDiaog] = useDialogLogic()
  const [isImpersonateDialogOpened, toggleImpersonateDialog] = useDialogLogic()
  const [selectedScan, setSelectedScan] = useState<{
    scan: SentinelModifiedScan
    accessToken?: ScanAccessToken
  }>()

  // init
  useEffect(() => {
    initTableRefresh(tableRefresh)

    dispatch(getScans({ scanType: PRODUCT }))
    return () => {
      dispatch(resetScans(true))
      dispatch(resetScansTable())
      dispatch(resetAdminSlice())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetTable = useCallback(() => {
    dispatch(resetScans())
    dispatch(getScans({ scanType: PRODUCT }))
  }, [dispatch])

  const tableRefresh = useCallback(() => {
    if (!getScansInProgress) {
      resetTable()
    }
  }, [resetTable, getScansInProgress])

  const updateTableData = useCallback(
    (changes: any = {}) => {
      dispatch(
        updateScansTable({
          ...changes,
          skip: 0
        })
      )

      initTableRefresh(tableRefresh)
      resetTable()
    },
    [dispatch, resetTable, initTableRefresh, tableRefresh]
  )

  const [debouncedOnChange, validateSearchString] = useSearchFieldLogic(
    (search: string) => {
      updateTableData({ search: search.trim() })
      setState({ searchString: search.trim() })
    },
    getScansInProgress,
    SEARCH_FIELD_ID
  )

  const tableData = useMemo(() => {
    const { skip, take } = scansTable

    const { data } = process(
      (scans?.data || []).map((report: ScanResults) => ({
        ...(report && {
          ...report,
          createdOnFormatted: formatDate(report.createdOn || '', config.DATETIME.ADMIN_DATE_FORMAT),
          refreshedOnDateFormatted: formatDate(report.refreshedOn || '', config.DATETIME.ADMIN_DATE_FORMAT),
          refreshedOnTimeFormatted: formatDate(report.refreshedOn || '', config.DATETIME.DEFAULT_TIME_FORMAT)
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.id),
      total: scans?.totalCount || 0
    }
  }, [scans, scansTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = scansTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateScansTable(e.page))

        if (!loadedScansOffsets?.includes(e.page.skip)) {
          dispatch(getScans({ scanType: PRODUCT }))
        }
      }
    }
  }, [scansTable, tableData, dispatch, loadedScansOffsets])

  const sortConfig: BDSGridSortConfig | {} = useMemo(() => {
    if (!tableData.total) {
      return {}
    }

    return {
      sortable: !getScansInProgress && {
        allowUnsort: false,
        mode: 'single'
      },
      sort: scansTable.sort,
      onSortChange: (e: any) => {
        updateTableData({ sort: e.sort })
      }
    }
  }, [scansTable, updateTableData, tableData.total, getScansInProgress])

  const searchFieldConfig: SearchFieldProps = useMemo(() => {
    return {
      id: SEARCH_FIELD_ID,
      value: state.searchString,
      onChange: (e: any) => {
        const validatedSearchString = validateSearchString(e.target.value)

        if (scansTable.search !== validatedSearchString) {
          debouncedOnChange(validatedSearchString)
          setState({ searchString: validatedSearchString })
        }
      },
      disabled: getScansInProgress
    }
  }, [state.searchString, scansTable, debouncedOnChange, validateSearchString, getScansInProgress])

  const onOpenScan = useCallback(
    (scan: SentinelModifiedScan, accessToken: ScanAccessToken) => {
      setSelectedScan({ scan, accessToken })
      toggleScanDialog()
    },
    [toggleScanDialog]
  )

  const onCloseDetails = useCallback(() => {
    setSelectedScan(undefined)
    toggleScanDialog()
  }, [toggleScanDialog])

  const onOpenScary = useCallback(
    (scan: SentinelModifiedScan, deactivateType: string) => {
      setSelectedScan({ scan })
      setState({ deactivateType })
      toggleScaryDiaog()
    },
    [toggleScaryDiaog]
  )

  const onCloseScary = useCallback(
    (confirmed: boolean, deactivateType?: string) => {
      toggleScaryDiaog()
      if (confirmed && selectedScan) {
        if (deactivateType === 'all') {
          dispatch(
            deactivateAndReset({
              accountId: selectedScan.scan.accountId,
              accessTokenId: selectedScan.scan.accessTokenId,
              email: selectedScan.scan.triggeredBy,
              product: PRODUCT
            })
          )
        } else {
          dispatch(
            deactivate({
              accountId: selectedScan.scan.accountId,
              accessTokenId: selectedScan.scan.accessTokenId,
              product: PRODUCT
            })
          )
        }
        setState({ deactivateType: 'single' })
        toggleDeactivateDiaog()
      } else {
        setSelectedScan(undefined)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggleScaryDiaog, dispatch, selectedScan]
  )

  const openAdminReportURI = useCallback(
    (scan: SentinelModifiedScan) => {
      const url = scans.accessTokens[scan.accessTokenId]
        ? `${config.shareReportURIPrefix}${scans.accessTokens[scan.accessTokenId].adminSecret.value}`
        : undefined
      window.open(url)
    },
    [scans]
  )

  const onOpenImpersonate = useCallback(
    (scan: SentinelModifiedScan) => {
      setSelectedScan({ scan })
      toggleImpersonateDialog()
      dispatch(impersonate({ email: scan.triggeredBy, host: config.domains.sentinel, path: 'v2/report' }))
    },
    [dispatch, toggleImpersonateDialog]
  )

  // close impersonate dialog on failed request
  useEffect(() => {
    if (impersonateFailed) {
      setSelectedScan(undefined)
      toggleImpersonateDialog()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impersonateFailed])

  // close deactivate dialog on failed request
  useEffect(() => {
    if ((deactivateFailed || deactivateAndResetFailed) && isDeactivateDialogOpened) {
      setSelectedScan(undefined)
      toggleDeactivateDiaog()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deactivateFailed, deactivateAndResetFailed])

  return useMemo(() => {
    return [
      scans.accessTokens,
      {
        isLoaded: !!scans.data,
        inProgress: getScansInProgress,
        searchFieldConfig,
        tableData,
        pageConfig,
        sortConfig,
        columns: scansTable.GRID_COLUMNS,
        columnsConfig: scansTable.columnsConfig,
        onOpenScan,
        onOpenScary,
        onOpenReport: openAdminReportURI,
        onOpenImpersonate
      },
      {
        accessTokenId: selectedScan?.scan.accessTokenId,
        deactivateType: state.deactivateType,
        onClose: onCloseScary,
        open: isScaryDialogOpened,
        product: PRODUCT,
        updateDeactivateType: () => (e: any) => {
          setState({ deactivateType: e.target.value })
        }
      },
      {
        open: isDeactivateDialogOpened,
        accessTokenId: selectedScan?.scan.accessTokenId
      },
      {
        open: isScanDialogOpened,
        onClose: onCloseDetails,
        scan: selectedScan?.scan,
        accessToken: selectedScan?.accessToken,
        product: PRODUCT
      },
      {
        open: isImpersonateDialogOpened,
        email: selectedScan?.scan.triggeredBy
      }
    ]
  }, [
    scans.accessTokens,
    scans.data,
    getScansInProgress,
    searchFieldConfig,
    tableData,
    pageConfig,
    sortConfig,
    scansTable.GRID_COLUMNS,
    scansTable.columnsConfig,
    onOpenScan,
    onOpenScary,
    openAdminReportURI,
    onOpenImpersonate,
    selectedScan,
    state.deactivateType,
    onCloseScary,
    isScaryDialogOpened,
    isDeactivateDialogOpened,
    isScanDialogOpened,
    onCloseDetails,
    isImpersonateDialogOpened
  ])
}
