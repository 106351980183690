import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import SigninSignup from 'sen/components/pages/signup/signinSignup/Signup'
import DomainFraudSignup from 'sen/components/pages/signup/domainFraudSignup/DomainFraudSignup'

const ONPREM = 'onprem'
const PRODUCT_DF = 'dp'

const Signup: React.FC = () => {
  const location = useLocation()

  const queryParams = queryString.parse(location.search)
  const isDomainFraudSignup = queryParams.provider === ONPREM || queryParams.product === PRODUCT_DF

  if (isDomainFraudSignup) {
    return <DomainFraudSignup />
  }

  return <SigninSignup />
}

export default Signup
