import React from 'react'

import useSignupLogic from 'fir/components/pages/signup/useSigninSignupLogic'

const SigninSignup: React.FC = () => {
  useSignupLogic()

  return null
}

export default SigninSignup
