import { useEffect, useMemo, useReducer, useCallback, useRef, RefObject } from 'react'

import { process } from '@progress/kendo-data-query'
import { SelectProps } from '@barracuda-internal/bds-core'
import { isEqual } from 'lodash'

import { config } from 'global/lib/config'
import * as dmarcLib from 'global/lib/domain/dmarc'
import useProductLib from 'global/lib/product/useProduct'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import useUserDataLib from 'global/lib/userData/useUserData'
import { ExportToCsvButtonProps } from 'global/components/lib/exportToCsvButton/ExportToCsvButton'
import { SearchFieldProps, useSearchFieldLogic } from 'global/components/lib/searchField/SearchField'
import { BDSGridPagerConfig, BDSGridSortConfig } from 'global/types/dataTables/dataTables'
import { ColumnsConfig } from 'global/types/dataTables/columnsConfigType'
import { formatDate, formatDateWithTime, isBeforeDate, luxonDate } from 'global/lib/datetime'
import { ATTACK_TYPES } from 'global/lib/attackTypeValidator/attackTypeValidator'
import { getSpAttackId, reset as resetAttack } from 'global/redux/features/attack/attackSlice'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { UseEmailDetailsSpAttackInterfaceProps } from 'global/components/lib/dialogs/emailDetailsDialog/interfaces/useEmailDetailsSpAttackInterface'
import useStatusTypeLabelLogicForSpAttacks from 'global/components/lib/statusTypeLabel/useStatusTypeLabelLogicForSpAttacks'
import { isSuccess, isPending } from 'global/redux/toolkit/api'
import useTablePeriodicCheck from 'global/lib/useTablePeriodicCheck/useTablePeriodicCheck'
import { FEATURES, isMyFeatureOn } from 'global/lib/splitio/splitio'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { getCmStats } from 'sen/redux/features/cm/cmSlice'
import { getAlertSummary, DEFAULT_ALERT_SUMMARY_TIMEFRAME } from 'sen/redux/features/ato/atoSlice'
import {
  getCompanyDomains,
  getAttacks,
  getListFeedback,
  resetAttacks,
  resetListFeedback
} from 'sen/redux/features/sp/spSlice'
import apiRoutes from 'sen/lib/api/apiRoutes'
import {
  update as updateSpAttacksTable,
  reset as resetSpAttacksTable
} from 'sen/redux/features/dataTables/spAttacks/spAttacksSlice'
import routesConfig from 'sen/lib/routes/routesConfig'

export type AccountName = string
export type SpAttackId = string
export type ConfidenceLevel = string

export interface RealTimePhishingCard {
  isLoaded: boolean
  fpRatio: number
  spCounts: number[]
  date: string
  onClick: () => void
}

export interface AccountTakeoverProtectionCard {
  isLoaded: boolean
  outstandingAlert: number
  date: string
  onClick: () => void
  isWarning: boolean
  timeframe: number
}

export interface DomainsFraudProtectionCard {
  isVisible: boolean
  isLoaded: boolean
  dmarcCounts: number[]
  onClick: () => void
}

export interface LicensingInformationCard {
  isVisible: boolean
  isLoaded: boolean
  licenseCounts: number
  serialNumber: string
  onClick: () => void
  isMailboxesDialogOpened: boolean
  onCloseMailboxesDialog: () => void
}

export interface SpAttacksTableConfig {
  ref: RefObject<HTMLDivElement>
  isLoaded: boolean
  inProgress: boolean
  exportToCsvConfig: ExportToCsvButtonProps
  searchFieldConfig: SearchFieldProps
  tableData: {
    total: number
    data: any[]
  }
  pageConfig: BDSGridPagerConfig
  sortConfig: BDSGridSortConfig | {}
  columns: { [key: string]: string }
  columnsConfig: { [key: string]: ColumnsConfig }
  highlightKeywords: string[]
}

export interface AttackSelectorConfig {
  select: SelectProps
  menuItems: { id: string; isSelected: boolean }[]
}

const SEARCH_FIELD_ID = 'sp-attacks-table-search'

export default function useRecentAttacksLogic(): [
  AccountName,
  RealTimePhishingCard,
  AccountTakeoverProtectionCard,
  DomainsFraudProtectionCard,
  LicensingInformationCard,
  ConfidenceLevel,
  SpAttacksTableConfig,
  SpAttackId,
  AttackSelectorConfig,
  UseEmailDetailsSpAttackInterfaceProps
] {
  const dispatch = useAppDispatch()
  const [productLib] = useProductLib()
  const [userDataLib] = useUserDataLib()
  const [isMailboxesDialogOpened, toggleMailboxesDialog] = useDialogLogic()
  const [statusTypeLabelLogic] = useStatusTypeLabelLogicForSpAttacks()

  const {
    accessTokenId,
    accountName,
    alertSummary,
    cmStats,
    companyDomains,
    confidenceLevel,
    feedbackPublicKey,
    isAlertSummaryLoaded,
    isAttacksLoadedSuccess,
    isAttacksinProgress,
    isCmStatsLoaded,
    isDomainsLoaded,
    isListFeedbackInProgress,
    listFeedback,
    spAttackId,
    spAttacks,
    spAttacksLoadedOffsets,
    spAttacksTable,
    splitStore
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    accountName: _stores.accessToken.accessToken?.name,
    alertSummary: _stores.ato.alertSummary?.report,
    cmStats: _stores.cm.stats,
    companyDomains: _stores.sp.companyDomains?.report?.data,
    confidenceLevel: _stores.attack.confidenceLevel,
    feedbackPublicKey: _stores.attack.feedbackPublicKey,
    isAlertSummaryLoaded: isSuccess(_stores.ato.getAlertSummaryApiStatus),
    isAttacksLoadedSuccess: isSuccess(_stores.sp.getAttacksApiStatus),
    isAttacksinProgress: isPending(_stores.sp.getAttacksApiStatus),
    isCmStatsLoaded: isSuccess(_stores.cm.getCmStatsApiStatus),
    isDomainsLoaded: isSuccess(_stores.sp.getCompanyDomainsApiStatus),
    isListFeedbackInProgress: isPending(_stores.sp.getListFeedbackApiStatus),
    listFeedback: _stores.sp.listFeedback,
    spAttackId: _stores.attack.spAttackId,
    spAttacks: _stores.sp.attacks,
    spAttacksLoadedOffsets: _stores.sp.attacksLoadedOffsets,
    spAttacksTable: _stores.dataTables.spAttacks,
    splitStore: _stores.splitio
  }))

  // Use the current account Id to select splitio treatment value
  const accountId = useMemo(() => userDataLib.getAccountByAccessToken(accessTokenId)?.accountId, [
    accessTokenId,
    userDataLib
  ])
  const isLicenseComplianceOn = useMemo(() => isMyFeatureOn(splitStore, FEATURES.IP_LICENSING_COMPLIANCE, accountId), [
    accountId,
    splitStore
  ])

  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    isAttackSelectorOpened: false,
    selectedAttackType: spAttacksTable.ALL_ATTACK_TYPE,
    searchString: ''
  })

  const spAttacksTableRef = useRef<null | HTMLDivElement>(null)

  const [initTableRefresh] = useTablePeriodicCheck()

  const maxRetention = luxonDate()
    .minus({ days: config.MAX_EMAIL_RETENTION_DAYS })
    .toISO()

  // init
  useEffect(() => {
    initTableRefresh(tableRefresh)

    dispatch(getCmStats())
    dispatch(getAlertSummary())
    dispatch(getCompanyDomains())
    dispatch(getAttacks())

    return () => {
      dispatch(resetAttacks(true))
      dispatch(resetSpAttacksTable())
      dispatch(resetListFeedback())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (accessTokenId && cmStats && companyDomains) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.DASHBOARD_VIEW, {
        accessTokenId,
        url: window.location.href,
        threatsProtected: cmStats.remediationsCount,
        licenseCounts: cmStats.mailboxCount,
        emailCount: cmStats.emailCount,
        domainsCount: companyDomains.length
      })
    }
  }, [accessTokenId, cmStats, companyDomains])

  useEffect(() => {
    if (feedbackPublicKey) {
      dispatch(getSpAttackId({ feedbackPublicKey }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackPublicKey])

  // list feedbacks
  const threatIds = useMemo(() => {
    return (
      spAttacks?.report?.data
        ?.slice(spAttacksTable.skip, spAttacksTable.skip + spAttacksTable.take)
        .map((attack: any) => attack?.threatId) || []
    )
  }, [spAttacks, spAttacksTable])

  useEffect(() => {
    if (isAttacksLoadedSuccess) {
      dispatch(getListFeedback(threatIds))
    }
  }, [isAttacksLoadedSuccess, threatIds, dispatch])

  const getFalsePositiveStatus = useCallback(
    (threatId: string) => {
      const feedbackForThreat = listFeedback?.feedback?.data?.find((feedback: any) => feedback.threatId === threatId)

      return feedbackForThreat && !!feedbackForThreat.markedAsFp
    },
    [listFeedback]
  )

  // cards
  const realTimePhishingCard: RealTimePhishingCard = useMemo(() => {
    const threatsProtectedCount = cmStats?.remediationsCount || 0
    const emailCount = cmStats?.emailCount || 0
    const fpCount = cmStats?.fpCount || 0
    const fpRatio = emailCount ? fpCount / emailCount : 0
    return {
      isLoaded: isCmStatsLoaded,
      fpRatio,
      spCounts: [threatsProtectedCount, fpCount, emailCount],
      date: formatDate(cmStats?.lastUpdatedDate || '', config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT),
      onClick: () => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.DASH_PHISHING_CARD, {
          accessToken: accessTokenId
        })
        const elementRect = spAttacksTableRef?.current?.getBoundingClientRect()
        const elementTop = (elementRect?.top || 0) + window.pageYOffset - 60

        window.scrollTo({
          top: elementTop,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  }, [isCmStatsLoaded, cmStats, accessTokenId])

  const accountTakeoverProtectionCard: AccountTakeoverProtectionCard = useMemo(
    () => ({
      isLoaded: isAlertSummaryLoaded,
      outstandingAlert: alertSummary?.openAlertsCount || 0,
      date: formatDate(alertSummary?.latestAlertDate || '', config.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT),
      onClick: () => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.DASH_ATO_CARD, {
          accessToken: accessTokenId
        })

        routesConfig.ACCOUNT_TAKEOVER.goto({ reportId: accessTokenId })
      },
      isWarning: (alertSummary?.openAlertsCount || 0) > 0,
      timeframe: DEFAULT_ALERT_SUMMARY_TIMEFRAME
    }),
    [isAlertSummaryLoaded, alertSummary, accessTokenId]
  )

  const domainsFraudProtectionCard: DomainsFraudProtectionCard = useMemo(() => {
    const dmarcCounts = dmarcLib.dmarcCounts(companyDomains || [])

    return {
      isVisible: productLib.getSentinelSerialBundleForAccessToken(accessTokenId) !== config.BUNDLES.BUNDLE1,
      isLoaded: isDomainsLoaded,
      dmarcCounts: [
        companyDomains?.length || 0,
        dmarcCounts[dmarcLib.DMARC_STATES.REPORTING],
        dmarcCounts[dmarcLib.DMARC_STATES.PROTECTED]
      ],
      onClick: () => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.DASH_DMARC_CARD, {
          accessToken: accessTokenId
        })

        routesConfig.DOMAIN_FRAUD.goto({ reportId: accessTokenId })
      }
    }
  }, [isDomainsLoaded, companyDomains, accessTokenId, productLib])

  const licensingInformationCard: LicensingInformationCard = useMemo(() => {
    return {
      isVisible: userDataLib.isMspManagedAccount(accessTokenId) || !isLicenseComplianceOn,
      isLoaded: isCmStatsLoaded,
      licenseCounts: cmStats?.mailboxCount || 0,
      serialNumber: productLib.getSentinelSerialNumberForAccessToken(accessTokenId),
      onClick: () => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.DASH_MAILBOX_CARD, {
          accessToken: accessTokenId
        })
        toggleMailboxesDialog()
      },
      isMailboxesDialogOpened,
      onCloseMailboxesDialog: toggleMailboxesDialog
    }
  }, [
    isLicenseComplianceOn,
    userDataLib,
    isCmStatsLoaded,
    cmStats,
    accessTokenId,
    productLib,
    isMailboxesDialogOpened,
    toggleMailboxesDialog
  ])

  // sp attacks table
  const resetTable = useCallback(() => {
    dispatch(resetAttacks())
    dispatch(getAttacks())
    dispatch(resetListFeedback())
  }, [dispatch])

  const tableRefresh = useCallback(() => {
    if (!isAttacksinProgress) {
      resetTable()
    }
  }, [resetTable, isAttacksinProgress])

  const isUserInteractionDisabledForTable: boolean = useMemo(() => {
    return isAttacksinProgress || spAttacks?.report?.totalCount === 0
  }, [isAttacksinProgress, spAttacks])

  const isUserInteractionDisabledForTableFilters: boolean = useMemo(() => {
    const { ALL_ATTACK_TYPE, FILTER_CONFIG } = spAttacksTable

    return (
      !spAttacks?.report?.totalCount &&
      !spAttacksTable.search.length &&
      isEqual(spAttacksTable.filter, FILTER_CONFIG?.[ALL_ATTACK_TYPE as any].filterQuery)
    )
  }, [spAttacks, spAttacksTable])

  const exportToCsvConfig: ExportToCsvButtonProps = useMemo(() => {
    return {
      getExportPath: sessionId => {
        return apiRoutes.EXPORT_ATTACKS_AS_CSV.path({
          accessTokenId,
          sessionId
        })
      },
      exportName: 'SP Attacks',
      analyticsParams: {
        accessTokenId
      },
      totalCount: spAttacks?.report?.totalCount || 0
    }
  }, [accessTokenId, spAttacks])

  const updateTableData = useCallback(
    (changes: any = {}) => {
      const { selectedTab, ...updatedValues } = changes

      dispatch(
        updateSpAttacksTable({
          ...updatedValues,
          skip: 0
        })
      )

      initTableRefresh(tableRefresh)
      resetTable()
    },
    [resetTable, dispatch, initTableRefresh, tableRefresh]
  )

  const [debouncedOnChange, validateSearchString] = useSearchFieldLogic(
    (search: string) => {
      updateTableData({ search: search.trim() })
      setState({ searchString: search.trim() })
    },
    isUserInteractionDisabledForTable,
    SEARCH_FIELD_ID
  )

  const searchFieldConfig: SearchFieldProps = useMemo(() => {
    return {
      id: SEARCH_FIELD_ID,
      value: state.searchString,
      onChange: (e: any) => {
        const validatedSearchString = validateSearchString(e.target.value)

        if (spAttacksTable.search !== validatedSearchString) {
          debouncedOnChange(validatedSearchString)
          setState({ searchString: validatedSearchString })
        }
      },
      disabled: isUserInteractionDisabledForTableFilters
    }
  }, [
    state.searchString,
    spAttacksTable,
    debouncedOnChange,
    validateSearchString,
    isUserInteractionDisabledForTableFilters
  ])

  const tableData: any = useMemo(() => {
    const { skip, take } = spAttacksTable

    // skip processing the report data if totalCount is 0 or DNE
    if (!spAttacks?.report?.totalCount) {
      return {
        data: [],
        total: 0
      }
    }

    const { data } = process(
      (spAttacks?.report?.data || []).map((report: any) => ({
        ...(report && {
          ...report,
          disableDetails: isBeforeDate({ initialDate: report.date, subtractedDate: maxRetention }),
          formattedDate: formatDateWithTime(report.date || ''),
          formatShortDate: formatDate(report.date || ''),
          markedAsFp: getFalsePositiveStatus(report.threatId),
          status: {
            inProgress: isListFeedbackInProgress || isAttacksinProgress,
            ...(!isListFeedbackInProgress && !isAttacksinProgress && { id: statusTypeLabelLogic.getStatusId(report) })
          }
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.displayName),
      total: spAttacks?.report?.totalCount
    }
  }, [
    spAttacksTable,
    spAttacks,
    getFalsePositiveStatus,
    maxRetention,
    isListFeedbackInProgress,
    isAttacksinProgress,
    statusTypeLabelLogic
  ])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = spAttacksTable

    return {
      pageable: {
        buttonCount: 10
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateSpAttacksTable(e.page))

        if (!spAttacksLoadedOffsets.includes(e.page.skip)) {
          dispatch(getAttacks())
        }
      }
    }
  }, [spAttacksTable, tableData, spAttacksLoadedOffsets, dispatch])

  const sortConfig: BDSGridSortConfig | {} = useMemo(() => {
    if (!tableData.total) {
      return {}
    }

    return {
      sortable: !isAttacksinProgress && {
        allowUnsort: false,
        mode: 'single'
      },
      sort: spAttacksTable.sort,
      onSortChange: (e: any) => {
        updateTableData({ sort: e.sort })
      }
    }
  }, [spAttacksTable, updateTableData, tableData.total, isAttacksinProgress])

  const spAttacksTableConfig: SpAttacksTableConfig = useMemo(() => {
    return {
      ref: spAttacksTableRef,
      isLoaded: !!spAttacks?.accessTokenId,
      inProgress: isAttacksinProgress,
      exportToCsvConfig,
      searchFieldConfig,
      tableData,
      pageConfig,
      sortConfig,
      columns: spAttacksTable.GRID_COLUMNS,
      columnsConfig: spAttacksTable.columnsConfig,
      highlightKeywords: [spAttacksTable.search]
    }
  }, [
    spAttacksTableRef,
    spAttacks,
    isAttacksinProgress,
    exportToCsvConfig,
    searchFieldConfig,
    tableData,
    pageConfig,
    sortConfig,
    spAttacksTable.GRID_COLUMNS,
    spAttacksTable.columnsConfig,
    spAttacksTable.search
  ])

  const attackSelectorConfig: AttackSelectorConfig = useMemo(() => {
    // eslint-disable-next-line quotes
    const dashboardMenu = document.querySelector("[data-menu='true']")
    const onCloseAttackSelector = () => {
      setState({ isAttackSelectorOpened: false })
      if (dashboardMenu) {
        dashboardMenu.removeEventListener('click', onCloseAttackSelector)
      }
    }

    return {
      menuItems: [
        {
          id: spAttacksTable.ALL_ATTACK_TYPE || '',
          isSelected: state.selectedAttackType === spAttacksTable.ALL_ATTACK_TYPE
        },
        ...Object.values(ATTACK_TYPES).map((attackType: string) => ({
          id: attackType,
          isSelected: state.selectedAttackType === attackType
        }))
      ],
      select: {
        open: state.isAttackSelectorOpened,
        onOpen: () => {
          setState({ isAttackSelectorOpened: true })

          if (dashboardMenu) {
            dashboardMenu.addEventListener('click', onCloseAttackSelector)
          }
        },
        onClose: onCloseAttackSelector,
        onChange: (e: any) => {
          if (state.selectedAttackType !== e.target.value) {
            setState({ selectedAttackType: e.target.value })
            updateTableData({ filter: spAttacksTable.FILTER_CONFIG?.[e.target.value]?.filterQuery })
          }
        },
        disabled: isUserInteractionDisabledForTableFilters || isAttacksinProgress,
        value: state.selectedAttackType
      }
    }
  }, [
    state.selectedAttackType,
    state.isAttackSelectorOpened,
    isUserInteractionDisabledForTableFilters,
    isAttacksinProgress,
    spAttacksTable,
    updateTableData
  ])

  const emailDetailsDialogActionCallbacks: UseEmailDetailsSpAttackInterfaceProps = useMemo(
    () => ({
      onClose: () => {
        dispatch(resetAttack())
      },
      onCloseReportFpDialog: isMarked => {
        if (isMarked) {
          dispatch(getListFeedback(threatIds))
        }
      }
    }),
    [dispatch, threatIds]
  )

  return useMemo(() => {
    return [
      accountName || '',
      realTimePhishingCard,
      accountTakeoverProtectionCard,
      domainsFraudProtectionCard,
      licensingInformationCard,
      confidenceLevel,
      spAttacksTableConfig,
      spAttackId,
      attackSelectorConfig,
      emailDetailsDialogActionCallbacks
    ]
  }, [
    accountName,
    realTimePhishingCard,
    accountTakeoverProtectionCard,
    domainsFraudProtectionCard,
    licensingInformationCard,
    confidenceLevel,
    spAttacksTableConfig,
    spAttackId,
    attackSelectorConfig,
    emailDetailsDialogActionCallbacks
  ])
}
