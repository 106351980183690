import { useMemo } from 'react'

import {
  IEmailLogApiClient,
  IEmailLogDeliverEmailRequest,
  IEmailLogDeliverEmailResponse,
  IEmailLogGetEmailDetailsRequest,
  IEmailLogGetEmailDetailsResponse,
  IEmailLogMoveToJunkRequest,
  IEmailLogMoveToJunkResponse,
  IEmailLogSearchParams,
  IEmailLogSearchResponse,
  IEmailLogTrackEventRequest,
  IEmailLogTrackEventResponse,
  IMixpanelConfig
} from '@barracuda-internal/converged-email-log'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { globalApiRoutes } from 'global/lib/api/apiRoutes'
import restClient from 'global/lib/api/restClient'
import { config } from 'global/lib/config'
import * as configurationLib from 'global/lib/configuration/configuration'
import { useEffectOnInit } from 'global/lib/useCustomEffect'
import userDataLib from 'global/lib/userData/userData'
import { useAppSelector } from 'global/redux/toolkit/hooks'

export interface ConvergedMessageLogLogic {
  emailLogApiClient: IEmailLogApiClient
  mixpanelConfig: IMixpanelConfig
}

export default function useConvergedMessageLogLogic(): ConvergedMessageLogLogic[] {
  const { user } = useAppSelector((_stores: any) => ({
    user: _stores.user.data
  }))

  // Send mixpanel tracking event on init
  useEffectOnInit(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.CONVERGED_EMAIL_PAGEVIEW, {
      url: window.location.href
    })
  }, [])

  const mixpanelConfig = useMemo((): IMixpanelConfig => {
    const product = configurationLib.getProductAbbr()

    return {
      distinctId: user.email,
      enabled: !config.isDev,
      product,
      profileProperties: {
        userId: user.id,
        email: user.email,
        name: user.displayName,
        phoneNumber: user.phone,
        country: user.country,
        state: user.state,
        zipCode: user.zip,
        productAbbr: product,
        productVersion: 'v2',
        impersonationMode: userDataLib.isImpersonationMode() || false
      }
    }
  }, [user])

  // EmailLogApiClient instance for ConvergedMessageLog feature
  const emailLogApiClient = useMemo(() => {
    const search = (abortController: AbortController) => async (
      params: IEmailLogSearchParams
    ): Promise<IEmailLogSearchResponse> => {
      const resp = await restClient(globalApiRoutes.GET_CONVERGED_MESSAGE_LOG, {
        data: params,
        signal: abortController.signal
      })

      return resp.data
    }

    const getEmailDetails = (abortController: AbortController) => async (
      request: IEmailLogGetEmailDetailsRequest
    ): Promise<IEmailLogGetEmailDetailsResponse> => {
      const res = await restClient(globalApiRoutes.GET_CONVERGED_MESSAGE_LOG_DETAILS, {
        data: request,
        signal: abortController.signal
      })

      return res.data
    }

    const deliverEmail = (abortController: AbortController) => async (
      request: IEmailLogDeliverEmailRequest
    ): Promise<IEmailLogDeliverEmailResponse> => {
      const res = await restClient(globalApiRoutes.DELIVER_CONVERGED_MESSAGE_LOG_MESSAGE, {
        data: request,
        signal: abortController.signal
      })

      return res.data
    }

    const moveToJunk = (abortController: AbortController) => async (
      request: IEmailLogMoveToJunkRequest
    ): Promise<IEmailLogMoveToJunkResponse> => {
      const res = await restClient(globalApiRoutes.MOVE_TO_JUNK_CONVERGED_MESSAGE_LOG_MESSAGE, {
        data: request,
        signal: abortController.signal
      })

      return res.data
    }

    const trackMixpanelEvent = async (request: IEmailLogTrackEventRequest): Promise<IEmailLogTrackEventResponse> => {
      const res = await restClient(globalApiRoutes.CONVERGED_MESSAGE_LOG_MIXPANEL_TRACK_EVENT, {
        data: request
      })
      return res.data
    }

    return {
      deliverEmail,
      moveToJunk,
      getEmailDetails,
      mixpanelConfig,
      search,
      trackMixpanelEvent
    }
  }, [mixpanelConfig])

  return useMemo(
    () => [
      {
        emailLogApiClient,
        mixpanelConfig
      }
    ],
    [emailLogApiClient, mixpanelConfig]
  )
}
