import React, { useMemo } from 'react'

import NavigationBar from 'global/components/lib/navigation/NavigationBar'

import useNavbarLogic from 'ets/components/lib/layout/useNavbarLogic'

const EtsNavbar: React.FC = () => {
  const [navbarLogic] = useNavbarLogic()

  const { logo, currentPath, onNavigate, routes, options } = navbarLogic

  return useMemo(
    () => (
      <NavigationBar logo={logo} currentPath={currentPath} routes={routes} options={options} onNavigate={onNavigate} />
    ),
    [currentPath, logo, onNavigate, options, routes]
  )
}

export default EtsNavbar
