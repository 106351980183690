import { useCallback, useEffect, useMemo, useReducer } from 'react'
import { isEqual } from 'lodash'

import { useErrorFormatMessage } from 'global/lib/localization'

import { isPending } from 'global/redux/toolkit/api'

import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import {
  createForensicsCustomNotification,
  deleteForensicsCustomNotification,
  displayCustomizeEmailAlertDialog,
  getForensicsNotificationPreview,
  getForensicsCustomNotification,
  resetForensicsNotificationForm,
  saveAccessTokenSettings,
  updateAccessTokenSettings
} from 'fir/redux/features/settings/settingsSlice'
import {
  CustomTemplate,
  CUSTOM_TEMPLATE_NAME,
  DEFAULT_NOTIFICATION_PREVIEW_CONTEXT,
  DEFAULT_TEMPLATE_NAME,
  ForensicsNotificationPreview
} from 'fir/redux/types/Settings'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseCustomizeEmailParams {}

export interface CustomizeEmailProps {
  errorMsg: string | undefined
  formIntroduction: string | null
  formSignature: string | null
  formSubject: string | null
  handleResetToDefault: () => void
  handleSaveSettings: () => void
  isNotificationPreviewLoading: boolean
  onClose: () => void
  onIntroChange: (e: any) => void
  onSignatureChange: (e: any) => void
  onSubjectChange: (e: any) => void
  previewBody: string
  previewSubject: string
}

export default function useCustomizeEmailNotificationLogic(): [CustomizeEmailProps] {
  const dispatch = useAppDispatch()
  const formatErrorMessage = useErrorFormatMessage()
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    introduction: '',
    showDemoAlert: false,
    signature: '',
    subject: ''
  })

  // Redux Toolkit stores
  const { accessTokenId, isFirDemoUser, settings } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    isFirDemoUser: _stores.user.isFirDemoUser,
    settings: _stores.settings
  }))

  // settings state
  const {
    editEmailErrorMessage,
    editEmailFormValues,
    forensicsSettings,
    isNotificationPreviewLoading,
    notificationPreview
  } = {
    editEmailErrorMessage: settings.errorMsg,
    editEmailFormValues: settings.editEmailFormValues,
    forensicsSettings: settings.forensics,
    isNotificationPreviewLoading: isPending(settings.getForensicsNotificationPreviewApiStatus),
    notificationPreview: settings.notificationPreview
  }

  const context = useMemo(
    () => ({
      emailDeleted: forensicsSettings.forensicsIncidentDeleteEmails,
      ...DEFAULT_NOTIFICATION_PREVIEW_CONTEXT
    }),
    [forensicsSettings.forensicsIncidentDeleteEmails]
  ) as ForensicsNotificationPreview

  // Init()
  useEffect(() => {
    if (forensicsSettings.forensicsEndUserNotificationTemplate !== DEFAULT_TEMPLATE_NAME) {
      dispatch(getForensicsCustomNotification({ accessTokenId, context }))
    } else {
      dispatch(getForensicsNotificationPreview({ accessTokenId, context }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Update Local state when typing in the form
  useEffect(() => {
    setState({
      introduction: editEmailFormValues.formIntro,
      signature: editEmailFormValues.formSignature,
      subject: editEmailFormValues.formSubject
    })
  }, [editEmailFormValues.formIntro, editEmailFormValues.formSignature, editEmailFormValues.formSubject])

  // Send request to preview changes when form input fields are updated
  useEffect(() => {
    dispatch(
      getForensicsNotificationPreview({
        accessTokenId,
        context: {
          ...context,
          customSubject: state.subject || null,
          introduction: state.introduction.replace(/(?:\r\n|\r|\n)/g, '<br>') || null,
          signature: state.signature.replace(/(?:\r\n|\r|\n)/g, '<br>') || null
        }
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state])

  const br2nl = (str: string) => {
    return str.replace(/<br\s*\/?>/gm, '\n')
  }

  const getErrorMessage = useMemo(() => {
    let errorMessage

    if (state.showDemoAlert) {
      errorMessage = formatErrorMessage('demo_error')
    } else if (editEmailErrorMessage) {
      errorMessage = formatErrorMessage('default')
    }

    return errorMessage
  }, [editEmailErrorMessage, formatErrorMessage, state.showDemoAlert])

  const handleResetToDefault = useCallback(() => {
    dispatch(resetForensicsNotificationForm())
    setState({
      introduction: editEmailFormValues.formIntro,
      subject: editEmailFormValues.formSubject,
      signature: editEmailFormValues.formSignature
    })
    dispatch(getForensicsNotificationPreview({ accessTokenId, context }))
  }, [dispatch, editEmailFormValues, accessTokenId, context])

  const handleSaveSettings = useCallback(() => {
    if (isFirDemoUser) {
      setState({ showDemoAlert: true })
      return
    }
    // convert intro & signature newlines to br tags
    const customTemplate: CustomTemplate = {
      introduction: state.introduction.replace(/(?:\r\n|\r|\n)/g, '<br>'),
      signature: state.signature.replace(/(?:\r\n|\r|\n)/g, '<br>'),
      subject: state.subject,
      name: CUSTOM_TEMPLATE_NAME
    }

    // delete empty template fields because remediation doesn't want empty strings
    Object.keys(customTemplate).forEach(key => {
      if (customTemplate[key] === '') {
        delete customTemplate[key]
      }
    })

    // If templateName is 'Custom' and template is empty delete existing custom template
    if (isEqual(customTemplate, { name: CUSTOM_TEMPLATE_NAME })) {
      // delete custom template
      dispatch(deleteForensicsCustomNotification({ accessTokenId, templateName: CUSTOM_TEMPLATE_NAME }))
      dispatch(
        saveAccessTokenSettings({
          accessTokenId,
          settings: { forensicsEndUserNotificationTemplate: DEFAULT_TEMPLATE_NAME }
        })
      )
      dispatch(updateAccessTokenSettings({ forensicsEndUserNotificationTemplate: DEFAULT_TEMPLATE_NAME }))
      dispatch(resetForensicsNotificationForm())
    } else {
      // save new custom template
      dispatch(createForensicsCustomNotification({ accessTokenId, template: customTemplate }))
      dispatch(
        saveAccessTokenSettings({
          accessTokenId,
          settings: { forensicsEndUserNotificationTemplate: CUSTOM_TEMPLATE_NAME }
        })
      )
      dispatch(updateAccessTokenSettings({ forensicsEndUserNotificationTemplate: CUSTOM_TEMPLATE_NAME }))
    }
  }, [accessTokenId, dispatch, isFirDemoUser, state.introduction, state.signature, state.subject])

  const onClose = useCallback(() => {
    dispatch(resetForensicsNotificationForm())
    dispatch(displayCustomizeEmailAlertDialog(false))
  }, [dispatch])

  const onIntroChange = (e: any) => {
    setState({ introduction: e.target.value })
  }

  const onSignatureChange = (e: any) => {
    setState({ signature: e.target.value })
  }

  const onSubjectChange = (e: any) => {
    setState({ subject: e.target.value })
  }

  return useMemo(
    () => [
      {
        errorMsg: getErrorMessage,
        formIntroduction: br2nl(state.introduction),
        formSignature: br2nl(state.signature),
        formSubject: state.subject,
        handleResetToDefault,
        handleSaveSettings,
        isNotificationPreviewLoading,
        onClose,
        onIntroChange,
        onSignatureChange,
        onSubjectChange,
        previewBody: notificationPreview.body,
        previewSubject: notificationPreview.subject
      }
    ],
    [
      getErrorMessage,
      handleResetToDefault,
      handleSaveSettings,
      isNotificationPreviewLoading,
      notificationPreview.body,
      notificationPreview.subject,
      onClose,
      state.introduction,
      state.signature,
      state.subject
    ]
  )
}
