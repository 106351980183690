import React, { useCallback, useMemo, useReducer, useEffect } from 'react'

import useUserDataLib from 'global/lib/userData/useUserData'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import {
  getScanStats,
  getScanThreatLog,
  resetScanStats,
  reset as resetScan
} from 'global/redux/features/scan/scanSlice'
import { isPending, isSuccess } from 'global/redux/toolkit/api'
import { ScanStats, ThreatLog } from 'global/types/api/scan'

import { ColumnsConfig } from 'ets/redux/types/dataTables'
import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'

export interface DashboardHeaderLogic {
  GRID_COLUMNS: {
    [key: string]: string
  }
  columnsConfig: ColumnsConfig
  isDeleteInProgress: boolean
  isNewScanDialogOpened: boolean
  isNewScanInProgress: boolean
  isTimeDurationDisabled: boolean
  onToggleNewScanDialog: () => void
  shouldRenderScanDetails: boolean
  threatLog: ThreatLog[]
}

export type State = {
  scannedId: string | undefined
  anchorOptionsMenu: React.SyntheticEvent['currentTarget'] | undefined
  lastSpAttackCount: number | undefined
}

const STATUS_REFRESH_INTERVAL = 10000
let checkScanStatus: number | undefined

export default function useDashboardHeaderLogic(): [DashboardHeaderLogic] {
  const dispatch = useAppDispatch()
  const { accessToken, isDeleteInProgress, isNewScanInProgress, scan, threatEmailsTable, userEmail } = useAppSelector(
    _stores => ({
      accessToken: _stores.accessToken?.accessToken,
      isDeleteInProgress: isPending(_stores.settings.deleteEtsReportApiStatus),
      isNewScanInProgress: isPending(_stores.settings.rerunCurrentScanApiStatus),
      scan: _stores.scan,
      threatEmailsTable: _stores.dataTables.threatEmails,
      userEmail: _stores.user.data.email || ''
    })
  )
  const [state, setState] = useReducer((_state: State, newState: Partial<State>) => ({ ..._state, ...newState }), {
    scannedId: undefined,
    anchorOptionsMenu: undefined,
    lastSpAttackCount: undefined
  })

  const [userDataLib] = useUserDataLib()
  const [isNewScanDialogOpened, toggleNewScanDialog] = useDialogLogic()
  // Remove to show progress again once the backend work is complete
  const isTimeDurationDisabled = true

  // init
  useEffect(() => {
    return () => {
      clearInterval(checkScanStatus)
      dispatch(resetScan())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // accessToken is Changed
  useEffect(() => {
    if (!!state.scannedId && state.scannedId !== accessToken?.id) {
      clearInterval(checkScanStatus)
      dispatch(getScanStats())
      dispatch(getScanThreatLog())
    }
  }, [state.scannedId, accessToken, dispatch, userDataLib])

  // scan-status check is completed
  useEffect(() => {
    if (isSuccess(scan.scanStatsApiStatus)) {
      if (scan.stats.spAttackCount !== state.lastSpAttackCount) {
        setState({ lastSpAttackCount: scan.stats.spAttackCount })

        if (state.lastSpAttackCount !== undefined) {
          dispatch(getScanThreatLog())
        }
      }

      if (state.scannedId !== accessToken?.id) {
        setState({ scannedId: accessToken?.id })
      }

      if (!scan.stats.inProgress) {
        clearInterval(checkScanStatus)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scan.scanStatsApiStatus])

  // refresh scan-status if necessary
  useEffect(() => {
    if (isSuccess(scan.scanStatsApiStatus) && scan.stats.inProgress) {
      clearInterval(checkScanStatus)

      if (scan.stats.inProgress) {
        dispatch(resetScanStats())

        checkScanStatus = window.setInterval(() => {
          dispatch(getScanStats())
        }, STATUS_REFRESH_INTERVAL)
      }
    }
  }, [scan.scanStatsApiStatus, scan.stats.inProgress, dispatch, accessToken?.id, userDataLib])

  const shouldRenderScanDetails = useMemo(() => {
    return scan.isInitialScanStatsLoaded
  }, [scan.isInitialScanStatsLoaded])

  const onToggleNewScanDialog = useCallback(() => {
    if (!isNewScanInProgress) {
      toggleNewScanDialog()
    }
  }, [isNewScanInProgress, toggleNewScanDialog])

  return useMemo(
    () => [
      {
        onToggleNewScanDialog,
        isNewScanDialogOpened,
        isDeleteInProgress,
        isNewScanInProgress,
        threatLog: scan.threatLog,
        shouldRenderScanDetails,
        GRID_COLUMNS: threatEmailsTable.GRID_COLUMNS,
        columnsConfig: threatEmailsTable.columnsConfig,
        isTimeDurationDisabled
      }
    ],
    [
      onToggleNewScanDialog,
      isNewScanDialogOpened,
      isDeleteInProgress,
      isNewScanInProgress,
      scan,
      shouldRenderScanDetails,
      threatEmailsTable,
      isTimeDurationDisabled
    ]
  )
}
