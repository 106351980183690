import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getUser } from 'global/redux/features/auth/authSlice'
import { User } from 'global/types/api/userType'

export interface UserState {
  data: User
  isSenDemoUser: boolean
  isUserDataLoaded: boolean
}

export const INITIAL_STATE: UserState = {
  data: {} as User,
  isSenDemoUser: false,
  isUserDataLoaded: false
}

/* eslint-disable no-param-reassign */
export const userSlice = createSlice({
  name: 'USER',
  initialState: INITIAL_STATE,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.data = action.payload || INITIAL_STATE.data
      state.isSenDemoUser = action.payload?.switches?.includes('SENTINEL_DEMO')
      state.isUserDataLoaded = true
    },
    reset: () => ({ ...INITIAL_STATE })
  },
  extraReducers: builder => {
    builder.addCase(getUser.rejected, state => {
      state.isUserDataLoaded = true
    })
  }
})
/* eslint-enable no-param-reassign */

export const { reset, setUser } = userSlice.actions

export default userSlice.reducer
